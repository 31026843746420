import * as React from "react";
import classnames from "classnames";

import "./style.scss";
const getVariantClasses = (variant?: string, active?: boolean) => {
    const defaultClasses = active
        ? "text-default" //bg-border
        : "text-default bg-light hover:bg-v-0";
    const primaryClasses = active
        ? "text-white bg-t-500"
        : "text-white bg-t-500 hover:bg-t-600";

    switch (variant) {
        case "default":
            return defaultClasses;
        case "primary":
            return primaryClasses;
        default:
            return "";
    }
};
const getWidthClasses = (width?: string) => {
    switch (width) {
        case "lg":
            return " w-[220px]";
            break;
        case "md":
            return " w-[160px]";
            break;
        case "sm":
            return "w-[60px]";
            break;
        case "xs":
            return "w-[40px]";
            break;
        default:
            return "";
    }
};
export default function Button(props: ButtonProps) {
    const {
        variant,
        active,
        className,
        style,
        width,
        height,
        padding,
        title,
        id,
        disabled,
        onClick,
        children,
        backgroundColor,
        textColor,
    } = props;

    const WidthClasses = getWidthClasses(width);
    const classNames = classnames(
        {
            "justify-around": !className?.some((simpleClass) =>
                simpleClass.includes("justify-"),
            ),
        },
        getVariantClasses(variant, active),
        WidthClasses,
        "Button",
        className,
    );
    const buttonStyles: React.CSSProperties = {
        ...style,
        width: WidthClasses !== "" ? undefined : width,
        height,
        padding,
        backgroundColor: disabled ? undefined : backgroundColor,
        color: disabled ? undefined : textColor,
    };
    return (
        <button
            className={classNames}
            title={title}
            id={id}
            style={buttonStyles}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
}

interface ButtonProps {
    children?: React.ReactNode;
    variant?: "default" | "primary" | "secondary";
    active?: boolean;
    title?: string;
    id?: string;
    width?: string;
    height?: string;
    padding?: string;
    backgroundColor?: string;
    textColor?: string;
    style?: React.CSSProperties;
    className?: string[];
    disabled?: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

Button.defaultProps = {
    children: "Button",
    variant: "default",
    className: ["bg-cyan-500", "rounded-md"],
    title: "",
    id: "id_btn",
    width: "90px",
    height: "30px",
    padding: null,
    backgroundColor: "blue",
    textColor: "white",
    // backgroundColor: "blue",
    // textColor: "white",
    onClick: () => {},
    disabled: false,
};
