import * as React from "react";
import classnames from "classnames";

import "./style.scss";

import { ReactComponent as PlusIcon } from "./plus.svg";

export default function NewTicket(props: NewTicketProps) {
    const {
        iconColor,
        text,
        handleAddNewTicket,
        textColor,
        textSize,
        textWeight,
        width,
    } = props;
    return (
        <div
            className='new-ticket-container'
            style={{ width }}
            onClick={handleAddNewTicket}
        >
            <div className='new-ticket-icon'>
                <PlusIcon stroke={iconColor} />
            </div>
            <div
                className='new-ticket-text'
                style={{
                    color: textColor,
                    fontSize: textSize,
                    fontWeight: textWeight,
                }}
            >
                {text}
            </div>
        </div>
    );
}

interface NewTicketProps {
    iconColor?: string;
    text?: string;
    textColor?: string;
    textSize?: string;
    textWeight?: string;
    width?: string;
    handleAddNewTicket?: React.MouseEventHandler<HTMLElement>;
}
NewTicket.defaultProps = {
    iconColor: "#4C5690",
    text: "Nouveau service",
    textColor: "#4C5690",
    textSize: "14px",
    textWeight: "bold",
    handleAddNewTicket: () => {
    },
};
