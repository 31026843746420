export const Search = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.2281 20.273L13.7128 15.758C10.1912 18.5335 5.11669 18.0836 2.13832 14.7319C-0.839926 11.3801 -0.689884 6.28801 2.48068 3.11749C5.65124 -0.053025 10.7436 -0.203274 14.0955 2.77479C17.4475 5.75285 17.8977 10.8272 15.1222 14.3488L19.6373 18.8648C19.9301 19.1071 20.0603 19.4944 19.9735 19.8644C19.8867 20.2345 19.5977 20.5234 19.2278 20.6102C18.8577 20.6971 18.4703 20.5668 18.2281 20.274V20.273ZM1.99085 9.10517C1.99085 11.7078 3.5498 14.0584 5.9484 15.0715C8.34688 16.0846 11.1189 15.5632 12.9855 13.7479C13.0052 13.7253 13.0251 13.7032 13.0451 13.6822C13.0652 13.6612 13.0881 13.6412 13.1101 13.6232C15.1474 11.5299 15.5317 8.33217 14.0484 5.81581C12.5651 3.29946 9.58106 2.0871 6.76288 2.85581C3.9447 3.62454 1.98975 6.18415 1.98962 9.10514L1.99085 9.10517Z"
      fill="#99B2C6"
    />
  </svg>
);
