import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LeaveBalanceState } from "./types";
import {
    getAllUserVacationBalanceHistoryService,
    getAllVacationBalanceHistoryService,
} from "./service";

const initialLeaveBalanceState: LeaveBalanceState = {
    loading: false,
    error: false,
    success: false,
    list: [],
    selectedUserLeaveBalanceHistory: {
        userID: null,
        history: null,
    },
};

const leaveBalanceState = createSlice({
    name: "leaveBalance",
    initialState: initialLeaveBalanceState,
    reducers: {
        resetAllLeaveBalance: (state) => {
            state = initialLeaveBalanceState;
        },
        setLeaveBalanceHistoryUserID: (state, action) => {
            if (action.payload.userID !== undefined) {
                state.selectedUserLeaveBalanceHistory.userID =
                    action.payload.userID;
            }
        },
    },
    extraReducers(builder) {
        //case :getAllVacationBalanceHistoryService
        builder.addCase(
            getAllVacationBalanceHistoryService.pending,
            (state: LeaveBalanceState) => {
                state.loading = true;
                state.success = false;
                state.error = false;
            }
        );
        builder.addCase(
            getAllVacationBalanceHistoryService.fulfilled,
            (state: LeaveBalanceState, action: PayloadAction<any>) => {
                state.loading = false;
                state.success = true;
                state.error = false;
                state.list = [];
                if (action.payload !== undefined) {
                    for (const leaveBalance of action.payload) {
                        let leaveBalances = [];
                        if (
                            leaveBalance.leaveBalances &&
                            leaveBalance.leaveBalances.length > 0
                        ) {
                            leaveBalances = leaveBalance.leaveBalances.map(
                                (leave: any) => {
                                    return {
                                        _id: leave._id,
                                        leaveRequest: leave.leaveRequest,
                                        leaveType: leave.leaveType,
                                        leaveCount: leave.leaveCount,
                                        leaveBalance: leave.leaveBalance,
                                        issueDate: leave.issueDate,
                                        approvedBy: leave.approvedBy,
                                    };
                                }
                            );
                        }
                        state.list.push({
                            _id: leaveBalance._id,
                            deleted: leaveBalance.deleted,
                            ruleLeaveEntitlement:
                                leaveBalance.ruleLeaveEntitlement,
                            leaveBalances: leaveBalances,
                        });
                    }
                }
            }
        );
        builder.addCase(
            getAllVacationBalanceHistoryService.rejected,
            (state: LeaveBalanceState) => {
                state.loading = false;
                state.success = false;
                state.error = true;
            }
        );
        //case :getAllUserVacationBalanceHistoryService
        builder.addCase(
            getAllUserVacationBalanceHistoryService.pending,
            (state: LeaveBalanceState) => {
                state.loading = true;
                state.success = false;
                state.error = false;
            }
        );
        builder.addCase(
            getAllUserVacationBalanceHistoryService.fulfilled,
            (state: LeaveBalanceState, action: PayloadAction<any>) => {
                state.loading = false;
                state.success = true;
                state.error = false;
                if (action.payload !== undefined) {
                    let historyBalance = [];
                    if (action.payload.length > 0) {
                        historyBalance = action.payload.map((leave: any) => {
                            return {
                                _id: leave._id,
                                leaveRequest: leave.leaveRequest,
                                leaveType: leave.leaveType,
                                leaveCount: leave.leaveCount,
                                leaveBalance: leave.leaveBalance,
                                issueDate: leave.issueDate,
                                approvedBy: leave.approvedBy,
                            };
                        });
                    }
                    state.selectedUserLeaveBalanceHistory.history =
                        historyBalance;
                }
            }
        );
        builder.addCase(
            getAllUserVacationBalanceHistoryService.rejected,
            (state: LeaveBalanceState) => {
                state.loading = false;
                state.success = false;
                state.error = true;
            }
        );
    },
});

export const { setLeaveBalanceHistoryUserID, resetAllLeaveBalance } =
    leaveBalanceState.actions;

export default leaveBalanceState.reducer;
