import React from "react";
import TableUserRole from "../../../../components/atoms/TableUserRole";

export const RoleColumnFormatter = (content: any, rowIdx: number, row: any) => {
  return (
    <TableUserRole
      role={
        content == "Administrator"
          ? "Administrator"
          : content == "Employee"
          ? "Employee"
          : "Manager"
      }
      text={"n-600"}
    />
  );
};
