export const Camera = ({
  width = 29,
  height = 29,
  color = "currentColor",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.31027 25.9566H20.385C23.565 25.9566 24.8324 24.0094 24.9822 21.6359L25.5814 12.119C25.7427 9.63026 23.7609 7.52178 21.2607 7.52178C20.5579 7.52178 19.9127 7.11852 19.59 6.49635L18.7605 4.82569C18.2305 3.77722 16.8479 2.91309 15.6727 2.91309H13.0342C11.8474 2.91309 10.4648 3.77722 9.93483 4.82569L9.10527 6.49635C8.78266 7.11852 8.13744 7.52178 7.43461 7.52178C4.9344 7.52178 2.95266 9.63026 3.11396 12.119L3.71309 21.6359C3.85135 24.0094 5.13027 25.9566 8.31027 25.9566Z"
      fill="white"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.6194 9.82617H16.0759"
      stroke={color}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.3477 21.348C16.41 21.348 18.0922 19.6658 18.0922 17.6035C18.0922 15.5411 16.41 13.8589 14.3477 13.8589C12.2853 13.8589 10.6031 15.5411 10.6031 17.6035C10.6031 19.6658 12.2853 21.348 14.3477 21.348Z"
      stroke={color}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}
