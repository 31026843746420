export const BorderArrowRight = ({
  width = 16,
  height = 16,
  color = "#FB3640",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.20601 14.6665L10.7927 14.6665C13.2193 14.6665 14.666 13.2198 14.666 10.7932L14.666 5.21317C14.666 2.77984 13.2194 1.33317 10.7927 1.33317L5.21268 1.33317C2.78602 1.33317 1.33935 2.77984 1.33935 5.2065L1.33935 10.7932C1.33268 13.2198 2.77935 14.6665 5.20601 14.6665ZM3.99935 7.49984L10.7927 7.49984L8.78602 5.49317C8.59268 5.29984 8.59268 4.97984 8.78602 4.7865C8.88602 4.6865 9.01268 4.63984 9.13935 4.63984C9.26602 4.63984 9.39268 4.6865 9.49268 4.7865L12.3527 7.6465C12.446 7.73984 12.4993 7.8665 12.4993 7.99984C12.4993 8.13317 12.446 8.25984 12.3527 8.35317L9.49268 11.2132C9.29935 11.4065 8.97935 11.4065 8.78602 11.2132C8.59268 11.0198 8.59268 10.6998 8.78602 10.5065L10.7927 8.49984L3.99935 8.49984C3.72602 8.49984 3.49935 8.27317 3.49935 7.99984C3.49935 7.7265 3.72602 7.49984 3.99935 7.49984Z"
      fill={color}
    />
  </svg>
);

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}
