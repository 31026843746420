export const MinimizedLogo = () => (
  <svg
    width="125"
    height="70"
    viewBox="0 0 125 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        d="M104.116 12.0483C116.012 15.7483 124.642 26.887 124.642 39.9731C124.642 56.0971 111.541 69.2221 95.4462 69.2221C82.8504 69.2221 71.1099 62.0559 67.028 50.8393L75.9305 43.8678C77.9132 51.9687 86.738 58.5507 95.4074 58.5507C105.632 58.5507 113.951 50.2161 113.951 39.9731C113.951 29.7302 105.632 21.3956 95.4074 21.3956L104.116 12.0483Z"
        fill="url(#paint0_linear_363_3100)"
      />
      <path
        d="M58.1245 50.3704C54.0814 61.626 42.613 69.2596 29.9783 69.2596C13.8837 69.2596 0.782471 56.1345 0.782471 39.9717C0.782471 23.8477 13.8837 10.7227 29.9783 10.7227C37.598 10.7227 44.5179 13.6437 49.7273 18.4341L42.5352 26.3013C39.2308 23.2635 34.8378 21.3941 30.0172 21.3941C19.7928 21.3941 11.4734 29.7287 11.4734 39.9717C11.4734 50.2146 19.7539 58.5492 29.9783 58.5492C38.8031 58.5492 47.8612 50.8767 49.7273 42.62"
        fill="url(#paint1_linear_363_3100)"
      />
      <path
        d="M53.2266 21.9016L45.6846 30.0415L62.5568 48.1517L108.78 0.130371H92.3746L62.5568 31.4046L53.2266 21.9016Z"
        fill="url(#paint2_linear_363_3100)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_363_3100"
        x1="2.55274"
        y1="40.6461"
        x2="123.486"
        y2="40.6461"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3598B8" />
        <stop offset="1" stop-color="#57569C" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_363_3100"
        x1="2.55179"
        y1="39.986"
        x2="123.485"
        y2="39.986"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3598B8" />
        <stop offset="1" stop-color="#57569C" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_363_3100"
        x1="2.55227"
        y1="24.1345"
        x2="123.485"
        y2="24.1345"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3598B8" />
        <stop offset="1" stop-color="#57569C" />
      </linearGradient>
    </defs>
  </svg>
);
