import classnames from "classnames";
import * as React from "react";

import "./style.scss";
export default function Checkbox(props: CheckboxProps) {
    const {
        id,
        text,
        textSize,
        textColor,
        title,
        className,
        style,
        disabled,
        checked,
        onClick,
        rounded,
        forceChange,
    } = props;
    const [isChecked, setIsChecked] = React.useState(checked);
    React.useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    if (!id) {
        return null;
    }
    const divClassNames = classnames(
        "checkbox-container",
        className?.divClassname,
    );
    const labelClassNames = classnames(
        className?.labelClassname,
        rounded ? "rounded" : "",
    );
    const handleCheckboxClick = (e: any) => {
        if (!forceChange) setIsChecked(!isChecked);
        onClick && onClick(e);
    };
    return (
        <div className={divClassNames} style={style?.divStyle} title={title}>
            <input
                type="checkbox"
                disabled={disabled}
                checked={isChecked}
                id={id}
                title={title}
                onClick={handleCheckboxClick}
            />
            <label
                className={labelClassNames}
                htmlFor={id}
                style={{
                    ...style?.labelStyle,
                    // fontSize: textSize,
                    color: disabled ? "#aaa" : textColor,
                    cursor: "pointer",
                }}
                title={title}
            >
                <div className="checkbox-icon" style={style?.iconStyle}></div>
                {text}
            </label>
        </div>
    );
}

interface CheckboxProps {
    id: string;
    text?: string | null;
    textSize?: string;
    textColor?: string;
    title?: string;
    disabled?: boolean;
    checked?: boolean;
    className?: {
        divClassname?: string[];
        labelClassname?: string[];
    };
    style?: {
        divStyle?: React.CSSProperties;
        labelStyle?: React.CSSProperties;
        iconStyle?: React.CSSProperties;
    };
    onClick: React.MouseEventHandler<HTMLElement>;
    rounded?: boolean;
    iconStyle?: React.CSSProperties;
    forceChange?: boolean;
}

Checkbox.defaultProps = {
    rounded: false,
    id: "1",
    text: "",
    textSize: "17px",
    textColor: "var(--t-600)",
    title: "",
    disabled: false,
    checked: false,
    className: ["string"],
    style: {
        labelStyle: { textDecoration: "none" },
    },
    onClick: (data: any) => {},
    iconStyle: {},
    forceChange: false,
};
