import React from "react";
import "./style.scss";
import moment from "moment";
import CellEmpty from "../../molecules/CellEmpty";
import PlanningDayCell from "../../molecules/PlanningDayCell";
import { usePlanning } from "../../../hooks/usePlanning";
import AttendanceCard from "../../organism/AttendanceCard";
export default function RenderDayCell(props: any) {
    const memoizedProps = React.useMemo(() => props, [props]);

    const {
        day,
        plansData,
        defaultEmployee,
        department,
        published,
        numberOfHourCellsInDay,
        startCellHour,
        selectedDay,
        dayOrder,
    } = memoizedProps;
    const { copyShift, planningStartOfWeek } = usePlanning();
    const [shiftDays, setShiftDays] = React.useState([
        {
            name: "Lun",
            index: 1,
            checked: false,
            day: moment(planningStartOfWeek).add(0, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Mar",
            index: 2,
            checked: false,
            day: moment(planningStartOfWeek).add(1, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Mer",
            index: 3,
            checked: false,
            day: moment(planningStartOfWeek).add(2, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Jeu",
            index: 4,
            checked: false,
            day: moment(planningStartOfWeek).add(3, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Ven",
            index: 5,
            checked: false,
            day: moment(planningStartOfWeek).add(4, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Sam",
            index: 6,
            checked: false,
            day: moment(planningStartOfWeek).add(5, "day").format("YYYY-MM-DD"),
        },
        {
            name: "Dim",
            index: 7,
            checked: false,
            day: moment(planningStartOfWeek).add(6, "day").format("YYYY-MM-DD"),
        },
    ]);

    const shiftData = plansData?.find((plan: any) => plan.day === selectedDay);
    const [isDraggingOver, setIsDraggingOver] = React.useState(false);
    const [isDraggingOverCopy, setIsDraggingOverCopy] = React.useState(false);
    const [isDraggingOverMove, setIsDraggingOverMove] = React.useState(false);
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDraggingOver(true); // Set dragging over state
    };
    const handleDragLeave = () => {
        setIsDraggingOver(false); // Reset dragging over state
    };
    const handleDragOverCopy = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDraggingOverCopy(true); // Set dragging over state
    };
    const handleDragLeaveCopy = () => {
        setIsDraggingOverCopy(false); // Reset dragging over state
    };
    const handleDragOverMove = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDraggingOverMove(true); // Set dragging over state
    };
    const handleDragLeaveMove = () => {
        setIsDraggingOverMove(false); // Reset dragging over state
    };
    const handleDropCopy = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const shiftData = JSON.parse(event.dataTransfer.getData("text/plain"));
        const { shift, type } = shiftData;
        if (shift && type) {
            copyShift(
                {
                    shiftId: shift._id,
                    employeeId: defaultEmployee?._id,
                    day,
                    department: department?._id || null,
                },
                "copy",
            );
        }
        setIsDraggingOverCopy(false);
        setIsDraggingOver(false);
    };
    const handleDropMove = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const shiftData = JSON.parse(event.dataTransfer.getData("text/plain"));
        const { shift, type } = shiftData;
        if (shift && type) {
            copyShift(
                {
                    shiftId: shift._id,
                    employeeId: defaultEmployee?._id,
                    day,
                    department: department._id || undefined,
                },
                "move",
            );
        }
        setIsDraggingOverMove(false);
        setIsDraggingOver(false);
    };
    const { setDraggedShiftType, draggedShiftType, employeesData } =
        usePlanning();
    const handleDragStart = (
        event: React.DragEvent<HTMLDivElement>,
        data: any,
    ) => {
        // Set the shift data to be transferred during drag
        setDraggedShiftType("normal");
        event.dataTransfer.setData(
            "text/plain",
            JSON.stringify({ type: "normal", shift: data }),
        );
    };
    const [isClicked, setIsClicked] = React.useState(false);
    const [startTime, setStartTime] = React.useState("");
    return (
        <th colSpan={7} style={{ height: "inherit" }}>
            <div
                className="cell-day-parent"
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                style={{
                    position: "relative",
                    height: "100%",
                }}
            >
                {isDraggingOver ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            gap: "10px",
                            padding: "10px",
                            position: "absolute",
                            height: "100%",
                        }}
                    >
                        <div
                            onDragOver={handleDragOverCopy}
                            onDragLeave={handleDragLeaveCopy}
                            onDrop={handleDropCopy}
                            style={{
                                padding: "10px",
                                borderRadius: "5px",
                                border: "1px dashed #6f78ab",
                                width:
                                    draggedShiftType === "normal"
                                        ? "50%"
                                        : "100%",
                                height: "100%",
                                marginBottom: "2px",
                                color: isDraggingOverCopy ? "white" : "#6f78ab",
                                backgroundColor: isDraggingOverCopy
                                    ? "#2a8bab"
                                    : "",
                            }}
                        >
                            Copier
                        </div>
                        {draggedShiftType === "normal" && (
                            <div
                                onDragOver={handleDragOverMove}
                                onDragLeave={handleDragLeaveMove}
                                onDrop={handleDropMove}
                                style={{
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px dashed #6f78ab",
                                    width: "50%",
                                    height: "100%",
                                    color: isDraggingOverMove
                                        ? "white"
                                        : "#6f78ab",
                                    backgroundColor: isDraggingOverMove
                                        ? "#2a8bab"
                                        : "",
                                }}
                            >
                                Déplacer
                            </div>
                        )}
                    </div>
                ) : (
                    shiftData?.workShares?.length > 0 && (
                        <>
                            {shiftData?.workShares?.map((data: any) => {
                                return (
                                    <div
                                        draggable
                                        onDragStart={(e) =>
                                            handleDragStart(e, data)
                                        }
                                        style={{
                                            position: "absolute",
                                            top: "3px",
                                            left:
                                                data.type === 0
                                                    ? "0"
                                                    : `calc(calc(calc(100% / ${
                                                          4 *
                                                          numberOfHourCellsInDay
                                                      }) * ${
                                                          (data?.from -
                                                              startCellHour *
                                                                  60 +
                                                              30) /
                                                          15
                                                      }) - 1px)`,
                                            width:
                                                data.type === 0
                                                    ? "100%"
                                                    : `calc(calc(calc(100% / ${
                                                          4 *
                                                          numberOfHourCellsInDay
                                                      }) * ${
                                                          data?.to > data?.from
                                                              ? (data?.to -
                                                                    data?.from) /
                                                                15
                                                              : (data?.to +
                                                                    1440 -
                                                                    data?.from) /
                                                                15
                                                      }) - 0px)`,
                                            height: "calc(100% - 6px)",
                                            // opacity: "0.2",
                                        }}
                                    >
                                        <PlanningDayCell
                                            data={data}
                                            planningEmployees={Object.values(
                                                employeesData,
                                            )}
                                            published={published}
                                            // disponibility={
                                            //     planning.disponibility[defaultEmployee._id][
                                            //         i
                                            //     ]
                                            // }
                                            defaultEmployee={defaultEmployee}
                                            department={department}
                                        />
                                    </div>
                                );
                            })}
                        </>
                    )
                )}
                {Array(numberOfHourCellsInDay * 2)
                    .fill(0)
                    .map((item, index) => (
                        <div
                            className="cell-day-child"
                            style={{
                                width: `calc(100% / ${
                                    numberOfHourCellsInDay * 2
                                })`,
                                borderRight:
                                    index % 2 === 0 &&
                                    index !== numberOfHourCellsInDay * 2 - 1
                                        ? "1px solid #c7cbe2ac"
                                        : index !==
                                            numberOfHourCellsInDay * 2 - 1
                                          ? "1px solid #edeff5"
                                          : "none",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "8px",
                                }}
                            ></span>
                            <CellEmpty
                                setIsClicked={setIsClicked}
                                published={published}
                                isWeek={false}
                                time={(startCellHour + index / 2) * 60 - 30}
                                setStartTime={setStartTime}
                            />
                        </div>
                    ))}
            </div>
            {isClicked && (
                <AttendanceCard
                    display={isClicked}
                    onClose={() => setIsClicked(false)}
                    defaultEmployee={defaultEmployee}
                    day={day}
                    dayOrder={dayOrder}
                    startTime={startTime}
                    department={department}
                />
            )}
        </th>
    );
}
