import React from "react";
import TabNavigator from "../../molecules/TabNavigator";
import TabContent from "../../atoms/TabContent";
import Heading from "../../atoms/Heading/Heading";
import Button from "../../atoms/ZButton";
import EmployeesFilter from "../EmployessFilter";
import TextData from "../../atoms/TextData";
import Pagination, { PaginationInterface } from "../../molecules/Pagination";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import * as Container from "../../atoms/Container";
import { EmployeesFilterProps, SortByProps } from "../../../hooks/useEmployees";
import { useLanguage } from "../../../hooks/useLanguage";
import { useBranch } from "../../../hooks/useBranch";

import * as Icons from "../../../Icons";
import PickEstablishement from "../../molecules/PickEstablishement";
import { ReactComponent as FullScreenSVG } from "./fullscreen.svg";
import { ReactComponent as ExitFullScreenSVG } from "./exitfullscreen.svg";
import { position } from "html2canvas/dist/types/css/property-descriptors/position";
import { truncateSync } from "fs";

export default function Employes({
    openRecordPage,
    openAddEmployeePage,
    FirstTabCompoenent,
    SecondTabCompoenent,
    employeesCount,
    archivedCount,
    paginationProps,
    onUpdateFilterBy,
    filterBy,
    selectedSite,
    sortBy,
    onUpdateSortBy,
    selectedBranch,
}: EmployeesInterface) {
    const intl = useIntl();
    const [activeTab, setActiveTab] = React.useState(0);
    const { branchSettings } = useBranch();
    const changeTab = (index: number) => {
        if (index !== activeTab) {
            setActiveTab(index);
        }
    };

    const paginationDivUsers = (
        <div
            className={`flex items-center gap-2 text-lg font-bold group-hover:text-t-600 ${
                activeTab == 0 ? "text-t-600" : "text-t-300"
            }`}
        >
            <Icons.UserGroup width={18} height={18} />
            <span>{intl.formatMessage({ id: "MENU.ACTIFS" })}</span>
            <span
                className={`rounded font-light text-base p-1 group-hover:text-t-700 group-hover:bg-n-0 ${
                    activeTab == 0 ? "text-t-700 bg-n-0" : "text-white bg-t-200"
                }`}
            >
                {employeesCount}
            </span>
        </div>
    );

    const paginationDivArchived = (
        <div
            className={`flex items-center gap-2 text-lg font-bold   group-hover:text-t-600  ${
                activeTab == 1 ? "text-t-600" : "text-t-300"
            }`}
        >
            <Icons.Archive width={18} height={18} />
            <span>{intl.formatMessage({ id: "MENU.ARCHIVED" })}</span>
            <span
                className={`rounded font-light text-base p-1 group-hover:text-t-700 group-hover:bg-n-0 ${
                    activeTab == 1 ? "text-t-700 bg-n-0" : "text-white bg-t-200"
                }`}
            >
                {archivedCount}
            </span>
        </div>
    );

    const tabs = [
        {
            title: intl.formatMessage({ id: "MENU.ACTIFS" }),
            data: employeesCount,
            formatter: () => paginationDivUsers,
        },
        {
            title: intl.formatMessage({ id: "MENU.ARCHIVED" }),
            data: archivedCount,
            formatter: () => paginationDivArchived,
        },
    ];
    const { switchFullScreen, isFullScreen } = useLanguage();

    const toggleFullScreen = () => {
        switchFullScreen();
    };

    return (
        <div
            // className="w-full"
            style={{
                padding: isFullScreen ? "20px" : "",
                // overflow: "hidden",
            }}
        >
            <Helmet>
                <title>
                    {intl.formatMessage({ id: "APP.TITLE" })} |{" "}
                    {intl.formatMessage({ id: "EMPLOYEES.LIST.TITLE" })}
                </title>
            </Helmet>
            <Container.Header>
                <div
                    style={{
                        display: "flex",
                        width: "auto",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "80px",
                        paddingLeft: "20px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "20px",
                        }}
                    >
                        <TextData color="#11106E" size="34" fontWeight="bold">
                            {intl.formatMessage({ id: "MENU.EMPLOYEES" })}
                        </TextData>
                        <button
                            className="fullscreen-btn"
                            onClick={toggleFullScreen}
                        >
                            <div className="fullscreen-icon">
                                {isFullScreen ? (
                                    <ExitFullScreenSVG
                                        height={18}
                                        width={18}
                                        color="white"
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    />
                                ) : (
                                    <FullScreenSVG
                                        height={18}
                                        width={18}
                                        color="white"
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    />
                                )}
                            </div>
                            <div className="fullscreen-title">
                                <div>
                                    {!isFullScreen
                                        ? "Mode plein écran"
                                        : "Quitter le mode plein écran"}
                                </div>
                            </div>
                        </button>
                    </div>

                    {/* <div
            style={{
              height: "fit-content",
              alignSelf: "flex-start",
            }}
          >
            <Heading
              variant="v-700"
              title={intl.formatMessage({ id: "MENU.EMPLOYEES" })}
              importance="h1"
            />
          </div> */}
                    <div className="flex flex-wrap gap-4">
                        {branchSettings?.register && selectedBranch && (
                            <Button
                                onClick={() => {
                                    openRecordPage();
                                }}
                                title={intl.formatMessage({
                                    id: "MENU.EMPLOYEES.REGISTER",
                                })}
                                leftComponent={<Icons.AdressBook />}
                                style={{
                                    fontSize: "16px",
                                    width: "auto",
                                    padding: "0.75rem 20px",
                                    height: "auto",
                                }}
                            />
                        )}
                        {/* {selectedBranch && ( */}
                        <Button
                            onClick={() => openAddEmployeePage()}
                            variant={"primary"}
                            title={intl.formatMessage({
                                id: "MENU.EMPLOYEES.ADD_EMPLOYEE",
                            })}
                            leftComponent={<Icons.AddSquare />}
                            size={"lg"}
                            style={{
                                fontSize: "16px",
                                width: "auto",
                                padding: "0.75rem 20px",
                                height: "auto",
                            }}
                        />
                        {/* )} */}
                    </div>
                </div>
            </Container.Header>
            <Container.Body>
                {true ? (
                    <>
                        <TabNavigator
                            containerClasses="!bg-n-0-op-2"
                            // headerContainerClasses="mx-4"
                            headerClasses="w-[158px] flex justify-center group"
                            tabs={tabs.map((tab, index) => ({
                                ...tab,
                                active: index == activeTab,
                                onClick: (idx) => changeTab(idx),
                            }))}
                            rightComponent={
                                <EmployeesFilter
                                    filterBy={filterBy}
                                    onUpdateFilterBy={onUpdateFilterBy}
                                    sortBy={sortBy}
                                    onUpdateSortBy={onUpdateSortBy}
                                />
                            }
                            style={{
                                position: "relative",
                            }}
                            tabsStyle={{
                                position: "sticky",
                                top: "0",
                                backgroundColor: "#fbfcfd",
                                zIndex: "99",
                                boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <TabContent
                                containerClasses=""
                                value={activeTab}
                                index={0}
                                component={FirstTabCompoenent}
                                containerCss={
                                    {
                                        // overflowX: "auto",
                                    }
                                }
                            />
                            <TabContent
                                containerClasses=""
                                value={activeTab}
                                index={1}
                                component={SecondTabCompoenent}
                                containerCss={
                                    {
                                        // overflowX: "auto",
                                    }
                                }
                            />
                        </TabNavigator>

                        <Container.Footer>
                            <div className="mt-5 mb-5 flex justify-center">
                                <Pagination
                                    paginationProps={{
                                        onPageChange:
                                            paginationProps.paginationProps
                                                .onPageChange,
                                        page: paginationProps.paginationProps
                                            .page,
                                        sizePerPage:
                                            paginationProps.paginationProps
                                                .sizePerPage,
                                        totalSize:
                                            activeTab === 0
                                                ? employeesCount
                                                : archivedCount,
                                    }}
                                />
                            </div>
                        </Container.Footer>
                    </>
                ) : (
                    <div className="py-8 text-n-500">
                        <PickEstablishement
                            Icon={<Icons.UserGroup />}
                            title={intl.formatMessage({ id: "MENU.EMPLOYEES" })}
                            desc={intl.formatMessage({
                                id: "EMPLOYEES.PLEASE_SELECT_BRANCH",
                            })}
                        />
                    </div>
                )}
            </Container.Body>
        </div>
    );
}

interface EmployeesInterface {
    employeesCount: number;
    archivedCount: number;
    openRecordPage: () => void;
    openAddEmployeePage: () => void;
    FirstTabCompoenent: React.ReactNode;
    SecondTabCompoenent: React.ReactNode;
    paginationProps: PaginationInterface;
    onUpdateFilterBy: (props: EmployeesFilterProps) => void;
    filterBy: EmployeesFilterProps;
    selectedSite: any;
    sortBy: SortByProps;
    onUpdateSortBy: (props: SortByProps) => void;
    selectedBranch?: string;
}
