import React from "react";
import TextData from "../../atoms/TextData";
import ColoredCircle from "../../atoms/ColoredCircle";
import "./style.css";
export default function TimingComponent(props: any) {
    const { startTime, endTime, hasPause, size } = props;
    return (
        <div className="timing-container">
            <TextData
                variant="label"
                width={"fit-content"}
                size={size === "small" ? "11px" : "14px"}
                color="#4C5690"
                style={{ textAlign: "center" }}
            >
                {startTime}
            </TextData>

            <div className="timing-decorator">
                <ColoredCircle
                    color="#2A8BAB"
                    size={size === "small" ? 3 : 4}
                    outlineSize={size === "small" ? 3 : 4}
                    outlineColor="#A6D3E2"
                    isChecked={true}
                />
                <TextData
                    width="auto"
                    variant="label"
                    style={{
                        whiteSpace: "nowrap",
                    }}
                    color="#2A8BAB"
                >
                    {hasPause ? <pre>---</pre> : <pre>----</pre>}
                </TextData>
                <TextData
                    width="auto"
                    variant="label"
                    size={size === "small" ? "11px" : "14px"}
                    color="#8E96C1"
                >
                    {hasPause ? ` ( ${hasPause}min ) ` : <pre> | </pre>}
                </TextData>
                <TextData
                    width="auto"
                    variant="label"
                    style={{
                        whiteSpace: "nowrap",
                    }}
                    color="#2D2AAD"
                >
                    {hasPause ? <pre>---</pre> : <pre>----</pre>}
                </TextData>
                <ColoredCircle
                    color="#2D2AAD"
                    size={size === "small" ? 3 : 4}
                    outlineSize={size === "small" ? 3 : 4}
                    outlineColor="#A8A7E4"
                    isChecked={true}
                />
            </div>

            <TextData
                variant="label"
                width={"fit-content"}
                size={size === "small" ? "11px" : "14px"}
                color="#4C5690"
                style={{ textAlign: "center" }}
            >
                {endTime}
            </TextData>
        </div>
    );
}
