/** @type {import('tailwindcss').Config} */

module.exports = {
  content: [
    "./src/**/*.{js,jsx,ts,tsx}",
  ],
  theme: {
    extend: {
      screens: {
        '3xl': '1600px',
        '4xl': '1840px',
        '5xl': '1960px',
      },
      colors: {
        "primary": "#646BD9",
        "secondary": "#726DA8",
        "success": "#4FAB2F",
        "success-secondary": "#4FB68E",
        "success-light": "#D5FFC7",
        "warning": "#E26D00",
        "warning-op-2": "rgba(226,109,0, 0.2)",
        "warn-light": "#FFF3C8",
        "error": "#FB3640",
        "error-hover": "#DE040F",
        "error-secondary": "#F56862",
        "error-op-1": "rgba(251, 54, 64, 0.1)",
        "error-op-2": "rgba(251, 54, 64, 0.2)",
        "error-light": "#FFC8C8",
        "info": "#418AEC",
        "info-light": "#C8CBFF",
        "testing": "#961a1a",
        "t-0": "#CFE0E6",
        "t-0-op-2": "rgba(207, 224, 230, 0.2)",
        "t-0-op-1": "rgba(207, 224, 230, 0.1)",
        "t-100": "#A6D3E2",
        "t-100-op-2": "rgba(166, 211, 226, 0.2)",
        "t-100-op-5": "rgba(166, 211, 226, 0.5)",
        "t-200": "#7EC3DB",
        "t-300": "#5BB3D0",
        "t-400": "#3FA0C1",
        "t-500": "#2A8BAB",
        "t-500-op-1": "rgba(42, 139, 171, 0.1)",
        "t-500-op-2": "rgba(42, 139, 171, 0.2)",
        "t-500-op-3": "rgba(42, 139, 171, 0.3)",
        "t-500-op-5": "rgba(42, 139, 171, 0.5)",
        "t-600": "#1A7290",
        "t-700": "#0F556D",
        "t-800": "#083544",
        "t-900": "#03141A",
        "t-900-op-2": "rgba(3,20,26, 0.2)",
        "v-0": "#D1D1E8",
        "v-0-op-2": "rgba(209, 209, 232, 0.2)",
        "v-0-op-5": "rgba(209, 209, 232, 0.5)",
        "v-100": "#A8A7E4",
        "v-100-op-2": "rgba(168, 167, 228, 0.2)",
        "v-100-op-5": "rgba(168, 167, 228, 0.5)",
        "v-200": "#817FDD",
        "v-300": "#5E5CD2",
        "v-400": "#4240C3",
        "v-500": "#2D2AAD",
        "v-600": "#1D1B91",
        "v-700": "#11106E",
        "v-800": "#090844",
        "v-900": "#03031A",
        "g-100": "#D1D5DB",
        'n-0': "#EDEFF5",
        'n-0-hover': "#d5d7dc",
        "n-0-op-2": "rgba(237, 239, 245, 0.2)",
        "n-0-op-3": "rgba(237, 239, 245, 0.3)",
        "n-0-op-5": "rgba(237, 239, 245, 0.5)",
        "n-100": "#C7CBE2",
        "n-200": "#BABFDB",
        "n-300": "#A7ADD0",
        "n-400": "#8E96C1",
        "n-500": "#6F78AB",
        "n-600": "#4C5690",
        "n-700": "#2A346D",
        "n-800": "#101844",
        'default': '#4C5690',
        'default-active': '#2A8BAB',
        'border': '#F7F8FA',
        "light-border": "#F9FAFC",
        "employee": "#00A42E",
        "employee-op-05": "rgba(0, 164, 46, 0.05)",
        "employee-op-2": "rgba(0, 164, 46, 0.2)",
        "manager": "#6628F1",
        "manager-op-05": "rgba(102, 40, 241, 0.05)",
        "manager-op-2": "rgba(102, 40, 241, 0.2)",
        "admin": "#DA2604",
        "admin-op-2": "rgba(218, 38, 4, 0.2)",
        "admin-op-05": "rgba(218, 38, 4, 0.05)",
        "custom-v-1": "#8309E3",
        "custom-v-1-op-1": "rgba(131, 9, 227, 0.1)",
        "custom-v-1-op-2": "rgba(131, 9, 227, 0.2)",
        "custom-v-2": "#E38609",
        "custom-v-2-op-1": "rgba(227, 134, 9, 0.1)",
        "custom-v-2-op-2": "rgba(227, 134, 9, 0.2)",
        "approve": "#21CB88",
        "approve-op-2": "rgba(33,203,136, 0.2)",
        "approve-op-1": "rgba(33,203,136, 0.1)"
      },
      maxHeight: {
        '400px': '400px'
      },
      height: {
        '8px': '8px',
        '12px': '12px',
        '14px': '14px',
        '16px': '16px',
        '20px': '20px',
        '30px': '30px',
        '40px': '40px',
        '50px': '50px',
        '64px': '64px',
        '70px': '70px',
        '80px': '80px',
        '90px': '90px',
        '94px': '94px',
        '100px': '100px',
        '200px': '200px',
        '400px': '400px',
      },
      width: {
        '8px': '8px',
        '12px': '12px',
        '14px': '14px',
        '16px': '16px',
        '20px': '20px',
        '30px': '30px',
        '40px': '40px',
        '94px': '94px',
        '100px': '100px',
        '200px': '200px',
        '250px': '250px',
        '300px': '300px',
        '400px': '400px',
      },
      maxWidth: {
        '100px': '100px',
        '120px': '120px',
        '150px': '150px',
        '200px': '200px',
        '400px': '400px',
      },
      inset: {
        '10px': '10px',
        '26px': '26px',
        '40px': '40px',
      },
      borderWidth: {
        '3px': '3px'
      },
      translate: {
        '90px': '90px'
      },
      fontSize: {
        "xs": "8px",
        "sm": "10px",
        "base": "12px",
        "md": "14px",
        "lg": "16px",
        "xl": "18px",
        "2xl": "20px",
        "3xl": "22px",
        "4xl": "24px",
        "5xl": "26px",
        "6xl": "28px",
        "7xl": "30px",
        "8xl": "32px",
        "9xl": "34px",
      },
      lineHeight: {
        '11': '44px'
      },
    },
  },
  plugins: [],
}