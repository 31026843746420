export const CheckBox = ({
  width = 16,
  height = 16,
  color = "currentColor",
  background = "white",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" fill={background} />
    <path
      d="M11 6L6.875 11L5 8.72727"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  background?: string;
}
