import React from "react";
import { useIntl } from "react-intl";
import DatepickerInput from "../../atoms/DatepickerInput";
import ZInput from "../../atoms/ZInput";
import ZSelect from "../../atoms/ZSelect";
import TabNavigator from "../../molecules/TabNavigator";
import theme from "../../../tailwind-theme";

const formatter = (data: any) => {
  const { title, active, color, activeColor } = data;
  return (
    <div
      className="flex items-center gap-1 px-4 py-2 rounded-full"
      style={{
        color: active ? "white" : activeColor,
        background: active ? activeColor : color,
      }}
    >
      <span className={`text-md font-light`}>{title}</span>
    </div>
  );
};

export default function EditAmendmentForm({
  amendment,
  onChange,
}: EditAmendmentFormInterface) {
  const intl = useIntl();

  const tabs = [
    {
      title: intl.formatMessage({ id: "MENU.PERMANENT" }),
      data: {
        title: intl.formatMessage({ id: "MENU.PERMANENT" }),
        active: amendment.permanent.value === true ? true : false,
        color: theme.colors["custom-v-1-op-2"],
        activeColor: theme.colors["custom-v-1"],
      },
      formatter,
      active: amendment.permanent.value === true ? true : false,
      onClick: () => onChange("permanent", true),
    },
    {
      title: intl.formatMessage({ id: "MENU.PUNCTUAL" }),
      data: {
        title: intl.formatMessage({
          id: "MENU.PUNCTUAL",
        }),
        active: amendment.permanent.value === false ? true : false,
        color: theme.colors["custom-v-2-op-2"],
        activeColor: theme.colors["custom-v-2"],
      },
      formatter,
      active: amendment.permanent.value === false ? true : false,
      onClick: () => onChange("permanent", false),
    },
  ];

  return (
    <div className="px-20 py-5">
      <div className="my-5">
        <label className="text-n-400 font-bold text-lg mb-2 inline-block">
          {intl.formatMessage({ id: "AMENDMENTS.LABEL.TYPE" })}
        </label>
        <TabNavigator
          headerContainerClasses={"!border-none"}
          headerClasses="!border-none mr-3 !p-0"
          tabs={tabs}
        />
      </div>
      <div className="my-5">
        <DatepickerInput
          required={false}
          label={intl.formatMessage({ id: "CONTRACT.START_AMENDMENT" })}
          field={amendment.startDate}
          onUpdate={(value) => {
            onChange("startDate", value);
          }}
        />
      </div>
      {!amendment.permanent.value && (
        <div className="my-5">
          <DatepickerInput
            required={false}
            label={intl.formatMessage({ id: "CONTRACT.END_AMENDMENT" })}
            field={amendment.endDate}
            onUpdate={(value) => {
              onChange("endDate", value);
            }}
          />
        </div>
      )}
      <div className="my-5">
        <ZInput
          required={false}
          field={amendment.tempsDeTravailHebdomadaire}
          label={intl.formatMessage({ id: "EMPLOYEE.CREATE.WORK_HOURS" })}
          onUpdate={(value) => {
            onChange("tempsDeTravailHebdomadaire", value);
          }}
        />
      </div>
      <div className="my-5">
        <ZInput
          required={false}
          field={amendment.joursParSemaine}
          label={intl.formatMessage({ id: "CONTRACT.DAYS_PER_WEEK" })}
          onUpdate={(value) => {
            onChange("joursParSemaine", value);
          }}
        />
      </div>
      <div className="my-5">
        <ZInput
          required={false}
          field={amendment.salaireBrutMensuel}
          label={intl.formatMessage({ id: "CONTRACT.GROSS_SALARY" })}
          onUpdate={(value) => {
            onChange("salaireBrutMensuel", value);
          }}
        />
      </div>
    </div>
  );
}

interface EditAmendmentFormInterface {
  amendment: any;
  onChange(field: string, value: any): void;
}
