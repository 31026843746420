export const LongArrowRight = ({
    width = 16,
    height = 16,
    color = "currentColor"
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 8.5H15.5"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5 3.5L15.5 8.5L10.5 13.5"
      stroke={color}
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface IconProps {
    width?: number;
    height?: number;
    color?: string;
}