import React from "react";
import TextData from "../../components/atoms/TextData";
import { ReactComponent as LateSVG } from "./late.svg";
import { ReactComponent as LowBatterySVG } from "./low_battery.svg";
import { ReactComponent as ExpirationContractSVG } from "./expiration_contract.svg";
import { ReactComponent as BirthdaySVG } from "./birthday.svg";
import { ReactComponent as CongeSVG } from "./conge.svg";
import moment from "moment";

export default function NotificationItem(props: any) {
    const { title, type, content, seen, created_at, isToday } = props;
    const [date, setDate] = React.useState<string>("");
    React.useEffect(() => {
        if (isToday) {
            const createdMoment = moment(created_at);
            const now = moment();
            const diffMinutes = now.diff(createdMoment, "minutes");
            if (diffMinutes < 60) {
                setDate(`${diffMinutes} min`);
            } else {
                const diffHours = Math.floor(diffMinutes / 60);
                setDate(`${diffHours} h`);
            }
        } else {
            const mongoDate = new Date(created_at);
            const day = mongoDate.getDate().toString().padStart(2, "0");
            const month = new Intl.DateTimeFormat("fr-FR", {
                month: "short",
            }).format(mongoDate);
            const formattedDate = `${day} ${month}`;
            setDate(formattedDate);
        }
    }, [created_at]);
    const defaultIcon = ICONS_TYPE[type] || {
        icon: <LateSVG height={45} width={45} />,
        color: "#000000",
        backgroundColor: "#FFFFFF",
    };
    return (
        <div
            className="notification-item"
            style={{ backgroundColor: `${!seen ? "#ebf1f3" : ""}` }}
        >
            <div
                className="notification-icon"
                style={{ backgroundColor: defaultIcon.backgroundColor }}
            >
                {defaultIcon.icon}
            </div>
            <div className="notification-text">
                <TextData
                    className={["title"]}
                    fontWeight="bold"
                    size="18px"
                    color="#2A346D"
                >
                    {title}
                </TextData>
                <TextData
                    className={["description"]}
                    size="16px"
                    color="#6F78AB"
                >
                    <p dangerouslySetInnerHTML={{ __html: content }} />
                </TextData>
            </div>
            <div className="notification-details">
                <div
                    className={`${seen ? "clicked-notif" : "unclicked-notif"}`}
                ></div>
                <TextData
                    fontWeight={`${seen ? "" : "bold"}`}
                    size="12px"
                    color={`${seen ? "#6F78AB" : "#2A346D"}`}
                    width={"60px"}
                    style={{ textAlign: "right" }}
                >
                    {date}
                </TextData>
            </div>
        </div>
    );
}

const ICONS_TYPE: any = {
    late: {
        icon: <LateSVG height={45} width={45} />,
        color: "#2D2AAD",
        backgroundColor: "#eeedfa",
    },
    low_battery: {
        icon: <LowBatterySVG height={45} width={45} />,
        color: "#FB3640",
        backgroundColor: "#ffebec",
    },
    expiration_contract: {
        icon: <ExpirationContractSVG height={45} width={45} />,
        color: "#57569c",
        backgroundColor: "#e3e3fe",
    },
    birthday: {
        icon: <BirthdaySVG height={45} width={45} />,
        color: "#2D2AAD",
        backgroundColor: "#eaf1fb",
    },
    conge_created: {
        icon: <CongeSVG height={45} width={45} />,
        color: "#FB3640",
        backgroundColor: "#d6f6df",
    },
    conge_accepted: {
        icon: <CongeSVG height={45} width={45} />,
        color: "#57569c",
        backgroundColor: "#d6f6df",
    },
    conge_rejected: {
        icon: <CongeSVG height={45} width={45} />,
        color: "#2D2AAD",
        backgroundColor: "#d6f6df",
    },
    expiration_papers: {
        icon: <LowBatterySVG height={45} width={45} />,
        color: "#FB3640",
        backgroundColor: "#ffebec",
    },
    planing: {
        icon: <ExpirationContractSVG height={45} width={45} />,
        color: "#57569c",
        backgroundColor: "#e3e3fe",
    },
    file: {
        icon: <LateSVG height={45} width={45} />,
        color: "#2D2AAD",
        backgroundColor: "#eeedfa",
    },
};
