import * as React from "react";
import "./style.css";
import Modal from "../../atoms/Modal";
import PopUpCard from "../../molecules/PopUpCard";
import TextData from "../../atoms/TextData";
import Picture from "../../atoms/Picture";
import { usePlanning } from "../../../hooks/usePlanning";
import moment from "moment";
export default function PlanningEditionCard(
    props: any /* PlanningEditionCardProps*/,
) {
    const { display, department, onClose } = props;
    const { publishPlanning, week, planningStartOfWeek, planningEndOfWeek } =
        usePlanning();
    const handleOnEdit = () => {
        publishPlanning(false, department.depId);
        onClose && onClose();
    };
    const HeaderTitle = () => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "40px",
                }}
            >
                <TextData
                    variant="label"
                    width="auto"
                    size="22px"
                    fontWeight="700"
                    color="#2A346D"
                >
                    Modifier le planning
                </TextData>
            </div>
        );
    };
    return (
        <Modal modalEnabled={display} onClose={onClose}>
            <div className="attendance-container">
                <PopUpCard
                    display={display}
                    onClose={onClose}
                    containerWidth="600px"
                    header={<HeaderTitle />}
                    widthCloseIcon="24px"
                    styleCloseIcon={{
                        height: "auto",
                        marginRight: "37px",
                        width: "24px",
                        border: "1px solid #edeff533",
                        borderTopRightRadius: "20px",
                    }}
                    headerStyle={{
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#2A346D",
                    }}
                    popupHeaderStyle={{
                        height: "80px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                    }}
                    button1Text={"Annuler"}
                    onClickButton1={onClose}
                    button2Text={"Confirmer"}
                    onClickButton2={handleOnEdit}
                >
                    <div className="planning-edit-container">
                        <TextData
                            variant="div"
                            width="auto"
                            color="#6F78AB"
                            size="20px"
                            fontWeight="400"
                        >
                            Voulez-vous vraiment modifier le planning de la
                            semaine {week} ({" "}
                            <TextData
                                variant="label"
                                width="auto"
                                color="#6F78AB"
                                size="20px"
                                fontWeight="700"
                            >
                                {moment(planningStartOfWeek).format("D MMMM")} -{" "}
                                {moment(planningEndOfWeek).format("D MMMM")}
                            </TextData>
                            ) ?
                        </TextData>
                    </div>
                </PopUpCard>
            </div>
        </Modal>
    );
}

interface PlanningEditionCardProps {
    display?: boolean;
    onClose?: (event?: Event) => void;
}

PlanningEditionCard.defaultProps = {
    display: true,
    onClose: () => {
        console.log("onClose");
    },
};
