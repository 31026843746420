import React from "react";

import PaginationLinks from "../PaginationLinks";

export default function Pagination({ paginationProps }: PaginationInterface) {
  return (
    <>
      <PaginationLinks {...paginationProps} />
    </>
  );
}

export interface PaginationInterface {
  paginationProps: {
    totalSize: number;
    sizePerPage: number;
    page: number;
    onPageChange: (page: number) => void;
  };
}
