import { getUsers } from "../redux/features/users/service";
import { useAppDispatch, useAppSelector } from "./useReduxHooks";

export const useUser = () => {
    const dispatch = useAppDispatch();
    const users = useAppSelector((state: any) => state.users).users;

    const getUsersList = () => {
        const token = localStorage.getItem("token");
        if (token) {
            dispatch(
                getUsers({
                    token,
                    archive: false,
                })
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    //navigate(ROUTER.SETTINGS.BRANCH);
                }
            });
        }
    };
    return {
        users,
        getUsersList,
    };
};
