export const Polygon = ({
  width = 14,
  height = 12,
  color = "currentColor",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 0L13.9282 12H0.0717969L7 0Z" fill={color} />
  </svg>
);

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}
