import React from "react";
import Button from "../../atoms/ZButton";

export default function ModalFooter({
  confirmAction,
  cancelAction,
  cancel,
  confirm,
  fotterClasses,
  confirmRightComponent,
  confirmLeftComponent,
  cancelLeftComponent,
  cancelRightComponent,
  ConfirmComponent,
  CancelComponent,
  confirmLoading,
}: ModalFooterInterface) {
  return (
    <div
      className={`absolute p-4 h-80px gap-2 bottom-0 w-full rounded-b-xl flex justify-end border-t bg-white border-n-0 z-10 ${fotterClasses}`}
    >
      {CancelComponent
        ? CancelComponent
        : cancel && (
            <Button
              onClick={() => cancelAction && cancelAction()}
              leftComponent={cancelLeftComponent}
              rightComponent={cancelRightComponent}
              title={cancel}
            />
          )}
      {ConfirmComponent
        ? ConfirmComponent
        : confirm && (
            <Button
              variant="primary"
              onClick={() => confirmAction && confirmAction()}
              leftComponent={confirmLeftComponent}
              rightComponent={confirmRightComponent}
              title={confirm}
              loading={confirmLoading}
            />
          )}
    </div>
  );
}

interface ModalFooterInterface {
  confirmAction?: () => void;
  cancelAction?: () => void;
  cancel?: string;
  confirm?: string;
  fotterClasses?: string;
  confirmRightComponent?: React.ReactNode;
  cancelRightComponent?: React.ReactNode;
  confirmLeftComponent?: React.ReactNode;
  cancelLeftComponent?: React.ReactNode;
  ConfirmComponent?: React.ReactNode;
  CancelComponent?: React.ReactNode;
  confirmLoading?: boolean;
}
