import React from "react";

export const Filter = ({
  width = 24,
  height = 25,
  color = "currentColor",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5.11719L10.2 13.3947V19.6094L13.8 21.3594V13.3947L21 5.11719H3Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linejoin="round"
      />
    </svg>
  );
};

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}
