import * as React from "react";
import "./style.scss";
import { ReactComponent as LeftArrowSVG } from "../../../components/organism/EmployeeProfileHeader/left-arrow.svg";
import { useNavigate } from "react-router-dom";
import EmployeeInfoHeaderRow from "../../molecules/EmployeeInfoHeaderRow";

export default function EmployeeProfileHeader(
    props: EmployeeProfileHeaderProps
) {
    const navigate = useNavigate();
    return (
        <div className='employee-profile-header-container'>
            <div className='go-back-box' onClick={() => navigate("/employees")}>
                <LeftArrowSVG color='#5BB3D0' />
                Retour
            </div>
            <div></div>
        </div>
    );
}

interface EmployeeProfileHeaderProps {}

EmployeeProfileHeader.defaultProps = {};
