export const ChevronLeft = ({
  width = 20,
  height = 20,
  color = "currentColor",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.08325 10.667L11.8333 15.3337C12.1666 15.667 12.6666 15.667 12.9999 15.3337C13.3333 15.0003 13.3333 14.5003 12.9999 14.167L8.91659 10.0003L12.9999 5.83366C13.3333 5.50033 13.3333 5.00033 12.9999 4.66699C12.8333 4.50033 12.6666 4.41699 12.4166 4.41699C12.1666 4.41699 11.9999 4.50033 11.8333 4.66699L7.08325 9.33366C6.74992 9.75033 6.74992 10.2503 7.08325 10.667C7.08325 10.5837 7.08325 10.5837 7.08325 10.667Z"
      fill={color}
    />
  </svg>
);

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}
