// @ts-nocheck
import * as React from "react";
import Select from "react-select";
import * as Charts from "chart.js";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    TooltipItem,
    TooltipModel,
    ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";

import "./style.scss";

import TextData from "../../atoms/TextData";
import Button from "../../atoms/Button";
import SelectInput from "../../atoms/SelectInput";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

function ChartLine(props: any) {
    const labels = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
    ];
    const data = {
        labels,
        datasets: [
            {
                label: "Dataset 1",
                data: [0, -800, 150, 1000, -850, -800, 999, -800, 999],
                borderColor: "#2D2AAD",
                backgroundColor: "rgba(45, 42, 173, 0.2)",
                fill: "start",
            },
        ],
    };
    const options: ChartOptions = {
        responsive: true,
        plugins: {
            tooltip: {
                enabled: false,
                external: (context) => {
                    /*
                    <div id="chartjs-tooltip">
                    <div>
                    <span>Jeu 12 Nov</span>
                    <span class="dot-tooltip"></span>
                    </div>
                    <div>
                    2134<span>Є</span>
                    </div>
                    </div>
                    */
                    // Tooltip Element
                    let tooltipEl = document.getElementById("chartjs-tooltip");
                    // Create element on first render
                    if (!tooltipEl) {
                        tooltipEl = document.createElement("div");
                        tooltipEl.id = "chartjs-tooltip";
                        tooltipEl.innerHTML =
                            "<div id='tooltip-body'>" +
                            //the body of tooltip
                            "</div>";
                        document.body.appendChild(tooltipEl);
                    }
                    // Hide if no tooltip
                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = "0";
                        return;
                    }
                    // Set caret Position
                    tooltipEl.classList.remove(
                        "above",
                        "below",
                        "no-transform"
                    );
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                        // tooltipModel.caretY = tooltipModel.caretY - 150;
                        // tooltipModel.caretX =
                        //     tooltipModel.caretX + tooltipModel.caretX / 2;
                    } else {
                        tooltipEl.classList.add("no-transform");
                    }
                    function getBody(bodyItem: any) {
                        return bodyItem.lines;
                    }
                    /* "<span>Jeu 12 Nov</span>" + "<span class='dot-tooltip'></span>" + "</div>" + "<div>" + "2134<span>Є</span>" + */
                    // Set Text
                    if (tooltipModel.body) {
                        let innerHtml = "<div class='tooltip-header'>";
                        const titleLines = tooltipModel.title || [];
                        titleLines.forEach(function (title) {
                            innerHtml += `<span>${title}</span><span id='dot-tooltip'></span>`;
                        });
                        innerHtml += "</div><div class='tooltip-content'>";
                        const bodyLines = tooltipModel.body.map(getBody);
                        bodyLines.forEach(function (body, i) {
                            body = body[0].split(":")[1];
                            innerHtml += `${body}<span>Є</span>`;
                        });
                        innerHtml += "</div>";
                        let elemRoot = tooltipEl.querySelector("#tooltip-body");
                        if (elemRoot != null) elemRoot.innerHTML = innerHtml;
                    }
                    const position =
                        context.chart.canvas.getBoundingClientRect();
                    // const bodyFont = ChartJS.helpers.toFont(
                    // tooltipModel.options.bodyFont
                    // );
                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = "1";
                    tooltipEl.style.position = "absolute";
                    let tooltipPos =
                        position.left +
                        window.pageXOffset +
                        tooltipModel.caretX -
                        tooltipEl.clientWidth / 2;
                    if (tooltipPos > 0) {
                        if (tooltipPos >= context.chart.chartArea.width) {
                            tooltipEl.style.left =
                                tooltipPos - tooltipEl.clientWidth / 2 + "px";
                        } else {
                            tooltipEl.style.left = tooltipPos + "px";
                        }
                    } else {
                        tooltipEl.style.left =
                            tooltipPos + tooltipEl.clientWidth / 2 + "px";
                    }
                    tooltipEl.style.top =
                        position.top + window.pageYOffset + "px";
                    tooltipEl.style.pointerEvents = "none";
                    //This code will display a horizontal and vertical line. However, since the code only
                    //renders when the component is changed or initialized, the lines are only visible briefly
                    //before disappearing
                    let ctx = context.chart.ctx;
                    let x = tooltipModel.caretX;
                    let y = tooltipModel.caretY;
                    let topY = context.chart.legend?.bottom
                        ? context.chart.legend?.bottom
                        : 100;
                    let bottomY = context.chart.chartArea.bottom;
                    let left = context.chart.chartArea.left;
                    let right = context.chart.chartArea.right;
                    // Set line opts
                    ctx.save();
                    ctx.lineWidth = 1;
                    ctx.setLineDash([3, 3]);
                    ctx.strokeStyle = "#1F1F1F";
                    // draw vertical line
                    ctx.beginPath();
                    ctx.moveTo(x, topY);
                    ctx.lineTo(x, bottomY);
                    ctx.stroke();
                    // Draw horizontal line
                    ctx.beginPath();
                    ctx.moveTo(left, y);
                    ctx.lineTo(right, y);
                    ctx.stroke();
                    ctx.restore();
                },
                backgroundColor: "#eee",
            },
            legend: {
                display: false,
                position: "top" as const,
            },
            title: {
                display: false,
                text: "Chart.js Line Chart",
            },
        },
        scales: {
            y: {
                type: "linear",
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: (value, index, ticks) => {
                        return value.toString() + " K";
                    },
                },
            },
        },
        interaction: {
            intersect: false,
        },
        elements: {
            line: {
                tension: 0.5,
            },
        },
    };
    return (
        <div style={{ width: "100%" }}>
            <Line options={options} data={data} />
        </div>
    );
}

export default function PayrollPerformanceCard(
    props: PayrollPerformanceCardProps
) {
    const options = [
        { label: "Novembre 2022", value: "Novembre 2022" },
        { label: "Decembre 2022", value: "Decembre 2022" },
        { label: "Octobre 2022", value: "Octobre 2022" },
    ];
    return (
        <div className='payroll-performance-card'>
            <div className='card-header'>
                <div className='card-title'>
                    <div className='money-title'>
                        <TextData
                            variant='label'
                            width='auto'
                            color='#2D2AAD'
                            size='24px'
                            fontWeight='bold'
                        >
                            100256
                        </TextData>
                        <TextData
                            variant='label'
                            width='auto'
                            color='#2D2AAD'
                            size='12px'
                            fontWeight='bold'
                            style={{ paddingBottom: "5px" }}
                        >
                            Є
                        </TextData>
                    </div>
                    <TextData
                        variant='label'
                        width='110px'
                        color='#2D2AAD'
                        size='14px'
                    >
                        Chiffre d'affaires
                    </TextData>
                </div>
                <div className='card-options-selection'>
                    <SelectInput
                        title=''
                        style={{
                            border: "none",
                            marginRight: "20px",
                            width: "150px",
                            padding: 0,
                            color: "#2A346D",
                        }}
                        options={options}
                    />

                    <div className='card-toggle-button'>
                        <Button
                            width='55px'
                            height='28px'
                            backgroundColor='#2A8BAB'
                            textColor='#fff'
                            style={{
                                borderRadius: "4px 0 0 4px",
                            }}
                        >
                            Mois
                        </Button>
                        <Button
                            width='55px'
                            height='28px'
                            backgroundColor='#fff'
                            textColor='#6F78AB'
                            style={{
                                borderRadius: "0 4px 4px 0",
                            }}
                        >
                            Année
                        </Button>
                    </div>
                </div>
            </div>
            <div className='card-body'>
                <ChartLine />
            </div>
        </div>
    );
}
interface PayrollPerformanceCardProps {}
PayrollPerformanceCard.defaultProps = {};
