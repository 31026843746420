import * as React from "react";

import { Schedule } from "../Settings";
import Button from "../../components/atoms/Button";
import TextData from "../../components/atoms/TextData";
import ProgressSteps from "../../components/molecules/ProgressSteps";
import { ScheduleProps } from "../Settings/Configurations/ScheduleSettings";

import { ReactComponent as ArrowSVG } from "./arrowIcon.svg";
function Slide2(props: Slide2Props) {
    const {
        handleMove,
        currentStep,
        header,
        buttonBackText,
        buttonNextTimeText,
        buttonSaveText,
        buttonDoneText,
        handleBackButton,
        handleNextTimeButton,
        handleSaveButton,
        handleDoneButton,
    } = props;
    const handleBack = () => {
        handleMove(-1);
        handleBackButton?.();
    };
    const handleNextTime = () => {
        handleMove(-1);
        handleNextTimeButton?.();
    };
    const handleSave = () => {
        handleSaveButton?.();
    };
    const handleDone = () => {
        handleMove(1);
        handleDoneButton?.();
    };

    const globalProps = {
        headerProps: {
            style: {
                marginTop: 25,
                marginLeft: 35,
            },
            color: "#11106E",
            size: "34px",
            fontWeight: "bolder",
        },
        progressStepsProps: {
            steps: [
                {
                    stepName: "Etape 1",
                    label: "Etablissement",
                },
                {
                    stepName: "Etape 2",
                    label: "Planning",
                },
                {
                    stepName: "Etape 3",
                    label: "Pointeuse",
                },
            ],
            initialStep: currentStep,
        },
        buttonBackProps: {
            className: ["button-style"],
            textColor: "#2A346D",
            backgroundColor: "#EDEFF5",
            style: {
                fontSize: "14px",
                justifyContent: "center",
            },
            onClick: handleBack,
            width: "160px",
            height: "45px",
        },
        leftSVGProps: {
            style: {
                transform: "rotate(180deg)",
                color: "#2A346D",
                marginRight: 7,
            },
        },
        buttonNextTimeProps: {
            className: ["button-style"],
            textColor: "#6F78AB",
            backgroundColor: "transparent",
            style: { fontSize: "14px" },
            onClick: handleNextTime,
            width: "160px",
            height: "45px",
        },
        buttonSaveTextProps: {
            backgroundColor: "transparent",
            textColor: "#2A8BAB",
            style: {
                border: "1px solid #2A8BAB",
                borderRadius: 7,
                fontSize: "14px",
            },
            className: ["button-style"],
            onClick: handleSave,
            width: "160px",
            height: "45px",
        },
        buttonDoneTextProps: {
            backgroundColor: "#2A8BAB",
            textColor: "white",
            style: {
                borderRadius: 7,
                fontSize: "14px",
                justifyContent: "center",
            },
            className: ["button-style"],
            onClick: handleDone,
            width: "160px",
            height: "45px",
        },
    };
    return (
        <div className='startup-body-container'>
            <div className='startup-header'>
                <TextData {...globalProps.headerProps}>{header}</TextData>
                <div className='progressSteps-container'>
                    <ProgressSteps {...globalProps.progressStepsProps} />
                </div>
            </div>
            <div className='startup-card-container'>
                <Schedule settings={ScheduleProps()} includeButtons={false} />
                <div className='footer-card-container'>
                    <div>
                        <Button {...globalProps.buttonBackProps}>
                            <ArrowSVG {...globalProps.leftSVGProps} />
                            {buttonBackText}
                        </Button>
                        <Button {...globalProps.buttonNextTimeProps}>
                            {buttonNextTimeText}
                        </Button>
                    </div>
                    <div>
                        <Button {...globalProps.buttonSaveTextProps}>
                            {buttonSaveText}
                        </Button>
                        <Button {...globalProps.buttonDoneTextProps}>
                            {buttonDoneText}
                            <ArrowSVG style={{ marginLeft: 7 }} />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
interface Slide2Props {
    currentStep?: number;
    header?: string;
    buttonBackText?: string;
    buttonNextTimeText?: string;
    buttonSaveText?: string;
    buttonDoneText?: string;
    handleMove: (count: number) => void;
    handleSaveButton?: () => void;
    handleDoneButton?: () => void;
    handleBackButton?: () => void;
    handleNextTimeButton?: () => void;
}
Slide2.defaultProps = {
    currentStep: 2,
    header: "Paramètres",
    buttonBackText: "Retour",
    buttonNextTimeText: "Plus tard",
    buttonSaveText: "Enregistrer",
    buttonDoneText: "Suivant",
};

export default Slide2;
