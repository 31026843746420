import * as React from "react";
import TextData from "../../../components/atoms/TextData";

import { ReactComponent as NoAccessSVG } from "../icons/no-access.svg";
export default function NoAccess(props: any) {
    const { ImageComponent, title, description, height } = props;
    return (
        <div
            className='no-access'
            style={{
                height: height ? height : "100%",
            }}
        >
            <div className='no-access-body'>
                <ImageComponent />
                <TextData
                    color='#6F78AB'
                    size='14px'
                    fontWeight='bolder'
                    style={{ textAlign: "center" }}
                >
                    {title}
                </TextData>
                <TextData
                    color='#6F78AB'
                    size='14px'
                    style={{ textAlign: "center" }}
                >
                    {description}
                </TextData>
            </div>
        </div>
    );
}
NoAccess.defaultProps = {
    ImageComponent: NoAccessSVG,
    title: "Paramètres",
    description:
        "Veuillez sélectionner un établissement pour accéder aux paramètres !",
};
