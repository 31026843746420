export const ArrowRight = ({
  color = "currentColor",
  width = 17,
  height = 16,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.7464 13.2802L11.0931 8.93355C11.6064 8.42021 11.6064 7.58021 11.0931 7.06688L6.7464 2.72021"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}
