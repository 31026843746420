import React from "react";
import AttendanceCard from "../../organism/AttendanceCard";
import "./style.css";
import Shift from "./Shift";

export default function PlanningDayCell({
    data,
    planningEmployees,
    defaultEmployee,
    published,
    department,
}: any) {
    const [isShiftEditing, setIsShiftEditing] = React.useState(false);
    const [isShift, setIsShift] = React.useState(true);
    const [toEdit, setShiftToEdit] = React.useState(null);
    const [isClicked, setIsClicked] = React.useState(false);
    return (
        <>
            <Shift
                // key={key}
                data={data}
                setIsShiftEditing={setIsShiftEditing}
                setShiftToEdit={setShiftToEdit}
                setIsShift={setIsShift}
                setIsClicked={setIsClicked}
                extern={
                    (department && department?._id !== data.department) ||
                    (!department && data.department !== null)
                }
            />
            {isClicked && (
                <AttendanceCard
                    display={isClicked}
                    onClose={() => setIsClicked(false)}
                    employees={planningEmployees}
                    defaultEmployee={defaultEmployee}
                    // day={day}
                    // dayOrder={dayOrder}
                    isShiftEditing={isShiftEditing}
                    setIsShiftEditing={setIsShiftEditing}
                    isShift={isShift}
                    setIsShift={setIsShift}
                    toEdit={toEdit}
                    setShiftToEdit={setShiftToEdit}
                />
            )}
        </>
    );
}
