import React from "react";
import { useIntl } from "react-intl";
import InputInfos from "../../atoms/InputInfos";

const levels = [
    "Employé",
    "Employé",
    "Employé",
    "Agent de maitrise ou cadre",
    "Cadre",
];

export default function EmployeLevel({
    value,
    onChange,
}: EmployeLevelInterface) {
    const intl = useIntl();
    const [hovred, setHovred] = React.useState(0);

    const handleClick = (level: number) => {
        if (level !== parseInt(value)) {
            onChange(level.toString());
        } else {
            onChange("");
        }
    };

    return (
        <div className="border border-n-100 rounded p-4">
            <div className="flex justify-between items-center mb-4">
                <div
                    style={{
                        display: "flex",
                    }}
                >
                    <span className="text-n-400 text-lg font-bold">
                        {intl.formatMessage({ id: "CONTRACT.EMPLOYEE.LEVEL" })}
                    </span>
                    <InputInfos rup={true} />
                </div>

                {hovred > 0 ? (
                    <>
                        {" "}
                        <div className="flex items-center gap-3">
                            <span className="text-n-600 text-md">
                                {levels[hovred - 1]}
                            </span>
                        </div>
                    </>
                ) : (
                    <>
                        {" "}
                        <div className="flex items-center gap-3">
                            <span className="text-n-600 text-md">
                                {levels[parseInt(value) - 1]}
                            </span>
                        </div>
                    </>
                )}
            </div>
            <div className="flex gap-[2px]">
                {Array(5)
                    .fill(0)
                    .map((element, key) => (
                        <div
                            onMouseEnter={() => setHovred(key + 1)}
                            onMouseLeave={() => setHovred(0)}
                            onClick={() => handleClick(key + 1)}
                            className={`text-center cursor-pointer font-bold text-lg w-[70px] py-3 first:rounded-l-full last:rounded-r-full  ${
                                parseInt(value) >= key + 1
                                    ? "bg-default-active text-white"
                                    : "text-n-700 bg-n-0"
                            }
                ${
                    hovred === 0
                        ? ""
                        : hovred >= key + 1
                          ? "!bg-default-active !text-white"
                          : "!text-n-700 !bg-n-0"
                }
                `}
                            key={key}
                        >
                            {key + 1}
                        </div>
                    ))}
            </div>
        </div>
    );
}

interface EmployeLevelInterface {
    value: string;
    onChange: (value: string) => void;
}
