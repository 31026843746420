import * as React from "react";
import classnames from "classnames";

import { ReactComponent as CloseSVG } from "./close.svg";

import "./style.scss";

export default function InputFile(props: InputFileProps) {
    const {
        text,
        textColor,
        textSize,
        backgroundColor,
        acceptFormat,
        uploadedFile,
    } = props;
    const [selectedFile, setSelectedFile] = React.useState<any>(null);
    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        event.target.value = null;
        setSelectedFile(file);
        uploadedFile?.(file);
    };
    const handleClose = () => {
        setSelectedFile(null);
    };

    const handleFormats = () => {
        switch (acceptFormat) {
            case "all":
                return "*";
            case "word":
                return ".doc,.docx";
            case "pdf":
                return ".pdf";
            case "img":
                return "image/*";
            default:
                return "*";
        }
    };
    return (
        <label htmlFor='upload-file' className='upload-file-container'>
            <div
                className='upload-text'
                style={{
                    color: textColor,
                    fontSize: textSize,
                    backgroundColor,
                }}
            >
                <span>{text}</span>
            </div>
            <input
                id='upload-file'
                type='file'
                accept={handleFormats()}
                onChange={handleFileChange}
            />
            {selectedFile && (
                <div
                    className='selected-file-msg'
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    {`${selectedFile.name.slice(
                        0,
                        5
                    )}...${selectedFile.name.slice(-7)}`}
                    <CloseSVG width={15} onClick={handleClose} />
                </div>
            )}
        </label>
    );
}
interface InputFileProps {
    text?: string;
    textColor?: string;
    textSize?: string;
    backgroundColor?: string;
    acceptFormat?: "all" | "word" | "pdf" | "img";
    uploadedFile?: (data: any) => void;
}
InputFile.defaultProps = {
    text: "Importer un fichier",
    textColor: "#1a7290",
    textSize: "14px",
    backgroundColor: "#eaf4f7",
    acceptFormat: "all",
};
