import * as React from "react";
import classnames from "classnames";
import "./style.scss";
const CHECKED_COLOR_OPACITY = 0.4;
export default function ColoredCircle(props: ColoredCircleProps) {
    const {
        name,
        borderRadius,
        outlineSize,
        color,
        outlineColor,
        outlineOpacity,
        size,
        isChecked,
        onSelectColor,
    } = props;
    const [radioIsChecked, setIsChecked] = React.useState(isChecked);
    React.useEffect(() => {
        setIsChecked(isChecked);
    }, [isChecked]);

    const handleChecked = (selectedColor: any) => {
        setIsChecked(!radioIsChecked); //todelete
        onSelectColor(color as any);
    };
    const circleStyle = {
        height: `${size}px`,
        width: `${size}px`,
        borderRadius,
        backgroundColor: color,
        border: `${outlineSize}px solid ${color}`,
    };
    const getColorRgba = (color: string) => {
        const rgbaValue = `rgba(${parseInt(
            color.slice(-6, -4),
            16
        )}, ${parseInt(color.slice(-4, -2), 16)}, ${parseInt(
            color.slice(-2),
            16
        )}, ${outlineOpacity})`;
        return rgbaValue;
    };
    const checkedCircleStyle = {
        ...circleStyle,
        height: `${size - 2 * outlineSize}px`,
        width: `${size - 2 * outlineSize}px`,
        margin: outlineSize,
        outline: `${outlineSize}px solid ${outlineColor}`,
        border: `${outlineSize}px solid ${color}`,
    };
    return (
        <div className='colored-circle-container'>
            <label className='radio'>
                <input
                    type='radio'
                    value={name}
                    name={name}
                    checked={radioIsChecked}
                    onClick={handleChecked}
                />
                {radioIsChecked ? (
                    <span
                        className='isChecked'
                        style={checkedCircleStyle}
                    ></span>
                ) : (
                    <span className='isNotChecked' style={circleStyle}></span>
                )}
            </label>
        </div>
    );
}

interface ColoredCircleProps {
    name?: string;
    size: number;
    color?: string;
    outlineColor?: string;
    outlineOpacity?: number;
    isChecked?: boolean;
    borderRadius?: string;
    outlineSize: number;
    onSelectColor: (color: string) => void;
}

ColoredCircle.defaultProps = {
    name: "test",
    color: "#15B905",
    outlineColor: "#15b90599",
    outlineOpacity: CHECKED_COLOR_OPACITY,
    size: 100,
    borderRadius: "50%",
    outlineSize: 20,
    isChecked: false,
    onSelectColor: (color: any) => {},
};
