import React from "react";
import { useIntl } from "react-intl";
import TabNavigator from "../../molecules/TabNavigator";
import TabContent from "../../atoms/TabContent";
import theme from "../../../tailwind-theme";
import RepeatUnavaibilityEditForm from "./RepeatUnavaibilityEditForm";
import { Repeat, CalendarThirty } from "../../../Icons";

export default function EditUnavaibilityForm({
  unavaibility,
  onChange,
  onSubmit,
  onBlur,
}: EditUnavaibilityFormInterface) {
  const intl = useIntl();
  const updateRepeatUnavaibility = (repeat: boolean) => {
    if (repeat) {
      if (unavaibility.repeat.value === true) {
        onChange("repeat", null);
      } else {
        onChange("repeat", true);
      }
    } else {
      if (unavaibility.repeat.value === false) {
        onChange("repeat", null);
      } else {
        onChange("repeat", false);
      }
    }
  };

  const formatter = (data: any) => {
    const { title, icon, containerClasses } = data;
    return (
      <div
        className={`flex items-center gap-1 px-4 py-2 rounded-full ${containerClasses}`}
      >
        {icon}
        <span className={`text-md font-light`}>{title}</span>
      </div>
    );
  };

  const tabs = [
    {
      title: intl.formatMessage({ id: "EMPLOYEE.EDIT.UNAVAIBILITY.REPEAT" }),
      data: {
        title: intl.formatMessage({ id: "EMPLOYEE.EDIT.UNAVAIBILITY.REPEAT" }),
        icon: <Repeat />,
        containerClasses: `${
          unavaibility.repeat.value === true
            ? "text-white bg-custom-v-1"
            : "bg-custom-v-1-op-2 text-custom-v-1 hover:text-white hover:bg-custom-v-1"
        }`,
      },
      formatter,
      active: unavaibility.repeat.value === true ? true : false,
      onClick: () => updateRepeatUnavaibility(true),
    },
    {
      title: intl.formatMessage({ id: "EMPLOYEE.EDIT.UNAVAIBILITY.NO_REPEAT" }),
      data: {
        title: intl.formatMessage({
          id: "EMPLOYEE.EDIT.UNAVAIBILITY.NO_REPEAT",
        }),
        icon: <CalendarThirty />,
        containerClasses: `${
          unavaibility.repeat.value === false
            ? "text-white bg-custom-v-2"
            : "bg-custom-v-2-op-2 text-custom-v-2 hover:text-white hover:bg-custom-v-2"
        }`,
      },
      formatter,
      active: unavaibility.repeat.value === false ? true : false,
      onClick: () => updateRepeatUnavaibility(false),
    },
  ];

  return (
    <div>
      <div className="p-1">
        <span className="text-n-400  text-lg font-bold inline-block mb-5">
          {intl.formatMessage({ id: "EMPLOYEE.EDIT.UNAVAIBILITY.ADD.TYPE" })}
        </span>
        <TabNavigator
          headerClasses="!border-b-0 !p-0 mr-2"
          headerContainerClasses="!border-b-0"
          tabs={tabs}
        >
          <TabContent
            containerClasses="mt-8"
            index={0}
            //value={unavaibility.repeat.value === true ? 0 : 1}
            value={0}
            component={
              <RepeatUnavaibilityEditForm
                unavaibility={unavaibility}
                onChange={onChange}
                onBlur={onBlur}
              />
            }
          />
        </TabNavigator>
      </div>
    </div>
  );
}

interface EditUnavaibilityFormInterface {
  unavaibility: any;
  onSubmit: () => void;
  onChange: (field: string, value: any) => void;
  onBlur: (field: string) => void;
}
