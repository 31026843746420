export function validateSiret(siret: any) {
    const siretRegex = new RegExp(/^[0-9]{14}$/);
    if (siretRegex.test(siret)) {
        return siret;
    }
    throw new Error("Invalid siret number");
}
export function validateDesignation(designation: any) {
    const designationRegex = new RegExp(
        /^[a-zA-Z0-9éèêëâàäöôûüîïç°²!#$%&'()*+,-./ :;<=>?@]{1,32}$/
    );
    if (designationRegex.test(designation)) {
        return designation;
    }
    throw new Error("Invalid designation");
}
export function validateAPECode(apeCode: any) {
    const apeCodeRegex = new RegExp(/^[0-9]{4}[A-Z]{0,1}$/);
    if (apeCodeRegex.test(apeCode)) {
        return apeCode;
    }
    throw new Error("Invalid APE (NAF) code");
}
const urssafCodeReference = [
    109, 116, 117, 200, 217, 219, 227, 229, 247, 249, 267, 287, 311, 317, 417,
    419, 427, 437, 438, 451, 527, 529, 537, 547, 595, 693, 727, 737, 747, 748,
    827, 828, 837, 917, 937, 971, 972, 973, 974, 976, 979,
];
export function validateURSSAFCode(urssafCode: any) {
    const urssafCodeRegex = new RegExp(/^[0-9]{3}$/);
    if (
        urssafCodeRegex.test(urssafCode) &&
        urssafCodeReference.includes(parseInt(urssafCode))
    ) {
        return urssafCode;
    }
    throw new Error(`Invalid URSSAF code`);
}
export function validateStreet(street: any) {
    const streetRegex = new RegExp(
        /^[a-zA-Z0-9éèêëâàäöôûüîïç°²!#$%&'()* +,-./:;<=>?@]{1,32}$/
    );
    if (streetRegex.test(street)) {
        return street;
    }
    throw new Error("Invalid street");
}
export function validateTown(town: any) {
    const townRegex = new RegExp(
        /^[a-zA-Z0-9éèêëâàäöôûüîïç°²!#$%&'()* +,-./:;<=>?@]{1,32}$/
    );
    if (townRegex.test(town)) {
        return town;
    }
    throw new Error("Invalid town");
}
export function validateZipCode(zipCode: any) {
    const zipCodeRegex = new RegExp(/^[0-9]{5}$/);
    if (zipCodeRegex.test(zipCode) && zipCode !== "00000") {
        return zipCode;
    }
    throw new Error("Invalid zip code");
}
export function validatePhoneNum(phoneNum: any) {
    const phoneNumRegex = new RegExp(/^[0-9]{10}$/);
    if (phoneNumRegex.test(phoneNum)) {
        return phoneNum;
    }
    throw new Error("Invalid phoneNum");
}
export function validateName(name: any) {
    name = name.toUpperCase();
    const nameRegex = new RegExp(/^[A-Z' .&-]{1,32}$/);
    if (nameRegex.test(name)) {
        return name;
    }
    throw new Error("Invalid name");
}
export function validateSex(sex: any) {
    if (
        sex.toLowerCase() === "male" ||
        sex.toLowerCase() === "homme" ||
        sex === 1
    ) {
        return 1;
    } else {
        return 0;
    }
}
export function validateNirID(nirID: any) {
    const nirIDRegex = new RegExp(/^[0-9]{11}$|^[0-9]{13}$/);
    if (nirIDRegex.test(nirID)) {
        return nirID;
    }
    throw new Error("Invalid NIR ID");
}
export function validateNirKey(nirKey: any) {
    const nirKeyRegex = new RegExp(/^[0-9]{2}$/);
    if (nirKeyRegex.test(nirKey)) {
        return nirKey;
    }
    throw new Error("Invalid NIR key");
}
export function validateBirthDate(birthDate: any) {
    const birthDateRegex = new RegExp(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/);
    if (birthDateRegex.test(birthDate)) {
        return birthDate;
    }
    throw new Error("Invalid birth date");
}
export function validateBirthTown(birthTown: any) {
    const birthTownRegex = new RegExp(/^[A-Z0-9 .'-]{1,32}$/);
    if (birthTownRegex.test(birthTown)) {
        return birthTown;
    }
    throw new Error("Invalid birth town");
}
export function validateBirthDept(birthDept: any) {
    const birthDeptRegex = new RegExp(/^[0-9]{2}$/);
    if (birthDeptRegex.test(birthDept)) {
        return birthDept;
    }
    throw new Error("Invalid birth department");
}
export function validateContractDate(contractStartDate: any) {
    const contractStartDateRegex = new RegExp(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/);
    if (contractStartDateRegex.test(contractStartDate)) {
        return contractStartDate;
    }
    throw new Error("Invalid contract start date");
}
export function validateContractStartTime(contractStartTime: any) {
    const contractStartTimeRegex = new RegExp(/^[0-9]{2}:[0-9]{2}/);
    if (contractStartTimeRegex.test(contractStartTime)) {
        return contractStartTime;
    }
    throw new Error("Invalid contract start time");
}
//TODO: we have some conditions for each type of contract
const contractTypes = ["CDD", "CDI", "CTT"];
export function validateContractType(contractType: any) {
    if (contractTypes.includes(contractType)) {
        return contractType;
    }
    throw new Error("Invalid contract type");
}
export function validateContractHealthServiceCode(
    contractHealthServiceCode: any
) {
    const contractHealthServiceCodeRegex = new RegExp(/^[0-9]{3}$/);
    if (
        contractHealthServiceCodeRegex.test(contractHealthServiceCode) ||
        contractHealthServiceCode === ""
    ) {
        return contractHealthServiceCode;
    }
    throw new Error("Invalid contract health service code");
}
export function validateContractTrialPeriod(contractTrialPeriod: any) {
    const contractTrialPeriodRegex = new RegExp(/^[0-9]{1,3}$/);
    if (
        contractTrialPeriod === "" ||
        contractTrialPeriodRegex.test(contractTrialPeriod)
    ) {
        return contractTrialPeriod;
    }
    throw new Error("Invalid contract trial period");
}

export function validateDPAE(validator: any, value: any) {
    try {
        return !!validator(value);
    } catch (e: any) {
        return false;
    }
}
