import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { HolidayState } from "./type";
import { getAllHolidaysService } from "./service";
const initialHolidayState: HolidayState = {
    loading: false,
    error: false,
    success: false,
    userHolidays: {
        enAttente: [],
        valide: [],
        refuse: [],
    },
};

const holidayState = createSlice({
    name: "holiday",
    initialState: initialHolidayState,
    reducers: {
        resetAllHolidays: (state) => {
            state = initialHolidayState;
        },
    },
    extraReducers: (builder) => {
        // CASE : GETALL VACATIONS
        builder.addCase(
            getAllHolidaysService.pending,
            (state: HolidayState) => {
                state.loading = true;
                state.success = false;
                state.error = false;
            }
        );
        builder.addCase(
            getAllHolidaysService.fulfilled,
            (state: HolidayState, action: PayloadAction<any>) => {
                state.loading = false;
                state.success = true;
                state.error = false;
                if (action.payload !== undefined) {
                    console.log("test valide get holidays");
                    console.log("payload ", action.payload);
                    if (state.userHolidays) {
                        state.userHolidays.enAttente = action.payload.enAttente;
                        state.userHolidays.valide = action.payload.valide;
                        state.userHolidays.refuse = action.payload.refuse;
                    }
                }
            }
        );
        builder.addCase(
            getAllHolidaysService.rejected,
            (state: HolidayState) => {
                state.loading = false;
                state.success = false;
                state.error = true;
            }
        );
    },
});

export const { resetAllHolidays } = holidayState.actions;
export default holidayState.reducer;
