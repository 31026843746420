import * as React from "react";
import AuthHeader from "../../organism/AuthHeader";
import AuthentificationCard from "../../molecules/AuthentificationCard";
import AuthFooter from "../../organism/AuthFooter";

import "./style.scss";
import { useLogin } from "../../../hooks/useLogin";
import { Navigate } from "react-router-dom";
import { ROUTER } from "../../../constants/env";
import Loading from "../../atoms/Loading";
export default function Authentification(props: AuthentificationProps) {
    const { mainPageOnClick, authType } = props;
    const { auth } = useLogin();
    const { loading } = auth;
    const globalProps = {
        authentificationCard: {
            // |"login"|"reset-password"|"change-password"|"forget-password"|"check-email"|"custom";
            authType: authType,
            style: {
                containerStyle: {
                    alignSelf: "center",
                    borderRadius: "8px",
                },
                leftStyle: {
                    backgroundColor: "#e2ebee",
                    borderRadius: "8px 0 0 8px",
                },
                rightStyle: {
                    backgroundColor: "white",
                    borderRadius: " 0 8px 8px 0",
                },
            },
        },
    };
    if (loading) return <Loading />;
    if (authType !== "change-password") {
        const token = localStorage.getItem("token");
        const user = sessionStorage.getItem("user");
        if (token && user) {
            return <Navigate to={ROUTER.HOME} />;
        }
    }
    return (
        <div className='authentification-template-container'>
            <AuthHeader logoOnClick={mainPageOnClick} />
            <AuthentificationCard {...globalProps.authentificationCard} />
            <AuthFooter />
        </div>
    );
}

interface AuthentificationProps {
    mainPageOnClick?: React.MouseEventHandler<HTMLElement>;
    authType?:
        | "login"
        | "reset-password"
        | "change-password"
        | "forget-password"
        | "check-email"
        | "custom";
}

Authentification.defaultProps = {
    mainPageOnClick: () => {
        window.open("https://esperoo.fr/", "_blank");
    },
    authType: "login",
};
