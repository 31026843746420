import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    SignInRequest,
    CheckmeRequest,
    ForgetPasswordRequest,
    ResetPasswordRequest,
    ChangePasswordRequest,
} from "./types";
import { API } from "../../../constants/env";

export const signIn = createAsyncThunk(
    "auth/signin",
    async (payload: SignInRequest, { rejectWithValue }) => {
        try {
            const res = await fetch(API.client.auth.login, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        throw new Error(res.message);
                    }
                    return res;
                });
            return res;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const verifyToken = createAsyncThunk(
    "auth/me",
    async (payload: CheckmeRequest, { rejectWithValue }) => {
        try {
            const res = await fetch(API.client.auth.me, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${payload.token}`,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        throw new Error(res.message);
                    }
                    return res;
                });
            return res;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const forgetPasswordServ = createAsyncThunk(
    "auth/forgetPassword",
    async (payload: ForgetPasswordRequest, { rejectWithValue }) => {
        try {
            const res = await fetch(API.client.auth.forgetPassword, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        throw new Error(res.message);
                    }
                    return res;
                });
            return res;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const resetPasswordServ = createAsyncThunk(
    "auth/resetPassword",
    async (payload: ResetPasswordRequest, { rejectWithValue }) => {
        try {
            const res = await fetch(API.client.auth.resetPassword, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        throw new Error(res.message);
                    }
                    return res;
                });
            return res;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const changePasswordServ = createAsyncThunk(
    "auth/changePassword",
    async (payload: ChangePasswordRequest, { rejectWithValue }) => {
        try {
            const res = await fetch(API.client.auth.changePassword, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        throw new Error(res.message);
                    }
                    return res;
                });
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
