import React, { useContext } from "react";
import { createContext } from "react";
import ActionNotification from "../../components/templates/ActionNotification";

const NotificationContext = createContext({
    addNotification : (props: addNotificationProps) => {}
});

export const useNotificationContext = () => {
    return useContext(NotificationContext)
}

const NotificationProvider = ({ children }: NotificationProviderInterface) => {
  const [show, setShow] = React.useState(false);
  const onHide = () => {
    setShow(false);
  };

  const [notification, setNotification] = React.useState<addNotificationProps>({
    title: "",
    type: "success",
    message: "",
  });

  const addNotification = ({ type, message, title }: addNotificationProps) => {
    setNotification({
      title,
      message,
      type,
    });
    setShow(true)
  };

  const value = {
    addNotification,
  };
  return (
    <NotificationContext.Provider value={value}>
      <ActionNotification
        title={notification.title}
        message={notification.message}
        type={notification.type}
        show={show}
        onHide={onHide}
      />
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;

interface NotificationProviderInterface {
  children: React.ReactNode;
}

type addNotificationProps = {
  title: string | React.ReactNode;
  type: "success" | "fail";
  message: string | React.ReactNode;
};
