import React from "react";
import NewTicket from "../../../components/atoms/NewTicket";
import ConditionItem from "./ConditionItem";
import NewConditionPopup from "../../../components/organism/NewConditionPopup";
export default function PauseConditions(props: any) {
    const { conditionsList, useRepas, usePause } = props;
    const [openPopup, setOpenPopup] = React.useState(false);
    const [toEdit, setToEdit] = React.useState(null);
    React.useEffect(() => {
        if (!openPopup) {
            setToEdit(null);
        }
    }, [openPopup]);
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                marginBottom: "10px",
            }}
        >
            {conditionsList?.map((item: any) => (
                <ConditionItem
                    item={item}
                    setOpenPopup={setOpenPopup}
                    setToEdit={setToEdit}
                />
            ))}
            <NewTicket
                handleAddNewTicket={() => setOpenPopup(true)}
                width="fit-content"
                text="Ajouter une condition"
            />
            <NewConditionPopup
                display={openPopup}
                onClose={() => setOpenPopup(false)}
                toEdit={toEdit}
                useRepas={useRepas}
                usePause={usePause}
            />
        </div>
    );
}
