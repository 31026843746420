import React from "react";
import Input from "../../atoms/Input";
export default function ShiftTimeInputs(props: any) {
    const { setPointage, pointage } = props;
    const inputRefs = React.useRef<(HTMLInputElement | null)[]>([]);
    const tabPressed = React.useRef(false);
    const handleTabPress = (
        e: React.KeyboardEvent<HTMLInputElement>,
        index: number,
    ) => {
        if (e.key === "Tab") {
            e.preventDefault();
            tabPressed.current = true;
            const nextIndex = index + 1;
            if (inputRefs.current[nextIndex]) {
                inputRefs.current[nextIndex]?.focus();
            } else {
                inputRefs.current[0]?.focus();
            }
        }
    };
    React.useEffect(() => {
        console.log(" pp = ", pointage);
    }, [pointage]);
    return (
        <div
            className="add-shift-time-container"
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                flexWrap: "wrap",
                gap: 16,
                width: "fit-content",
            }}
        >
            <Input
                reference={(el: any) => (inputRefs.current[0] = el)}
                onKeyDown={(e: any) => handleTabPress(e, 0)}
                type="time"
                captionText={"Début"}
                defaultValue={pointage.start}
                onChange={(value: any) => {
                    if (tabPressed.current) {
                        tabPressed.current = false;
                    } else {
                        setPointage((prev: any) => ({
                            ...prev,
                            start: value,
                        }));
                    }
                }}
                onBlur={(value: any) => {
                    setPointage((prev: any) => ({
                        ...prev,
                        start: value,
                    }));
                }}
                inputWidth="110px"
                borderColor="#C7CBE2"
                captionColor="#C7CBE2"
                borderWidth={1}
                captionSize="12px"
                // iconImg={Clock}

                inputRadius="4px"
                textColor="#4C5690"
                textSize={14}
                iconLocation="right"
                style={{
                    inputStyle: {
                        height: "40px",

                        fontWeight: "400",
                    },
                    captionStyle: {
                        fontWeight: "500",
                    },
                }}
            />
            <Input
                reference={(el: any) => (inputRefs.current[1] = el)}
                onKeyDown={(e: any) => handleTabPress(e, 1)}
                type="time"
                captionText={"Fin"}
                defaultValue={pointage.end}
                onChange={(value: any) => {
                    if (tabPressed.current) {
                        tabPressed.current = false;
                    } else {
                        setPointage((prev: any) => ({
                            ...prev,
                            end: value,
                        }));
                    }
                }}
                onBlur={(value: any) => {
                    setPointage((prev: any) => ({
                        ...prev,
                        end: value,
                    }));
                }}
                inputWidth="110px"
                borderColor="#C7CBE2"
                captionColor="#C7CBE2"
                borderWidth={1}
                captionSize="12px"
                inputRadius="4px"
                textColor="#4C5690"
                textSize={14}
                iconLocation="right"
                style={{
                    inputStyle: {
                        height: "40px",
                        fontWeight: "400",
                    },
                    captionStyle: {
                        fontWeight: "500",
                    },
                }}
            />
        </div>
    );
}
