import React from "react";

export default function PickEstablishement({
  Icon,
  title,
  desc,
}: PickEstablishementProps) {
  return (
    <div className="flex flex-col items-center justify-center max-w-[300px] mx-auto">
      <div>{Icon}</div>
      <p className="font-bold text-xl text-n-500 text-center">{title}</p>
      <p className="font-medium text-lg text-n-500 text-center">{desc}</p>
    </div>
  );
}

interface PickEstablishementProps {
  Icon?: React.ReactNode;
  title?: React.ReactNode;
  desc?: React.ReactNode;
}
