import React from "react";

import TextData from "../../atoms/TextData";
import ProfilePicture from "../../molecules/ProfilePicture";
import { ROUTER } from "../../../constants/env";

import "./style.scss";

import profileImg from "./profile.jpg";

function EmployeeInfoHeaderRow(props: EmployeeInfoHeaderRowProps) {
    const {
        employeeInfoStyle,
        profileImage,
        imageWidth,
        imageHeight,
        imageRadius,
        imageStyle,

        fullnameColor,
        fullnameSize,
        fullnameWeight,
        firstName,
        lastName,

        dotColor,
        positionColor,
        positionSize,
        positionWidth,
        position,

        weekHours,
        weekHoursColor,
        weekHoursSize,
        weekHoursWidth,
    } = props;

    const globalProps = {
        pictureProps: {
            imgPath: ROUTER.STATIC_IMG(profileImage),
            width: imageWidth,
            height: imageHeight,
            style: { borderRadius: imageRadius, ...imageStyle },
        },
        fullnameProps: {
            variant: "label" as "label",
            color: fullnameColor,
            size: fullnameSize,
            fontWeight: fullnameWeight,
            width: "auto",
        },
        dotProps: {
            className: "dot",
            style: { backgroundColor: dotColor },
        },
        positionProps: {
            variant: "label" as "label",
            color: positionColor,
            size: positionSize,
            width: positionWidth,
        },
        weekHoursProps: {
            variant: "label" as "label",
            color: weekHoursColor,
            size: weekHoursSize,
            width: weekHoursWidth,
        },
    };
    return (
        <div className='employee-info-container' style={employeeInfoStyle}>
            <div className='employee-picture'>
                <ProfilePicture
                    profileImage={profileImage}
                    firstName={firstName}
                    lastName={lastName}
                    imageWidth={imageWidth}
                    imageHeight={imageHeight}
                    imageRadius={imageRadius}
                    pictureProps={globalProps.pictureProps}
                    altColor={dotColor}
                />
            </div>
            <div className='employee-info'>
                <TextData
                    {...globalProps.fullnameProps}
                >{`${firstName} ${lastName}`}</TextData>
                {position && (
                    <div className='employee-position'>
                        <div {...globalProps.dotProps}></div>
                        <TextData {...globalProps.positionProps}>
                            {position === "Administrator"
                                ? "Administrateur"
                                : position == "Manager"
                                ? "Manager"
                                : "Employé"}
                        </TextData>
                    </div>
                )}
            </div>
            {weekHours !== undefined && (
                <div className='employee-more-info'>
                    <div className='employee-hours'>
                        <TextData {...globalProps.weekHoursProps}>
                            {weekHours}
                        </TextData>
                    </div>
                </div>
            )}
        </div>
    );
}
interface EmployeeInfoHeaderRowProps {
    employeeInfoStyle?: React.CSSProperties;

    profileImage: string;
    imageWidth?: string;
    imageHeight?: string;
    imageRadius?: string;
    imageStyle?: React.CSSProperties;

    fullnameColor?: string;
    fullnameSize?: string;
    fullnameWeight?: string;
    firstName: string;
    lastName: string;

    dotColor?: string;
    positionColor?: string;
    positionSize?: string;
    positionWidth?: string;
    position: string;

    weekHours: string | null;
    weekHoursColor?: string;
    weekHoursSize?: string;
    weekHoursWidth?: string;
}

EmployeeInfoHeaderRow.defaultProps = {
    profileImage: profileImg,
    imageWidth: "40px",
    imageHeight: "40px",
    imageRadius: "50%",
    fullnameColor: "#101844",
    fullnameSize: "14px",
    fullnameWeight: "bold",
    firstName: "Jacob",
    lastName: "Thornton",
    dotColor: "green",
    positionColor: "#4C5690",
    positionSize: "10px",
    positionWidth: "auto",
    position: "Manager",
    weekHours: "20h00",
    weekHoursColor: "#2A346D",
    weekHoursSize: "11px",
    weekHoursWidth: "auto",
};

export default EmployeeInfoHeaderRow;
