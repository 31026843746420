import React from "react";
import moment from "moment";

export const StartDateColmunFormatter = (
  content: string,
  rowIdx: number,
  row: any
) => {
  return (
    <>
      {content && content !== "" && (
        <span className="text-n-800 text-md font-light">
          {moment(content).format("DD/MM/YYYY")}
          {row.startHour ? ` - ${row.startHour}` : ""}
        </span>
      )}
    </>
  );
};
