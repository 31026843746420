import * as React from "react";

import logoImg from "../../../utils/img/logo.svg";
import Picture from "../../atoms/Picture";
import ChangeLanguage from "../../molecules/ChangeLanguage";

import "./style.scss";

export default function AuthHeader(props: AuthHeaderProps) {
    const { logo, logoOnClick } = props;
    return (
        <div className='auth-header-container'>
            <Picture
                imgPath={logo}
                onClick={logoOnClick}
                height='44px'
                width='12vw'
                style={{
                    zIndex: 1,
                    // marginTop: 5,
                    // marginBottom: 5,
                    cursor: "pointer",
                }}
                alt=''
            />
            <div className='auth-header-lang-container'>
                <ChangeLanguage />
            </div>
        </div>
    );
}

interface AuthHeaderProps {
    logo?: string;
    logoOnClick: React.MouseEventHandler;
}

AuthHeader.defaultProps = {
    logo: logoImg,
    logoOnClick: () => {},
};
