import React from "react";
import "./style.scss";
import moment from "moment";
import PlanningCell from "../../molecules/PlanningCell";
import { usePlanning } from "../../../hooks/usePlanning";

export default function RenderCells(props: any) {
    const memoizedProps = React.useMemo(() => props, [props]);
    const { defaultEmployee, published, department, weekNumber, dayOrder } =
        memoizedProps;
    const { data } = memoizedProps;

    let cells: any = [];
    const {
        planningStartOfWeek,
        planningDisponibility,
        employeesData,
        planningOptions,
        week,
        draggedShiftType,
    } = usePlanning();
    const cellDivStyle = {
        // height: "100%",
        width: "100%",
        padding: "3px",
        // flexGrow: 1,
        display: "flex",
    };
    if (!data) {
        Array(7)
            .fill(0)
            .map((item: any, i: number) => {
                let repeat: any = undefined;
                let deleted: any = undefined;
                let startDate: any = undefined;
                let endDate: any = undefined;
                let thisDay = moment(planningStartOfWeek)
                    .startOf("isoWeek")
                    .add(i, "day");
                if (
                    defaultEmployee &&
                    defaultEmployee?._id &&
                    planningDisponibility &&
                    planningDisponibility[defaultEmployee?._id] &&
                    planningDisponibility[defaultEmployee?._id][i]
                ) {
                    repeat =
                        planningDisponibility[defaultEmployee._id][i].repeat;
                    deleted =
                        planningDisponibility[defaultEmployee._id][i].deleted;
                    startDate = moment(
                        planningDisponibility[defaultEmployee._id][i].startDate,
                    );
                    endDate = moment(
                        planningDisponibility[defaultEmployee._id][i].endDate,
                    );
                }
                if (
                    !planningOptions.hideDisponibility &&
                    defaultEmployee &&
                    defaultEmployee._id &&
                    planningDisponibility[defaultEmployee._id] &&
                    planningDisponibility[defaultEmployee._id][i] &&
                    !deleted &&
                    ((repeat && startDate.isSameOrBefore(thisDay, "day")) ||
                        (!repeat &&
                            startDate.isSameOrBefore(thisDay, "day") &&
                            thisDay.isSameOrBefore(endDate, "day")))
                ) {
                    cells.push(
                        <th
                            key={i}
                            id={`col-${i}`}
                            style={{
                                height: "inherit",
                                flexGrow: "1",
                                backgroundColor: `${
                                    weekNumber === week && dayOrder === i + 1
                                        ? "rgb(42 ,139, 171, 0.05)"
                                        : ""
                                }`,
                                width: "10%",
                                maxWidth: "10%",
                            }}
                        >
                            <div style={cellDivStyle}>
                                <PlanningCell
                                    isEmpty={false}
                                    planningEmployees={Object.values(
                                        employeesData,
                                    )}
                                    disponibility={
                                        planningDisponibility[
                                            defaultEmployee._id
                                        ][i]
                                    }
                                    defaultEmployee={defaultEmployee}
                                    day={moment(
                                        planningStartOfWeek,
                                        "YYYY-MM-DD",
                                    )
                                        .add(i, "days")
                                        .format("YYYY-MM-DD")}
                                    dayOrder={i}
                                    published={published}
                                    department={department}
                                />
                            </div>
                        </th>,
                    );
                } else {
                    cells.push(
                        <th
                            key={i}
                            id={`col-${i}`}
                            style={{
                                height: "inherit",
                                flexGrow: "1",
                                backgroundColor: `${
                                    weekNumber === week && dayOrder === i + 1
                                        ? "rgb(42 ,139, 171, 0.05)"
                                        : ""
                                }`,
                                width: "10%",
                                maxWidth: "10%",
                            }}
                        >
                            <div style={cellDivStyle}>
                                <PlanningCell
                                    isEmpty={true}
                                    defaultEmployee={defaultEmployee}
                                    day={moment(
                                        planningStartOfWeek,
                                        "YYYY-MM-DD",
                                    )
                                        .add(i, "days")
                                        .format("YYYY-MM-DD")}
                                    dayOrder={i}
                                    published={published}
                                    department={department}
                                />
                            </div>
                        </th>,
                    );
                }
            });

        return cells;
    }
    let firstDayOfWeek = planningStartOfWeek;
    let j = 0;
    Array(7)
        .fill(0)
        .map((item: any, i: number) => {
            let repeat: any = undefined;
            let deleted: any = undefined;
            let startDate: any = undefined;
            let endDate: any = undefined;
            let thisDay = moment(planningStartOfWeek)
                .startOf("isoWeek")
                .add(i, "day");
            if (
                defaultEmployee &&
                defaultEmployee._id &&
                planningDisponibility[defaultEmployee._id] &&
                planningDisponibility[defaultEmployee._id][i]
            ) {
                repeat = planningDisponibility[defaultEmployee._id][i].repeat;
                deleted = planningDisponibility[defaultEmployee._id][i].deleted;
                startDate = moment(
                    planningDisponibility[defaultEmployee._id][i].startDate,
                );
                endDate = moment(
                    planningDisponibility[defaultEmployee._id][i].endDate,
                );
            }
            if (j < data.length && firstDayOfWeek === data[j].day) {
                if (
                    !planningOptions.hideDisponibility &&
                    defaultEmployee &&
                    defaultEmployee._id &&
                    planningDisponibility[defaultEmployee._id] &&
                    planningDisponibility[defaultEmployee._id][i] &&
                    !deleted &&
                    ((repeat && startDate.isSameOrBefore(thisDay, "day")) ||
                        (!repeat &&
                            startDate.isSameOrBefore(thisDay, "day") &&
                            thisDay.isSameOrBefore(endDate, "day")))
                ) {
                    cells.push(
                        <th
                            key={i}
                            id={`col-${i}`}
                            style={{
                                height: "inherit",
                                backgroundColor: `${
                                    weekNumber === week && dayOrder === i + 1
                                        ? "rgb(42 ,139, 171, 0.05)"
                                        : ""
                                }`,
                                width: "10%",
                                maxWidth: "10%",
                            }}
                        >
                            <div style={cellDivStyle}>
                                <PlanningCell
                                    isEmpty={false}
                                    data={data[j].workShares}
                                    planningEmployees={Object.values(
                                        employeesData,
                                    )}
                                    defaultEmployee={defaultEmployee}
                                    day={firstDayOfWeek}
                                    dayOrder={i}
                                    disponibility={
                                        planningDisponibility[
                                            defaultEmployee._id
                                        ][i]
                                    }
                                    published={published}
                                    department={department}
                                />
                            </div>
                        </th>,
                    );
                } else {
                    cells.push(
                        <th
                            key={i}
                            id={`col-${i}`}
                            style={{
                                height: "inherit",
                                backgroundColor: `${
                                    weekNumber === week && dayOrder === i + 1
                                        ? "rgb(42 ,139, 171, 0.05)"
                                        : ""
                                }`,
                                width: "10%",
                                maxWidth: "10%",
                            }}
                        >
                            <div style={cellDivStyle}>
                                <PlanningCell
                                    isEmpty={false}
                                    data={data[j].workShares}
                                    planningEmployees={Object.values(
                                        employeesData,
                                    )}
                                    defaultEmployee={defaultEmployee}
                                    day={firstDayOfWeek}
                                    dayOrder={i}
                                    published={published}
                                    department={department}
                                />
                            </div>
                        </th>,
                    );
                }

                j++;
            } else {
                if (
                    !planningOptions.hideDisponibility &&
                    defaultEmployee &&
                    defaultEmployee._id &&
                    planningDisponibility[defaultEmployee._id] &&
                    planningDisponibility[defaultEmployee._id][i] &&
                    !deleted &&
                    ((repeat && startDate.isSameOrBefore(thisDay, "day")) ||
                        (!repeat &&
                            startDate.isSameOrBefore(thisDay, "day") &&
                            thisDay.isSameOrBefore(endDate, "day")))
                ) {
                    cells.push(
                        <th
                            key={i}
                            id={`col-${i}`}
                            style={{
                                height: "inherit",
                                backgroundColor: `${
                                    weekNumber === week && dayOrder === i + 1
                                        ? "rgb(42 ,139, 171, 0.05)"
                                        : ""
                                }`,
                                width: "10%",
                                maxWidth: "10%",
                            }}
                        >
                            <div style={cellDivStyle}>
                                <PlanningCell
                                    isEmpty={false}
                                    planningEmployees={Object.values(
                                        employeesData,
                                    )}
                                    defaultEmployee={defaultEmployee}
                                    day={firstDayOfWeek}
                                    dayOrder={i}
                                    disponibility={
                                        planningDisponibility[
                                            defaultEmployee._id
                                        ][i]
                                    }
                                    published={published}
                                    department={department}
                                />
                            </div>
                        </th>,
                    );
                } else {
                    cells.push(
                        <th
                            key={i}
                            id={`col-${i}`}
                            style={{
                                height: "inherit",
                                backgroundColor: `${
                                    weekNumber === week && dayOrder === i + 1
                                        ? "rgb(42 ,139, 171, 0.05)"
                                        : ""
                                }`,
                                width: "10%",
                                maxWidth: "10%",
                            }}
                        >
                            <div style={cellDivStyle}>
                                <PlanningCell
                                    isEmpty={true}
                                    planningEmployees={Object.values(
                                        employeesData,
                                    )}
                                    defaultEmployee={defaultEmployee}
                                    day={firstDayOfWeek}
                                    dayOrder={i}
                                    published={published}
                                    department={department}
                                />
                            </div>
                        </th>,
                    );
                }
            }
            firstDayOfWeek = moment(firstDayOfWeek, "YYYY-MM-DD")
                .add(1, "days")
                .format("YYYY-MM-DD");
        });
    // for (let i = 0; i < 7; i++) {

    // }
    return <>{cells}</>;
}
