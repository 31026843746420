import resolveConfig from "tailwindcss/resolveConfig";
const tailwindConfig = require("./tailwind.config");
interface ThemeProps {
    screens: {
        [key: string]: string;
    };
    colors: {
        [key: string]: string;
    };
    maxHeight: {
        [key: string]: string;
    };
    height: {
        [key: string]: string;
    };
    width: {
        [key: string]: string;
    };
    maxWidth: {
        [key: string]: string;
    };
    inset: {
        [key: string]: string;
    };
    borderWidth: {
        [key: string]: string;
    };
    translate: {
        [key: string]: string;
    };
    fontFamily: {
        [key: string]: string;
    };
    fontSize: {
        [key: string]: string;
    };
    lineHeight: {
        [key: string]: string;
    };
}
const config = resolveConfig(tailwindConfig);
export default config.theme as ThemeProps;
