export const SMS = ({
    width = 24,
    height = 25,
    color = "currentColor",
    background = "#EDEFF5"
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12.2002" r="12" fill={background} />
    <path
      d="M14.5 16.4497H9.5C8 16.4497 7 15.6997 7 13.9497V10.4497C7 8.69971 8 7.94971 9.5 7.94971H14.5C16 7.94971 17 8.69971 17 10.4497V13.9497C17 15.6997 16 16.4497 14.5 16.4497Z"
      fill={color}
    />
    <path
      d="M14.5 10.6997L12.935 11.9497C12.42 12.3597 11.575 12.3597 11.06 11.9497L9.5 10.6997"
      stroke={background}
      stroke-width="0.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface IconProps {
    width?: number;
    height?: number;
    color?: string;
    background?: string;
  }
  
