import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { NotificationsState } from "./types";
// const userString = sessionStorage.getItem("user");
// const user = userString ? JSON.parse(userString) : null;
// const notifyOnAbsence = user?.notifyOnAbsence || false;
const initialState: NotificationsState = {
    some: {
        today: [],
        old: [],
        unread: 0,
    },
    all: {
        today: [],
        old: [],
        unread: 0,
    },
    loading: false,
    settings: {
        birthdayNotif: false,
        lateArrivalNotif: false,
        notClockedOutNotif: false,
    },
};

export const counterSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        resetAllNotifications: (state) => {
            state = initialState;
        },
        updateNotifications: (state, action) => {
            if (action.payload?.today !== undefined) {
                state.some.today = action.payload.today;
            }
            if (action.payload?.old !== undefined) {
                state.some.old = action.payload.old;
            }
            if (action.payload?.unread !== undefined) {
                state.some.unread = action.payload.unread;
            }
            if (action.payload?.settings !== undefined) {
                state.settings = action.payload.settings;
            }
            if (action.payload?.updateOne !== undefined) {
                let updatedState: any = [];
                let unread = 0;
                let found = false;
                for (let i = 0; i < state.some.today.length; i++) {
                    if (
                        state.some.today[i]._id ===
                        action.payload?.updateOne._id
                    ) {
                        updatedState.push(action.payload?.updateOne);
                        // unread += state.some.today[i].seen ? 0 : 1;
                        found = true;
                    } else {
                        updatedState.push(state.some.today[i]);
                        // unread += state.some.today[i].seen ? 0 : 1;
                    }
                }
                state.some.today = updatedState;
                if (!found) {
                    updatedState = [];
                    unread = 0;
                    for (let i = 0; i < state.some.old.length; i++) {
                        if (
                            state.some.old[i]._id ===
                            action.payload?.updateOne._id
                        ) {
                            updatedState.push(action.payload?.updateOne);
                            // unread += action.payload?.updateOne.seen ? 0 : 1;
                            found = true;
                        } else {
                            updatedState.push(state.some.old[i]);
                            // unread += state.some.old[i].seen ? 0 : 1;
                        }
                    }
                    state.some.old = updatedState;
                }
                if (found) {
                    state.some.unread = state.some.unread - 1;
                }
                // for (let i = 0; i < state.all.today.length; i++) {}
                // for (let i = 0; i < state.all.old.length; i++) {}
            }
        },
        updateAllNotifications: (state, action) => {
            if (action.payload?.today !== undefined) {
                state.all.today = action.payload.today;
            }
            if (action.payload?.old !== undefined) {
                state.all.old = action.payload.old;
            }
            if (action.payload?.unread !== undefined) {
                state.all.unread = action.payload.unread;
            }
        },
        setAllNotificationsSeen: (state) => {
            state.some.unread = 0;
            state.some.today = state.some.today.map((notification) => ({
                ...notification,
                seen: true,
            }));
            state.some.old = state.some.old.map((notification) => ({
                ...notification,
                seen: true,
            }));
            state.all.unread = 0;
            state.all.today = state.all.today.map((notification) => ({
                ...notification,
                seen: true,
            }));
            state.all.old = state.all.old.map((notification) => ({
                ...notification,
                seen: true,
            }));
        },
        setLoadingNotifications: (state, action) => {
            state.loading = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    updateNotifications,
    updateAllNotifications,
    setAllNotificationsSeen,
    setLoadingNotifications,
    resetAllNotifications,
} = counterSlice.actions;

export default counterSlice.reducer;
