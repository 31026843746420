import React from "react";
import CommingSoon from "../Settings/Elements/CommingSoon";
import dashboardImage from "./dashboard2.png";
export default function DashboardPage() {
    return (
        <div
            style={{
                position: "relative",
                height: "calc(100vh - 100px)",
                backgroundColor: "white",
                margin: "10px",
                overflow: "hidden",
                borderRadius: "10px",
                // padding: "20px",
            }}
        >
            <img src={dashboardImage} width='100%' />
            <CommingSoon page={"dashboard"} />
        </div>
    );
}
