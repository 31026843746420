import React from "react";

export default function PaginationLink({
  title,
  rightIcon,
  leftIcon,
  active,
  onClick,
  deactivated = false,
}: PaginationLinkInterface) {
  const getAnchorClassName = () => {
    let baseClass =
      "flex gap-1 items-center rounded py-2 px-4 border cursor-pointer font-semibold";
    if (active) {
      baseClass += " bg-default-active text-white border-transparent";
    } else if (deactivated) {
      baseClass += " bg-white text-default opacity-75";
    } else {
      baseClass +=
        " bg-white text-default hover:bg-default-active hover:text-white hover:border-transparent";
    }
    return baseClass;
  };
  return (
    <a onClick={onClick} className={getAnchorClassName()}>
      {leftIcon && leftIcon}
      {title}
      {rightIcon && rightIcon}
    </a>
  );
}

interface PaginationLinkInterface {
  title: string | number | React.ReactNode;
  rightIcon?: string | JSX.Element;
  leftIcon?: string | JSX.Element;
  active?: boolean;
  deactivated?: boolean;
  onClick?: React.MouseEventHandler;
}
