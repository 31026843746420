import React from "react";
import Datatable from "../../../organism/Datatable";
import { useIntl } from "react-intl";
import * as columnFormatters from "./column-formatters";

export default function UnavailabilityTable({
  data,
  queryParams,
  openEditUnavailabilityDialog,
  openEditDeleteUnavailabilityDialog
}: UnavailabilityTableInterface) {
  const intl = useIntl();
  const columns = [
    {
      dataField: "startDate",
      text: intl.formatMessage({ id: "EMPLOYEE.EDIT.UNAVAIBILITY.START_DATE" }),
      formatter: columnFormatters.StartDateColmunFormatter,
    },
    {
      dataField: "endDate",
      text: intl.formatMessage({ id: "EMPLOYEE.EDIT.UNAVAIBILITY.END_DATE" }),
      formatter: columnFormatters.EndDateColumnFormatter,
    },
    {
      dataField: "type",
      text: intl.formatMessage({ id: "EMPLOYEE.EDIT.UNAVAIBILITY.REPEAT" }),
      formatter: columnFormatters.TypeColumnFormatter,
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "EMPLOYEE.EDIT.UNAVAIBILITY.ADD_DATE" }),
      formatter: columnFormatters.AddDateColumnFormatter,
    },
    {
      dataField: "employee",
      text: intl.formatMessage({ id: "EMPLOYEE.EDIT.UNAVAIBILITY.CREATED_BY" }),
      formatter: columnFormatters.CreatedByColumnFormatter,
    },
    {
      dataField: "",
      text: "",
      formatter: columnFormatters.ActionsColumnFormattter,
      formatExtraData: {
        openEditUnavailabilityDialog,
        deleteUnavaibility: openEditDeleteUnavailabilityDialog,
      },
    },
  ];

  return <Datatable columns={columns} data={data} />;
}

interface UnavailabilityTableInterface {
  data: any[];
  queryParams: {};
  openEditUnavailabilityDialog: (unavaibility: any) => void;
  openEditDeleteUnavailabilityDialog: (unavaibility: any) => void;
}
