import React from "react";
import { useIntl } from "react-intl";

export default function NoDocumentsFound({
    addDocumentButton,
}: NoContractsFoundInterface) {
    const intl = useIntl();
    return (
        <div className="p-10 mx-auto flex flex-col max-w-[600px] items-center gap-5">
            <img
                className="max-w-[200px]"
                src="/assets/imgs/file-loop.png"
                alt="icon"
            />
            <h2 className="inline-block text-n-700 font-bold text-6xl">
                {intl.formatMessage({ id: "EMPLOYEE.EDIT.NO_DOCUMENT_FOUND" })}
            </h2>
            <p className="font-light text-md text-n-500 text-center">
                Le module d'ajout de documents permet de télécharger, organiser
                et stocker divers types de fichiers (PDF, Word, Excel, etc.). Il
                facilite l'importation et la gestion des documents, assurant un
                accès rapide et sécurisé.
            </p>
            {addDocumentButton}
        </div>
    );
}

interface NoContractsFoundInterface {
    addDocumentButton: React.ReactNode;
}
