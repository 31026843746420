import React from "react";

export const AdressBook = ({ width = 27, height = 24, color = "currentColor" }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.34546 18V22H21.7273V2H4.34546V6"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.25903 15H5.43176"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.25903 12H5.43176"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.25903 9H5.43176"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.0363 10.5C14.2362 10.5 15.209 9.60457 15.209 8.5C15.209 7.39543 14.2362 6.5 13.0363 6.5C11.8363 6.5 10.8635 7.39543 10.8635 8.5C10.8635 9.60457 11.8363 10.5 13.0363 10.5Z"
        fill={color}
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.3818 17.5C17.3818 15.2908 15.4363 13.5 13.0364 13.5C10.6364 13.5 8.69092 15.2908 8.69092 17.5"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

interface IconProps {
    width?: number;
    height?: number;
    color?: string;
  }
  