export const BordereArrowLeft = ({
  color = "#00A42E",
  width = 16,
  height = 16,
}: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.794 1.3335H5.20732C2.78065 1.3335 1.33398 2.78016 1.33398 5.20683V10.7868C1.33398 13.2202 2.78065 14.6668 5.20732 14.6668H10.7873C13.214 14.6668 14.6607 13.2202 14.6607 10.7935V5.20683C14.6673 2.78016 13.2207 1.3335 10.794 1.3335ZM12.0007 8.50016H5.20732L7.21398 10.5068C7.40732 10.7002 7.40732 11.0202 7.21398 11.2135C7.11398 11.3135 6.98732 11.3602 6.86065 11.3602C6.73398 11.3602 6.60732 11.3135 6.50732 11.2135L3.64732 8.3535C3.55398 8.26016 3.50065 8.1335 3.50065 8.00016C3.50065 7.86683 3.55398 7.74016 3.64732 7.64683L6.50732 4.78683C6.70065 4.5935 7.02065 4.5935 7.21398 4.78683C7.40732 4.98016 7.40732 5.30016 7.21398 5.4935L5.20732 7.50016H12.0007C12.274 7.50016 12.5007 7.72683 12.5007 8.00016C12.5007 8.2735 12.274 8.50016 12.0007 8.50016Z"
      fill="#00A42E"
    />
  </svg>
);

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}
