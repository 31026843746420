import * as React from "react";

import Authentification from "../../components/templates/Authentification/Authentification";

export default function LoginPage(props: LoginPageProps) {
  const { authType } = props;
  return (
    <div>
      <Authentification authType={authType} />
    </div>
  );
}

interface LoginPageProps {
  authType?:
    | "login"
    | "reset-password"
    | "change-password"
    | "forget-password"
    | "check-email"
    | "custom";
}

LoginPage.defaultProps = {
  authType: "login",
};
