import * as React from "react";
import "./style.scss";
import Modal from "../../atoms/Modal";
import PopUpCard from "../../molecules/PopUpCard";
import TextData from "../../atoms/TextData";
// import SelectInput from "../../atoms/SelectInput";
import moment from "moment";
import {
    generateWeekList,
    // employeeRoleColor,
} from "../../../_helpers/Functions";
// import ToggleButton from "../../atoms/Toggle";
import Checkbox from "../../atoms/Checkbox";
// import Picture from "../../atoms/Picture";
// import Input from "../../atoms/Input";

import img1 from "./pictures/1.png";
import img2 from "./pictures/2.png";
import img3 from "./pictures/3.png";
import img4 from "./pictures/4.png";
import img6 from "./pictures/6.png";
// import SearchSVG from "./search.svg";
// import ProfilePicture from "../../molecules/ProfilePicture";
// import { ROUTER } from "../../../constants/env";
import { ReactComponent as CalendarSVG } from "./calendar.svg";
import { usePlanning } from "../../../hooks/usePlanning";

export default function DuplicatePlanningCard(
    props: any /* DuplicatePlanningCardProps*/,
) {
    const { display, onClose, department } = props;
    const {
        getPlannedWeeks,
        duplicatePlanning,
        planningLoading,
        plannedWeeks,
        planningStartOfWeek,
        planningInfo,
        employeesData,
    } = usePlanning();
    const [searchTerm, setSearchTerm] = React.useState("");
    // const handleSearch = (data: any) => {
    //     setSearchTerm(data);
    // };

    // const [selectAll, setselectAll] = React.useState(false);
    // const handleCheckAll = (e: any) => {
    //     // setselectAll(!selectAll);
    // };
    // const [filteredEmployees, setFilteredEmployees] = React.useState([]);
    // const handleEmployeeCheckedStatus = (
    //     actualEmpSelection: boolean | undefined
    // ) => {
    //     if (selectAll == undefined) {
    //         return actualEmpSelection;
    //     } else {
    //         return selectAll;
    //     }
    // };
    // const [weeksToDuplicate, setWeeks] = React.useState<any>([]);
    React.useEffect(() => {
        const handleCheckWeeks = async () => {
            let weeks: any = [];
            let year = -1;
            for (let i = 1; i < 9; i++) {
                let weekDay = moment(planningStartOfWeek, "YYYY-MM-DD").add(
                    i,
                    "weeks",
                );
                if (year === -1) {
                    year = weekDay.year();
                    weeks = [{ year, weeks: [weekDay.week()] }];
                } else {
                    if (weekDay.year() === year) {
                        weeks[weeks.length - 1].weeks.push(weekDay.week());
                    } else {
                        year = weekDay.year();
                        weeks = [...weeks, { year, weeks: [weekDay.week()] }];
                    }
                }
            }
            // setWeeks(weeks);
            await getPlannedWeeks(weeks, department.depId);
        };
        if (display) {
            handleCheckWeeks();
        }
        // else {
        // setWeeks([]);
        // }
    }, [display]);
    React.useEffect(() => {
        if (employeesData) {
            const filtered: any = Object.values(employeesData)?.filter(
                (employee: any) => {
                    const fullName =
                        `${employee.fname} ${employee.lname}`.toLowerCase();
                    return fullName.includes(searchTerm.toLowerCase());
                },
            );
            filtered?.sort((a: any, b: any) => {
                // First, sort by isSelected
                if (b.isSelected - a.isSelected !== 0) {
                    return b.isSelected - a.isSelected;
                }
                // Then, sort by name
                return a.fname.localeCompare(b.fname);
            });
            // setFilteredEmployees(filtered);
        }
    }, [employeesData, searchTerm]);

    const HeaderTitle = () => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "25px auto 25px 40px",
                }}
            >
                <TextData
                    variant="label"
                    width="auto"
                    size="22px"
                    fontWeight="700"
                    color="#2A346D"
                >
                    <div>
                        Dupliquer le planning de la semaine {planningInfo.week}
                    </div>
                    <span
                        style={{
                            fontSize: "18px",
                            fontWeight: "600",
                        }}
                    >
                        Département: {department.name}
                    </span>
                </TextData>
            </div>
        );
    };
    // const weekList = generateWeekList(
    //     "2022-01-07",
    //     moment().format("YYYY-MM-DD")
    // );
    const isWeekPlaned = (weekNumber: any, year: any) => {
        if (plannedWeeks?.length > 0) {
            for (let i = 0; i < plannedWeeks.length; i++) {
                if (
                    plannedWeeks[i].week === weekNumber &&
                    plannedWeeks[i].year === year
                ) {
                    return true;
                }
            }
        }
        return false;
    };
    const [checkedWeeks, setCheckedWeeks] = React.useState<any>([]);
    const [weekCheckBox, setWeekCheckBox] = React.useState(
        Array(8).fill(false),
    );
    const handleClose = () => {
        onClose && onClose();
        setWeekCheckBox(Array(8).fill(false));
        setCheckedWeeks([]);
    };
    const handleDuplicatePlanning = async () => {
        let ok = await duplicatePlanning(checkedWeeks, department.depId);
        if (ok) handleClose();
    };
    const WeekToReplace = (props: any) => {
        const futureWeeks = generateWeekList(
            moment(planningStartOfWeek, "YYYY-MM-DD")
                .startOf("day")
                .clone()
                .add(1, "week")
                .format("YYYY-MM-DD"),
            moment(planningStartOfWeek, "YYYY-MM-DD")
                .startOf("day")
                .clone()
                .add(4, "week")
                .format("YYYY-MM-DD"),
        );

        const handleChangeCheckWeeks = (
            weekNumber: any,
            year: any,
            index: number,
        ) => {
            let updatedWeekCheckBox = weekCheckBox;
            updatedWeekCheckBox[index] = !updatedWeekCheckBox[index];
            setWeekCheckBox(updatedWeekCheckBox);
            let weekFound = false;
            let updatedCheckedWeeks = [];
            for (let i = 0; i < checkedWeeks.length; i++) {
                if (checkedWeeks[i].week !== weekNumber) {
                    updatedCheckedWeeks.push(checkedWeeks[i]);
                } else {
                    weekFound = true;
                }
            }
            if (!weekFound) {
                updatedCheckedWeeks.push({
                    checked: true,
                    date: moment()
                        .year(year)
                        .isoWeek(weekNumber)
                        .startOf("isoWeek")
                        .add(1, "week")
                        .format("DD/MM/YYYY"),
                    end: moment()
                        .year(year)
                        .isoWeek(weekNumber)
                        .endOf("isoWeek")
                        .format("dddd D MMMM"),
                    start: moment()
                        .year(year)
                        .isoWeek(weekNumber)
                        .startOf("isoWeek")
                        .format("dddd D MMMM"),
                    planned: isWeekPlaned(weekNumber, year),
                    week: weekNumber,
                    year: year,
                });
            }
            setCheckedWeeks(updatedCheckedWeeks);
        };
        return (
            <div className="week-to-replace-container">
                {futureWeeks?.map(({ date, weekNumber, year }: any, index) => {
                    return (
                        <div className="week-item-box">
                            <div className="week-item-right">
                                <CalendarSVG width={16} height={16} />
                                <TextData
                                    variant="label"
                                    width="auto"
                                    color="#2A346D"
                                    size="14px"
                                    fontWeight="bold"
                                >
                                    Semaine {weekNumber}
                                </TextData>
                                <TextData
                                    variant="label"
                                    width="auto"
                                    color="#2A346D"
                                    size="14px"
                                    fontWeight="400"
                                >
                                    ({date})
                                </TextData>
                            </div>
                            <div className="week-item-left">
                                {isWeekPlaned(weekNumber, year) ? (
                                    <TextData
                                        variant="label"
                                        width="auto"
                                        color="#E38609"
                                        size="12px"
                                        fontWeight="400"
                                    >
                                        Un shift est déjà planifié
                                    </TextData>
                                ) : (
                                    <TextData
                                        variant="label"
                                        width="auto"
                                        color="gray"
                                        size="12px"
                                        fontWeight="400"
                                    >
                                        Aucun shift planifié
                                    </TextData>
                                )}
                                <Checkbox
                                    onClick={() =>
                                        handleChangeCheckWeeks(
                                            weekNumber,
                                            year,
                                            index,
                                        )
                                    }
                                    id={`week-${index}`}
                                    text={null}
                                    rounded={true}
                                    checked={weekCheckBox[index]}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };
    return (
        <Modal modalEnabled={display} onClose={handleClose}>
            <div className="duplicate-container">
                <PopUpCard
                    containerWidth="600px"
                    display={display}
                    onClose={handleClose}
                    header={<HeaderTitle />}
                    widthCloseIcon="24px"
                    styleCloseIcon={{
                        height: "auto",
                        marginRight: "37px",
                        width: "24px",
                        border: "1px solid #edeff533",
                        borderTopRightRadius: "20px",
                    }}
                    headerStyle={{
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#2A346D",
                    }}
                    popupHeaderStyle={{
                        height: "80px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                    }}
                    button2Text="Dupliquer"
                    button2Style={{
                        color: "white",
                        fontSize: "14px",
                        opacity: planningLoading ? "0.5" : "1",
                        cursor: planningLoading ? "wait" : "pointer",
                        backgroundColor: planningLoading ? "gray" : "#2A8BAB",
                    }}
                    onClickButton2={handleDuplicatePlanning}
                    footerMessage={
                        planningLoading
                            ? "Chargement en cours. Veuillez patienter..."
                            : ""
                    }
                >
                    <div className="duplication-container">
                        {/* <div className='week-duplication-container'>
                            <div style={{ marginLeft: "14px" }}>
                                <TextData
                                    width='auto'
                                    fontWeight='700'
                                    variant='label'
                                    size='16px'
                                    color='#6F78AB'
                                >
                                    Semaine à dupliquer
                                </TextData>
                                <TextData
                                    variant='label'
                                    width='auto'
                                    size='12px'
                                    fontWeight='500'
                                    color='#6F78AB'
                                >
                                    (Copier)
                                </TextData>
                            </div>
                            <SelectInput
                                title=''
                                options={weekList.map(({ date }: any) => {
                                    return {
                                        label: (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "5px",
                                                    margin: 0,
                                                }}
                                            >
                                                <CalendarSVG
                                                    width={16}
                                                    height={16}
                                                />
                                                <TextData
                                                    variant='label'
                                                    width='auto'
                                                    color='#2A346D'
                                                    size='14px'
                                                    fontWeight='400'
                                                >
                                                    {date}
                                                </TextData>
                                            </div>
                                        ),
                                        value: date,
                                    };
                                })}
                                selectedValue={{
                                    label: (
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                margin: 0,
                                                alignItems: "center",
                                            }}
                                        >
                                            <CalendarSVG
                                                width={16}
                                                height={16}
                                            />
                                            <TextData
                                                variant='label'
                                                width='auto'
                                                color='#2A346D'
                                                size='14px'
                                                fontWeight='400'
                                            >
                                                {weekList[0].date}
                                            </TextData>
                                        </div>
                                    ),
                                    value: weekList[0].date,
                                }}
                                style={{ height: "48px" }}
                            />
                        </div> */}
                        <div className="week-replace-container">
                            <div style={{ marginLeft: "14px" }}>
                                <TextData
                                    width="auto"
                                    fontWeight="700"
                                    variant="label"
                                    size="16px"
                                    color="#6F78AB"
                                >
                                    Semaine à remplacer
                                </TextData>
                                <TextData
                                    variant="label"
                                    width="auto"
                                    size="12px"
                                    fontWeight="500"
                                    color="#6F78AB"
                                >
                                    (Copier)
                                </TextData>
                            </div>
                            <WeekToReplace />
                        </div>
                        {/* <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                            className='duplicate-absence-container'
                        >
                            <TextData
                                variant='label'
                                width='auto'
                                size='14px'
                                fontWeight='400'
                                color='#8E96C1'
                            >
                                Dupliquer les absences
                            </TextData>
                            <ToggleButton />
                        </div> */}
                        {/* // TODO: Separation */}
                        {/* <div className='search-container'>
                            <div className='employees-section'>
                                <TextData
                                    variant='label'
                                    width='auto'
                                    color='#6F78AB'
                                    size='16px'
                                    fontWeight='bolder'
                                    style={{
                                        textAlign: "left",
                                        marginLeft: "15px",
                                    }}
                                >
                                    Selectionner les employés
                                </TextData>
                                <div className='new-dept-search-bar'>
                                    <Input
                                        captionText={null}
                                        borderWidth={0}
                                        iconImg={SearchSVG}
                                        iconLocation='left'
                                        placeholder='Rechercher'
                                        inputWidth='100%'
                                        style={{
                                            inputStyle: {
                                                backgroundColor: "transparent",
                                                outline: "none",
                                                width: "100%",
                                                padding: "7px 11px 7px 13px",
                                                color: "#8E96C1",
                                                gap: "8px",
                                            },
                                        }}
                                        onChange={handleSearch}
                                    />
                                    <TextData
                                        style={{
                                            marginRight: 5,
                                        }}
                                        width='auto'
                                        size='12px'
                                        variant='regular'
                                        fontWeight='600'
                                    >
                                        Tous
                                    </TextData>
                                    <Checkbox
                                        onClick={handleCheckAll}
                                        id='all-emp'
                                        text={null}
                                        rounded={true}
                                        checked={false}
                                        style={{
                                            divStyle: {
                                                marginRight: "11px",
                                            },
                                        }}
                                    />
                                </div>
                                <div className='new-dept-list-of-employees'>
                                    {filteredEmployees &&
                                        filteredEmployees.map((elem: any) => {
                                            const {
                                                fname,
                                                lname,
                                                picture,
                                                role,
                                            } = elem;
                                            const pictureProps = {
                                                imgPath:
                                                    ROUTER.STATIC_IMG(picture),
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: "50%",
                                                style: { borderRadius: "50%" },
                                            };
                                            return (
                                                <div
                                                    key={elem._id}
                                                    className='employee'
                                                >
                                                    <div className='information'>
                                                        <ProfilePicture
                                                            profileImage={
                                                                picture
                                                            }
                                                            firstName={fname}
                                                            lastName={lname}
                                                            pictureProps={
                                                                pictureProps
                                                            }
                                                            altColor={employeeRoleColor(
                                                                role
                                                            )}
                                                        />
                                                        <TextData
                                                            variant='label'
                                                            width='auto'
                                                            size='14px'
                                                            fontWeight='bolder'
                                                            color='#101844'
                                                        >
                                                            {fname +
                                                                " " +
                                                                lname}
                                                        </TextData>
                                                    </div>
                                                   <div className='employee-selection'>
                                                        <Checkbox
                                                            id={_id}
                                                            text={null}
                                                            rounded={true}
                                                            checked={handleEmployeeCheckedStatus(
                                                                elem.isSelected
                                                            )}
                                                            onClick={() => {
                                                                console.log(
                                                                    "checked"
                                                                );
                                                            }}
                                                        />
                                                    </div> 
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div> */}
                    </div>
                </PopUpCard>
            </div>
        </Modal>
    );
}
interface Employee {
    id: string;
    name: string;
    picture: string;
    isSelected?: boolean;
}
interface DuplicatePlanningCardProps {
    display?: boolean;
    onClose?: (event?: Event) => void;
    employeeList?: Employee[];
}
DuplicatePlanningCard.defaultProps = {
    display: true,
    onClose: () => {
        console.log("onClose");
    },
    employeeList: [
        {
            name: "Kristin Watson",
            id: "1",
            picture: img1,
            isSelected: false,
        },
        {
            name: "Theresa Webb",
            id: "2",
            picture: img2,
            isSelected: false,
        },
        {
            name: "Cameron Williamson",
            id: "3",
            picture: img3,
            isSelected: false,
        },
        {
            name: "Jerome Bell",
            id: "4",
            picture: img4,
            isSelected: false,
        },
        {
            name: "Annette Black",
            id: "5",
            picture: img6,
            isSelected: false,
        },
    ],
};
