import React from "react";
import classNames from "classnames";
import Button from "../../atoms/Button";
import { NavLink, Link, Route, useLocation } from "react-router-dom";

import "./style.scss";
import Table from "../../organism/Table";
import SelectInput from "../../atoms/SelectInput";
import TextData from "../../atoms/TextData";
import ReportDisplayOption from "../ReportDisplayOption";
import CalendarInput from "../../molecules/CalendarInput";
import EmployeeInfoHeaderRow from "../EmployeeInfoHeaderRow";

import {
    WorkedHoursTable,
    WorkedHoursTableRightMenu,
    ActivityTable,
    ActivityTableRightMenu,
    PayrollTable,
    PayrollTableRightMenu,
} from "../../organism/ReportManagement";

export default function TabNavigatorReport(props: TabnavigatorInterface) {
    const { tabs } = props;
    const location = useLocation();
    const [tabState, settabState] = React.useState(
        tabs.map((tab) => location.pathname === tab.link),
    );

    const handleClick = (key: number) => {
        const updatedArr = Array(tabs.length).fill(false);
        updatedArr[key] = true;
        settabState(updatedArr);
        tabs[key]?.onClick?.(key);
    };

    React.useEffect(() => {
        const currentTab = tabs.findIndex(
            (tab) => tab.link === location.pathname,
        );
        if (currentTab !== -1) {
            const updatedTabState = Array(tabs.length).fill(false);
            updatedTabState[currentTab] = true;
            settabState(updatedTabState);
        }
    }, [tabs, location]);

    return (
        <>
            <div>
                {tabs.map(
                    (tab, key) =>
                        tabState[key] && (
                            <div key={key}>{tab.headerComponent}</div>
                        ),
                )}
            </div>
            <div className="report-tab-navigation-container">
                <div className="header-banner">
                    <ul className="tab-switcher">
                        {tabs.map((tab, key) => (
                            <NavLink to={tab.link}>
                                <li
                                    onClick={() => handleClick(key)}
                                    style={{
                                        color: tabState[key]
                                            ? "#1A7290"
                                            : "#5BB3D0",
                                        borderBottom: tabState[key]
                                            ? "4px solid #2A8BAB"
                                            : "",
                                        // backgroundColor: "red",
                                    }}
                                    key={key}
                                >
                                    {tab.title}
                                </li>
                            </NavLink>
                        ))}
                    </ul>
                    <ul className="side-tabs">
                        {tabs.map(
                            (tab, key) =>
                                tabState[key] && (
                                    <li key={key}>
                                        {tabState[key] && tab.rightTabs}
                                    </li>
                                ),
                        )}
                    </ul>
                </div>
                {tabs.map(
                    (tab, key) =>
                        tabState[key] && <div key={key}>{tab.component}</div>,
                )}
            </div>
        </>
    );
}
interface TabnavigatorInterface {
    tabs: Array<TabsInterface>;
    onClick?: (data: any) => void;
}
interface TabsInterface {
    title: string;
    component?: React.ReactNode;
    headerComponent?: React.ReactNode;
    rightTabs?: React.ReactNode;
    link: string;
    active?: boolean;
    onClick?: (data: any) => void;
}

TabNavigatorReport.defaultProps = {
    tabs: [
        {
            title: "Heures travaillées",
            component: <Table {...WorkedHoursTable} />,
            rightTabs: <WorkedHoursTableRightMenu />,
            link: "/test1",
            active: false,
            onClick: () => {},
        },
        {
            title: "Productivité",
            component: <Table />,
            rightTabs: (
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <ReportDisplayOption />
                    <SelectInput
                        title=""
                        options={[{ label: "Année", value: "Année" }]}
                        style={{
                            width: "auto",
                            height: "30px",
                            marginRight: "10px",
                            borderRadius: "9px",
                            fontSize: "12px",
                            padding: "0 10px",
                        }}
                    ></SelectInput>
                    <SelectInput
                        title=""
                        options={[{ label: "Mois", value: "Mois" }]}
                        style={{
                            width: "auto",
                            height: "30px",
                            marginRight: "10px",
                            borderRadius: "9px",
                            fontSize: "12px",
                            padding: "0 10px",
                        }}
                    ></SelectInput>
                    <SelectInput
                        title=""
                        options={[
                            {
                                label: "Selectionner un employé",
                                value: "Selectionner un employé",
                            },
                        ]}
                        style={{
                            width: "210px",
                            height: "30px",
                            marginRight: "10px",
                            borderRadius: "9px",
                            fontSize: "12px",
                            padding: "0 10px",
                        }}
                    ></SelectInput>
                </div>
            ),
            link: "/test2",
            active: false,
            onClick: () => {},
        },
        {
            title: "Rapport paie",
            component: <Table {...PayrollTable} />,
            rightTabs: <PayrollTableRightMenu />,
            link: "/test3",
            active: false,
            onClick: () => {},
        },
        {
            title: "Activité",
            component: (
                <>
                    <Table {...ActivityTable} />
                </>
            ),
            rightTabs: <ActivityTableRightMenu />,
            link: "/test4",
            active: false,
            onClick: () => {},
        },
    ],
    onClick: () => {},
};
