import React from "react";
import AddAmendmentDialog from "../../../components/templates/EmployeesEdit/EditAmendmentDialog";
import { generateFields } from "../../../_helpers/FormHelpers";

export default function AddAmendmentDialogPage({
  amendment,
  show,
  onHide,
}: EditAmendmentDialogPageInterface) {
  const [amend, setAmendment] = React.useState<any>();

  React.useEffect(() => {
    const fields = generateFields([
      { name: "permanent", value: amendment ? amendment.permanent : false },
      { name: "startDate", value: amendment ? amendment.startDate : null },
      { name: "endDate", value: amendment ? amendment.endDate : null },
      {
        name: "tempsDeTravailHebdomadaire",
        value: amendment ? amendment.tempsDeTravailHebdomadaire : "",
      },
      {
        name: "joursParSemaine",
        value: amendment ? amendment.joursParSemaine : "",
      },
      {
        name: "salaireBrutMensuel",
        value: amendment ? amendment.joursParSemaine : "",
      },
    ]);
    setAmendment(fields);
  }, [amendment]);

  const onSubmit = () => {};

  const onChange = (field: string, value: any) => {
    setAmendment((prev: any) => ({
      ...prev,
      [field]: {
        ...prev[field],
        value,
      },
    }));
  };

  return (
    <>
      {amend && (
        <AddAmendmentDialog
          amendment={amend}
          onHide={onHide}
          show={show}
          onSubmit={onSubmit}
          onChange={onChange}
        />
      )}
    </>
  );
}

interface EditAmendmentDialogPageInterface {
  amendment: any;
  show: boolean;
  onHide(): void;
}
