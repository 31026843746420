export const RestoreTray = ({
  width = 24,
  height = 24,
  color = "currentColor",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 3.69141H4.5C4.08579 3.69141 3.75 4.02195 3.75 4.42969V19.1953C3.75 19.6031 4.08579 19.9336 4.5 19.9336H19.5C19.9142 19.9336 20.25 19.6031 20.25 19.1953V4.42969C20.25 4.02195 19.9142 3.69141 19.5 3.69141Z"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.1816 9.77686L11.9997 6.6446L8.8177 9.77685"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.9995 14.0273L11.9995 6.64453"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.74951 14.7656H7.18885C7.28734 14.7656 7.38487 14.7847 7.47586 14.8218C7.56686 14.8589 7.64954 14.9133 7.71918 14.9819L9.52984 16.7642C9.59949 16.8328 9.68216 16.8872 9.77316 16.9243C9.86415 16.9614 9.96168 16.9805 10.0602 16.9805H13.9389C14.0373 16.9805 14.1349 16.9614 14.2259 16.9243C14.3169 16.8872 14.3995 16.8328 14.4692 16.7642L16.2798 14.9819C16.3495 14.9133 16.4322 14.8589 16.5232 14.8218C16.6142 14.7847 16.7117 14.7656 16.8102 14.7656H20.2495"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}
