import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchSitesManagemnt } from "./service";
import { SitesState } from "./types";
const initialState: SitesState = {
    sites: [],
    sitesManagement: [],
    listLoading: false,
    error: null,
};

const sitesSlice = createSlice({
    name: "sites",
    initialState,
    reducers: {
        resetAllSites: (state) => {
            state = initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSitesManagemnt.pending, (state: SitesState) => {
            state.listLoading = true;
            state.error = null;
        });
        builder.addCase(
            fetchSitesManagemnt.fulfilled,
            (state: SitesState, action: PayloadAction<any>) => {
                const sites = action.payload;
                state.listLoading = false;
                state.error = null;
                state.sitesManagement = sites;
            }
        );
        builder.addCase(
            fetchSitesManagemnt.rejected,
            (state: SitesState, action: PayloadAction<any>) => {
                state.listLoading = false;
                state.error = "error";
            }
        );
    },
});

export const { resetAllSites } = sitesSlice.actions;
const { reducer } = sitesSlice;
export default reducer;
