import React from "react";

import { ReactComponent as MsgSVG } from "./Msg.svg";
import { ReactComponent as TimeSVG } from "./time.svg";
import TextData from "../../atoms/TextData";
import { usePlanning } from "../../../hooks/usePlanning";
import "./style.css";
export default function ShiftDetails(props: any) {
    const { workedTime, color, comment } = props;
    const { planningInfo } = usePlanning();
    return (
        <div
            className="day-cell-details"
            style={{
                alignItems: "center",
                backgroundColor: color,
                border: `1px solid ${color}`,
                cursor: !planningInfo?.published ? "pointer" : "",
                flexDirection: "row",
                justifyContent: "center",
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
            }}
        >
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                }}
            >
                {comment && /\S/.test(comment) && (
                    <div
                        className="cell-comment"
                        style={{
                            width: "fit-content",
                            height: "fit-content",
                            padding: "0",
                            position: "absolute",
                            alignSelf: "flex-start",
                            marginTop: "7px",
                            cursor: "pointer",
                            top: "0",
                            right: "0",
                        }}
                    >
                        <MsgSVG
                            height={20}
                            width={20}
                            style={{
                                minHeight: 15,
                                minWidth: 15,
                                alignSelf: "flex-start",
                                justifySelf: "end",
                            }}
                            color="white"
                        />

                        <div
                            className="comment-text"
                            style={{
                                position: "absolute",
                                fontSize: "13px",
                                backgroundColor: "#d1d1e8",
                                zIndex: "99",
                                left: "calc(100% + 4px)",
                                top: "0",
                                border: "1px solid #817fdd",
                                borderRadius: "5px",
                                color: "#4240c3",
                                textAlign: "left",
                                width: "max-content",
                                padding: "3px 6px",
                            }}
                        >
                            {comment}
                        </div>
                    </div>
                )}

                <TimeSVG style={{ minHeight: 20, minWidth: 20 }} />
                <TextData
                    variant="label"
                    width="auto"
                    color="white"
                    size="15px"
                    fontWeight="500"
                    style={{
                        marginLeft: "3px",
                        cursor: !planningInfo?.published ? "pointer" : "",
                    }}
                >
                    Durée: {workedTime}
                </TextData>
            </div>
        </div>
    );
}
