import React from "react";
import { useIntl } from "react-intl";
import TabNavigator from "../../molecules/TabNavigator";
import TabContent from "../../atoms/TabContent";

export default function HRFolder({
    PersonalInfo,
    Contracts,
    WorkHours,
    Holidays,
    Notes,
    Bonuses,
}: HRFolderInterface) {
    const intl = useIntl();

    const tabs = [
        { title: intl.formatMessage({ id: "EMPLOYEE.EDIT.PERSONAL_INFOS" }) },
        { title: intl.formatMessage({ id: "EMPLOYEE.EDIT.CONTRACTS" }) },
        { title: intl.formatMessage({ id: "EMPLOYEE.EDIT.WORK_HOURS" }) },
        { title: intl.formatMessage({ id: "EMPLOYEE.EDIT.HOLIDAYS" }) },
        { title: intl.formatMessage({ id: "EMPLOYEE.EDIT.NOTES" }) },
        { title: intl.formatMessage({ id: "EMPLOYEE.EDIT.BONUSES" }) },
    ];

    const [activeTab, setActiveTab] = React.useState(0);

    const onMenuChange = (tab: number) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return (
        <TabNavigator
            headerClasses={`!border-b-[1px] hover:border-t-500 hover:text-t-500 border-b-transparent !text-base`}
            tabs={tabs.map((tab, index) => ({
                ...tab,
                onClick: onMenuChange,
                active: activeTab === index,
            }))}
        >
            <TabContent
                value={activeTab}
                index={0}
                component={PersonalInfo}
                containerClasses="py-5"
            />
            <TabContent
                value={activeTab}
                index={1}
                component={Contracts}
                containerClasses="py-5"
            />
            <TabContent
                value={activeTab}
                index={2}
                component={WorkHours}
                containerClasses="py-5"
            />
            <TabContent
                value={activeTab}
                index={3}
                component={Holidays}
                containerClasses="py-5"
            />
            <TabContent
                value={activeTab}
                index={4}
                component={Notes}
                containerClasses="py-5"
            />
            <TabContent
                value={activeTab}
                index={5}
                component={Bonuses}
                containerClasses="py-5"
            />
        </TabNavigator>
    );
}

interface HRFolderInterface {
    PersonalInfo: React.ReactNode;
    Contracts: React.ReactNode;
    WorkHours: React.ReactNode;
    Holidays: React.ReactNode;
    Notes: React.ReactNode;
    Bonuses: React.ReactNode;
}
