import React from "react";
import "./style.css";
import Modal from "../../atoms/Modal";
import PopUpCard from "../../molecules/PopUpCard";
import TextData from "../../atoms/TextData";
import Button from "../../atoms/Button";
import SelectInput from "../../atoms/SelectInput";
import Input from "../../atoms/Input";
import { ReactComponent as UploadSVG } from "./upload.svg";
import { useEmployees } from "../../../hooks/useEmployees";
import Swal from "sweetalert2";
import Loading from "../../atoms/Loading";

export default function AddDocumentCard(props: AddDocumentCardProps) {
    const { display, onClose, isAddCard } = props;
    const {
        documents,
        manageSelectedDocument,
        selectedDocument,
        addNewDocument,
        editExistingDocument,
        getEmployeeDocuments,
        employeeForEdit,
    } = useEmployees();
    const HeaderTitle = () => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "25px auto 25px 40px",
                }}
            >
                <TextData
                    variant='label'
                    width='auto'
                    size='22px'
                    fontWeight='700'
                    color='#2A346D'
                >
                    {isAddCard ? "Ajouter un document" : "Modifier un document"}
                </TextData>
            </div>
        );
    };

    const visibilitiesDocument = [
        {
            label: "Employés, Managers et Administrateurs",
            value: "forEmployees",
        },
        {
            label: "Managers et Administrateurs",
            value: "forManagers",
        },
        {
            label: "Administrateurs uniquement",
            value: "forAdministrators",
        },
    ];

    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
    const [fileName, setFileName] = React.useState("Importer un document");

    const handleFileChange = (event: any) => {
        if (event.target.files.length > 0) {
            setIsImportedFile(false);
            setSelectedFile(event.target.files[0]);
        } else {
            setIsImportedFile(true);
            setSelectedFile(null);
        }
    };

    React.useEffect(() => {
        if (selectedFile) {
            setFileName(selectedFile.name);
        } else {
            setFileName("Importer un document");
        }
    }, [selectedFile]);

    const handleClick = () => {
        const refElem = fileInputRef?.current;
        if (refElem) {
            refElem.click();
        }
    };

    const handleFileName = (text: string) => {
        if (text.length === 0) {
            setIsErrFileName(true);
        } else {
            setIsErrFileName(false);
        }
        manageSelectedDocument({ fileName: text });
    };
    const [documentsCategories, setCategories] = React.useState(
        documents?.categories?.map((category: string) => {
            return {
                label: (
                    <div
                        style={{
                            display: "flex",
                            gap: "8px",
                            margin: 0,
                            alignItems: "center",
                        }}
                    >
                        <TextData
                            variant='label'
                            width='auto'
                            color='#2A346D'
                            size='14px'
                            fontWeight='400'
                        >
                            {category}
                        </TextData>
                    </div>
                ),
                value: category,
            };
        })
    );

    const handleCategories = (category: any) => {
        if (category.length > 0) {
            setIsErrCategory(false);
        } else {
            setIsErrCategory(true);
        }
        category = category.map((cat: any) => cat.value);
        manageSelectedDocument({ categories: category });
    };
    const handleDefaultCategories: any = () => {
        if (selectedDocument?.categories) {
            return selectedDocument?.categories.map((category: any) => {
                return {
                    label: (
                        <div
                            style={{
                                display: "flex",
                                gap: "8px",
                                margin: 0,
                                alignItems: "center",
                            }}
                        >
                            <TextData
                                variant='label'
                                width='auto'
                                color='#2A346D'
                                size='14px'
                                fontWeight='400'
                            >
                                {category}
                            </TextData>
                        </div>
                    ),
                    value: category,
                };
            });
            // if (selectedDocument?.categories.length >= 0) {
            //     return documentsCategories.filter((availableCategory: any) => {
            //         if (
            //             selectedDocument?.categories.find(
            //                 (sc: any) => sc === availableCategory.value
            //             )
            //         ) {
            //             return availableCategory;
            //         }
            //     });
            // }
        }
    };
    const handleDefaultVisibility = () => {
        if (!isAddCard && selectedDocument?.visibility) {
            return visibilitiesDocument.find(
                (visibleType: any) =>
                    visibleType.value === selectedDocument?.visibility
            );
        }
        return null;
    };
    const handleVisibilities = (visibility: any) => {
        if (visibility) {
            setIsErrVisibility(false);
        } else {
            setIsErrVisibility(true);
        }
        manageSelectedDocument({ visibility: visibility.value });
    };

    const [loading, setLoading] = React.useState(false);

    const handleSubmitDocument = async () => {
        const hasError =
            !selectedDocument?.fileName ||
            selectedDocument.fileName.length === 0 ||
            !selectedDocument?.categories ||
            selectedDocument.categories.length === 0 ||
            !selectedDocument?.visibility ||
            (isAddCard && !selectedFile);

        if (hasError) {
            Swal.fire({
                title: "Erreur!",
                text: "Veuillez corriger les champs en rouge pour continuer",
                icon: "error",
                timer: 1500,
                timerProgressBar: true,
            });
            setIsErrFileName(
                !selectedDocument?.fileName ||
                    selectedDocument.fileName.length <= 0
            );
            setIsErrCategory(
                !selectedDocument?.categories ||
                    selectedDocument.categories.length <= 0
            );
            setIsErrVisibility(!selectedDocument?.visibility);
            setIsImportedFile(!selectedFile);
            return;
        }
        await setLoading(true);
        if (isAddCard) {
            await addNewDocument(selectedFile);
        } else {
            await editExistingDocument();
        }
        await setLoading(false);
        await manageSelectedDocument({ reset: true });
        await getEmployeeDocuments({ id: employeeForEdit._id });
        await setIsErrFileName(false);
        await setIsErrCategory(false);
        await setIsErrVisibility(false);
        await setIsImportedFile(false);
        onClose && onClose();
    };

    const [isErrFileName, setIsErrFileName] = React.useState(false);
    const [isErrCategory, setIsErrCategory] = React.useState(false);
    const [isErrVisibility, setIsErrVisibility] = React.useState(false);
    const [isErrImportedFile, setIsImportedFile] = React.useState(false);

    return (
        <Modal modalEnabled={display} onClose={onClose}>
            <div className='duplicate-container'>
                <PopUpCard
                    containerWidth='400px'
                    display={display}
                    onClose={onClose}
                    header={<HeaderTitle />}
                    widthCloseIcon='24px'
                    styleCloseIcon={{
                        height: "auto",
                        marginRight: "37px",
                        width: "24px",
                        border: "1px solid #edeff533",
                        borderTopRightRadius: "20px",
                        cursor: "pointer",
                    }}
                    headerStyle={{
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#2A346D",
                    }}
                    popupHeaderStyle={{
                        height: "80px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                    }}
                    button1Text={isAddCard ? "Ajouter" : "Modifier"}
                    button2Text='Annuler'
                    // button2Text='Cancel'
                    button1Style={{ backgroundColor: "#2a8bab" }}
                    button2Style={{
                        backgroundColor: "#edeff5",
                        color: "#2a346d",
                    }}
                    onClickButton1={handleSubmitDocument}
                    onClickButton2={onClose}
                >
                    {loading ? (
                        <div style={{ height: 400 }}>
                            <Loading width='400px' height='200px' />
                        </div>
                    ) : (
                        <div className='add-document-form-container'>
                            <div className='doument-name-container'>
                                <TextData
                                    width='auto'
                                    fontWeight='700'
                                    variant='label'
                                    size='16px'
                                    color='#6F78AB'
                                >
                                    Nom du document
                                </TextData>
                                <Input
                                    type='text'
                                    placeholder='Nom du document'
                                    defaultValue={selectedDocument?.fileName}
                                    // value='09:05'
                                    captionText=''
                                    inputWidth='100%'
                                    borderColor='#C7CBE2'
                                    borderWidth={1}
                                    inputRadius='4px'
                                    textColor='#4C5690'
                                    textSize={14}
                                    iconLocation='right'
                                    style={{
                                        inputStyle: {
                                            minHeight: "48px",
                                            fontWeight: "400",
                                            paddingLeft: "15px",
                                            paddingRight: "15px",
                                        },
                                        captionStyle: {
                                            fontWeight: "500",
                                        },
                                    }}
                                    onChange={handleFileName}
                                />
                                {isErrFileName && (
                                    <TextData size='13px' color='#fb3640'>
                                        Ce champ est obligatoire
                                    </TextData>
                                )}
                            </div>
                            <div className='documents-categories-container'>
                                <TextData
                                    width='auto'
                                    fontWeight='700'
                                    variant='label'
                                    size='16px'
                                    color='#6F78AB'
                                >
                                    Catégorie du document
                                </TextData>
                                <SelectInput
                                    title=''
                                    placeholder=''
                                    options={documentsCategories}
                                    selectedValue={handleDefaultCategories()}
                                    style={{ height: "48px" }}
                                    isCreatable={true}
                                    isMultiple={true}
                                    onSelectOption={handleCategories}
                                />
                                {isErrCategory && (
                                    <TextData size='13px' color='#fb3640'>
                                        Ce champ est obligatoire
                                    </TextData>
                                )}
                            </div>
                            <div className='documents-categories-container'>
                                <TextData
                                    width='auto'
                                    fontWeight='700'
                                    variant='label'
                                    size='16px'
                                    color='#6F78AB'
                                >
                                    Accès au document
                                </TextData>
                                <SelectInput
                                    title=''
                                    placeholder=''
                                    options={visibilitiesDocument}
                                    selectedValue={handleDefaultVisibility()}
                                    style={{ height: "48px" }}
                                    onSelectOption={handleVisibilities}
                                    isSearchable={false}
                                />
                                {isErrVisibility && (
                                    <TextData size='13px' color='#fb3640'>
                                        Ce champ est obligatoire
                                    </TextData>
                                )}
                            </div>
                            {isAddCard && (
                                <div className='documents-categories-container'>
                                    <input
                                        ref={fileInputRef}
                                        type='file'
                                        accept='.pdf,.doc,.docx,.txt'
                                        hidden
                                        id='fileInput'
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor='fileInput'>
                                        <Button
                                            width='100%'
                                            backgroundColor='#CFE0E6'
                                            height='48px'
                                            style={{
                                                border: "1px solid #2A8BAB",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "10px",
                                            }}
                                            padding='7px'
                                            onClick={handleClick}
                                        >
                                            <UploadSVG
                                                height={20}
                                                width={20}
                                                color='#2A8BAB'
                                            />
                                            <TextData
                                                width='auto'
                                                fontWeight='500'
                                                variant='label'
                                                size='16px'
                                                color='#2A8BAB'
                                                style={{ cursor: "pointer" }}
                                            >
                                                {fileName}
                                            </TextData>
                                        </Button>
                                    </label>
                                    {isErrImportedFile && (
                                        <TextData size='13px' color='#fb3640'>
                                            Ce champ est obligatoire
                                        </TextData>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </PopUpCard>
            </div>
        </Modal>
    );
}
interface AddDocumentCardProps {
    display: boolean;
    onClose?: () => void;
    isAddCard?: boolean;
}
