export const CalendarThirty = ({
  width = 15,
  height = 14,
  color = "currentColor",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3337 2.3335H1.66699V12.8335H13.3337V2.3335Z"
      stroke={color}
      stroke-width="0.75"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.66699 5.8335V9.91683H11.0003V5.8335H8.66699Z"
      stroke={color}
      stroke-width="0.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.45801 1.1665V3.49984"
      stroke={color}
      stroke-width="0.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.54199 1.1665V3.49984"
      stroke={color}
      stroke-width="0.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 5.8335H6.33333V9.91683H4"
      stroke={color}
      stroke-width="0.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.33301 7.875H4.58301"
      stroke={color}
      stroke-width="0.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}
