import * as React from "react";
import classnames from "classnames";

import "./style.scss";

import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as EditIcon } from "./edit.svg";

export default function Ticket(props: TicketProps) {
    const {
        id,
        text,
        backgroundColor,
        border,
        fontSize,
        textColor,
        width,
        iconBackgroundColor,
        closeIconColor,
        editIconColor,
        handleClick,
        showEdit,
        handleEdit,
        showClose,
        handleClose,
        style,
    } = props;
    const onClickFunction = () => {
        handleEdit?.bind(null, id);
        handleClick?.();
    };
    const globalStyles = {
        ticketContainer: {
            className: "ticket-container",
            style: {
                backgroundColor,
                border,
                width,
                ...style?.container,
                minWidth: "80px",
                cursor: "pointer",
            },
            // onClick: handleEdit?.bind(null, id),
            onClick: handleClick,
        },
        ticketLabel: {
            className: "ticket-label",
            style: {
                fontSize,
                color: textColor,
                ...style?.text,
                cursor: "pointer",
            },
            onClick: handleEdit?.bind(null, id),
        },
        ticketEdit: {
            className: "ticket-edit",
            style: {
                backgroundColor: iconBackgroundColor,
                ...style?.edit,
            },
            onClick: handleEdit?.bind(null, id),
        },
        ticketClose: {
            className: "ticket-close",
            style: {
                backgroundColor: iconBackgroundColor,
                ...style?.close,
            },
            onClick: handleClose?.bind(null, id),
        },
    };

    return (
        <div {...globalStyles.ticketContainer}>
            <span {...globalStyles.ticketLabel}>{text}</span>
            {/* {(showEdit || showClose) && (
                <div
                    className='ticket-actions'
                    onClick={(e) => e.stopPropagation()}
                >
                    {showEdit && (
                        <div {...globalStyles.ticketEdit}>
                            <EditIcon
                                stroke={editIconColor}
                                width={10}
                                height={10}
                            />
                        </div>
                    )}
                    {showClose && (
                        <div {...globalStyles.ticketClose}>
                            <CloseIcon
                                stroke={closeIconColor}
                                width={10}
                                height={10}
                            />
                        </div>
                    )}
                </div>
            )} */}
        </div>
    );
}

interface TicketProps {
    id?: string;
    text?: string;
    backgroundColor?: string;
    border?: string;
    fontSize?: string;
    textColor?: string;
    width?: string;
    iconBackgroundColor?: string;
    closeIconColor?: string;
    editIconColor?: string;
    handleClick?: () => void;
    showEdit: boolean;
    handleEdit?: (data: any) => void;
    showClose: boolean;
    handleClose?: (data: any) => void;
    style?: {
        container?: React.CSSProperties;
        text?: React.CSSProperties;
        edit?: React.CSSProperties;
        close?: React.CSSProperties;
    };
    checked?: boolean;
}

Ticket.defaultProps = {
    id: "0",
    text: "Pizza",
    backgroundColor: "#eaf4f7",
    border: "1px solid var(--t-500)",
    fontSize: "14px",
    textColor: "var(--t-500)",
    width: "auto",
    iconBackgroundColor: "var(--t-500)",
    closeIconColor: "white",
    editIconColor: "white",
    showEdit: true,
    handleClick: () => {
        console.log("handleClick Ticket");
    },
    handleEdit: () => {},
    showClose: true,
    handleClose: () => {},
};
