import React from "react";
import Datatable from "../../../../components/organism/Datatable";
import moment from "moment";
import * as columnFormatters from "./column-formatters";

export default function RegisterTable({ data }: RegisterTableInterface) {
    let rows = new Array(data.length);
    const columns = [
        {
            dataField: "created_at",
            formatter: columnFormatters.YearColumnFormatter,
            formatExtraData: {
                rows,
            },
            getRowSpan: (data: any, row: any, column: any, rowId: number) => {
                let target = moment(row[column]).format("YYYY");
                if (
                    rowId > 0 &&
                    moment(data[rowId - 1][column]).format("YYYY") === target
                ) {
                    rows[rowId] = rows[rowId - 1];
                    return -1;
                }
                let repeatCount = 0;
                if (rowId === 0) {
                    rows[0] = true;
                } else {
                    rows[rowId] = !rows[rowId - 1];
                }

                data.forEach((element: any) => {
                    if (moment(element[column]).format("YYYY") === target) {
                        repeatCount += 1;
                    }
                });
                return repeatCount;
            },
            getCellClassName: (data: any, column: string, rowId: number) => {
                let className = " border-r-[6px] align-top w-[100px]";
                className +=
                    rows[rowId] === true ? " border-v-100" : " border-t-100";
                if (rowId > 0 && rows[rowId] === rows[rowId - 1])
                    className += " hidden";
                return className;
            },
        },
        {
            dataField: "fname",
            formatter: columnFormatters.CellColumnFormatter,
            formatExtraData: {
                rows,
            },
        },
    ];

    return (
        <>
            <Datatable
                data={data}
                columns={columns}
                headerContainerClasses="!hidden"
                rowClasses={(row, id) => {
                    let className = "";
                    if (id === 0 || (id > 0 && rows[id] !== rows[id - 1])) {
                        className += " border-t border-n-0";
                    }
                    if (id % 2 === 0) {
                        return className + " bg-white";
                    }
                    return (
                        className +
                        (rows[id] === true ? " bg-v-0-op-2" : " bg-t-0-op-2")
                    );
                }}
            />
        </>
    );
}

interface RegisterTableInterface {
    data: any[];
}
