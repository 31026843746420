import React from "react";
import { DoubleChevron, ArrowLineDownUp } from "../../../Icons";

export default function Datatable({
    columns = [],
    data = [],
    headerClasses,
    rowClasses,
    sortField,
    sortOrder,
    onTableSort,
    tableContainerClasses = "",
    headerContainerClasses = "",
    tableFixed = false,
    footer,
}: DatatableProps) {
    const sort = (field: string) => {
        let order: string;
        if (field == sortField) {
            order = sortOrder == "asc" ? "desc" : "asc";
        } else {
            order = "asc";
        }
        onTableSort && onTableSort(field, order);
    };

    return (
        <div className="">
            <table
                className={`bg-white w-full ${tableContainerClasses} ${
                    tableFixed ? "table-fixed" : ""
                }`}
            >
                <thead
                    className={`border-b border-border  ${headerContainerClasses}`}
                >
                    <tr>
                        {columns?.map((column, key) => (
                            <th
                                style={column.style ? { ...column.style } : {}}
                                className="p-5 font-light text-n-600 text-base text-left"
                                key={key}
                            >
                                {column.headerFormatter ? (
                                    column.headerFormatter()
                                ) : (
                                    <div>
                                        {column.sort ? (
                                            <div
                                                onClick={() =>
                                                    sort(column.dataField)
                                                }
                                                className="flex cursor-pointer hover:text-n-800"
                                            >
                                                {column.text
                                                    ? column.text
                                                    : column.dataField}
                                                {column.dataField !==
                                                sortField ? (
                                                    <span className="ml-2 flex flex-col gap-1 justify-center items-center">
                                                        <DoubleChevron />
                                                    </span>
                                                ) : (
                                                    <span className="ml-2 flex items-center">
                                                        <div
                                                            className={
                                                                sortOrder ==
                                                                "asc"
                                                                    ? "rotate-180"
                                                                    : ""
                                                            }
                                                        >
                                                            <ArrowLineDownUp />
                                                        </div>
                                                    </span>
                                                )}
                                            </div>
                                        ) : (
                                            <>
                                                {column.text
                                                    ? column.text
                                                    : column.dataField}
                                            </>
                                        )}
                                    </div>
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data?.map((row, keyRow) => (
                        <tr
                            className={
                                rowClasses ? rowClasses(row, keyRow) : ""
                            }
                            key={keyRow}
                        >
                            {columns?.map((column, keyCol) => (
                                <td
                                    rowSpan={
                                        column.getRowSpan
                                            ? column.getRowSpan(
                                                  data,
                                                  row,
                                                  column.dataField,
                                                  keyRow,
                                              )
                                            : 1
                                    }
                                    className={`px-5 py-4 ${
                                        column.getCellClassName &&
                                        column.getCellClassName(
                                            row,
                                            column.dataField,
                                            keyRow,
                                        )
                                    }`}
                                    key={keyCol}
                                    style={column.style}
                                >
                                    {column.formatter
                                        ? column.formatter(
                                              row[column.dataField],
                                              keyRow,
                                              row,
                                              column.formatExtraData,
                                          )
                                        : row[column.dataField]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
                {footer && (
                    <tfoot>
                        <tr>
                            {footer?.map((row, key) => (
                                <td
                                    style={row.style ? { ...row.style } : {}}
                                    className={`p-5 font-light text-n-600 text-base text-left`}
                                    key={key}
                                >
                                    <span className={row.classes}>
                                        {row.data}
                                    </span>
                                </td>
                            ))}
                        </tr>
                    </tfoot>
                )}
            </table>
        </div>
    );
}

interface DatatableProps {
    data: any[];
    columns: ColumnsProps[];
    footer?: any[];
    headerContainerClasses?: string;
    headerClasses?: string;
    tableContainerClasses?: string;
    rowClasses?(row: any, idRow: number): string;
    onTableSort?: (field: string, order: string) => void;
    sortField?: string;
    sortOrder?: string;
    tableFixed?: boolean;
}

interface ColumnsProps {
    dataField: string;
    text?: string;
    sort?: boolean;
    formatter?(
        dataField: any,
        rowIdx?: number,
        row?: any,
        extras?: any,
    ): React.ReactNode;
    headerFormatter?: () => React.ReactNode;
    formatExtraData?: any;
    className?: string;
    style?: React.CSSProperties;
    getRowSpan?: (
        data: any[],
        row: any,
        columnDataField: string,
        rowId: number,
    ) => number;
    getColSpan?: (
        data: any[],
        row: any,
        columnDataField: string,
        rowId: number,
    ) => number;
    getCellClassName?: (data: any, column: string, rowId: number) => string;
}
