import * as React from "react";
import classNames from "classnames";
import TextData from "../../atoms/TextData";
import EmployeeInfoHeaderRow from "../../molecules/EmployeeInfoHeaderRow";
import TableWeekHeader from "../../molecules/TableWeekHeader";

import "./style.scss";
import { CurrencyValue } from "../../../_helpers/typeInterfaces";
import EmployeeInfoCellRow from "../../molecules/EmployeeInfoCellRow";

function Table(props: TableProps) {
  const { fields, data } = props;
  const { fieldRow, onClickRow, style, className } = fields;
  const classnamesField = classNames(className);
  return (
    <div style={{ minHeight: "40vh" }}>
      <table className="__table" style={{ width: "100%" }}>
        <tr style={style} onClick={onClickRow} className={classnamesField}>
          {fieldRow.map(
            ({ title, name, style, titleContainerStyle }, index) => {
              return (
                <th style={style} key={index}>
                  <div style={titleContainerStyle}>{title}</div>
                </th>
              );
            }
          )}
        </tr>
        {data.map((item, index) => {
          const { dataRow, onClickRow, style, className } = item;
          const classnames = classNames(className);
          return (
            <tr
              style={style}
              key={index}
              onClick={onClickRow}
              className={classnames}
            >
              {dataRow.map(({ value, name, style, onClickCell }, index) => {
                return (
                  <td key={index} onClick={onClickCell} style={style}>
                    {value}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </table>
    </div>
  );
}

interface TableProps {
  fields: {
    fieldRow: {
      title?: string | React.ReactNode;
      name?: string;
      style?: React.CSSProperties;
      titleContainerStyle?: React.CSSProperties;
    }[];
    onClickRow?: () => void;
    style?: React.CSSProperties;
    className?: string[];
  };
  data: {
    dataRow: {
      value?: string | React.ReactNode;
      name?: string;
      style?: React.CSSProperties;
      onClickCell?: () => void;
    }[];
    onClickRow?: () => void;
    style?: React.CSSProperties;
    className?: string[];
  }[];
}
Table.defaultProps = {
  fields: {
    fieldRow: [
      {
        title: (
          <TextData color="#2A346D" size="16px" fontWeight="bold" width="auto">
            Employé
          </TextData>
        ),
        name: "name",
        style: { padding: "10px 0", minWidth: "310px" },
      },
      {
        title: (
          <TableWeekHeader
            frontTitle="S35"
            frontSubTitle="29/08 - 04/09"
            tagCornerColor="#00A42E"
            backBackgroundColor="rgba(0, 164, 46, 0.2)"
          />
        ),
        name: "email",
      },
      {
        title: (
          <TableWeekHeader
            frontTitle="S36"
            frontSubTitle="29/08 - 04/09"
            tagCornerColor="#00A42E"
            backBackgroundColor="rgba(0, 164, 46, 0.2)"
          />
        ),
        name: "phone",
      },
      {
        title: (
          <TableWeekHeader
            frontTitle="S37"
            frontSubTitle="29/08 - 04/09"
            tagCornerColor="#FB3640"
            backTitle={null}
            backBackgroundColor="rgba(251, 54, 64, 0.2)"
          />
        ),
        name: "email",
      },
      {
        title: (
          <TableWeekHeader
            frontTitle="S38"
            frontSubTitle="29/08 - 04/09"
            tagCornerColor="#FB3640"
            backTitle={null}
            backBackgroundColor="rgba(251, 54, 64, 0.2)"
          />
        ),
        name: "phone",
      },
      {
        title: (
          <TableWeekHeader
            frontTitle="S39"
            frontSubTitle="29/08 - 04/09"
            tagCornerColor="#FB3640"
            backTitle={null}
            backBackgroundColor="rgba(251, 54, 64, 0.2)"
          />
        ),
        name: "phone",
      },
      {
        title: (
          <TextData
            color="#2A346D"
            size="16px"
            fontWeight="bold"
            width="auto"
            style={{
              marginLeft: "60px",
              marginRight: "60px",
            }}
          >
            Total
          </TextData>
        ),
        name: "address",
      },
      {
        title: (
          <TextData
            color="#2A346D"
            size="16px"
            fontWeight="bold"
            width="auto"
            style={{
              marginLeft: "60px",
              marginRight: "60px",
            }}
          >
            Jours travaillés
          </TextData>
        ),
        name: "address",
      },
      {
        title: (
          <TextData
            color="#2A346D"
            size="16px"
            fontWeight="bold"
            width="auto"
            style={{
              marginLeft: "60px",
              marginRight: "60px",
            }}
          >
            Paie
          </TextData>
        ),
        name: "address",
      },
    ],
    onClickRow: () => {},
    style: {},
    className: [],
  },
  data: [
    {
      dataRow: [
        {
          value: (
            <EmployeeInfoHeaderRow
              firstName="Jacob"
              lastName="Jones"
              position="Manager"
              weekHours="20h00"
            />
          ),
          name: "name",
        },
        {
          value: (
            <EmployeeInfoCellRow
              data={[
                {
                  value: "15h00",
                  fontWeight: "bold",
                  width: "auto",
                  color: "#4C5690",
                  size: "14px",
                },
                {
                  value: "(-5h00)",
                  fontWeight: "bold",
                  width: "auto",
                  color: "#00A42E",
                  size: "14px",
                },
              ]}
            />
          ),
          name: "email",
        },
        {
          value: (
            <EmployeeInfoCellRow
              data={[
                {
                  value: "15h00",
                  fontWeight: "bold",
                  width: "auto",
                  color: "#4C5690",
                  size: "14px",
                },
                {
                  value: "(-5h00)",
                  fontWeight: "bold",
                  width: "auto",
                  color: "#00A42E",
                  size: "14px",
                },
              ]}
            />
          ),
          name: "phone",
        },
        {
          value: (
            <EmployeeInfoCellRow
              data={[
                {
                  value: "15h00",
                  fontWeight: "bold",
                  width: "auto",
                  color: "#4C5690",
                  size: "14px",
                },
                {
                  value: "(-5h00)",
                  fontWeight: "bold",
                  width: "auto",
                  color: "#00A42E",
                  size: "14px",
                },
              ]}
            />
          ),
          name: "123 Main Street",
        },
        {
          value: (
            <EmployeeInfoCellRow
              data={[
                {
                  value: "15h00",
                  fontWeight: "bold",
                  width: "auto",
                  color: "#4C5690",
                  size: "14px",
                },
                {
                  value: "(-5h00)",
                  fontWeight: "bold",
                  width: "auto",
                  color: "#00A42E",
                  size: "14px",
                },
              ]}
            />
          ),
          name: "email",
        },
        {
          value: (
            <EmployeeInfoCellRow
              data={[
                {
                  value: "15h00",
                  fontWeight: "bold",
                  width: "auto",
                  color: "#4C5690",
                  size: "14px",
                },
                {
                  value: "(-5h00)",
                  fontWeight: "bold",
                  width: "auto",
                  color: "#00A42E",
                  size: "14px",
                },
              ]}
            />
          ),
          name: "phone",
        },
        {
          value: (
            <EmployeeInfoCellRow
              data={[
                {
                  value: "0 h",
                  fontWeight: "bold",
                  width: "auto",
                  color: "#4C5690",
                  size: "14px",
                },
              ]}
            />
          ),
          name: "123 Main Street",
        },
        {
          value: (
            <EmployeeInfoCellRow
              data={[
                {
                  value: "1",
                  fontWeight: "bold",
                  width: "auto",
                  color: "#4C5690",
                  size: "14px",
                },
              ]}
            />
          ),
          name: "phone",
        },
        {
          value: (
            <EmployeeInfoCellRow
              data={[
                {
                  value: "0.00",
                  fontWeight: "bold",
                  width: "auto",
                  color: "#4C5690",
                  size: "14px",
                },
              ]}
            />
          ),
          name: "123 Main Street",
        },
      ],
      onClickRow: () => {},
      style: {
        cursor: "pointer",
      },
      className: ["hover:bg-slate-100"],
    },
  ],
};

export default Table;
