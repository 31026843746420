import React from "react";
import { useIntl } from "react-intl";
import Button from "../../atoms/ZButton";
import { AddSquare } from "../../../Icons";

export default function PointageNoRecord() {
    const intl = useIntl();
    return (
        <div className="max-w-[520px] mx-auto py-8">
            <div className="flex flex-col items-center gap-6">
                <img src="/assets/imgs/no-employees-record.png" />
                <h2 className="font-bold text-5xl text-n-700 text-center">
                    Pas de pointage à consulter
                </h2>
                <p className="text-n-500 text-md text-center">
                    Pour l'instant, il n'y a pas de données à montrer. Votre
                    premier pointage les fera apparaître.
                </p>
            </div>
        </div>
    );
}
