import * as React from "react";

import "./style.scss";

import PopupCard from "../../molecules/PopUpCard";
import Input from "../../atoms/Input";
import TextData from "../../atoms/TextData";
import SVGIcon from "../../../utils/img/icons/close.svg";
import Checkbox from "../../atoms/Checkbox";
import SelectInput from "../../atoms/SelectInput";

export default function NewReceiptCard(props: any /*NewReceiptCardProps*/) {
    //header field
    const {
        header,
        onClose,
        headerLabel,
        headerLabelColor,
        headerLabelSize,
        headerBorderColor,
        headerTextSize,
        headerTextColor,
        headerRadius,
        headerPlaceholder,
        headerWidth,
        headerValue,
        onHeaderChange,
        isEdit,
        data,
        handleDeleteBonusType,
    } = props;
    //startTime field
    const {
        checkboxIsRounded,
        checkboxText,
        checkboxTextColor,
        checkboxTextSize,
        checkboxValue,
        onCheckboxChange,
    } = props;
    //select field
    const {
        selectOption,
        selectTitle,
        selectCaptionColor,
        selectCaptionSize,
        selectValue,
        onSelectChange,
    } = props;
    const [checkboxAffect, setcheckboxAffect] = React.useState(checkboxValue);
    React.useEffect(() => {
        setcheckboxAffect(checkboxValue);
    }, [checkboxValue]);

    const { onCancelButton, onSubmitButton } = props;
    const [selectedValueDefault, setSelectedValueDefault] = React.useState(
        () => {
            switch (selectValue) {
                case "+":
                    return selectOption?.[0];
                    break;
                case "-":
                    return selectOption?.[1];
                    break;
                default:
                    return selectOption?.[0];
                    break;
            }
        },
    );
    const [avanceData, setAvanceData] = React.useState(
        data || {
            acompteTypeId: null,
            affectSalary: false,
            affectType: "",
            name: "",
            siteId: "",
        },
    );
    React.useEffect(() => {
        console.log(" avance data = ", data);
    }, [data]);
    React.useEffect(() => {
        if (selectValue === "+" && selectedValueDefault?.label !== "Addition") {
            setSelectedValueDefault({
                label: "Addition",
                value: "Addition",
            });
        } else if (
            selectValue === "-" &&
            selectedValueDefault?.label !== "Soustraction"
        ) {
            setSelectedValueDefault({
                label: "Soustraction",
                value: "Soustraction",
            });
        }
    }, [selectedValueDefault]);
    const handleOnSelectChange = (data: any) => {
        switch (data.value.toLowerCase()) {
            case "addition":
                setAvanceData((prev: any) => ({
                    ...prev,
                    affectType: "+",
                }));
                break;
            case "soustraction":
                setAvanceData((prev: any) => ({
                    ...prev,
                    affectType: "-",
                }));
                break;
        }
        // onSelectChange && onSelectChange(data);
    };
    const handleOnCheckboxChange = (data: any) => {
        setcheckboxAffect(data.target.checked);
        setAvanceData((prev: any) => ({
            ...prev,
            affectSalary: data.target.checked,
            affectType: data.target.checked ? "+" : "",
        }));
        onCheckboxChange && onCheckboxChange(data);
    };
    const handleClose = () => {
        onClose && onClose();
        setAvanceData({
            acompteTypeId: null,
            affectSalary: false,
            affectType: "",
            name: "",
            siteId: "",
        });
    };
    const onDelete = () => {
        handleDeleteBonusType(avanceData.acompteTypeId);
    };
    const handleOnSubmitButton = () => {
        // console.log(" d d avance data = ", avanceData);
        onSubmitButton(avanceData);
    };
    return (
        <PopupCard
            header={header}
            onClose={handleClose}
            isCloseIcon={true}
            onClickButton1={isEdit ? onDelete : undefined}
            button1Text={isEdit ? "Supprimer" : ""}
            button1Style={{
                backgroundColor: isEdit ? "red" : "",
                color: "white",
            }}
            onClickButton2={handleOnSubmitButton}
            button2Text={isEdit ? "Modifier" : "Ajouter"}
        >
            {/* <PopupCard
            header={header}
            onClose={onClose}
            onClickButton1={onCancelButton}
            onClickButton2={onSubmitButton}
        > */}
            <div className="new-service-card-container">
                <Input
                    value={avanceData.name}
                    captionText={headerLabel}
                    captionColor={headerLabelColor}
                    captionSize={headerLabelSize}
                    borderColor={headerBorderColor}
                    borderWidth={1}
                    style={{
                        inputStyle: {
                            padding: "7px 15px 7px 15px",
                            outline: "none",
                            marginBottom: 15,
                        },
                    }}
                    textSize={headerTextSize}
                    textColor={headerTextColor}
                    inputRadius={headerRadius}
                    placeholder={headerPlaceholder}
                    inputWidth={headerWidth}
                    onChange={(value: any) => {
                        setAvanceData((prev: any) => ({
                            ...prev,
                            name: value,
                        }));
                    }}
                />
                <Checkbox
                    rounded={checkboxIsRounded}
                    text={checkboxText}
                    textColor={checkboxTextColor}
                    textSize={checkboxTextSize}
                    checked={avanceData.affectSalary}
                    onClick={handleOnCheckboxChange}
                />
                {avanceData.affectSalary && (
                    <>
                        <br />
                        <SelectInput
                            options={selectOption}
                            selectedValue={selectedValueDefault}
                            title={selectTitle}
                            captionColor={selectCaptionColor}
                            captionSize={selectCaptionSize}
                            onSelectOption={handleOnSelectChange}
                        />
                        <br />
                    </>
                )}
            </div>
        </PopupCard>
    );
}
interface NewReceiptCardProps {
    header?: string;
    onClose?: () => void;
    //header field
    headerLabel?: string;
    headerLabelColor?: string;
    headerLabelSize?: string;
    headerBorderColor?: string;
    headerTextSize?: number;
    headerTextColor?: string;
    headerRadius?: string;
    headerPlaceholder?: string;
    headerWidth?: string;
    headerValue?: string;
    onHeaderChange?: (data: any) => void;
    //checkbox field
    checkboxIsRounded?: boolean;
    checkboxText?: string;
    checkboxTextColor?: string;
    checkboxTextSize?: string;
    checkboxValue?: boolean;
    onCheckboxChange?: (data: any) => void;
    //select field
    selectOption?: any[];
    selectTitle?: string;
    selectCaptionColor?: string;
    selectCaptionSize?: string;
    selectValue?: string;
    onSelectChange?: (data: any) => void;
    // submit
    onCancelButton?: () => void;
    onSubmitButton?: () => void;
}
NewReceiptCard.defaultProps = {
    header: "Ajouter un type d'avance / prime",
    //header field
    headerLabel: "Nom",
    headerLabelColor: "#8E96C1",
    headerLabelSize: "12px",
    headerBorderColor: "#C7CBE2",
    headerTextSize: 14,
    headerTextColor: "#2A346D",
    headerRadius: "7px",
    headerPlaceholder: "",
    headerWidth: "100%",
    headerValue: "",
    onHeaderChange: (data: any) => {},
    //checkbox field
    checkboxIsRounded: true,
    checkboxText: "Affecte le salaire",
    checkboxTextColor: "#2A8BAB",
    checkboxTextSize: "12px",
    checkboxValue: false,
    onCheckboxChange: (data: any) => {
        console.log(data);
    },
    //select field
    selectOption: [
        { label: "Addition", value: "Addition" },
        { label: "Soustraction", value: "Soustraction" },
    ],
    selectTitle: "Opération",
    selectCaptionColor: "#8E96C1",
    selectCaptionSize: "12px",
    selectValue: "+",
    onSelectChange: (data: any) => {
        console.log(data);
    },
    // submit
    onCancelButton: () => {},
    onSubmitButton: () => {},
};
