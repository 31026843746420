import React from "react";
import { FormattedMessage } from "react-intl";
import { Edit, Trash } from "../../../../../Icons";

export function ActionColumnFormattter(
  content: any,
  rowIdx: number,
  row: any,
  extras: any
) {
  return (
    <div className="flex items-center gap-2">
      <div
        className="flex items-center gap-2 cursor-pointer text-n-500  hover:text-n-700"
        onClick={() => extras.openEditPrimesDialog(row)}
      >
        <Edit />
        <span className="font-light text-base">
          <FormattedMessage id="MENU.UPDATE" />
        </span>
      </div>
      <div
        className="flex items-center gap-2 cursor-pointer text-n-500  hover:text-n-700"
        onClick={() => extras.openDeletePrimesDialog(row)}
      >
        <Trash />
        <span className="text-n-500 font-light text-base">
          <FormattedMessage id="MENU.DELETE" />
        </span>
      </div>
    </div>
  );
}
