import * as React from "react";
import "./style.scss";
import TextData from "../../atoms/TextData";
import Picture from "../../atoms/Picture";
import Button from "../../atoms/Button";
import { ReactComponent as Close } from "./close.svg";

export default function PopUpCard(props: PopupCardProps) {
    const {
        display,
        header,
        containerWidth,
        headerStyle,
        children,
        activateFooter,
        button1Text,
        button1Style,
        onClickButton1,
        button2Text,
        button2Style,
        onClickButton2,
        button3Text,
        button3Style,
        onClickButton3,
        onClose,
        widthCloseIcon,
        styleCloseIcon,
        popupHeaderStyle,
        isCloseIcon,
        footerMessage,
    } = props;
    const [showPopup, setshowPopup] = React.useState(true);
    React.useEffect(() => {
        setshowPopup(display ?? true);
    }, [display]);

    const handlePopupClose = () => {
        if (display !== null) {
            setshowPopup(false);
        }
        onClose && onClose();
    };
    if (showPopup) {
        return (
            <div
                className="popup-card-container"
                style={{ width: containerWidth }}
            >
                <div
                    className="popup-header"
                    style={{
                        ...popupHeaderStyle,
                        backgroundColor: "#fafafc",
                        borderBottom: "1px solid #c7cbe2",
                    }}
                >
                    {typeof header === "object" ? (
                        header
                    ) : (
                        <TextData style={headerStyle} width="auto">
                            {header}
                        </TextData>
                    )}
                    {isCloseIcon && (
                        <Close
                            style={{
                                ...styleCloseIcon,
                                cursor: "pointer",
                            }}
                            color="#2a346d"
                            width={widthCloseIcon}
                            onClick={handlePopupClose}
                        />
                    )}
                </div>
                <div className="popup-content">{children}</div>
                {activateFooter && (
                    <div
                        className="popup-footer"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#2a346d",
                        }}
                    >
                        {footerMessage && footerMessage}
                        {(button1Text || onClickButton1) && (
                            <Button
                                style={button1Style}
                                backgroundColor={button1Style?.backgroundColor}
                                textColor={button1Style?.color}
                                onClick={onClickButton1}
                                width="120px"
                                height="40px"
                            >
                                {button1Text}
                            </Button>
                        )}
                        {(button2Text || onClickButton2) && (
                            <Button
                                style={button2Style}
                                backgroundColor={button2Style?.backgroundColor}
                                textColor={button2Style?.color}
                                onClick={onClickButton2}
                                width="120px"
                                height="40px"
                            >
                                {button2Text}
                            </Button>
                        )}
                        {(button3Text || onClickButton3) && (
                            <Button
                                style={button3Style}
                                backgroundColor={button3Style?.backgroundColor}
                                textColor={button3Style?.color}
                                onClick={onClickButton3}
                                width="120px"
                                height="40px"
                            >
                                {button3Text}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        );
    } else {
        return null;
    }
}

interface PopupCardProps {
    display?: boolean | null;
    header?: string | React.ReactNode;
    containerWidth?: string;
    headerStyle?: React.CSSProperties;
    children?: React.ReactNode;
    activateFooter?: boolean;
    button1Text?: string;
    button2Text?: string;
    button3Text?: string;
    button1Style?: React.CSSProperties;
    button2Style?: React.CSSProperties;
    button3Style?: React.CSSProperties;
    onClickButton1?: () => void;
    onClickButton2?: () => void;
    onClickButton3?: () => void;
    onClose?: () => void;
    widthCloseIcon?: string;
    styleCloseIcon?: React.CSSProperties;
    popupHeaderStyle?: React.CSSProperties;
    isCloseIcon?: boolean;
    footerMessage?: string;
}
PopUpCard.defaultProps = {
    display: null,
    header: "Ajouter un département",
    headerStyle: {
        color: "#2A346D",
        fontWeight: "bold",
        fontSize: 17,
    },
    children: "test",
    activateFooter: true,
    button1Text: "Annuler",
    button2Text: "Ajouter",
    button1Style: {
        backgroundColor: "#EDEFF5",
        color: "#2A346D",
        fontSize: "14px",
    },
    button2Style: {
        backgroundColor: "#2A8BAB",
        color: "white",
        fontSize: "14px",
    },
    button3Style: {
        backgroundColor: "#2A8BAB",
        color: "white",
        fontSize: "14px",
    },
    // onClickButton1: () => {},
    // onClickButton2: () => {},
    onClose: () => {},
    widthCloseIcon: 20,
    isCloseIcon: true,
};
