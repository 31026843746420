import React from "react";
import { FormattedMessage } from "react-intl";
import { Repeat, CalendarThirty } from "../../../../../Icons";

export function TypeColumnFormatter(content: any, rowIdx: number, row: any) {
    const checkType = row?.repeat === false;
    return (
        <div
            className={`flex gap-2 items-center justify-center text-md rounded-full py-2 px-2 max-w-[110px] ${
                !checkType
                    ? "text-custom-v-1 bg-custom-v-1-op-1"
                    : "text-custom-v-2 bg-custom-v-2-op-1"
            }`}
        >
            {!checkType ? <Repeat /> : <CalendarThirty />}
            {!checkType ? (
                <FormattedMessage id="EMPLOYEE.EDIT.UNAVAIBILITY.REPEAT" />
            ) : (
                <FormattedMessage id="EMPLOYEE.EDIT.UNAVAIBILITY.NO_REPEAT" />
            )}
        </div>
    );
}
