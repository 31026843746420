import * as React from "react";

import "./style.scss";

import PopupCard from "../../molecules/PopUpCard";
import Input from "../../atoms/Input";
import TextData from "../../atoms/TextData";
import SVGIcon from "../../../utils/img/icons/close.svg";

export default function NewServiceCard(props: any /*NewServiceCardProps*/) {
    //header field
    const {
        header,
        onClose,
        headerValue,
        headerLabel,
        headerLabelColor,
        headerLabelSize,
        headerBorderColor,
        headerTextSize,
        headerTextColor,
        headerRadius,
        headerPlaceholder,
        headerWidth,
        onChangeHeader,
        data,
        isEdit,
        handleDeleteService,
    } = props;
    //startTime field
    const {
        startTimeValue,
        startTimeLabel,
        startTimeLabelColor,
        startTimeLabelSize,
        startTimeBorderColor,
        startTimeTextSize,
        startTimeTextColor,
        startTimeRadius,
        startTimePlaceholder,
        startTimeWidth,
        onStartTimeChange,
    } = props;
    //endTime field
    const {
        endTimeValue,
        endTimeLabel,
        endTimeLabelColor,
        endTimeLabelSize,
        endTimeBorderColor,
        endTimeTextSize,
        endTimeTextColor,
        endTimeRadius,
        endTimePlaceholder,
        endTimeWidth,
        onEndTimeChange,
    } = props;
    const { onCancelButton, onSubmitButton } = props;
    const [serviceData, setServiceData] = React.useState(
        data || {
            from: "",
            id: null,
            name: "",
            siteId: null,
            to: "",
        },
    );
    const handleClose = () => {
        onClose && onClose();
        setServiceData({
            from: "",
            id: null,
            name: "",
            siteId: null,
            to: "",
        });
    };
    const onDelete = () => {
        handleDeleteService?.(serviceData.id);
    };

    return (
        <PopupCard
            header={header}
            onClose={handleClose}
            containerWidth="350px"
            isCloseIcon={true}
            onClickButton1={isEdit ? onDelete : undefined}
            button1Text={isEdit ? "Supprimer" : ""}
            button1Style={{
                backgroundColor: isEdit ? "red" : "",
                color: "white",
            }}
            onClickButton2={onSubmitButton}
            button2Text={isEdit ? "Modifier" : "Ajouter"}
        >
            {/* <PopupCard
            header={header}
            onClose={onClose}
            // button1Text='Annuler'
            button2Text="Valider"
            // onClickButton1={onCancelButton}
            onClickButton2={onSubmitButton}
        > */}
            <div className="new-service-card-container">
                <Input
                    value={serviceData.name}
                    captionText={headerLabel}
                    captionColor={headerLabelColor}
                    captionSize={headerLabelSize}
                    borderColor={headerBorderColor}
                    borderWidth={1}
                    style={{
                        inputStyle: {
                            padding: "7px 15px 7px 15px",
                            outline: "none",
                            marginBottom: 15,
                        },
                    }}
                    textSize={headerTextSize}
                    textColor={headerTextColor}
                    inputRadius={headerRadius}
                    placeholder={headerPlaceholder}
                    inputWidth={headerWidth}
                    onChange={onChangeHeader}
                    maxTextLength={20}
                />
                <div className="service-schedule">
                    <Input
                        value={serviceData.from}
                        captionText={startTimeLabel}
                        captionColor={startTimeLabelColor}
                        captionSize={startTimeLabelSize}
                        borderColor={startTimeBorderColor}
                        borderWidth={1}
                        iconLocation="right"
                        style={{
                            inputStyle: {
                                padding: "7px 15px 7px 15px",
                                outline: "none",
                                marginBottom: 15,
                                width: "80%",
                            },
                        }}
                        type="time"
                        textSize={startTimeTextSize}
                        textColor={startTimeTextColor}
                        inputRadius={startTimeRadius}
                        placeholder={startTimePlaceholder}
                        inputWidth={startTimeWidth}
                        onChange={onStartTimeChange}
                    />
                    <Input
                        value={serviceData.to}
                        captionText={endTimeLabel}
                        captionColor={endTimeLabelColor}
                        captionSize={endTimeLabelSize}
                        borderColor={endTimeBorderColor}
                        borderWidth={1}
                        iconLocation="right"
                        style={{
                            inputStyle: {
                                padding: "7px 15px 7px 15px",
                                outline: "none",
                                marginBottom: 15,
                                width: "80%",
                            },
                        }}
                        type="time"
                        textSize={endTimeTextSize}
                        textColor={endTimeTextColor}
                        inputRadius={endTimeRadius}
                        placeholder={endTimePlaceholder}
                        inputWidth={endTimeWidth}
                        onChange={onEndTimeChange}
                    />
                </div>
            </div>
        </PopupCard>
    );
}
interface NewServiceCardProps {
    header?: string;
    onClose?: () => void;
    //header field
    headerValue?: string;
    headerLabel?: string;
    headerLabelColor?: string;
    headerLabelSize?: string;
    headerBorderColor?: string;
    headerTextSize?: number;
    headerTextColor?: string;
    headerRadius?: string;
    headerPlaceholder?: string;
    headerWidth?: string;
    onChangeHeader?: (name: string) => void;
    //startTime field
    startTimeValue?: string;
    startTimeLabel?: string;
    startTimeLabelColor?: string;
    startTimeLabelSize?: string;
    startTimeBorderColor?: string;
    startTimeTextSize?: number;
    startTimeTextColor?: string;
    startTimeRadius?: string;
    startTimePlaceholder?: string;
    startTimeWidth?: string;
    onStartTimeChange?: (from: string) => void;
    //endTime field
    endTimeValue?: string;
    endTimeLabel?: string;
    endTimeLabelColor?: string;
    endTimeLabelSize?: string;
    endTimeBorderColor?: string;
    endTimeTextSize?: number;
    endTimeTextColor?: string;
    endTimeRadius?: string;
    endTimePlaceholder?: string;
    endTimeWidth?: string;
    onEndTimeChange?: (to: string) => void;
    // submit
    onCancelButton?: () => void;
    onSubmitButton?: () => void;
}
NewServiceCard.defaultProps = {
    header: "Ajouter un service",
    //header field
    headerValue: "",
    headerLabel: "Nom du service",
    headerLabelColor: "#8E96C1",
    headerLabelSize: "12px",
    headerBorderColor: "#C7CBE2",
    headerTextSize: 14,
    headerTextColor: "#2A346D",
    headerRadius: "7px",
    headerPlaceholder: "",
    headerWidth: "100%",
    onChangeHeader: (name: string) => {},
    //startTime field
    startTimeValue: "",
    startTimeLabel: "Heure début",
    startTimeLabelColor: "#8E96C1",
    startTimeLabelSize: "12px",
    startTimeBorderColor: "#C7CBE2",
    startTimeTextSize: 14,
    startTimeTextColor: "#2A346D",
    startTimeRadius: "7px",
    startTimePlaceholder: "",
    startTimeWidth: "100%",
    //endTime field
    endTimeValue: "",
    endTimeLabel: "Heure fin",
    endTimeLabelColor: "#8E96C1",
    endTimeLabelSize: "12px",
    endTimeBorderColor: "#C7CBE2",
    endTimeTextSize: 14,
    endTimeTextColor: "#2A346D",
    endTimeRadius: "7px",
    endTimePlaceholder: "",
    endTimeWidth: "100%",
    // submit
    onCancelButton: () => {},
    onSubmitButton: () => {},
};
