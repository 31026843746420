import React from "react";
import HRFolderStatics from "../../molecules/HRFolderStatics";
import { useIntl } from "react-intl";
import UserHeaderInfos from "../../atoms/UserHeaderInfos";
import { Phone, SMS, RestoreTray, ArchiveTray } from "../../../Icons";
import Theme from "../../../tailwind-theme";
import Button from "../../atoms/Button";
import TextData from "../../atoms/TextData";
import { useDPAE } from "../../../hooks/useDPAE";
import { useBranch } from "../../../hooks/useBranch";
import { useAdministrative } from "../../../hooks/useAdministrative";
import PopUpCard from "../../molecules/PopUpCard";
import Loading from "../../atoms/Loading";
import Modal from "../../atoms/Modal";
import Swal from "sweetalert2";

export default function ProfileHeader({
    profilePic,
    reference,
    firstName,
    lastName,
    role,
    phone,
    email,
    archived,
    archiveAction,
    restoreAction,
    withRole = true,
    userScore = 0,
}: ProfileHeaderInterface) {
    const intl = useIntl();
    const { depositEmployeeDPAE, isDPAEactive, isDepositLoading } = useDPAE();
    const { getAdministrative } = useAdministrative();
    const { selectedBranchSettings } = useBranch();
    React.useEffect(() => {
        getAdministrative();
    }, [selectedBranchSettings._id]);

    return (
        <div className="w-full flex items-center flex-wrap gap-5 px-5 py-3">
            <UserHeaderInfos
                firstName={firstName}
                lastName={lastName}
                reference={reference}
                role={role}
                profilePic={profilePic}
                withRole={withRole}
            />
            <div className="grow">
                <div className="flex items-center  gap-3 mb-2">
                    <div className="flex justify-center items-center bg-n-0 rounded-full h-[24px] w-[24px]">
                        <Phone color={Theme.colors["n-500"]} />
                    </div>
                    <span className="text-n-600 text-base">
                        {phone && phone !== "" ? (
                            phone
                        ) : (
                            <i>
                                {intl.formatMessage({
                                    id: "EMPLOYEE.PROFILE.PHONE.EMPTY",
                                })}
                            </i>
                        )}
                    </span>
                </div>
                <div className="flex items-center gap-3">
                    <div className="flex justify-center items-center bg-n-0 rounded-full h-[24px] w-[24px]">
                        <SMS color={Theme.colors["n-500"]} />
                    </div>
                    <span className="text-n-600 text-base">
                        {email && email !== "" ? (
                            email
                        ) : (
                            <i>
                                {intl.formatMessage({
                                    id: "EMPLOYEE.PROFILE.EMAIL.EMPTY",
                                })}
                            </i>
                        )}
                    </span>
                </div>
            </div>
            <div className="grow">
                <HRFolderStatics value={userScore} />
            </div>
            <div
                className="grow"
                style={{ display: "flex", justifyContent: "end" }}
            >
                {isDPAEactive && (
                    <Button
                        backgroundColor="#408BAB"
                        width="auto"
                        padding="4px 12px"
                        onClick={() => {
                            Swal.fire({
                                title: "Êtes-vous sûr ?",
                                text: "Êtes-vous sûr de vouloir envoyer une DPAE ?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Oui",
                                cancelButtonText: "Non",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    depositEmployeeDPAE();
                                }
                            });
                        }}
                    >
                        <TextData width="auto" color="white" size="13px">
                            Déposer DPAE
                        </TextData>
                    </Button>
                )}
                <Modal
                    modalEnabled={isDepositLoading}
                    onClose={() => {
                        Swal.fire({
                            title: "La procédure de dépôt de la DPAE est en cours. Merci de votre patience.",
                            icon: "warning",
                            timer: 2500,
                            timerProgressBar: true,
                        });
                    }}
                >
                    <PopUpCard
                        display={true}
                        onClose={() => {}}
                        header="Veuillez patienter un moment, s'il vous plaît"
                        button1Text=""
                        button2Text=""
                        activateFooter={false}
                    >
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                height: "30vh",
                            }}
                        >
                            <Loading width="400px" height="150px" />
                        </div>
                    </PopUpCard>
                </Modal>
            </div>
            <div className="justify-self-end">
                <div>
                    <div>
                        {archived ? (
                            <div
                                className="flex items-center gap-3 cursor-pointer text-n-600 hover:text-n-800"
                                onClick={restoreAction}
                            >
                                <RestoreTray />
                                <span className=" text-base">
                                    {intl.formatMessage({ id: "MENU.RESTORE" })}
                                </span>
                            </div>
                        ) : (
                            <>
                                <div
                                    className="flex items-center gap-3  cursor-pointer text-error hover:text-error-hover "
                                    onClick={archiveAction}
                                >
                                    <ArchiveTray />
                                    <span className="text-base">
                                        {intl.formatMessage({
                                            id: "MENU.ARCHIVE",
                                        })}
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                    {/* <span className="inline-block text-base font-bold text-t-300">
            {intl.formatMessage({ id: "MENU.HISTORY" })}
          </span> */}
                </div>
            </div>
        </div>
    );
}

interface ProfileHeaderInterface {
    id: string;
    profilePic?: string;
    reference: string;
    firstName: string;
    lastName: string;
    role: "Employee" | "Manager" | "Administrator";
    phone: string;
    email: string;
    archived: boolean;
    archiveAction?: () => void;
    restoreAction?: () => void;
    withRole?: boolean;
    userScore?: number;
}
