import React from "react";
import { useLanguage } from "../../../hooks/useLanguage";

export function Body({ children, classNames }: BodyProps) {
    const { isFullScreen } = useLanguage();

    return (
        <div
            className={`px-0  bg-white rounded-xl _shadow  ${classNames}`}
            style={{
                // backgroundColor: "red",
                maxHeight: `calc(100vh - ${isFullScreen ? 140 : 180}px)`,
                overflow: "auto",
                height: `calc(100vh - ${isFullScreen ? 140 : 180}px)`,
            }}
        >
            {children}
        </div>
    );
}

interface BodyProps {
    children: React.ReactNode;
    classNames?: string;
}
