import * as React from "react";

import TextData from "../../atoms/TextData";
import Picture from "../../atoms/Picture";
import Input from "../../atoms/Input";
import Button from "../../atoms/Button";

import InputIcon from "./assets/passwordIcon.svg";
import invalidIcon from "./assets/falseIcon.svg";
import validIcon from "./assets/validIcon.svg";

import "./style.scss";
import { useChangePassword } from "../../../hooks/useChangePassword";

export default function ChangePassword(props: ChangePasswordProps) {
    const {
        heading,
        title,
        passwordRequirements,
        currentPasswordLabel,
        newPasswordLabel,
        newPasswordError,
        confirmPasswordLabel,
        confirmPasswordError,
        badRequestError,
        submitButtonText,
    } = props;
    const {
        auth,
        oldPassword,
        confirmedNeoPassword,
        setActualPassword,
        setNewPassword,
        confirmedNewPassword,
        changePassword,
    } = useChangePassword();
    const [validation, setValidation] = React.useState<boolean[]>(
        Array(passwordRequirements?.length).fill(false)
    );
    const [isNewPasswordSameAsOld, setIsNewPasswordSameAsOld] =
        React.useState(false);
    const [isConfirmedPasswordMatchesNew, setIsConfirmedPasswordMatchesNew] =
        React.useState(true);
    const [inputOnClickIconImg, setInputOnClickIconImg] = React.useState({
        currentPasswordField: true,
        newPasswordField: true,
        confirmNewPasswordField: true,
    });

    function handleOnClickIconImg(
        event: React.MouseEvent<HTMLElement>,
        fieldName: keyof {
            currentPasswordField: boolean;
            newPasswordField: boolean;
            confirmNewPasswordField: boolean;
        }
    ) {
        setInputOnClickIconImg({
            ...inputOnClickIconImg,
            [fieldName]: !inputOnClickIconImg[fieldName],
        });
    }
    const handleCurrentPasswordInputChange = (inputValue: any) => {
        setActualPassword(inputValue);
    };
    const handleNewPasswordInputChange = (inputValue: any) => {
        const newValidation = Array(passwordRequirements?.length).fill(false);
        passwordRequirements?.forEach((condition, index) => {
            const { patternCondition } = condition;
            const isValid = new RegExp(`${patternCondition}`).test(inputValue);
            newValidation[index] = isValid;
        });
        setValidation(newValidation);
        if (oldPassword === inputValue) {
            setIsNewPasswordSameAsOld(true);
        } else {
            setIsNewPasswordSameAsOld(false);
            setNewPassword(inputValue);
            if (confirmedNeoPassword === inputValue) {
                setIsConfirmedPasswordMatchesNew(true);
            } else {
                setIsConfirmedPasswordMatchesNew(false);
            }
        }
    };
    const handleConfirmInputChange = (inputValue: any) => {
        if (auth.password === inputValue) {
            setIsConfirmedPasswordMatchesNew(true);
            confirmedNewPassword(inputValue);
        } else {
            setIsConfirmedPasswordMatchesNew(false);
        }
    };
    const isPasswordValid = () => {
        if (
            validation.every((isValid) => isValid) &&
            isConfirmedPasswordMatchesNew &&
            !isNewPasswordSameAsOld
        ) {
            return true;
        }
        return false;
    };
    const handleChangePassword = () => {
        if (isPasswordValid()) {
            changePassword();
        }
    };

    const globalProps = {
        headingProps: {
            variant: "div" as "div",
            color: "#555",
            width: "100%",
            size: "24px",
            style: {
                fontWeight: "700",
                marginBottom: "10px",
            },
        },
        titleProps: {
            variant: "div" as "div",
            fontWeight: "700",
            width: "100%",
            color: "#555",
            size: "14",
        },
        passwordRequirementsProps: {
            eachPicture: {
                width: "14px",
                height: "14px",
            },
            eachTextData: {
                variant: "div" as "div",
                width: "auto",
                size: "12px",
                style: {},
            },
        },
        inputCurrentPasswordProps: {
            autoComplete: "on" as "on",
            type: inputOnClickIconImg.currentPasswordField
                ? ("password" as "password")
                : ("text" as "text"),
            captionText: currentPasswordLabel,
            placeholder: currentPasswordLabel,
            onChange: handleCurrentPasswordInputChange,
            iconImg: InputIcon,
            onClickIconImg: (event: React.MouseEvent<HTMLElement>) =>
                handleOnClickIconImg(event, "currentPasswordField"),
            captionColor: "#7b99b1",
            captionSize: "12px",
            borderWidth: 1,
            borderColor: "#3598b8",
            textSize: 14,
            textColor: "#555",
            inputRadius: "4px",
            inputWidth: "100%",
            style: {
                captionStyle: {
                    marginTop: "10px",
                },
                inputStyle: {
                    padding: "7px",
                },
            },
            value: oldPassword,
        },
        inputNewPasswordProps: {
            autoComplete: "on" as "on",
            type: inputOnClickIconImg.newPasswordField
                ? ("password" as "password")
                : ("text" as "text"),
            captionText: newPasswordLabel,
            placeholder: newPasswordLabel,
            onChange: handleNewPasswordInputChange,
            iconImg: InputIcon,
            onClickIconImg: (event: React.MouseEvent<HTMLElement>) =>
                handleOnClickIconImg(event, "newPasswordField"),
            errorIsActivated: isNewPasswordSameAsOld,
            errorText: newPasswordError,
            errorColor: "red",
            errorSize: 12,
            captionColor: "#7b99b1",
            captionSize: "12px",
            borderWidth: 1,
            borderColor: "#3598b8",
            textSize: 14,
            textColor: "#555",
            inputRadius: "4px",
            inputWidth: "100%",
            style: {
                captionStyle: {
                    marginTop: "10px",
                },
                inputStyle: {
                    padding: "7px",
                },
            },
        },
        inputConfirmPasswordProps: {
            autoComplete: "on" as "on",
            type: inputOnClickIconImg.confirmNewPasswordField
                ? ("password" as "password")
                : ("text" as "text"),
            captionText: confirmPasswordLabel,
            placeholder: confirmPasswordLabel,
            onChange: handleConfirmInputChange,
            iconImg: InputIcon,
            onClickIconImg: (event: React.MouseEvent<HTMLElement>) =>
                handleOnClickIconImg(event, "confirmNewPasswordField"),
            errorIsActivated: !isConfirmedPasswordMatchesNew,
            errorText: confirmPasswordError,
            errorColor: "red",
            errorSize: 12,
            captionColor: "#7b99b1",
            captionSize: "12px",
            borderWidth: 1,
            borderColor: "#3598b8",
            textSize: 14,
            textColor: "#555",
            inputRadius: "4px",
            inputWidth: "100%",
            style: {
                captionStyle: {
                    marginTop: "10px",
                },
                inputStyle: {
                    padding: "7px",
                },
            },
        },
        errorTextProps: {
            variant: "label" as "label",
            width: "100%",
            style: {
                backgroundColor: "#FFDEDE",
                padding: "8px 10px",
                marginTop: "7px",
                borderRadius: "4px",
            },
            color: "#7D2828",
            size: "12px",
        },
        buttonProps: {
            width: "100%",
            height: "50px",
            backgroundColor: "#3598B8",
            textColor: "white",
            padding: "10px 5px 5px 10px",
            style: {
                marginTop: 15,
                marginBottom: 15,
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "-0.5px",
            },
            onClick: handleChangePassword,
            disabled: !isPasswordValid(),
        },
    };

    return (
        <div className='changePassword-container'>
            <TextData {...globalProps.headingProps}>{heading}</TextData>
            <TextData {...globalProps.titleProps}>{title}</TextData>
            <div className='password-requirements'>
                {validation.map((isValid, index) => {
                    const textCondition =
                        passwordRequirements?.[index].textCondition;
                    const color = isValid ? "green" : "red";
                    return (
                        <div className='each-requirement' key={index}>
                            <Picture
                                {...globalProps.passwordRequirementsProps
                                    .eachPicture}
                                imgPath={isValid ? validIcon : invalidIcon}
                            />
                            <TextData
                                color={color}
                                {...globalProps.passwordRequirementsProps
                                    .eachTextData}
                            >
                                {textCondition}
                            </TextData>
                        </div>
                    );
                })}
            </div>
            <Input {...globalProps.inputCurrentPasswordProps} />
            <Input {...globalProps.inputNewPasswordProps} />
            <Input {...globalProps.inputConfirmPasswordProps} />
            {auth.error && (
                <TextData {...globalProps.errorTextProps}>
                    {badRequestError}
                </TextData>
            )}
            <Button {...globalProps.buttonProps}>{submitButtonText}</Button>
        </div>
    );
}

interface ChangePasswordProps {
    heading?: string;
    title?: string;
    passwordRequirements?: {
        textCondition?: string;
        patternCondition?: string;
    }[];
    currentPasswordLabel?: string;
    newPasswordLabel?: string;
    newPasswordError?: string;
    confirmPasswordLabel?: string;
    confirmPasswordError?: string;
    badRequestError?: string;
    submitButtonText?: string;
}
ChangePassword.defaultProps = {
    heading: "Changer mot de passe",
    title: "Votre mot de passe doit contenir:",
    passwordRequirements: [
        { textCondition: "Au moins 8 caractères", patternCondition: ".{8,}" },
        {
            textCondition: "Au moins une majuscule",
            patternCondition: "(.*[A-Z]+.*)",
        },
        {
            textCondition: "Au moins une minuscule",
            patternCondition: "(.*[a-z].*)",
        },
        {
            textCondition: "Au moins un chiffre",
            patternCondition: "(.*[0-9].*)",
        },
        {
            textCondition: "Au moins un caractère special (#?!@$%^&*_.,-)",
            patternCondition: "(.*[#?!@$%^&_.,-].*)",
        },
    ],
    currentPasswordLabel: "Mot de passe actuel",
    newPasswordLabel: "Nouveau mot de passe",
    newPasswordError:
        "Veuillez écrire un nouveau mot de passe different du mot de passe actuel",
    confirmPasswordLabel: "Confirmer le nouveau mot de passe",
    confirmPasswordError: "Veuillez vérifier votre nouveau mot de passe",
    badRequestError: "Erreur de requête, un problème est survenu.",
    submitButtonText: "Réinitialiser",
};
