//TODO: should be removed
import { Route, Routes } from "react-router-dom";
import DashboardTemplate from "../components/templates/Dashboard";
import EmployesPage from "../pages/Employees/EmployeesPage";
import PointeusePage from "../pages/Pointeuse";
import EmployeesEditPage from "../pages/Employees/EmployeesEditPage";
import RegisterPage from "../pages/Employees/Register";
import { ROUTER } from "../constants/env";
import {
    Branch,
    Schedule,
    TimeTracker,
    Vacation,
    Integration,
    Administrative,
} from "../pages/Settings";
import SettingsPage from "../pages/Settings";
import Mobile from "../pages/Settings/Mobile";
import NotificationsSettings from "../pages/Settings/Notifications";
import Report from "../pages/Report";
import Planning from "../pages/Planning";
import Support from "../pages/Support";
import Notifications from "../pages/Notifications";
import DashboardPage from "../pages/DashboardPage";
import RequireAuth from "./RequireAuth";
import EmployeeProfile from "../pages/Employees/EmployeeProfile";
import { useBranch } from "../hooks/useBranch";
import React from "react";

const SettingsPages = [
    {
        path: ROUTER.SETTINGS.BRANCH,
        element: (
            <SettingsPage>
                <Branch />
            </SettingsPage>
        ),
    },
    {
        path: ROUTER.SETTINGS.SCHEDULE,
        element: (
            <SettingsPage>
                <Schedule />
            </SettingsPage>
        ),
    },
    {
        path: ROUTER.SETTINGS.TIME_TRACKER,
        element: (
            <SettingsPage>
                <TimeTracker />
            </SettingsPage>
        ),
    },
    {
        path: ROUTER.SETTINGS.MOBILE,
        element: (
            <SettingsPage>
                <Mobile />
            </SettingsPage>
        ),
    },
    {
        path: ROUTER.SETTINGS.NOTIFICATIONS,
        element: (
            <SettingsPage>
                <NotificationsSettings />
            </SettingsPage>
        ),
    },
    {
        path: ROUTER.SETTINGS.VACATION,
        element: (
            <SettingsPage>
                <Vacation />
            </SettingsPage>
        ),
    },
    {
        path: ROUTER.SETTINGS.INTEGRATION,
        element: (
            <SettingsPage>
                <Integration />
            </SettingsPage>
        ),
    },

    {
        path: ROUTER.SETTINGS.ADMINISTRATIVE,
        element: (
            <RequireAuth>
                <SettingsPage>
                    <Administrative />
                </SettingsPage>
            </RequireAuth>
        ),
    },
];

const ReportPages = [
    {
        path: ROUTER.REPORT.WORKED_HOURS,
        element: <Report />,
    },
    {
        path: ROUTER.REPORT.PRODUCTIVITY,
        element: <Report />,
    },
    {
        path: ROUTER.REPORT.DELAY,
        element: <Report />,
    },
    {
        path: ROUTER.REPORT.VACATION,
        element: <Report />,
    },
    {
        path: ROUTER.REPORT.PAYROLL,
        element: <Report />,
    },
    {
        path: ROUTER.REPORT.ACTIVITY,
        element: <Report />,
    },
];

export default function BasePage({}) {
    const { selectedBranchSettings, Settings } = useBranch();
    const [allowedAccessPages, setAllowedAccessPages] = React.useState<
        string[]
    >([]);
    // Planning', 'Pointeuse', 'Employés', 'Rapports'
    React.useEffect(() => {
        let allowedPages: string[] = [
            "Planning",
            "Pointeuse",
            "Employés",
            "Rapports",
        ];
        let currentSite = selectedBranchSettings?._id ?? Settings.site;
        if (currentSite) {
            const user = sessionStorage.getItem("user");
            if (user) {
                let manage = JSON.parse(user).manage;
                for (let element of manage) {
                    if (element.site === currentSite) {
                        allowedPages = [...element.roles];
                        break;
                    }
                }
            }
        }
        setAllowedAccessPages(allowedPages);
    }, [selectedBranchSettings?._id, Settings.site]);
    return (
        <DashboardTemplate>
            <Routes>
                {allowedAccessPages.includes("Employés") && (
                    <>
                        <Route path="/employees" element={<EmployesPage />} />
                        <Route
                            path="/employees/register"
                            element={<RegisterPage />}
                        />
                        <Route
                            path="/employees/:id/edit"
                            // element={<EmployeeProfile />}
                            element={<EmployeesEditPage />}
                        />
                        <Route
                            path="/employee/:id/edit"
                            element={<EmployeeProfile />}
                            // element={<EmployeesEditPage />}
                        />
                    </>
                )}
                {allowedAccessPages.includes("Pointeuse") && (
                    <Route path="/pointeuse" element={<PointeusePage />} />
                )}
                {SettingsPages.map((page) => (
                    <Route
                        key={page.path}
                        path={page.path}
                        element={page.element}
                    />
                ))}
                {allowedAccessPages.includes("Rapports") &&
                    ReportPages.map((page) => (
                        <Route
                            key={page.path}
                            path={page.path}
                            element={page.element}
                        />
                    ))}
                {allowedAccessPages.includes("Planning") && (
                    <Route path={ROUTER.PLANNING} element={<Planning />} />
                )}
                <Route path={ROUTER.SUPPORT} element={<Support />} />
                <Route
                    path={ROUTER.NOTIFICATIONS}
                    element={<Notifications />}
                />
                <Route path={ROUTER.DASHBOARD} element={<DashboardPage />} />
                {/* <Route path='*' element={<Planning />} /> */}
            </Routes>
        </DashboardTemplate>
    );
}
