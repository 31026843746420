import * as React from "react";

import Authentification from "../../components/templates/Authentification/Authentification";
import { useParams } from "react-router-dom";

export default function ResetPasswordPage(props: ResetPasswordPageProps) {
    const { authType } = props;
    return (
        <div>
            <Authentification authType={authType} />
        </div>
    );
}

interface ResetPasswordPageProps {
    authType?:
        | "login"
        | "reset-password"
        | "change-password"
        | "forget-password"
        | "check-email"
        | "custom";
}

ResetPasswordPage.defaultProps = {
    authType: "reset-password",
};
