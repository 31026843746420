import React from "react";
import Unavailability from "../../../components/templates/EmployeesEdit/Unavailability";
import { useEmployees } from "../../../hooks/useEmployees";
import AddUnavailabilityDialog from "../../../components/templates/EmployeesEdit/AddUnavaibilityDialog";
import EditUnavaibilityForm from "../../../components/templates/EmployeesEdit/EditUnavaibilityForm";
import {
  generateFields,
  destructFields,
  Validation,
} from "../../../_helpers/FormHelpers";
import moment from "moment";
import { timeOptions } from "../../../Options";
import { useNotificationContext } from "../../../Context/Notification";
import { useIntl } from "react-intl";
import DeleteUnavaibilityDialog from "./DeleteUnavaibilityDialog";

interface Unavavaibility {
  _id?: string;
  endDate: string;
  startDate: string;
  day: number;
  employeeId: string;
  repeat: boolean;
  startHour: { value: string; option: string };
  endHour: { value: string; option: string };
  note: string;
  allDay: boolean;
}

export default function UnavailabilityPage({}: UnavailabilityInterface) {
  const {
    disponibilities,
    employeeForEdit,
    createUnavaibility,
    updateUnavaibility,
    actionLoading,
    getDisponibilities,
  } = useEmployees();
  const { addNotification } = useNotificationContext();
  const [queryParams, setQueryParams] = React.useState({});
  const [unavaibility, setUnavaibility] = React.useState<any>(null);
  const [unavaibilityForDelete, setUnavaibilityForDelete] =
    React.useState<any>(null);
  const intl = useIntl();
  const getTimeValue = (value: string) => {
    if (value === "") return "";
    let index = timeOptions.findIndex((val) => val.value === value);
    if (index !== -1) {
      return timeOptions[index];
    }
    return "";
  };

  const openEditDeleteUnavailabilityDialog = (unavaibility: any) => {
    setUnavaibilityForDelete(unavaibility);
  };

  const openEditUnavailabilityDialog = (unavaibility: any) => {
    if (unavaibility) {
      setUnavaibility(
        generateFields([
          { name: "_id", value: unavaibility._id },
          { name: "startDate", value: unavaibility.startDate },
          { name: "endDate", value: unavaibility.endDate },
          {
            name: "day",
            value: unavaibility.day,
            touched: true,
          },
          { name: "repeat", value: unavaibility.repeat },
          {
            name: "startHour",
            value: getTimeValue(unavaibility.startHour),
            touched: true,
          },
          {
            name: "endHour",
            value: getTimeValue(unavaibility.endHour),
            touched: true,
          },
          { name: "note", value: unavaibility.note },
          { name: "employeeId", value: unavaibility.employee },
          {
            name: "allDay",
            value:
              unavaibility.startHour === "00:00" &&
              unavaibility.endHour === "23:30",
          },
          {
            name: "addNotes",
            value: unavaibility.note && unavaibility.note !== "" ? true : false,
          },
        ])
      );
    } else {
      setUnavaibility(
        generateFields([
          { name: "startDate", value: "" },
          { name: "endDate", value: "" },
          { name: "day", value: "" },
          { name: "repeat", value: true },
          { name: "startHour", value: getTimeValue("09:00") },
          { name: "endHour", value: getTimeValue("18:00") },
          { name: "note", value: "" },
          { name: "employeeId", value: employeeForEdit._id },
          { name: "allDay", value: false },
          {
            name: "addNotes",
            value: false,
          },
        ])
      );
    }
  };

  const hideEditUnavailabilityDialog = () => {
    setUnavaibility(null);
  };

  const validate = (unavaibility: any) => {
    let error = false;
    new Validation()
      .add(unavaibility.startDate, [
        {
          TEST: () => {
            if (
              !unavaibility.startDate.value ||
              unavaibility.startDate.value === ""
            ) {
              return true;
            }
            if (
              unavaibility.startDate.value &&
              unavaibility.startDate.value !== "" &&
              unavaibility.endDate.value &&
              unavaibility.endDate.value !== ""
            ) {
              return moment(unavaibility.startDate.value).isAfter(
                unavaibility.endDate.value
              );
            }

            return false;
          },
          message: "",
        },
      ])
      .add(unavaibility.endDate, [
        {
          TEST: () => {
            if (
              !unavaibility.endDate.value ||
              unavaibility.endDate.value === ""
            ) {
              return true;
            }
            if (
              unavaibility.startDate.value &&
              unavaibility.startDate.value !== "" &&
              unavaibility.endDate.value &&
              unavaibility.endDate.value !== ""
            ) {
              return moment(unavaibility.endDate.value).isBefore(
                unavaibility.startDate.value
              );
            }

            return false;
          },
          message: "",
        },
      ])
      .add(unavaibility.day, [
        {
          TEST: () => {
            if (
              unavaibility.day.value === undefined ||
              unavaibility.day.value === ""
            ) {
              return true;
            }
            return false;
          },
          message: "Veuillez choisir une journée",
        },
      ])
      .validate((newState) => {
        setUnavaibility((prev: any) => ({
          ...prev,
          ...generateFields(newState),
        }));
        newState.forEach((val) => {
          if (val.hasError) {
            error = true;
          }
        });
      });
    return error;
  };

  const onBlur = (field: string) => {
    if (unavaibility) {
      const newState = {
        ...unavaibility,
        [field]: {
          ...unavaibility[field],
          touched: true,
        },
      };
      setUnavaibility(newState);
      validate(newState);
    }
  };
  const onSubmitUnavaibility = () => {
    if (
      validate({
        ...unavaibility,
        startDate: {
          ...unavaibility.startDate,
          touched: true,
        },
        endDate: {
          ...unavaibility.endDate,
          touched: true,
        },
      })
    ) {
      return;
    }
    const values = destructFields(unavaibility) as Unavavaibility;

    if (values._id) {
      updateUnavaibility({
        ...values,
        startDate:
          values.startDate && typeof values.startDate !== "string"
            ? moment(values.startDate).toISOString()
            : values.startDate,
        endDate:
          values.endDate && typeof values.endDate !== "string"
            ? moment(values.endDate).toISOString()
            : values.endDate,
        startHour: values.allDay ? "00:00" : values.startHour.value,
        endHour: values.allDay ? "23:30" : values.endHour.value,
        note : unavaibility.addNotes.value ? values.note : ""
      }).then((res: any) => {
        const data: any = res.payload;
        if (data.status === "success") {
          addNotification({
            title: intl.formatMessage({
              id: "UNAVAIBILITY.EDIT.NOTIFICATION.SUCCESS.TITLE",
            }),
            message: intl.formatMessage({
              id: "UNAVAIBILITY.EDIT.NOTIFICATION.SUCCESS.MESSAGE",
            }),
            type: "success",
          });
          getDisponibilities({ id: employeeForEdit._id });
        } else if (data.status === "fail") {
          addNotification({
            title: intl.formatMessage({
              id: "UNAVAIBILITY.EDIT.NOTIFICATION.FAIL.TITLE",
            }),
            message: intl.formatMessage({
              id: "UNAVAIBILITY.EDIT.NOTIFICATION.FAIL.MESSAGE",
            }),
            type: "fail",
          });
        }
        setUnavaibility(null);
      });
    } else {
      createUnavaibility({
        ...values,
        startDate:
          values.startDate && typeof values.startDate !== "string"
            ? moment(values.startDate).toISOString()
            : values.startDate,
        endDate:
          values.endDate && typeof values.endDate !== "string"
            ? moment(values.endDate).toISOString()
            : values.endDate,
        startHour: values.allDay ? "00:00" : values.startHour.value,
        endHour: values.allDay ? "23:30" : values.endHour.value,
        note : unavaibility.addNotes.value ? values.note : ""
      }).then((res: any) => {
        const data: any = res.payload;
        if (data.status === "success") {
          addNotification({
            title: "Ajout d'une indisponibilité",
            message: "Indisponibilité ajoutée avec succès",
            type: "success",
          });
          setUnavaibility(null);
          getDisponibilities({ id: employeeForEdit._id });
        } else if (data.status === "fail") {
          addNotification({
            title: intl.formatMessage({
              id: "UNAVAIBILITY.EDIT.NOTIFICATION.FAIL.TITLE",
            }),
            message: intl.formatMessage({
              id: "UNAVAIBILITY.EDIT.NOTIFICATION.FAIL.MESSAGE",
            }),
            type: "fail",
          });
          setUnavaibility(null);
        }
      });
    }
  };

  const handleChangeUnavaibility = (field: string, value: any) => {
    if (unavaibility) {
      const newState = {
        ...unavaibility,
        [field]: {
          ...unavaibility[field],
          value: value,
        },
      };
      setUnavaibility(newState);
      validate(newState);
    }
  };

  return (
    <>
      {unavaibilityForDelete && (
        <DeleteUnavaibilityDialog
          show={unavaibilityForDelete !== null}
          unavaibility={unavaibilityForDelete}
          onHide={() => setUnavaibilityForDelete(null)}
        />
      )}
      {unavaibility && (
        <AddUnavailabilityDialog
          show={unavaibility ? true : false}
          onHide={hideEditUnavailabilityDialog}
          actionLoading={actionLoading}
          EditUnavaibilityForm={
            <EditUnavaibilityForm
              unavaibility={unavaibility}
              onChange={handleChangeUnavaibility}
              onSubmit={onSubmitUnavaibility}
              onBlur={onBlur}
            />
          }
          unavaibility={unavaibility}
          onSubmit={onSubmitUnavaibility}
        />
      )}
      {disponibilities && (
        <Unavailability
          openEditUnavailabilityDialog={openEditUnavailabilityDialog}
          openEditDeleteUnavailabilityDialog={
            openEditDeleteUnavailabilityDialog
          }
          data={disponibilities}
          queryParams={queryParams}
        />
      )}
    </>
  );
}

interface UnavailabilityInterface {}
