import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EmployeeWorkHourState } from "./types";
import { getEmployeeWorkHoursService } from "./service";
import moment from "moment";

const initialEmployeeWorkHourState: EmployeeWorkHourState = {
    loading: false,
    error: false,
    success: false,
    workHourPeriod: [],
};
const employeeWorkHourState = createSlice({
    name: "employeeWorkHour",
    initialState: initialEmployeeWorkHourState,
    reducers: {
        resetAllEmpWorkHours: (state) => {
            state = initialEmployeeWorkHourState;
        },
    },
    extraReducers: (builder) => {
        // // CASE : GETALL VACATIONS
        builder.addCase(
            getEmployeeWorkHoursService.pending,
            (state: EmployeeWorkHourState) => {
                state.loading = true;
                state.success = false;
                state.error = false;
            }
        );
        builder.addCase(
            getEmployeeWorkHoursService.fulfilled,
            (state: EmployeeWorkHourState, action: PayloadAction<any>) => {
                state.loading = false;
                state.success = true;
                state.error = false;
                if (action.payload !== undefined) {
                    console.log("test valide get holidays");
                    console.log("payload ", action.payload);
                    const workPeriod = [];
                    for (const weeklyWorkHours of action.payload) {
                        let shifts = weeklyWorkHours.shifts.map(
                            (shiftInfo: any) => {
                                return {
                                    shiftDay: shiftInfo.shiftDay,
                                    shiftBadge: {
                                        startShift:
                                            shiftInfo.shiftBadge.startShift,
                                        endShift: shiftInfo.shiftBadge.endShift,
                                        pause: shiftInfo.shiftBadge.pause,
                                    },
                                    shiftReel: {
                                        startShift:
                                            shiftInfo.shiftReel.startShift,
                                        endShift: shiftInfo.shiftReel.endShift,
                                        pause: shiftInfo.shiftReel.pause,
                                    },
                                    duration: (shiftInfo.duration / 60).toFixed(
                                        2
                                    ),
                                    repas: shiftInfo.repas,
                                    site: shiftInfo.site,
                                    comment: shiftInfo.comment,
                                };
                            }
                        );

                        shifts.sort((a: any, b: any) => {
                            const dateA = moment(a.shiftDay, "YYYY-MM-DD");
                            const dateB = moment(b.shiftDay, "YYYY-MM-DD");

                            if (dateA.isValid() && dateB.isValid()) {
                                return dateA.diff(dateB);
                            } else {
                                return 0;
                            }
                        });
                        workPeriod.push({
                            weekNumber: weeklyWorkHours.weekNumber,
                            totalShiftBadges: parseFloat(
                                (weeklyWorkHours.totalShiftBadges / 60).toFixed(
                                    2
                                )
                            ),
                            totalShiftReels: parseFloat(
                                (weeklyWorkHours.totalShiftReels / 60).toFixed(
                                    2
                                )
                            ),
                            totalDuration: parseFloat(
                                (weeklyWorkHours.totalDuration / 60).toFixed(2)
                            ),
                            totalRepas: weeklyWorkHours.totalRepas,
                            shifts: shifts,
                        });
                    }
                    state.workHourPeriod = workPeriod;
                }
            }
        );
        builder.addCase(
            getEmployeeWorkHoursService.rejected,
            (state: EmployeeWorkHourState) => {
                state.loading = false;
                state.success = false;
                state.error = true;
            }
        );
    },
});

export const { resetAllEmpWorkHours } = employeeWorkHourState.actions;
export default employeeWorkHourState.reducer;
