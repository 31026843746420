import React from "react";

export function Footer({ children }: FooterProps) {
    return (
        <div
            // className="px-5"
            style={{
                position: "sticky",
                bottom: "-2px",
                backgroundColor: "white",
                padding: "1px",
                boxShadow: "0px -10px 10px -12px rgba(0, 0, 0, 0.2)",
            }}
        >
            {children}
        </div>
    );
}

interface FooterProps {
    children: React.ReactNode;
}
