import * as React from "react";
import "./style.scss";
import Button from "../../atoms/Button";
import Checkbox from "../../atoms/Checkbox";
import TextData from "../../atoms/TextData";
import { ReactComponent as Icon } from "./icon.svg";

function ReportDisplayOption(props: ReportDisplayOptionProps) {
    const {
        IconSVG,
        isOpen: isOpenProp,
        options: optionsProp,
        handleOptionChange,
        buttonbackgroundColor,
        buttonwidth,
        buttonstyle,
        cardHeaderText,
        cardHeaderColor,
        cardHeaderSize,
        cardHeaderWeight,
        cardBodyTextWidth,
        cardBodyTextColor,
        cardBodyTextSize,
        cardBodyTextWeight,
        cardBodyCheckboxRounded,
        isRapport,
        hideOptions = [],
    } = props;
    const [isOpen, setisOpen] = React.useState(isOpenProp || false);
    const [options, setoptions] = React.useState(optionsProp || []);
    const handleChange = (e: any) => {
        const { id, checked } = e.target;
        const newOptions = options.map((option, index) => {
            if (index === Number(id)) {
                const changedOption = {
                    ...option,
                    checked,
                };
                handleOptionChange?.(changedOption);
                return changedOption;
            }
            return option;
        });
        setoptions(newOptions);
    };
    React.useEffect(() => {
        setoptions(options);
    }, [options]);
    const globalProps = {
        buttonProps: {
            backgroundColor: buttonbackgroundColor,
            width: buttonwidth,
            style: buttonstyle,
            onClick: () => setisOpen((previous) => !previous), // toogle between true and false accourding to the previous value
        },
        cardHeaderProps: {
            fontWeight: cardHeaderWeight,
            color: cardHeaderColor,
            size: cardHeaderSize,
        },
        cardBodyTextProps: {
            variant: "label" as "label",
            width: cardBodyTextWidth,
            color: cardBodyTextColor,
            size: cardBodyTextSize,
            fontWeight: cardBodyTextWeight,
        },
        cardBodyCheckboxProps: {
            rounded: cardBodyCheckboxRounded,
            onClick: handleChange,
            text: null,
        },
    };
    return (
        <div className="display-option-container">
            <Button {...globalProps.buttonProps}>
                <IconSVG />
            </Button>
            {isOpen && (
                <>
                    <div
                        className="card-container"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="card-header">
                            <TextData {...globalProps.cardHeaderProps}>
                                {cardHeaderText}
                            </TextData>
                        </div>
                        <div className="card-body">
                            {options.map((option, index) => {
                                const { text, checked } = option;
                                return (
                                    <>
                                        {!hideOptions.includes(index) && (
                                            <div
                                                className="card-body-item"
                                                key={index}
                                            >
                                                <TextData
                                                    {...globalProps.cardBodyTextProps}
                                                >
                                                    {text}
                                                </TextData>
                                                <Checkbox
                                                    id={index.toString()}
                                                    checked={checked}
                                                    {...globalProps.cardBodyCheckboxProps}
                                                />
                                            </div>
                                        )}
                                        {isRapport &&
                                            index % 3 === 0 &&
                                            index !== 0 && (
                                                <span
                                                    style={{
                                                        borderBottom:
                                                            "1px solid #8e96c178",
                                                        marginLeft: "auto",
                                                        marginRight: "auto",
                                                        width: "95%",
                                                    }}
                                                ></span>
                                            )}
                                    </>
                                );
                            })}
                        </div>
                    </div>

                    <div
                        className="card-modal-container"
                        onClick={() => setisOpen(!isOpen)}
                    ></div>
                </>
            )}
        </div>
    );
}
interface ReportDisplayOptionProps {
    isOpen?: boolean;
    options?: {
        text: string;
        checked: boolean;
    }[];
    handleOptionChange?: (options: any) => void;
    IconSVG: React.ElementType;
    buttonbackgroundColor?: string;
    buttonwidth?: string;
    buttonstyle?: React.CSSProperties;
    cardHeaderText?: string;
    cardHeaderColor?: string;
    cardHeaderSize?: string;
    cardHeaderWeight?: string;
    cardBodyTextWidth?: string;
    cardBodyTextColor?: string;
    cardBodyTextSize?: string;
    cardBodyTextWeight?: string;
    cardBodyCheckboxRounded?: boolean;
    isRapport?: boolean;
    hideOptions?: number[];
}
ReportDisplayOption.defaultProps = {
    isOpen: false,
    options: [
        {
            text: "option 1",
            checked: false,
        },
        {
            text: "option 2",
            checked: false,
        },
    ],
    handleOptionChange: (option: any) => {},
    IconSVG: Icon,
    buttonbackgroundColor: "transparent",
    buttonwidth: "auto",
    buttonstyle: { margin: "0 15px" },
    cardHeaderText: "OPTIONS D'AFFICHAGE",
    cardHeaderColor: "#8E96C1",
    cardHeaderSize: "10px",
    cardHeaderWeight: "bold",
    cardBodyTextWidth: "auto",
    cardBodyTextColor: "#2A346D",
    cardBodyTextSize: "12px",
    cardBodyTextWeight: "normal",
    cardBodyCheckboxRounded: true,
    isRapport: false,
    hideOption: [],
};

export default ReportDisplayOption;
