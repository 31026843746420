import * as React from "react";
import "./style.css";
import Modal from "../../atoms/Modal";
import PopUpCard from "../../molecules/PopUpCard";
import TextData from "../../atoms/TextData";
import Picture from "../../atoms/Picture";
import { usePlanning } from "../../../hooks/usePlanning";
import Loading from "../../atoms/Loading";
import { ReactComponent as ValidSVG } from "./valid.svg";
import { ReactComponent as InvalidSVG } from "./invalid.svg";
import { useCollectiveAgreement } from "../../../hooks/useCollectiveAgreement";
import { useBranch } from "../../../hooks/useBranch";
import { useAdministrative } from "../../../hooks/useAdministrative";
export default function PlanningPublishCard(
    props: any /*PlanningPublishCardProps*/,
) {
    const { display, department, onClose } = props;
    const { publishPlanning, loadingVerification, verifyConvention } =
        usePlanning();
    const {
        enableCollectiveAgreement,
        enforceCollectiveAgreement,
        getAllCollectiveAgreements,
    } = useCollectiveAgreement();
    const { selectedBranchSettings } = useBranch();
    const { getAdministrative } = useAdministrative();
    React.useEffect(() => {
        getAdministrative();
        getAllCollectiveAgreements();
    }, [selectedBranchSettings._id]);
    const [conventionData, setConventionData] = React.useState<any>(null);
    const [isConvention, setIsConvention] = React.useState(false);
    React.useEffect(() => {
        setIsConvention(
            enableCollectiveAgreement && enforceCollectiveAgreement,
        );
    }, [enableCollectiveAgreement, enforceCollectiveAgreement]);
    const checkConvention = async () => {
        let data: any = await verifyConvention(
            department ? department.depId : null,
        );
        setConventionData(data);
    };
    React.useEffect(() => {
        if (display && isConvention) {
            checkConvention();
        } else {
            setConventionData(null);
        }
    }, [display]);
    const handleOnPusblish = () => {
        publishPlanning(true, department ? department.depId : null);
        onClose && onClose();
    };
    const HeaderTitle = () => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "40px",
                }}
            >
                <TextData
                    variant="label"
                    width="auto"
                    size="22px"
                    fontWeight="700"
                    color="#2A346D"
                >
                    {loadingVerification
                        ? "Verification du convention collective en cours..."
                        : "Publier le planning"}
                </TextData>
            </div>
        );
    };
    return (
        <Modal modalEnabled={display} onClose={onClose}>
            <div className="attendance-container">
                <PopUpCard
                    display={display}
                    onClose={onClose}
                    containerWidth="auto"
                    header={<HeaderTitle />}
                    widthCloseIcon="24px"
                    styleCloseIcon={{
                        height: "auto",
                        marginRight: "37px",
                        width: "24px",
                        border: "1px solid #edeff533",
                        borderTopRightRadius: "20px",
                    }}
                    headerStyle={{
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#2A346D",
                    }}
                    popupHeaderStyle={{
                        height: "80px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                    }}
                    button1Text={"Annuler"}
                    onClickButton1={onClose}
                    button2Text={"Confirmer"}
                    onClickButton2={handleOnPusblish}
                    activateFooter={
                        (isConvention && conventionData?.length === 0) ||
                        !isConvention
                    }
                >
                    <div
                        className="planning-publish-container"
                        style={{
                            width: "600px",
                            height: "80px",
                            // backgroundColor: "red",
                            position: "relative",
                        }}
                    >
                        {loadingVerification && isConvention ? (
                            <Loading width="200px" height="200px" />
                        ) : (
                            <>
                                {isConvention && (
                                    <div
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        {conventionData?.length === 0 ? (
                                            <TextData
                                                variant="div"
                                                width="auto"
                                                color="#248d27"
                                                size="20px"
                                                fontWeight="400"
                                                style={{
                                                    backgroundColor: "#ddefd7",
                                                    padding: "10px 15px",
                                                    width: "100%",
                                                    borderRadius: "8px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "8px",
                                                }}
                                            >
                                                <ValidSVG color="inherit" />
                                                Convention validée
                                            </TextData>
                                        ) : (
                                            conventionData?.length > 0 && (
                                                <TextData
                                                    variant="div"
                                                    width="auto"
                                                    color="#ce2525"
                                                    size="20px"
                                                    fontWeight="400"
                                                    style={{
                                                        backgroundColor:
                                                            "#f7d6d6",
                                                        padding: "10px 15px",
                                                        width: "100%",
                                                        borderRadius: "8px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <InvalidSVG color="inherit" />
                                                    Convention non validée, vous
                                                    ne pouvez pas publier le
                                                    planning
                                                </TextData>
                                            )
                                        )}
                                    </div>
                                )}
                                {((isConvention &&
                                    conventionData?.length === 0) ||
                                    !isConvention) && (
                                    <div
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <TextData
                                            variant="div"
                                            width="auto"
                                            color="#6F78AB"
                                            size="20px"
                                            fontWeight="400"
                                            style={{
                                                width: "100%",
                                            }}
                                        >
                                            Voulez-vous vraiment publier le
                                            planning de la semaine?{" "}
                                            {/* <TextData
                                                variant='label'
                                                width='auto'
                                                color='#6F78AB'
                                                size='20px'
                                                fontWeight='700'
                                            >
                                                38 (20 - 26 décembre) ?
                                            </TextData> */}
                                        </TextData>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </PopUpCard>
            </div>
        </Modal>
    );
}

interface PlanningPublishCardProps {
    display?: boolean;
    onClose?: (event?: Event) => void;
}

PlanningPublishCard.defaultProps = {
    display: true,
    onClose: () => {
        console.log("onClose");
    },
};
