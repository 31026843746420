import * as React from "react";
import classnames from "classnames";
import "./style.scss";

import frFlag from "../../../utils/img/fr-flag.svg";
import esFlag from "../../../utils/img/es-flag.svg";
import arrow from "./arrow.svg";

import Picture from "../../atoms/Picture";
import TextData from "../../atoms/TextData";

import { useLanguage } from "../../../hooks/useLanguage";

export default function ChangeLanguage(props: ChangeLanguageProps) {
    const { languages, className, selectDefaultLanguage } = props;

    const classNames = classnames(className);
    const { language, setLanguage } = useLanguage();
    const [selectedLanguage, setSelectedLanguage] = React.useState(
        languages?.find((lang) => lang.langName === selectDefaultLanguage)
    );
    React.useEffect(() => {
        if (!language && selectDefaultLanguage) {
            setLanguage(selectDefaultLanguage);
        }
    }, [selectDefaultLanguage]);
    React.useEffect(() => {
        setSelectedLanguage(
            languages?.find((lang) => lang.langName === language)
        );
    }, [language, languages]);

    const [isOpen, setIsOpen] = React.useState(false);

    const handleClickArrow = () => {
        setIsOpen(!isOpen);
    };
    const handleSelectLang = (lang: any) => {
        setIsOpen(!isOpen);
        setSelectedLanguage(lang);
        setLanguage(lang.langName);
    };

    if (selectedLanguage && languages && languages.length > 0) {
        return (
            <div className={classNames}>
                {isOpen ? (
                    <div className='lang-container lang-list'>
                        {languages.map((lang) => {
                            if (lang.langName === selectedLanguage.langName) {
                                return (
                                    <div
                                        className='lang-item'
                                        onClick={() => handleSelectLang(lang)}
                                        style={{
                                            order: -1,
                                        }}
                                    >
                                        <Picture imgPath={lang.icon} />
                                        <TextData color='#2A346D' width='auto'>
                                            {lang.langName}
                                        </TextData>
                                        <Picture
                                            imgPath={arrow}
                                            onClick={handleClickArrow}
                                            style={{
                                                transform: isOpen
                                                    ? "rotate(0deg)"
                                                    : "rotate(180deg)",
                                            }}
                                        />
                                    </div>
                                );
                            } else {
                                return (
                                    <div
                                        className='lang-item lang-item-absolute'
                                        onClick={() => handleSelectLang(lang)}
                                    >
                                        <Picture imgPath={lang.icon} />
                                        <TextData color='#6F78AB' width='auto'>
                                            {lang.langName}
                                        </TextData>
                                    </div>
                                );
                            }
                        })}
                    </div>
                ) : (
                    <div className='lang-container' onClick={handleClickArrow}>
                        <Picture imgPath={selectedLanguage.icon} />
                        <TextData color='#6F78AB' width='auto'>
                            {selectedLanguage.langName}
                        </TextData>
                        <Picture
                            imgPath={arrow}
                            style={{
                                transform: isOpen
                                    ? "rotate(0deg)"
                                    : "rotate(180deg)",
                            }}
                        />
                    </div>
                )}
            </div>
        );
    }
    return null;
}

interface ChangeLanguageProps {
    languages?: {
        langName: string;
        icon: string;
    }[];
    selectDefaultLanguage?: string;
    className?: string[];
}

ChangeLanguage.defaultProps = {
    languages: [
        {
            langName: "Français",
            icon: frFlag,
        },
        {
            langName: "espagnol",
            icon: esFlag,
        },
    ],
    selectDefaultLanguage: "Français",
};
