import React from "react";
import { ReactComponent as TrashSVG } from "./trash.svg";
export default function ConditionItem(props: any) {
    const { item, setToEdit, setOpenPopup } = props;
    return (
        <div
            className="hover:bg-gray-100"
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#6f78ab",
                borderRadius: "8px",
            }}
        >
            <div
                style={{
                    cursor: "pointer",
                    padding: "10px",
                    flexGrow: "1",
                }}
                onClick={() => {
                    setToEdit(item);
                    setOpenPopup(true);
                }}
            >
                Si l’employé{" "}
                {item.workHours
                    ? `dépasse ${item.workHours} heures de travail`
                    : item.startHour && item.endHour
                      ? `travaille entre ${item.startHour} et ${item.endHour}`
                      : ""}
                , on lui accorde automatiquement{" "}
                {item.pause ? <b>{item.pause}mn de pause</b> : ""}
                {item.pause && item.repas ? " et " : ""}
                {item.repas ? <b> {item.repas} repas</b> : ""}.
            </div>
            {/* <div
                style={{
                    padding: "10px",
                }}
            >
                <TrashSVG
                    style={{
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        handleDelete(item);
                    }}
                />
            </div> */}
        </div>
    );
}
