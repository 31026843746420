import * as React from "react";

import NewTicket from "../../../../components/atoms/NewTicket";
import Ticket from "../../../../components/atoms/Ticket";
import NewTicketCard from "../../../../components/organism/NewTicketCard";
import Modal from "../../../../components/atoms/Modal";
import NewDepartmentCard from "../../../../components/organism/NewDepartmentCard";

function TicketsList(props: TicketsListProps) {
    const {
        tickets,
        handleTicketEdit,
        handleTicketDelete,
        //create new planning
        isCreatePopup,
        setIsCreatePopup,
        createPopupCard,
        //update planning
        isUpdatePopup,
        setIsUpdatePopup,
        UpdatePopupCard,
        //delete planning
        isDeletePopup,
        setIsDeletePopup,
        DeletePopupCard,
    } = props;

    const [listOfTickets, setListOfTickets] = React.useState(tickets ?? []);
    React.useEffect(() => {
        setListOfTickets(
            tickets?.sort((a: any, b: any) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                return nameA.localeCompare(nameB, "en", {
                    sensitivity: "base",
                });
            }) ?? [],
        );
    }, [tickets]);

    const handleUpdate = (id: any) => {
        handleTicketEdit?.(id);
    };
    const handleDelete = (id: any) => {
        handleTicketDelete?.(id);
    };
    return (
        <div>
            <div className="multi-planning-tickets">
                {listOfTickets.length > 0 &&
                    listOfTickets.map((elem: any, index) => {
                        let text = elem.name;
                        if (elem.fromHour || elem.toHour) {
                            text += " ( ";
                            text +=
                                elem.fromHour !== "" ? elem.fromHour : " ... ";
                            text += " - ";
                            text += elem.toHour !== "" ? elem.toHour : " ... ";
                            text += " ) ";
                        }
                        text =
                            text.slice(0, 25) + (text.length > 25 ? "..." : "");
                        return (
                            <Ticket
                                id={elem.id}
                                key={index}
                                text={text}
                                backgroundColor={elem.backgroundColor}
                                closeIconColor={elem.backgroundColor}
                                editIconColor={elem.backgroundColor}
                                handleEdit={handleUpdate}
                                handleClose={handleDelete}
                                iconBackgroundColor="white"
                                textColor="white"
                                border="none"
                                width="200px"
                                style={{
                                    text: {
                                        width: "100%",
                                    },
                                    container: {
                                        padding: "5px 8px",
                                    },
                                }}
                            />
                        );
                    })}
            </div>
            <NewTicket
                handleAddNewTicket={() => setIsCreatePopup(!isCreatePopup)}
                width="fit-content"
                text="Nouvelle étiquette"
            />
            <Modal
                modalEnabled={isCreatePopup}
                onClose={() => setIsCreatePopup(false)}
            >
                {createPopupCard}
            </Modal>
            <Modal
                modalEnabled={isUpdatePopup}
                onClose={() => setIsUpdatePopup(false)}
            >
                {UpdatePopupCard}
            </Modal>
            <Modal
                modalEnabled={isDeletePopup}
                onClose={() => setIsDeletePopup(false)}
            >
                {DeletePopupCard}
            </Modal>
        </div>
    );
}
interface TicketsListProps {
    tickets?: {
        id: string;
        name: string;
        backgroundColor: string;
        fromHour?: string;
        toHour?: string;
    }[];
    handleTicketEdit?: (id: string) => void;
    handleTicketDelete?: (id: string) => void;
    //create new planning
    isCreatePopup?: boolean;
    setIsCreatePopup: (state: boolean) => void;
    createPopupCard: React.ReactNode;
    //update planning
    isUpdatePopup?: boolean;
    setIsUpdatePopup: (state: boolean) => void;
    UpdatePopupCard: React.ReactNode;
    //delete planning
    isDeletePopup?: boolean;
    setIsDeletePopup: (state: boolean) => void;
    DeletePopupCard: React.ReactNode;
}
TicketsList.defaultProps = {
    tickets: [
        {
            id: "Pizza",
            name: "Pizza",
            backgroundColor: "blue",
        },
        {
            id: "Pizza",
            name: "Salle",
            backgroundColor: "blue",
        },
        {
            id: "Cuisine",
            name: "Cuisine",
            backgroundColor: "blue",
        },
    ],
    handleTicketEdit: (id: string) => {},
    handleTicketDelete: (id: string) => {},
    //create new planning
    isCreatePopup: false,
    setIsCreatePopup: (state: boolean) => {},
    createPopupCard: <NewDepartmentCard />,
    //update planning
    isUpdatePopup: false,
    setIsUpdatePopup: (state: boolean) => {},
    UpdatePopupCard: <NewDepartmentCard />,
    //delete planning
    isDeletePopup: false,
    setIsDeletePopup: (state: boolean) => {},
    DeletePopupCard: <NewDepartmentCard />,
};
export default TicketsList;
