import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../constants/env";

export const getEmployeeWorkHoursService = createAsyncThunk(
    "employeeProfile/getEmployeeWorkHours",
    async (payload: any, { rejectWithValue }) => {
        const { siteID, startDay, endDay, employeeID } = payload;
        const token = localStorage.getItem("token");
        if (token && siteID && startDay && endDay && employeeID) {
            try {
                const res = await fetch(
                    API.client.report.periodWorkHours(employeeID),
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({ siteID, startDay, endDay }),
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    }
);

// export const getEmergencePaperService = createAsyncThunk(
//     "employeeProfile/getEmergencePaper",
//     async (payload: any, { rejectWithValue }) => {
//         const {
//             employee,
//             enseigne,
//             startDate,
//             endDate,
//             month,
//             showOnlyThisMonthRapport,
//             siteName,
//             sites,
//         } = payload;
//         const token = localStorage.getItem("token");
//         if (token && siteID && startDay && endDay && employeeID) {
//             try {
//                 const res = await fetch(
//                     API.client.report.periodWorkHours(employeeID),
//                     {
//                         method: "POST",
//                         headers: {
//                             "Content-Type": "application/json",
//                             authorization: `Bearer ${token}`,
//                         },
//                         body: JSON.stringify({ siteID, startDay, endDay }),
//                     }
//                 )
//                     .then((res) => res.json())
//                     .then((res) => {
//                         if (res.error) {
//                             throw new Error(res.message);
//                         }
//                         return res;
//                     });
//                 return res;
//             } catch (err) {
//                 return rejectWithValue(err);
//             }
//         }
//     }
// );
