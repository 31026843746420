import React from "react";
import HRFolder from "../../../components/templates/EmployeesEdit/HRFolder";
import ContractsPage from "./ContractsPage";
import PersonalInfoPage from "./PersonalInfoPage";
import NotesPage from "./NotesPage";
import PrimesPages from "./PrimesPage";
import WorkHoursPage from "./WorkHoursPage";
import HolidaysPage from "./HolidaysPage";

export default function HRFolderPage({}: HRFolderPageinterface) {
    return (
        <>
            <HRFolder
                PersonalInfo={<PersonalInfoPage />}
                Contracts={<ContractsPage />}
                WorkHours={<WorkHoursPage />}
                Holidays={<HolidaysPage />}
                Notes={<NotesPage />}
                Bonuses={<PrimesPages />}
            />
        </>
    );
}

interface HRFolderPageinterface {}
