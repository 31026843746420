import * as React from "react";
import classnames from "classnames";
import { useDrag, useDrop } from "react-dnd";
import "./style.scss";

import { ReactComponent as LineSVG } from "./lines.svg";
import { ReactComponent as ArrowSVG } from "./arrow.svg";
import Profile from "./photo.jpg";
import Picture from "../../atoms/Picture";
import TextData from "../../atoms/TextData";
import Button from "../../atoms/Button";
import ProfilePicture from "../../molecules/ProfilePicture";
import { ROUTER } from "../../../constants/env";
import { employeeRoleColor } from "../../../_helpers/Functions";
import { DndContext, closestCorners } from "@dnd-kit/core";
import {
    SortableContext,
    verticalListSortingStrategy,
    useSortable,
    arrayMove,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactComponent as DragSvg } from "./drag.svg";

export default function DraggableOrderItem(props: DraggableOrderItemProps) {
    const { employee, id } = props;
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
        isOver,
    } = useSortable({ id });
    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    };
    const { fname, lname, picture, role } = employee;
    const pictureProps = {
        imgPath: ROUTER.STATIC_IMG(picture),
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        style: { borderRadius: "50%" },
    };

    return (
        <div
            // style={{
            //     display: isDragging ? "none" : "block",
            // }}
            className="draggable-item-container"
            ref={setNodeRef}
            {...attributes}
            // {...listeners}
            style={{
                ...style,
                position: isDragging ? "relative" : "static",
                padding: "10px",
                backgroundColor: isDragging
                    ? "#a6d3e2"
                    : isOver
                      ? "#cfe0e6"
                      : "white",
                boxShadow: isDragging
                    ? "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset"
                    : "none",
                zIndex: isDragging ? "9999" : "0",
                margin: "3px",
                borderRadius: "5px",
                cursor: "auto",
            }}
            key={id}
        >
            <div
                // className={containerClasses}
                // onMouseEnter={handleHover}
                // onMouseLeave={handleHover}
                // ref={(node) => {
                //     drag(drop(node));
                //     dropRef.current = node;
                // }}
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div
                    className="left-item"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "10px",
                    }}
                >
                    {/* <LineSVG /> */}
                    {/* <Picture
                        imgPath={Profile}
                        height='40px'
                        width='40px'
                        style={{
                            borderRadius: "100%",
                            marginLeft: "14px",
                            marginRight: "11px",
                        }}
                    /> */}
                    <ProfilePicture
                        profileImage={picture}
                        firstName={fname}
                        lastName={lname}
                        pictureProps={pictureProps}
                        altColor={employeeRoleColor(role)}
                    />
                    <TextData
                        width="auto"
                        variant="label"
                        size="14px"
                        fontWeight="700"
                        color="#101844"
                    >
                        {fname + " " + lname}
                    </TextData>
                </div>
                <div {...listeners} className="dragIcon">
                    <DragSvg />
                </div>
                {/* {isHover && !isOver && ( */}
                {/* <div className='right-item'>
                    <Button
                        width='auto'
                        backgroundColor='transparent'
                        onClick={handleDownButton}
                    >
                        <ArrowSVG />
                    </Button>
                    <Button
                        width='auto'
                        backgroundColor='transparent'
                        onClick={handleUpButton}
                    >
                        <ArrowSVG style={{ transform: "rotate(180deg)" }} />
                    </Button>
                </div> */}
                {/* )} */}
            </div>
        </div>
    );
}

interface DraggableOrderItemProps {
    employee: any;
    id?: any;
    handleUpButton?: (data?: any) => void;
    handleDownButton?: (data?: any) => void;
    moveItem?: (
        originalItemIndex?: any,
        positionIndex?: any,
        isHoverBelow?: any,
    ) => void;
}

DraggableOrderItem.defaultProps = {
    name: "Jacob Smith",
    id: 0,
    handleUpButton: () => {
        console.log("handle up button");
    },
    handleDownButton: () => {
        console.log("handle down button");
    },
};
