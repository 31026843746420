export const Female = ({
  width = 13,
  height = 21,
  color = "#E40FF6",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7062 11.8625C12.9625 10.6062 13.6562 8.93281 13.6562 7.15625C13.6562 5.37734 12.9648 3.70625 11.7062 2.45C10.45 1.19375 8.77656 0.5 7 0.5C5.22344 0.5 3.55 1.19141 2.29375 2.45C1.0375 3.70859 0.34375 5.37734 0.34375 7.15625C0.34375 8.65156 0.833594 10.0719 1.73594 11.232C1.90703 11.4523 2.09219 11.6609 2.29141 11.8625C2.49063 12.0617 2.70156 12.2469 2.91953 12.418C3.84766 13.1398 4.94219 13.5992 6.10938 13.7539V16.25H3.4375C3.33437 16.25 3.25 16.3344 3.25 16.4375V17.8438C3.25 17.9469 3.33437 18.0312 3.4375 18.0312H6.10938V21.3125C6.10938 21.4156 6.19375 21.5 6.29688 21.5H7.70312C7.80625 21.5 7.89062 21.4156 7.89062 21.3125V18.0312H10.5625C10.6656 18.0312 10.75 17.9469 10.75 17.8438V16.4375C10.75 16.3344 10.6656 16.25 10.5625 16.25H7.89062V13.7539C9.33203 13.5617 10.6609 12.9078 11.7062 11.8625ZM7 12.0312C5.69688 12.0312 4.47578 11.525 3.55234 10.6039C2.63125 9.68281 2.125 8.45937 2.125 7.15625C2.125 5.85313 2.63125 4.63203 3.55234 3.70859C4.47344 2.78516 5.69688 2.28125 7 2.28125C8.30312 2.28125 9.52422 2.7875 10.4477 3.70859C11.3687 4.62969 11.875 5.85313 11.875 7.15625C11.875 8.45937 11.3687 9.68047 10.4477 10.6039C9.52422 11.525 8.30312 12.0312 7 12.0312Z"
      fill={color}
    />
  </svg>
);

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}
