import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PlanningState } from "./types";
import moment from "moment";
const initialPlanningState: PlanningState = {
    planningInfo: null,
    planningInfoDepartments: null,
    employeesData: null,
    employeesPlanningData: null,
    dayPlanningData: null,
    week: moment().week(),
    year: String(moment().year()),
    startOfWeek: moment().startOf("week").format("YYYY-MM-DD"),
    endOfWeek: moment().endOf("week").format("YYYY-MM-DD"),
    services: [],
    steakers: [],
    absenceColors: null,
    customSort: null,
    fixedPlans: [],
    disponibility: null,
    siteSettingsEtab: {
        ouverture: "06:00",
        fermeture: "23:00",
    },
    createLoading: false,
    loading: false,
    error: null,
    options: {
        hideNotes: false,
        hideAlerts: false,
        hideAbsences: false,
        hideDisponibility: false,
        hidePlansEmployees: false,
        hideNoPlansEmployees: false,
        hideTotalTimeDepartment: false,
        hideFixedShifts: false,
    },
    daysServices: null,
    departmentsServices: null,
    toSearchPlanningEmployees: {},
    plannedWeeks: [],
    departments: null,
    orderedDepartments: [],
    sortedEmployeesWithOrder: [],
    employeesWithoutDepartment: {},
    hoursServices: {},
    departmentsHoursServices: {},
    draggedShiftType: "predefined",
    loadingVerification: false,
};
const planningSlice = createSlice({
    name: "planning",
    initialState: initialPlanningState,
    reducers: {
        resetAllPlanning: (state) => {
            state = initialPlanningState;
        },
        resetPlanningSlice: (state) => {
            state.planningInfo = null;
            state.planningInfoDepartments = null;
            state.employeesData = null;
            state.employeesPlanningData = null;
            state.dayPlanningData = null;
            state.week = moment().week();
            state.year = String(moment().year());
            state.startOfWeek = moment().startOf("week").format("YYYY-MM-DD");
            state.endOfWeek = moment().endOf("week").format("YYYY-MM-DD");
            state.services = [];
            state.steakers = [];
            state.absenceColors = [];
            state.customSort = null;
            state.fixedPlans = [];
            state.disponibility = null;
            state.siteSettingsEtab = {
                ouverture: "06:00",
                fermeture: "23:00",
            };
            state.loading = false;
            state.createLoading = false;
            state.error = null;

            state.options = {
                hideNotes: false,
                hideAlerts: false,
                hideAbsences: false,
                hideDisponibility: false,
                hidePlansEmployees: false,
                hideNoPlansEmployees: false,
                hideTotalTimeDepartment: false,
                hideFixedShifts: false,
            };
            state.daysServices = null;
            state.departmentsServices = null;
            state.toSearchPlanningEmployees = {};
            state.plannedWeeks = [];
            state.departments = null;
            state.sortedEmployeesWithOrder = [];
            state.orderedDepartments = [];
            state.employeesWithoutDepartment = {};
            state.hoursServices = {};
            state.departmentsHoursServices = {};
            state.draggedShiftType = "normal";
            state.loadingVerification = false;
        },
        setPlanningInfo: (state, action) => {
            state.planningInfo = action.payload;
            if (action.payload?.note?.length > 0) {
                state.planningInfo.note = state.planningInfo.note.sort(
                    (a: any, b: any) => {
                        const dateA = moment(a.day, "DD-MM-YYYY");
                        const dateB = moment(b.day, "DD-MM-YYYY");
                        if (dateA.isBefore(dateB)) {
                            return -1;
                        }
                        if (dateA.isAfter(dateB)) {
                            return 1;
                        }
                        return 0;
                    },
                );
            }
        },
        setPlanningInfoNote: (state, action) => {
            let newNotes = action.payload.note;
            if (action.payload?.note?.length > 0) {
                newNotes = newNotes.sort((a: any, b: any) => {
                    const dateA = moment(a.day, "DD-MM-YYYY");
                    const dateB = moment(b.day, "DD-MM-YYYY");
                    if (dateA.isBefore(dateB)) {
                        return -1;
                    }
                    if (dateA.isAfter(dateB)) {
                        return 1;
                    }
                    return 0;
                });
            }
            let note: any = [];
            for (let i = 0; i < newNotes.length; i++) {
                if (i > 0) {
                    if (newNotes[i].day === newNotes[i - 1].day) {
                        continue;
                    }
                }
                note.push(newNotes[i]);
            }
            if (action.payload.department) {
                state.planningInfoDepartments[action.payload.department].note =
                    note;
            } else {
                state.planningInfo.note = note;
            }
        },
        setEmployeesData: (state, action) => {
            if (action.payload !== undefined) {
                state.employeesData = action.payload;
            }
        },
        setEmployeesPlanningData: (state, action) => {
            if (action.payload?.addNewShift !== undefined) {
                // state.employeesPlanningData.employees[
                //     action.payload.employeeId
                // ] = [
                //     state.employeesPlanningData.employees[
                //         action.payload.employeeId
                //     ],
                //     ...action.payload.data,
                // ];
                // let updatedState = state.employeesPlanningData;
                // let convertedDay = moment(
                //     action.payload?.addNewShift.day
                // ).format("YYYY-MM-DD");
                // if (
                //     state.employeesPlanningData.employees[
                //         action.payload.employeeId
                //     ] !== undefined
                // ) {
                //     for (
                //         let i = 0;
                //         i <
                //         state.employeesPlanningData.employees[
                //             action.payload.employeeId
                //         ].length;
                //         i++
                //     ) {
                //         if (
                //             state.employeesPlanningData.employees[
                //                 action.payload.employeeId
                //             ][i].day === convertedDay
                //         ) {
                //             updatedState.employeesPlanningData.employees[
                //                 action.payload.employeeId
                //             ][i].workShares = [
                //                 updatedState.employeesPlanningData.employees[
                //                     action.payload.employeeId
                //                 ][i].workShares,
                //                 ...action.payload?.addNewShift,
                //             ];
                //         }
                //     }
                // } else {
                //     updatedState.employeesPlanningData.employees[
                //         action.payload.employeeId
                //     ] = {
                //         day: convertedDay,
                //         workShares: [action.payload.addNewShift],
                //     };
                // }
                // state.employeesPlanningData = updatedState;
            } else {
                console.log(
                    " setting after delete operation = ",
                    action.payload,
                );
                state.employeesPlanningData = action.payload;
                console.log(" after settt = ", state.employeesPlanningData);
            }
        },
        updatePlanning: (state, action) => {
            if (
                action.payload.week !== undefined &&
                action.payload.year !== undefined
            ) {
                state.week = action.payload.week;
                const year = action.payload.year;
                state.startOfWeek = moment()
                    .isoWeekYear(year)
                    .isoWeek(action.payload.week)
                    .startOf("isoWeek")
                    .format("YYYY-MM-DD");
                state.endOfWeek = moment()
                    .isoWeekYear(year)
                    .isoWeek(action.payload.week)
                    .endOf("isoWeek")
                    .format("YYYY-MM-DD");
            }
            if (action.payload.loadingVerification !== undefined) {
                state.loadingVerification = action.payload.loadingVerification;
            }
            if (action.payload.year !== undefined) {
                state.year = action.payload.year;
            }
            if (action.payload.planningInfoDepartments !== undefined) {
                state.planningInfoDepartments =
                    action.payload.planningInfoDepartments;
            }
            if (action.payload.services !== undefined) {
                state.services = action.payload.services;
            }
            if (action.payload.planningInfoDepartment !== undefined) {
                state.planningInfoDepartments[
                    action.payload.planningInfoDepartment.depId
                ] = action.payload.planningInfoDepartment.data;
                if (
                    action.payload.planningInfoDepartment.data.note?.length > 0
                ) {
                    state.planningInfoDepartments[
                        action.payload.planningInfoDepartment.depId
                    ].note = state.planningInfoDepartments[
                        action.payload.planningInfoDepartment.depId
                    ].note
                        ?.sort((a: any, b: any) => {
                            const dateA = moment(a.day, "DD-MM-YYYY");
                            const dateB = moment(b.day, "DD-MM-YYYY");
                            if (dateA.isBefore(dateB)) {
                                return -1;
                            }
                            if (dateA.isAfter(dateB)) {
                                return 1;
                            }
                            return 0;
                        })
                        .filter((nota: any) => /\S/.test(nota.note));
                }
            }
            if (action.payload.customSort !== undefined) {
                state.customSort = action.payload.customSort;
            }
            if (action.payload.fixedPlans !== undefined) {
                state.fixedPlans = action.payload.fixedPlans;
            }
            if (action.payload.disponibility !== undefined) {
                state.disponibility = action.payload.disponibility;
            }
            if (action.payload.loading !== undefined) {
                state.loading = action.payload.loading;
            }
            if (action.payload.createLoading !== undefined) {
                state.createLoading = action.payload.createLoading;
            }
            if (action.payload.error !== undefined) {
                state.error = action.payload.error;
            }
            if (action.payload.steakers !== undefined) {
                state.steakers = action.payload.steakers;
            }
            if (action.payload.absenceColors !== undefined) {
                state.absenceColors = action.payload.absenceColors;
            }
            if (action.payload.siteSettingsEtab !== undefined) {
                state.siteSettingsEtab = action.payload.siteSettingsEtab;
            }

            if (action.payload.toSearchPlanningEmployees !== undefined) {
                state.toSearchPlanningEmployees =
                    action.payload.toSearchPlanningEmployees;
            }
            if (action.payload.daysServices !== undefined) {
                state.daysServices = action.payload.daysServices;
            }
            if (action.payload.departmentsServices !== undefined) {
                state.departmentsServices = action.payload.departmentsServices;
            }
            if (action.payload.sortedEmployeesWithOrder !== undefined) {
                state.sortedEmployeesWithOrder =
                    action.payload.sortedEmployeesWithOrder;
            }
            if (action.payload.orderedDepartments !== undefined) {
                state.orderedDepartments = action.payload.orderedDepartments;
            }
            if (action.payload.plannedWeeks !== undefined) {
                state.plannedWeeks = action.payload.plannedWeeks;
            }
            if (action.payload.departments !== undefined) {
                state.departments = action.payload.departments;
            }
            if (action.payload.dayPlanningData !== undefined) {
                state.dayPlanningData = action.payload.dayPlanningData;
            }
            if (action.payload.employeesWithoutDepartment !== undefined) {
                state.employeesWithoutDepartment =
                    action.payload.employeesWithoutDepartment;
            }
            if (action.payload.hoursServices !== undefined) {
                state.hoursServices = action.payload.hoursServices;
            }
            if (action.payload.departmentsHoursServices !== undefined) {
                state.departmentsHoursServices =
                    action.payload.departmentsHoursServices;
            }
            if (action.payload.draggedShiftType !== undefined) {
                state.draggedShiftType = action.payload.draggedShiftType;
            }
        },
        updatePlanningOptions: (state, action) => {
            if (action.payload.hideNotes !== undefined) {
                state.options.hideNotes = action.payload.hideNotes;
            }
            if (action.payload.hideAlerts !== undefined) {
                state.options.hideAlerts = action.payload.hideAlerts;
            }
            if (action.payload.hideAbsences !== undefined) {
                state.options.hideAbsences = action.payload.hideAbsences;
            }
            if (action.payload.hideDisponibility !== undefined) {
                state.options.hideDisponibility =
                    action.payload.hideDisponibility;
            }
            if (action.payload.hidePlansEmployees !== undefined) {
                state.options.hidePlansEmployees =
                    action.payload.hidePlansEmployees;
            }
            if (action.payload.hideNoPlansEmployees !== undefined) {
                state.options.hideNoPlansEmployees =
                    action.payload.hideNoPlansEmployees;
            }
            if (action.payload.hideTotalTimeDepartment !== undefined) {
                state.options.hideTotalTimeDepartment =
                    action.payload.hideTotalTimeDepartment;
            }
            if (action.payload.hideFixedShifts !== undefined) {
                state.options.hideFixedShifts = action.payload.hideFixedShifts;
            }
            localStorage.setItem(
                "planningOptions",
                JSON.stringify(state.options),
            );
        },
    },
});
export const {
    resetPlanningSlice,
    setEmployeesData,
    setEmployeesPlanningData,
    setPlanningInfo,
    updatePlanning,
    updatePlanningOptions,
    setPlanningInfoNote,
    resetAllPlanning,
} = planningSlice.actions;
export default planningSlice.reducer;
