import React from "react";
import Modal from "../../../components/organism/Modal";
import { useIntl } from "react-intl";
import Button from "../../../components/atoms/ZButton";

export default function DeleteContractDialog({
    contract,
    show,
    onHide,
}: DeleteContractDialogInterface) {
    const intl = useIntl();

    const Body = (
        <div className="h-full flex justify-center items-center flex-wrap">
            <div>
                {intl.formatMessage({
                    id: "CONTRACT.DIALOG.DELETE.CONFIRMATION",
                })}
            </div>
        </div>
    );

    return (
        <>
            <Modal
                width={200}
                height={300}
                show={show}
                onHide={onHide}
                title={`${intl.formatMessage({
                    id: "CONTRACT.DIALOG.ARCHIVE.TITLE",
                })}`}
                body={Body}
                ConfirmComponent={
                    <Button
                        title={intl.formatMessage({ id: "MENU.DELETE" })}
                        variant="danger"
                        onClick={() => {}}
                        size="block"
                    />
                }
            />
        </>
    );
}

interface DeleteContractDialogInterface {
    contract: any;
    show: boolean;
    onHide(): void;
}
