import * as React from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import emailSuccess from "./assets/emailSuccess.gif";
import logo from "./assets/logo.svg";

import Picture from "../../atoms/Picture";
import TextData from "../../atoms/TextData";
import Button from "../../atoms/Button";
import { ROUTER } from "../../../constants/env";

export default function CheckEmail(props: CheckEmailProps) {
    const { heading, subHeading, emailText, buttonText } = props;
    const navigate = useNavigate();
    const globalProps = {
        headingProps: {
            variant: "div" as "div",
            color: "#555",
            width: "100%",
            size: "25px",
            style: {
                textAlign: "center" as "center",
                fontWeight: "bold",
            },
        },
        subHeadingProps: {
            variant: "div" as "div",
            color: "#555",
            style: {
                textAlign: "center" as "center",
                marginTop: 15,
                marginBottom: 15,
            },
            width: "100%",
        },

        emailTextProps: {
            variant: "div" as "div",
            color: "#555",
            style: { textAlign: "center" as "center", fontWeight: "bold" },
            width: "100%",
        },
        buttonProps: {
            width: "100%",
            height: "50px",
            backgroundColor: "#3598B8",
            textColor: "white",
            padding: "10px 5px 5px 10px",
            style: {
                marginTop: 10,
                marginBottom: 20,
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "-0.5px",
            },
            onClick: () => {
                navigate(ROUTER.AUTH.LOGIN);
            },
        },
    };
    return (
        <div className='check-email-container'>
            <Picture imgPath={emailSuccess} height='120px' />
            <TextData {...globalProps.headingProps}>{heading}</TextData>
            <TextData {...globalProps.subHeadingProps}>{subHeading}</TextData>
            <TextData {...globalProps.emailTextProps}>{emailText}</TextData>
            <Button {...globalProps.buttonProps}>{buttonText}</Button>
            <Picture imgPath={logo} />
        </div>
    );
}

interface CheckEmailProps {
    heading?: string;
    subHeading?: string;
    emailText?: string;
    buttonText?: string;
}
CheckEmail.defaultProps = {
    heading: "Consultez votre boîte de réception",
    subHeading:
        "Un lien vous permettant de réinitialiser votre mot de passe a été envoyé à l’adresse suivante:",
    emailText: "jamel1estzg@yahoo.fr",
    buttonText: "OK",
};
