import React from "react";
import Button from "../../atoms/Button";
import TextData from "../../atoms/TextData";
import { ReactComponent as PlusSVG } from "./plus.svg";
import "./style.css";
export default function CellEmpty(props: any) {
    const { setIsClicked, published, isWeek, withoutText, setStartTime, time } =
        props;
    // const backgroundColor = isOver ? "#27ae60" : "#EDEFF5";

    // const handleMouseLeave = () => {
    //     setIsHover(false);
    // };
    if (published) {
        return null;
    }
    return (
        <div
            className="empty-cell-container"
            style={{
                // font-size: 12px;
                // fontWeight: 500;
                // background-color: #e7f3f7;
                color: "#2a8bab",
                backgroundImage: "url(./bg.png)",
                height: !withoutText ? "80px" : "max-content",
                // border-radius: 5px;
                // padding: 5px;
                // display: flex;
                // gap: 5px;
            }}
            // onMouseLeave={handleMouseLeave}
            // onDragLeave={handleMouseLeave}
        >
            <Button
                height="100%"
                width="100%"
                // backgroundColor='#EDEFF5'
                backgroundColor="transparent"
                onClick={() => {
                    if (!published) {
                        setIsClicked(true);
                        setStartTime && time && setStartTime(time);
                    }
                }}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "3px",
                }}
            >
                <PlusSVG color="#2a8bab" height={20} width={20} />
                {isWeek && !withoutText && (
                    <TextData
                        width="auto"
                        size="14px"
                        fontWeight="500"
                        // color='#7277aa'
                        color="#2a8bab"
                        style={{
                            overflowWrap: "break-word",
                        }}
                    >
                        Ajouter un shift
                    </TextData>
                )}
            </Button>
        </div>
    );
}
