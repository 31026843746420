import React from "react";

export default function TopbarDropdown({
    items,
    show,
    onHide,
}: TopbarDropdownProps) {
    return (
        <div
            className={`z-[99999] absolute top-[85px] right-5 rouned-lg bg-white py-[17px] px-[15px] w-[250px] _shadow ${
                show ? "" : "hidden"
            }`}
            style={{
                boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                borderRadius: "5px",
            }}
        >
            {items.map((item, key) => (
                <div
                    key={key}
                    className='flex gap-[17px] group cursor-pointer hover:bg-n-0 px-[9px] py-[12px] border-md text-n-500 hover:text-t-500 '
                    onClick={() => item.action()}
                    style={{
                        borderRadius: "5px",
                        transition: "0.3s ease",
                    }}
                >
                    {item.Icon}
                    <div className='font-bold text-sans text-md '>
                        {item.title}
                    </div>
                </div>
            ))}
        </div>
    );
}

type ItemProps = {
    title: string | React.ReactNode;
    action: () => void;
    Icon: React.ReactElement;
};

interface TopbarDropdownProps {
    items: ItemProps[];
    show: boolean;
    onHide: () => void;
}
