import * as React from "react";

import "./style.scss";

import { Branch } from "../Settings";
import Button from "../../components/atoms/Button";
import TextData from "../../components/atoms/TextData";

import { ReactComponent as StartSVG } from "./startIcon.svg";
import SelectInput from "../../components/atoms/SelectInput";

function Slide0(props: Slide0Props) {
    const {
        handleMove,
        header,
        title,
        description,
        buttonText,
        branchs,
        defaultBranch,
        handleSelectBranch,
    } = props;
    const handleSubmit = () => {
        handleMove(1);
    };
    const handleSelectionBranches = (branchSelection: any) => {
        const branchSelectedIndex = branchSelection.selectedIndex;
        const branchSelected = branchs?.[branchSelectedIndex];
        handleSelectBranch?.(branchSelected);
    };
    const globalProps = {
        headerProps: {
            style: {
                marginTop: 25,
                marginLeft: 35,
            },
            color: "#11106E",
            size: "34px",
            fontWeight: "bolder",
        },
        titleProps: {
            width: "auto",
            color: "#1A7290",
            size: "18px",
            fontWeight: "bold",
            style: {
                marginBottom: 7,
            },
        },
        descriptionProps: {
            width: "400px",
            color: "#6F78AB",
            size: "14px",
            style: {
                textAlign: "center" as "center",
                marginBottom: 5,
            },
        },
        selectInputProps: {
            options: branchs?.map((branch) => ({
                label: branch.name,
                value: branch.name,
            })),
            selectedValue: {
                label: defaultBranch?.name,
                value: defaultBranch?.name,
            },
            onSelectOption: handleSelectionBranches,
            style: {
                marginBottom: 20,
            },
        },
        buttonProps: {
            width: "170px",
            height: "35px",
            backgroundColor: "#2A8BAB",
            textColor: "white",
            style: {
                borderRadius: 7,
                fontSize: "14px",
            },
            className: ["button-style"],
            onClick: handleSubmit,
        },
    };
    return (
        <div className='startup-body-container'>
            <div className='startup-header'>
                <TextData {...globalProps.headerProps}>{header}</TextData>
            </div>
            <div className='startup-card-container' id='slide0-body-container'>
                <StartSVG />
                <TextData {...globalProps.titleProps}>{title}</TextData>
                <TextData {...globalProps.descriptionProps}>
                    {description}
                </TextData>
                {branchs && branchs.length > 1 && (
                    <SelectInput {...globalProps.selectInputProps} />
                )}
                <Button {...globalProps.buttonProps}>{buttonText}</Button>
            </div>
        </div>
    );
}
interface Slide0Props {
    header?: string;
    title?: string;
    description?: string;
    buttonText?: string;
    handleMove: (count: number) => void;
    branchs?: {
        _id: string;
        address: string;
        name: string;
    }[];
    defaultBranch?: {
        _id: string;
        address: string;
        name: string;
    };
    handleSelectBranch?: (branch: any) => void;
}
Slide0.defaultProps = {
    header: "Premier Pas",
    title: "Configuration",
    description:
        "Bienvenue dans le setup de configuration de l'application Esperoo! " +
        "Veuillez commencer et suivre les étapes de configuration.",
    buttonText: "Démarrer",
};
export default Slide0;
