import * as React from "react";
import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import { useNavigate } from "react-router-dom";
import { ROUTER } from "../constants/env";
import { changePasswordServ } from "../redux/features/auth/service";
import { updatePassword } from "../redux/features/auth/authSlice";

export const useChangePassword = () => {
    let auth = useAppSelector((state: any) => state.auth);
    const [passwordIsIdentical, setPasswordIsIdentical] = React.useState(false);
    const [oldPassword, setOldPass] = React.useState("");
    const [confirmedNeoPassword, setConfirmedNeoPassword] = React.useState("");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const setActualPassword = (actualPassword: string) => {
        setOldPass(actualPassword);
    };
    const setNewPassword = (newPassword: string) => {
        dispatch(updatePassword({ password: newPassword }));
        if (confirmedNeoPassword === auth.password) {
            setPasswordIsIdentical(true);
        } else {
            setPasswordIsIdentical(false);
        }
    };
    const confirmedNewPassword = (confirmedPassword: string) => {
        setConfirmedNeoPassword(confirmedPassword);
        if (confirmedPassword === auth.password) {
            setPasswordIsIdentical(true);
        } else {
            setPasswordIsIdentical(false);
        }
    };

    const changePassword = async () => {
        const userString = sessionStorage.getItem("user");
        const user = userString ? JSON.parse(userString) : null;
        const userId = user?._id ?? null;
        if (passwordIsIdentical && userId && oldPassword !== auth.password) {
            dispatch(
                changePasswordServ({
                    userId: userId,
                    oldPassword,
                    newPassword: auth.password,
                })
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    navigate(ROUTER.AUTH.LOGIN);
                }
            });
        }
    };
    return {
        auth,
        oldPassword,
        confirmedNeoPassword,
        setActualPassword,
        setNewPassword,
        confirmedNewPassword,
        changePassword,
    };
};
