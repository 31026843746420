import React from "react";
import DayPicker from "../../molecules/DayPicker";
import { FormattedMessage, useIntl } from "react-intl";
import ZToggleButton from "../../atoms/ZToggleButton";
import DatepickerInput from "../../atoms/DatepickerInput";
import ZTextarea from "../../atoms/ZTextarea";
import { timeOptions } from "../../../Options";
import SelectInput from "../../molecules/SelectInput";

const DAYS = [
  { label: <FormattedMessage id="MENU.MONDAY_SHORT" />, value: 0 },
  { label: <FormattedMessage id="MENU.TUESDAY_SHORT" />, value: 1 },
  { label: <FormattedMessage id="MENU.WEDNESDAY_SHORT" />, value: 2 },
  { label: <FormattedMessage id="MENU.THURSDAY_SHORT" />, value: 3 },
  { label: <FormattedMessage id="MENU.FRIDAY_SHORT" />, value: 4 },
  { label: <FormattedMessage id="MENU.SATURDAY_SHORT" />, value: 5 },
  { label: <FormattedMessage id="MENU.SUNDAY_SHORT" />, value: 6 },
];

export default function RepeatUnavaibilityEditForm({
  unavaibility,
  onChange,
  onBlur,
}: RepeatUnavaibilityEditFormInterface) {
  const intl = useIntl();
  return (
    <div className="w-full">
      <div>
        <label className="text-n-400 font-bold text-lg inline-block mb-3">
          {intl.formatMessage({
            id: "EMPLOYEE.EDIT.UNAVAIBILITY.ADD.DAYS_OF_WEEK",
          })}
        </label>
        <DayPicker
          days={DAYS}
          onChange={(value: any) => {
            onChange("day", value);
          }}
          value={unavaibility.day.value}
        />
        {unavaibility.day.hasError && (
          <div className="text-error text-sm font-light w-full mt-2">
            {unavaibility.day.error}
          </div>
        )}
      </div>

      <div className="flex justify-between mt-8">
        <label className="text-n-400 font-bold text-lg inline-block mb-3">
          {intl.formatMessage({ id: "EMPLOYEE.EDIT.UNAVAIBILITY.ADD.ALL_DAY" })}
        </label>
        <div>
          <ZToggleButton
            isChecked={unavaibility.allDay.value}
            onPress={() => {
              onChange("allDay", !unavaibility.allDay.value);
            }}
          />
        </div>
      </div>
      {unavaibility.allDay.value ? (
        <>
          <div className="flex gap-4 my-3">
            <div className="basis-[100px] grow">
              <DatepickerInput
                onBlur={onBlur}
                required={true}
                label={intl.formatMessage({
                  id: "EMPLOYEE.EDIT.UNAVAIBILITY.START_DATE",
                })}
                field={unavaibility.startDate}
                onUpdate={(date) => {
                  onChange("startDate", date);
                }}
              />
            </div>
            <div className="basis-[100px] grow">
              <DatepickerInput
                onBlur={onBlur}
                required={true}
                label={intl.formatMessage({
                  id: "EMPLOYEE.EDIT.UNAVAIBILITY.END_DATE",
                })}
                field={unavaibility.endDate}
                onUpdate={(date) => {
                  onChange("endDate", date);
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex gap-4 my-3">
            <div className="basis-[100px] grow">
              <DatepickerInput
                onBlur={onBlur}
                required={true}
                label={intl.formatMessage({
                  id: "EMPLOYEE.EDIT.UNAVAIBILITY.START_DATE",
                })}
                field={unavaibility.startDate}
                onUpdate={(date) => {
                  onChange("startDate", date);
                }}
              />
            </div>
            <div className="basis-[100px] grow">
              <label className="text-base text-n-400 font-medium mb-1">
                {intl.formatMessage({
                  id: "EMPLOYEE.EDIT.UNAVAIBILITY.START_DATE",
                })}
              </label>
              <span className="text-error">*</span>
              <SelectInput
                controlCssStyles={{ height: "48px" }}
                options={timeOptions}
                value={unavaibility.startHour.value}
                onChange={(time) => {
                  onChange("startHour", time);
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 my-4">
            <div className="basis-[100px] grow">
              <DatepickerInput
                onBlur={onBlur}
                required={true}
                label={intl.formatMessage({
                  id: "EMPLOYEE.EDIT.UNAVAIBILITY.END_DATE",
                })}
                field={unavaibility.endDate}
                onUpdate={(date) => {
                  onChange("endDate", date);
                }}
              />
            </div>
            <div className="basis-[100px] grow">
              <label className="text-base text-n-400 font-medium mb-1">
                {intl.formatMessage({
                  id: "EMPLOYEE.EDIT.UNAVAIBILITY.END_DATE",
                })}
              </label>
              <span className="text-error">*</span>
              <SelectInput
                controlCssStyles={{ height: "48px" }}
                options={timeOptions}
                value={unavaibility.endHour.value}
                onChange={(time) => {
                  onChange("endHour", time);
                }}
              />
            </div>
          </div>
        </>
      )}
      <div className="flex justify-between mt-8">
        <label className="text-n-400 font-bold text-lg inline-block mb-3">
          {intl.formatMessage({ id: "EMPLOYEE.EDIT.UNAVAIBILITY.ADD.NOTES" })}
        </label>
        <div>
          <ZToggleButton
            isChecked={unavaibility.addNotes.value}
            onPress={() => {
              onChange("addNotes", !unavaibility.addNotes.value);
            }}
          />
        </div>
      </div>

      <div
        className={`basis-[100px] grow my-4 transition duration-1000 ${
          unavaibility.addNotes.value ? "" : "!h-0 overflow-hidden"
        }`}
      >
        <ZTextarea
          required={false}
          label={intl.formatMessage({
            id: "EMPLOYEE.EDIT.UNAVAIBILITY.NOTES.LABEL",
          })}
          placeholder={intl.formatMessage({
            id: "EMPLOYEE.EDIT.UNAVAIBILITY.NOTES.PLACEHOLDER",
          })}
          field={unavaibility.note}
          onUpdate={(value: any) => {
            onChange("note", value);
          }}
          rows={4}
        />
      </div>
    </div>
  );
}

interface RepeatUnavaibilityEditFormInterface {
  unavaibility: any;
  onChange: (field: string, value: any) => void;
  onBlur: (field: string) => void;
}
