import React from "react";
import Modal from "../../../components/organism/Modal";
import { useIntl } from "react-intl";
import { useEmployees } from "../../../hooks/useEmployees";
import { Helmet } from "react-helmet";
import Button from "../../../components/atoms/ZButton";
import { useNotificationContext } from "../../../Context/Notification";

export default function ArchiveEmployeeDialog({
    employee,
    show,
    onHide,
}: RestoreEmployeeDialogInterface) {
    const {
        actionLoading,
        archiveEmployee,
        getEmployees,
        employeeForEdit,
        getEmployee,
    } = useEmployees();
    const { addNotification } = useNotificationContext();

    const intl = useIntl();

    const Body = (
        <div className='h-full flex justify-center items-center flex-wrap'>
            <div>
                {actionLoading === true
                    ? intl.formatMessage({
                          id: "EMPLOYEE.DIALOG.ARCHIVE.ARCHIVING",
                      })
                    : intl.formatMessage(
                          { id: "EMPLOYEE.DIALOG.ARCHIVE.CONFIRMATION" },
                          {
                              name: (
                                  <b>
                                      {employee.fname} {employee.lname}
                                  </b>
                              ),
                          }
                      )}
            </div>
        </div>
    );

    const archEmployee = (id: string) => {
        archiveEmployee({ id }).then((res) => {
            const data: any = res.payload;
            if (data.status === "success") {
                addNotification({
                    title: intl.formatMessage({
                        id: "EMPLOYEE.ARCHIVE.NOTIFICATION.SUCCESS.TITLE",
                    }),
                    message: intl.formatMessage({
                        id: "EMPLOYEE.ARCHIVE.NOTIFICATION.SUCCESS.MESSAGE",
                    }),
                    type: "success",
                });
                if (employeeForEdit) {
                    getEmployee({ id: employeeForEdit._id });
                } else {
                    getEmployees({
                        archived: false,
                    });
                }
            } else if (data.status === "fail") {
                addNotification({
                    title: intl.formatMessage({
                        id: "EMPLOYEE.ARCHIVE.NOTIFICATION.FAIL.TITLE",
                    }),
                    message: intl.formatMessage({
                        id: "EMPLOYEE.ARCHIVE.NOTIFICATION.FAIL.MESSAGE",
                    }),
                    type: "fail",
                });
            }
            onHide();
        });
    };

    return (
        <>
            <Helmet>
                <title>
                    {intl.formatMessage({ id: "APP.TITLE" })} |{" "}
                    {intl.formatMessage({
                        id: "EMPLOYEE.DIALOG.ARCHIVE.TITLE",
                    })}
                </title>
            </Helmet>
            <Modal
                width={400}
                height={260}
                show={show}
                onHide={onHide}
                title={`${intl.formatMessage({
                    id: "EMPLOYEE.DIALOG.ARCHIVE.TITLE",
                })}`}
                body={Body}
                ConfirmComponent={
                    <Button
                        title={intl.formatMessage({ id: "MENU.ARCHIVE" })}
                        variant='danger'
                        loading={actionLoading}
                        onClick={() => {
                            archEmployee(employee._id);
                        }}
                        size='block'
                    />
                }
            />
        </>
    );
}

interface RestoreEmployeeDialogInterface {
    employee: any;
    show: boolean;
    onHide(): void;
}
