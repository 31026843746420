/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import { useNavigate } from "react-router-dom";
import { ROUTER } from "../constants/env";

import { signIn, verifyToken } from "../redux/features/auth/service";

import {
    updateEmail,
    updatePassword,
    toggleIsRememberedMe,
    updateToken,
    updateLoading,
    logout,
    resetAllAuth,
} from "../redux/features/auth/authSlice";
import { AuthState, SignInRequest } from "../redux/features/auth/types";
import { shallowEqual } from "react-intl/src/utils";
import { usePlanning } from "./usePlanning";
import { resetAllEmp } from "../redux/features/employees/employeesSlice";
import { resetAllCountries } from "../redux/features/countries/countriesSlice";
import { resetAllEmpWorkHours } from "../redux/features/employeeWorkHours/employeeWorkHourSlice";
import { resetAllHolidays } from "../redux/features/holiday/holidaySlice";
import { resetAllLeaveBalance } from "../redux/features/leaveBalance/leaveBalanceSlice";
import { resetAllNotifications } from "../redux/features/notifications/notificationsSlice";
import { resetAllPlanning } from "../redux/features/planning/planningSlice";
import { resetAllPointeuse } from "../redux/features/pointeuse/pointeuseSlice";
import { resetAllReport } from "../redux/features/reports/reportSlice";
import { resetAllSettings } from "../redux/features/settings/settingsSlice";
import { resetAllSites } from "../redux/features/sites/sitesSlice";
import { resetAllVacation } from "../redux/features/vacation/vacationSlice";
import { resetAllVacationRule } from "../redux/features/vacationRules/vacationRuleSlice";
import { resetAllUsers } from "../redux/features/users/userSlice";
export const useLogin = () => {
    let auth = useAppSelector((state: any) => state.auth, shallowEqual);
    const { resetPlanning } = usePlanning();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const setEmail = (email: string) => {
        dispatch(updateEmail({ email }));
    };
    const setPassword = (password: string) => {
        dispatch(updatePassword({ password }));
    };
    const setIsRememberedMe = (isRememberedMe: boolean) => {
        dispatch(toggleIsRememberedMe({ isRememberedMe }));
    };
    const setToken = (token: string) => {
        dispatch(updateToken({ token }));
    };

    const login = async () => {
        const { email, password, isRememberedMe } = auth;
        dispatch(signIn({ username: email, password, isRememberedMe })).then(
            (res) => {
                if (
                    res?.meta?.requestStatus === "fulfilled" &&
                    res.payload?.token &&
                    res.payload?.role !== "Employee"
                ) {
                    navigate(ROUTER.PLANNING);
                }
            },
        );
    };

    const signout = async () => {
        /*
        resetAllAuth
        resetAllCountries
        resetAllEmp
        resetAllEmpWorkHours
        resetAllHolidays
        resetAllLeaveBalance
        resetAllNotifications

        resetAllPlanning
        resetAllPointeuse
        resetAllReport
        resetAllSettings
        resetAllSites
        resetAllUsers
        resetAllVacation
        resetAllVacationRule
        */
        dispatch(logout({}));
        await dispatch(resetAllAuth());
        await dispatch(resetAllCountries());
        await dispatch(resetAllEmp());
        await dispatch(resetAllEmpWorkHours());
        await dispatch(resetAllHolidays());
        await dispatch(resetAllLeaveBalance());
        await dispatch(resetAllNotifications());
        await dispatch(resetAllPlanning());
        await dispatch(resetAllPointeuse());
        await dispatch(resetAllReport());
        await dispatch(resetAllSettings());
        await dispatch(resetAllSites());
        await dispatch(resetAllUsers());
        await dispatch(resetAllVacation());
        await dispatch(resetAllVacationRule());
        ////////////////////
        await resetPlanning();
    };

    const checkMe = async () => {
        const token = localStorage.getItem("token");
        dispatch(
            verifyToken({
                token: token,
            }),
        );
    };

    return {
        auth,
        setEmail,
        setPassword,
        setIsRememberedMe,
        setToken,
        login,
        checkMe,
        user: auth.user,
        signout,
    };
};
