export const Male = ({
  width = 19,
  height = 19,
  color = "#3598B8",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6719 0H11.7656C11.6883 0 11.625 0.0632813 11.625 0.140625V1.45312C11.625 1.53047 11.6883 1.59375 11.7656 1.59375H15.525L10.8539 6.26484C9.68203 5.3625 8.25234 4.875 6.75 4.875C4.94766 4.875 3.25078 5.57812 1.97812 6.85312C0.705469 8.12812 0 9.82266 0 11.625C0 13.4273 0.703125 15.1242 1.97812 16.3969C3.25078 17.6719 4.94766 18.375 6.75 18.375C8.55234 18.375 10.2492 17.6719 11.5219 16.3969C12.7969 15.1242 13.5 13.4273 13.5 11.625C13.5 10.1227 13.0125 8.69766 12.1125 7.52578L16.7812 2.85703V6.60938C16.7812 6.68672 16.8445 6.75 16.9219 6.75H18.2344C18.3117 6.75 18.375 6.68672 18.375 6.60938V0.703125C18.375 0.316406 18.0586 0 17.6719 0ZM6.75 16.5938C4.01016 16.5938 1.78125 14.3648 1.78125 11.625C1.78125 8.88516 4.01016 6.65625 6.75 6.65625C9.48984 6.65625 11.7188 8.88516 11.7188 11.625C11.7188 14.3648 9.48984 16.5938 6.75 16.5938Z"
      fill={color}
    />
  </svg>
);

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}
