import * as React from "react";
import { useDrop } from "react-dnd";

import "./style.scss";
import DraggableOrderItem from "../DraggableOrderItem";
import { usePlanning } from "../../../hooks/usePlanning";
import { DndContext, closestCorners } from "@dnd-kit/core";
import {
    SortableContext,
    verticalListSortingStrategy,
    useSortable,
    arrayMove,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
export default function DraggableOrderContainer(
    props: any /*DraggableOrderContainerProps*/,
) {
    const { data, employees, depId, setData, index, opened } = props;
    const [employeesData, setEmployeesData] = React.useState(data ?? []);
    React.useEffect(() => {
        setEmployeesData(data ?? []);
    }, [data]);
    // const moveItem = (
    //     originalItemIndex: any,
    //     positionIndex: any,
    //     isHoverBelow: any
    // ) => {
    //     let newData = data.filter((item: any) => true);
    //     // for (let i = 0; i < data.length; i++) newData.push(data[i]);
    //     console.log("newData before data ", data);
    //     console.log("newData before ", newData);
    //     const itemToMove = newData[originalItemIndex];

    //     newData.splice(originalItemIndex, 1);
    //     console.log("newData, ", newData);
    //     console.log("ishoverBelow, ", isHoverBelow);
    //     console.log("positionIndex, ", positionIndex);

    //     if (isHoverBelow) {
    //         if (positionIndex === 0) {
    //             newData.unshift(itemToMove);
    //         } else {
    //             newData.splice(positionIndex - 1, 0, itemToMove);
    //         }
    //     } else {
    //         if (positionIndex === data.length - 1) {
    //             newData.splice(positionIndex, 0, itemToMove);
    //             console.log("case 1: ");
    //         } else {
    //             newData.splice(positionIndex, 0, itemToMove);
    //             console.log("case 2: ");
    //         }
    //     }
    //     console.log("result : ", newData);

    //     setData(newData);
    // };

    // const handleArrowDownBtn = (itemIndex: number) => {
    //     if (itemIndex < data.length - 1) {
    //         const updatedData = [...data];
    //         const temp = updatedData[itemIndex];
    //         updatedData[itemIndex] = updatedData[itemIndex + 1];
    //         updatedData[itemIndex + 1] = temp;
    //         // setData(updatedData);
    //         setData((prev: any) => {
    //             let updatedMap = { ...prev };
    //             updatedMap[depId] = updatedData;
    //             return updatedMap;
    //         });
    //     }
    // };
    // const handleArrowUpBtn = (itemIndex: number) => {
    //     if (itemIndex > 0) {
    //         const updatedData = [...data];
    //         const temp = updatedData[itemIndex];
    //         updatedData[itemIndex] = updatedData[itemIndex - 1];
    //         updatedData[itemIndex - 1] = temp;
    //         setData((prev: any) => {
    //             let updatedMap = { ...prev };
    //             updatedMap[depId] = updatedData;
    //             return updatedMap;
    //         });
    //     }
    // };

    return (
        <div
            className="draggable-custom-order-container"
            style={{
                display: opened === index ? "block" : "none",
                // position: "relative",
            }}
        >
            <SortableContext
                // handle={true}
                items={employeesData}
                strategy={verticalListSortingStrategy}
            >
                {employeesData?.map((item: any, index: any) => {
                    if (employees?.[item.employee]) {
                        return (
                            <DraggableOrderItem
                                employee={employees?.[item.employee]}
                                id={item.id}
                                key={item.id}
                                // handleDownButton={() =>
                                //     handleArrowDownBtn(index)
                                // }
                                // handleUpButton={() => handleArrowUpBtn(index)}
                                // moveItem={moveItem}
                            />
                        );
                    }
                })}
                {/* {tasks.map((task: any) => (
                        <Task id={task.id} title={task.title} key={task.id} />
                    ))} */}
            </SortableContext>
        </div>
    );
}

interface DraggableOrderContainerProps {}

DraggableOrderContainer.defaultProps = {};
