import * as React from "react";
import classnames from "classnames";

import "./style.scss";
import { number } from "prop-types";

export default function Radio(props: RadioProps) {
    const {
        name,
        items,
        fontSize,
        color,
        descriptionColor,
        descriptionSize,
        descriptionWidth,
        selectedValue,
        getCurrentValue,
    } = props;
    let [radioItems, setItems] = React.useState(items);
    React.useEffect(() => {
        setItems(
            items?.map((item, i) => {
                return {
                    ...item,
                    itemIsChecked: item.itemValue === selectedValue,
                };
            })
        );
    }, [selectedValue]);

    const handleClick = (index: number) => {
        setItems(
            items?.map((item, i) => {
                return {
                    ...item,
                    itemIsChecked: item.itemValue === items[index].itemValue,
                };
            })
        );
        const currentSelectedVal = items?.[index];
        getCurrentValue?.(currentSelectedVal);
    };

    return (
        <div
            className='radio-group'
            style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
            }}
        >
            {radioItems?.map((item, index) => (
                <label
                    className='radio'
                    style={{
                        color,
                        fontSize,
                    }}
                    key={index}
                    onClick={handleClick.bind(null, index)}
                >
                    <input
                        type='radio'
                        value={item.itemValue}
                        checked={item.itemIsChecked}
                        key={`input-${index}`}
                    />

                    <div>
                        <div>{item.itemDesignation}</div>
                        <p
                            style={{
                                color: descriptionColor,
                                fontSize: descriptionSize,
                                width: descriptionWidth,
                            }}
                        >
                            {item.itemDescription}
                        </p>
                    </div>
                    <span></span>
                </label>
            ))}
        </div>
    );
}

interface RadioProps {
    name: string;
    fontSize?: string;
    color?: string;
    descriptionColor?: string;
    descriptionSize?: string;
    descriptionWidth?: string;
    selectedValue?: string;
    getCurrentValue?: (data: any) => void;
    items?: {
        itemValue?: string;
        itemDesignation?: string | null;
        itemDescription?: string | null;
        itemIsChecked?: boolean;
    }[];
}

Radio.defaultProps = {
    name: "period",
    fontSize: 16,
    descriptionSize: "13px",
    color: "#2A346D",
    descriptionColor: "#6F78AB",
    descriptionWidth: 200,
    selectedValue: "hour",
    items: [
        {
            itemValue: "hour",
            itemDesignation: "male",
            itemDescription: "description texte",
        },
        {
            itemValue: "day",
            itemDesignation: "female",
            itemDescription: "description texte",
        },
    ],
};
