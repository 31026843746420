import * as React from "react";

import Authentification from "../../components/templates/Authentification/Authentification";

export default function ForgetPasswordPage(props: ForgetPasswordPageProps) {
    const { authType } = props;
    return (
        <div>
            <Authentification authType={authType} />
        </div>
    );
}

interface ForgetPasswordPageProps {
    authType?:
        | "login"
        | "reset-password"
        | "change-password"
        | "forget-password"
        | "check-email"
        | "custom";
}

ForgetPasswordPage.defaultProps = {
    authType: "forget-password",
};
