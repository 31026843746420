export const User = ({
  width = 25,
  height = 25,
  color = "currentColor",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3118 11.0058C12.2105 10.9957 12.089 10.9957 11.9777 11.0058C9.56792 10.9248 7.6543 8.9504 7.6543 6.5204C7.6543 4.03978 9.65905 2.0249 12.1498 2.0249C14.6304 2.0249 16.6453 4.03978 16.6453 6.5204C16.6352 8.9504 14.7215 10.9248 12.3118 11.0058Z"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.24931 14.7422C4.79906 16.3824 4.79906 19.0554 7.24931 20.6856C10.0337 22.5486 14.6001 22.5486 17.3844 20.6856C19.8347 19.0453 19.8347 16.3723 17.3844 14.7422C14.6102 12.8893 10.0438 12.8893 7.24931 14.7422Z"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}
