import React from "react";
import Picture from "../../../../components/atoms/Picture";
import { useIntl } from "react-intl";
import { ArchiveTray, Edit } from "../../../../Icons";
import Theme from "../../../../tailwind-theme";

export const ActionColumnFormatter = (
    cellContent: any,
    rowIdx: number,
    row: any,
    extras: any,
) => {
    const intl = useIntl();
    return (
        <div className="flex flex-col gap-1">
            <div
                className="flex mb-2 items-center gap-2 cursor-pointer  text-n-500 hover:text-n-700"
                onClick={() => extras.openEditEmployee(row._id)}
            >
                <Edit />
                <span className=" font-light text-base">
                    {intl.formatMessage({ id: "MENU.UPDATE" })}
                </span>
            </div>
            {/*
      <div
        className="flex items-center gap-2 cursor-pointer  text-n-500 hover:text-n-700"
        onClick={() => extras.openArchiveEmployeeDialog(row)}
      >
        <ArchiveTray />
        <span className="font-light text-base">
          {intl.formatMessage({ id: "MENU.ARCHIVE" })}
        </span>
      </div>
      */}
        </div>
    );
};
