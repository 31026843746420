import * as React from "react";

import "./style.scss";
import "./media.query.scss";

import TextData from "../../atoms/TextData";
import Picture from "../../atoms/Picture";

import LoginMolecule from "./Login";
import ResetPassword from "./ResetPassword";
import ChangePassword from "./ChangePassword";
import ForgetPassword from "./ForgetPassword";
import CheckEmail from "./CheckEmail";

import authentificationImg from "./assets/authentificationImg.svg";
import { useForgetPassword } from "../../../hooks/useForgetPassword";

export default function AuthentificationCard(props: AuthentificationCardProps) {
    const { auth } = useForgetPassword();
    const { style, cardWidth, cardHeight, authType, emailForCheching } = props;
    const authenContainer = {
        className: "authentification-container",
        style: {
            ...style?.containerStyle,
            width: cardWidth,
            height: cardHeight,
            borderRadius: "10px",
            overflow: "hidden",
        },
    };
    const leftTitle = {
        color: "#57569C",
        // size: "36",
        style: { fontWeight: "700", fontSize: "inherit" },
        className: ["authentification-title"],
    };
    const leftPicture = {
        width: "auto",
        height: "238px",
        className: ["authentification-picture"],
    };
    let SubCard;
    switch (authType) {
        case "login":
            SubCard = <LoginMolecule />;
            break;
        case "reset-password":
            SubCard = <ResetPassword />;
            break;
        case "change-password":
            SubCard = <ChangePassword />;
            break;
        case "forget-password":
            SubCard = <ForgetPassword />;
            break;
        case "check-email":
            SubCard = (
                <CheckEmail
                    emailText={auth.email ? auth.email : emailForCheching}
                />
            );
            break;
        default:
            SubCard = <h1> Error 404</h1>;
    }
    return (
        <div {...authenContainer}>
            <div className='left-auth' style={{ ...style?.leftStyle }}>
                <TextData {...leftTitle} variant='div'>
                    Votre solution de planning et de pointage en ligne
                </TextData>
                <Picture {...leftPicture} imgPath={authentificationImg} />
            </div>
            <div className='right-auth' style={{ ...style?.rightStyle }}>
                {SubCard}
            </div>
        </div>
    );
}
interface AuthentificationCardProps {
    authType?:
        | "login"
        | "reset-password"
        | "change-password"
        | "forget-password"
        | "check-email"
        | "custom";
    cardWidth?: string;
    cardHeight?: string;
    style?: {
        containerStyle?: React.CSSProperties;
        leftStyle?: React.CSSProperties;
        rightStyle?: React.CSSProperties;
    };
    emailForCheching?: string;
}
AuthentificationCard.defaultProps = {
    authType: "check-email", //"reset-password",
    style: {
        containerStyle: {
            borderRadius: "8px",
            backgroundColor: "white",
            boxShadow: "0px 14px 74px 0px rgba(24, 29, 39, 0.50)",
        },
        leftStyle: {
            backgroundColor: "#e2ebee",
            borderRadius: "8px 0 0 8px",
        },
        rightStyle: {
            backgroundColor: "white",
            borderRadius: " 0 8px 8px 0",
        },
    },
    emailForCheching: "",
};
