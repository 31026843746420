import moment from "moment";
import React from "react";

export function YearColumnFormatter(
  content: string,
  rowId: number,
  row: any,
  props: any
) {
  return (
    <div
      className={`!p-0 inline-block font-bold text-3xl max-w-[50px] ${
        props.rows[rowId] === true ? "text-v-200" : "text-t-400"
      }`}
    >
      {moment(content).format("YYYY")}
    </div>
  );
}
