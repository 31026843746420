import * as React from "react";
import { ReactComponent as ServiceSVG } from "./service.svg";
import { ReactComponent as NoteSVG } from "./note.svg";
import { ReactComponent as WeatherSVG } from "./weather.svg";
import { ReactComponent as SnowSVG } from "./Snow.svg";
import { ReactComponent as WindSVG } from "./wind.svg";
import { ReactComponent as SunSVG } from "./sun.svg";
import { ReactComponent as PlusSVG } from "./plus.svg";
import { ReactComponent as CloseSVG } from "./close.svg";
import moment from "moment";

import "./style.scss";
import TextData from "../../atoms/TextData";
import Button from "../../atoms/Button";
import { getCurrentWeekNumberAndDays } from "../../../_helpers/Functions";
import AddNoteCard from "../../organism/AddNoteCard";
import { usePlanning } from "../../../hooks/usePlanning";
import { spawn } from "child_process";

export default function PlanningTableConfiguration(
    props: any /*PlanningTableConfigurationProps*/,
) {
    const {
        configTypeName,
        serviceValues,
        noteValues,
        department,
        weatherValues,
        isServices,
        startOfWeek,
        toggleOpenNote,
        setDayNumber,
        setDepartment,
        setIsEdit,
        isWeek,
        period,
        openingTime,
        closingTime,
        numberOfHourCellsInDay,
        isMeteo = true,
        isNotes = true,
        selectedDay,
        startCellHour,
        style,
    } = props;
    const numberOfDaysInTheWeek = 7;
    const [configType, setConfigType] = React.useState(configTypeName);
    const [serviceList, setServiceList] = React.useState(serviceValues ?? []);
    React.useEffect(() => {
        const configTypes = localStorage.getItem("configTypes");
        let ob: any = {};
        let type = "service";
        if (configTypes) {
            let parsedConfigTypes = JSON.parse(configTypes);
            if (parsedConfigTypes[department?._id || "without"]) {
                type = parsedConfigTypes[department?._id || "without"];
            } else {
                ob = { ...parsedConfigTypes };
                if (isServices) {
                    type = "service";
                    ob[department?._id || "without"] = "service";
                } else if (isNotes) {
                    type = "note";
                    ob[department?._id || "without"] = "note";
                } else {
                    type = "weather";
                    ob[department?._id || "without"] = "weather";
                }
                localStorage.setItem("configTypes", JSON.stringify(ob));
            }
        } else {
            if (isServices) {
                type = "service";
                ob[department?._id || "without"] = "service";
            } else if (isNotes) {
                type = "note";
                ob[department?._id || "without"] = "note";
            } else {
                type = "weather";
                ob[department?._id || "without"] = "weather";
            }
            localStorage.setItem("configTypes", JSON.stringify(ob));
        }
        if (type === "weather" && !isMeteo) {
            if (isServices) {
                type = "service";
            }
            if (isNotes) {
                type = "note";
            }
        }
        if (type === "note" && !isNotes) {
            if (isServices) {
                type = "service";
            }
            if (isMeteo) {
                type = "weather";
            }
        }
        if (type === "service" && !isServices) {
            if (isNotes) {
                type = "note";
            }
            if (isMeteo) {
                type = "weather";
            }
        }
        setConfigType(type);
    }, [isServices, isMeteo, isNotes]);
    const {
        hoursServices,
        departmentsHoursServices,
        planningInfo,
        planningInfoDepartments,
    } = usePlanning();
    const SelectServices = () => {
        if (configType === "service") {
            if (period === "day") {
                const theHoursServices = department
                    ? departmentsHoursServices[department._id]
                    : hoursServices;
                return (
                    <th colSpan={7}>
                        <div className="service-day-parent">
                            {Array(numberOfHourCellsInDay)
                                .fill(0)
                                .map((item, index) => {
                                    let filteredValues = theHoursServices[
                                        startCellHour + index
                                    ]?.filter((item: string) =>
                                        /\S/.test(item),
                                    );
                                    const valuesSet = filteredValues
                                        ?.filter(
                                            (item: any, i: any, ar: any) =>
                                                ar.indexOf(item) === i,
                                        )
                                        ?.sort();
                                    let occurrences: Record<string, number> =
                                        {};
                                    if (filteredValues?.length > 0) {
                                        for (const item of filteredValues) {
                                            occurrences[item] =
                                                (occurrences[item] || 0) + 1;
                                        }
                                    }
                                    return (
                                        <div
                                            className="service-day-child"
                                            style={{
                                                width: `calc(100% / ${numberOfHourCellsInDay})`,
                                            }}
                                        >
                                            <TextData
                                                variant="div"
                                                size="14px"
                                                width="auto"
                                                fontWeight="100"
                                                color="#6F78AB"
                                                className={["serives-number"]}
                                                style={{
                                                    // backgroundColor:
                                                    //     index % 2
                                                    //         ? "#ccc"
                                                    //         : "#2A8BAB",
                                                    backgroundColor:
                                                        hoursServices[
                                                            startCellHour +
                                                                index
                                                        ]?.length > 0
                                                            ? "#2A8BAB"
                                                            : "#ccc",
                                                    opacity:
                                                        filteredValues?.length >
                                                            0 ||
                                                        theHoursServices[
                                                            startCellHour +
                                                                index
                                                        ]?.length === 0
                                                            ? "1"
                                                            : "0.5",
                                                    color: "white",
                                                    borderRadius: "8px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    height: "27px",
                                                    width: "27px",
                                                    padding: "2px 5px",
                                                    position: "relative",
                                                }}
                                            >
                                                {theHoursServices[
                                                    startCellHour + index
                                                ]?.length || 0}
                                                {filteredValues?.length > 0 && (
                                                    <div
                                                        className="steakers-show"
                                                        style={{
                                                            left:
                                                                index <
                                                                numberOfHourCellsInDay -
                                                                    4
                                                                    ? "calc(100% + 5px)"
                                                                    : "",
                                                            right:
                                                                index >=
                                                                numberOfHourCellsInDay -
                                                                    4
                                                                    ? "calc(100% + 5px)"
                                                                    : "",
                                                            zIndex: "9999",
                                                        }}
                                                    >
                                                        {valuesSet.map(
                                                            (
                                                                item: string,
                                                                index: number,
                                                            ) => {
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            color: "#2a346d",
                                                                            zIndex: "50",
                                                                            gap: "5px",
                                                                            padding:
                                                                                "2px 4px",
                                                                            fontWeight:
                                                                                "100",
                                                                            borderRadius:
                                                                                "3px",
                                                                            backgroundColor:
                                                                                !(
                                                                                    index %
                                                                                    2
                                                                                )
                                                                                    ? "#CFE0E6"
                                                                                    : "white",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                fontSize:
                                                                                    "13px",
                                                                                display:
                                                                                    "flex",
                                                                                alignItems:
                                                                                    "center",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                                gap: "5px",
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    alignItems:
                                                                                        "center",
                                                                                    gap: "5px",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        width: "6px",
                                                                                        height: "6px",
                                                                                        borderRadius:
                                                                                            "20px",
                                                                                        backgroundColor:
                                                                                            "#2a346d",
                                                                                        opacity:
                                                                                            "0.6",
                                                                                    }}
                                                                                ></div>
                                                                                <div>
                                                                                    {
                                                                                        item
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                {
                                                                                    occurrences[
                                                                                        item
                                                                                    ]
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            },
                                                        )}
                                                    </div>
                                                )}
                                            </TextData>
                                        </div>
                                    );
                                })}
                        </div>
                    </th>
                );
            } else if (period === "week") {
                let cells: any = [];
                for (let i = 0; i < numberOfDaysInTheWeek; i++) {
                    const values =
                        serviceValues[i]?.length > 0
                            ? serviceValues[i]?.map(
                                  (service: any, index: any) => {
                                      let filteredValues =
                                          service.value?.filter(
                                              (item: string) => /\S/.test(item),
                                          );
                                      const valuesSet = filteredValues
                                          ?.filter(
                                              (item: any, i: any, ar: any) =>
                                                  ar.indexOf(item) === i,
                                          )
                                          ?.sort();
                                      const occurrences: Record<
                                          string,
                                          number
                                      > = {};
                                      for (const item of filteredValues) {
                                          occurrences[item] =
                                              (occurrences[item] || 0) + 1;
                                      }
                                      return (
                                          <div
                                              key={`${i}-${index}`}
                                              style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                              }}
                                          >
                                              {index !== 0 && (
                                                  <TextData
                                                      variant="div"
                                                      width="auto"
                                                      size="18px"
                                                      fontWeight="100"
                                                      color="#6F78AB"
                                                      style={{
                                                          marginRight: "2px",
                                                      }}
                                                  >
                                                      |
                                                  </TextData>
                                              )}
                                              <TextData
                                                  variant="div"
                                                  className={["service-name"]}
                                                  color="#6F78AB"
                                                  size="11%"
                                                  fontWeight="600"
                                                  style={{
                                                      marginRight: "2px",
                                                      fontFamily:
                                                          '"DM Sans-Bold", Helvetica',
                                                      cursor: "default",
                                                  }}
                                                  //   title={service.name}
                                              >
                                                  {/* {service.name} */}
                                                  {service.name
                                                      .slice(0, 1)
                                                      .toUpperCase()}
                                              </TextData>
                                              <div
                                                  className="serives-number"
                                                  style={{
                                                      backgroundColor:
                                                          service.value.length >
                                                          0
                                                              ? "#2A8BAB"
                                                              : "#ccc",
                                                      opacity:
                                                          filteredValues.length >
                                                              0 ||
                                                          service.value
                                                              .length === 0
                                                              ? "1"
                                                              : "0.5",
                                                  }}
                                              >
                                                  {service.value.length}
                                                  {filteredValues.length >
                                                      0 && (
                                                      <div
                                                          className="steakers-show"
                                                          style={{
                                                              left:
                                                                  i <
                                                                  numberOfDaysInTheWeek -
                                                                      1
                                                                      ? "calc(100% + 5px)"
                                                                      : "",
                                                              right:
                                                                  i ===
                                                                  numberOfDaysInTheWeek -
                                                                      1
                                                                      ? "calc(100% + 5px)"
                                                                      : "",
                                                          }}
                                                      >
                                                          <div
                                                              style={{
                                                                  textAlign:
                                                                      "left",
                                                                  fontSize:
                                                                      "14px",
                                                                  color: "#2a346d",
                                                                  fontWeight:
                                                                      "bold",
                                                                  margin: "2px",
                                                              }}
                                                          >
                                                              {service.name}
                                                          </div>
                                                          {valuesSet.map(
                                                              (
                                                                  item: string,
                                                                  index: number,
                                                              ) => {
                                                                  return (
                                                                      <div
                                                                          style={{
                                                                              color: "#2a346d",
                                                                              zIndex: "50",
                                                                              gap: "5px",
                                                                              padding:
                                                                                  "2px 4px",
                                                                              fontWeight:
                                                                                  "100",
                                                                              borderRadius:
                                                                                  "3px",
                                                                              backgroundColor:
                                                                                  !(
                                                                                      index %
                                                                                      2
                                                                                  )
                                                                                      ? "#CFE0E6"
                                                                                      : "white",
                                                                          }}
                                                                      >
                                                                          <div
                                                                              style={{
                                                                                  fontSize:
                                                                                      "13px",
                                                                                  display:
                                                                                      "flex",
                                                                                  alignItems:
                                                                                      "center",
                                                                                  justifyContent:
                                                                                      "space-between",
                                                                                  gap: "5px",
                                                                                  width: "100%",
                                                                              }}
                                                                          >
                                                                              <div
                                                                                  style={{
                                                                                      display:
                                                                                          "flex",
                                                                                      alignItems:
                                                                                          "center",
                                                                                      gap: "5px",
                                                                                  }}
                                                                              >
                                                                                  <div
                                                                                      style={{
                                                                                          width: "6px",
                                                                                          height: "6px",
                                                                                          borderRadius:
                                                                                              "20px",
                                                                                          backgroundColor:
                                                                                              "#2a346d",
                                                                                          opacity:
                                                                                              "0.6",
                                                                                      }}
                                                                                  ></div>
                                                                                  <div>
                                                                                      {
                                                                                          item
                                                                                      }
                                                                                  </div>
                                                                              </div>
                                                                              <div>
                                                                                  {
                                                                                      occurrences[
                                                                                          item
                                                                                      ]
                                                                                  }
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  );
                                                              },
                                                          )}
                                                      </div>
                                                  )}
                                              </div>
                                          </div>
                                      );
                                  },
                              )
                            : null;
                    cells.push(
                        <th
                            id={`col-${i + 2}`}
                            style={{
                                width: "10%",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    // gap: "5px",
                                    width: "100%",
                                }}
                            >
                                {values}
                            </div>
                        </th>,
                    );
                }
                return cells;
            } else return null;
            // return serviceList.map((service: any, index: any) => {
            //     return (
            //         <th
            //             id='col-2'
            //             style={{
            //                 padding: 0,
            //                 boxShadow: "inset 0 0 0 0.5px #edeff5",
            //             }}
            //         >
            //             <div className='option-box'>
            //                 {service.map((service: any, index: any) => (
            //                     <>
            //                         {index !== 0 && (
            //                             <TextData
            //                                 variant='regular'
            //                                 width='auto'
            //                                 size='20px'
            //                                 fontWeight='100'
            //                                 color='#6F78AB'
            //                                 style={{ margin: "0 4px" }}
            //                             >
            //                                 |
            //                             </TextData>
            //                         )}
            //                         <TextData
            //                             variant='regular'
            //                             color='#6F78AB'
            //                             size='16px'
            //                             fontWeight='700'
            //                             style={{
            //                                 margin: "0 2px",
            //                                 fontFamily:
            //                                     '"DM Sans-Bold", Helvetica',
            //                             }}
            //                         >
            //                             {service.name.slice(0, 1).toUpperCase()}
            //                         </TextData>
            //                         <TextData
            //                             variant='regular'
            //                             color='#2A8BAB'
            //                             size='16px'
            //                             fontWeight='500'
            //                             style={{
            //                                 fontFamily:
            //                                     '"DM Sans-Bold", Helvetica',
            //                             }}
            //                         >
            //                             {service.valeur}
            //                         </TextData>
            //                     </>
            //                 ))}
            //             </div>
            //         </th>
            //     );
            // });
        }
        return null;
    };

    // const [notes, setnotes] = React.useState(noteValues ?? []);
    const [isAddNoteBtnVisible, setShowAddNoteBtn] = React.useState(
        Array(7).fill(false),
    );

    const [noteToEdit, setNoteToEdit] = React.useState("");
    let theNoteItem: any = {
        day: "",
        note: "",
    };
    const SelectNotes = () => {
        if (configType === "note") {
            let notesList =
                planningInfoDepartments?.[department?._id]?.note ||
                planningInfo?.note ||
                [];
            if (period === "day") {
                const day = moment(selectedDay, "YYYY-MM-DD");
                const formattedSelectedDay = day.format("DD/MM/YYYY");
                const dayNote = notesList?.find(
                    (noteItem: any) => noteItem.day === formattedSelectedDay,
                );
                const dayNumber = day.day() === 0 ? 6 : day.day() - 1;
                const notesNumber = dayNote?.note?.split("\n").length;

                return (
                    <th colSpan={7}>
                        {dayNote ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    gap: "25px",
                                    padding: "0 10px",
                                    overflow: "hidden",
                                    // backgroundColor: "red",
                                    width: "fit-content",
                                    maxWidth: "100%",
                                }}
                                onClick={() => {
                                    department &&
                                        setDepartment &&
                                        setDepartment(department._id);
                                    setIsEdit(true);
                                    setDayNumber(dayNumber);
                                    toggleOpenNote(true);
                                }}
                            >
                                {dayNote?.note
                                    .slice(0, 140 - 4 * (notesNumber + 1))
                                    .split("\n")
                                    .map((nota: any, index: number) => {
                                        return (
                                            <div
                                                key={index}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    gap: "5px",
                                                }}
                                            >
                                                {/\S/.test(nota) && (
                                                    <div
                                                        style={{
                                                            width: "8px",
                                                            height: "8px",
                                                            borderRadius: "50%",
                                                            backgroundColor:
                                                                "#6F78AB",
                                                        }}
                                                    ></div>
                                                )}
                                                <div
                                                    style={{
                                                        color: "#6F78AB",
                                                        fontSize: "16px",
                                                        fontWeight: "200",
                                                    }}
                                                >
                                                    {nota}
                                                    {index ===
                                                        dayNote.note
                                                            .slice(
                                                                0,
                                                                140 -
                                                                    4 *
                                                                        (notesNumber +
                                                                            1),
                                                            )
                                                            .split("\n")
                                                            .length -
                                                            1 &&
                                                        dayNote?.note?.length >
                                                            140 -
                                                                4 *
                                                                    (notesNumber +
                                                                        1) && (
                                                            <span>. . .</span>
                                                        )}
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        ) : (
                            <div
                                className="option-box add-note-btn-container"
                                style={{
                                    height: "70%",
                                }}
                            >
                                <Button
                                    backgroundColor="#EDEFF5"
                                    width="fit-content"
                                    height="100%"
                                    padding="0 7px 0 2px"
                                    onClick={() => {
                                        setDayNumber(dayNumber);
                                        department &&
                                            setDepartment &&
                                            setDepartment(department._id);
                                        toggleOpenNote(true);
                                    }}
                                    style={{
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        border: "1px solid #c7cbe2",
                                        cursor: "pointer",
                                        display: "flex",
                                    }}
                                >
                                    <PlusSVG
                                        color="#4C5690"
                                        height={24}
                                        width={24}
                                    />
                                    <TextData
                                        variant="label"
                                        width="auto"
                                        color="#4C5690"
                                        size="10px"
                                        fontWeight="400"
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        Ajouter une note
                                    </TextData>
                                </Button>
                            </div>
                        )}
                    </th>
                );
            } else if (period === "week") {
                const cells: any = [];
                let firstDayOfWeek = startOfWeek;

                // notesList = notesList.sort((a: any, b: any) =>
                //     moment(a.day, "DD/MM/YYYY").diff(moment(b.day, "DD/MM/YYYY"))
                // );
                let j = 0;
                for (let i = 0; i < numberOfDaysInTheWeek; i++) {
                    theNoteItem = notesList[j] || {
                        day: "",
                        note: "",
                    };
                    // if (typeof theNoteItem.note === "string") {
                    //     let copyNote = [];
                    //     copyNote.push(theNoteItem.note);
                    //     theNoteItem = {
                    //         ...theNoteItem,
                    //         note: copyNote,
                    //     };
                    // }
                    if (
                        j < notesList.length &&
                        firstDayOfWeek ===
                            moment(theNoteItem.day, "DD/MM/YYYY").format(
                                "YYYY-MM-DD",
                            )
                    ) {
                        cells.push(
                            <th
                                key={i}
                                id={`col-${i + 2}`}
                                style={{
                                    padding: 0,
                                    boxShadow: "inset 0 0 0 0.5px #edeff5",
                                    // width: `${100 / 6}px`,
                                }}
                            >
                                <div
                                    className="option-box add-note-btn-container"
                                    style={{
                                        height: "70%",
                                        padding: "10px 2px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "flex-start",
                                        flexDirection: "column",
                                    }}
                                >
                                    <TextData
                                        className={["first-note-item"]}
                                        variant="div"
                                        color="#6F78AB"
                                        size="12px"
                                        fontWeight="500"
                                        style={{
                                            margin: "0 2px",
                                            fontFamily:
                                                '"DM Sans-Bold", Helvetica',
                                            whiteSpace: "nowrap",
                                            borderRadius: "5px",
                                            // marginLeft: "auto",
                                            // marginRight: "auto",
                                            cursor: "pointer",
                                            position: "relative",
                                        }}
                                        // width='fit-content'
                                        onClick={() => {
                                            department &&
                                                setDepartment &&
                                                setDepartment(department._id);
                                            setIsEdit(true);
                                            setDayNumber(i);
                                            toggleOpenNote(true);
                                        }}
                                    >
                                        <TextData
                                            variant="div"
                                            className={["hover-notes"]}
                                            color="#6F78AB"
                                            size="12px"
                                            fontWeight="500"
                                            style={{
                                                position: "absolute",
                                                padding: "10px",
                                                border: "1px solid #c7cbe2",
                                                borderRadius: "5px",
                                                backgroundColor: "#f7f8fa",
                                                top: "calc(100% + 5px)",
                                                left: "50%",
                                                transform: "translateX(-50%)",
                                                zIndex: "99",
                                            }}
                                        >
                                            {theNoteItem.note
                                                .split("\n")
                                                .map(
                                                    (
                                                        nota: any,
                                                        index: number,
                                                    ) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                    gap: "5px",
                                                                }}
                                                            >
                                                                {/\S/.test(
                                                                    nota,
                                                                ) && (
                                                                    <span
                                                                        style={{
                                                                            width: "5px",
                                                                            height: "5px",
                                                                            display:
                                                                                "block",
                                                                            backgroundColor:
                                                                                "#6F78AB",
                                                                            borderRadius:
                                                                                "50%",
                                                                        }}
                                                                    ></span>
                                                                )}
                                                                {nota}{" "}
                                                            </div>
                                                        );
                                                    },
                                                )}
                                        </TextData>

                                        {theNoteItem.note
                                            .split("\n")
                                            .slice(0, 3)
                                            .map((nota: any, index: number) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "row",
                                                            alignItems:
                                                                "center",
                                                            gap: "5px",
                                                        }}
                                                    >
                                                        {/\S/.test(nota) && (
                                                            <div
                                                                style={{
                                                                    width: "5px",
                                                                    height: "5px",
                                                                    borderRadius:
                                                                        "50%",
                                                                    backgroundColor:
                                                                        "#6F78AB",
                                                                }}
                                                            ></div>
                                                        )}
                                                        <div>
                                                            {nota.slice(0, 12)}
                                                            {((index === 2 &&
                                                                theNoteItem.note.split(
                                                                    "\n",
                                                                ).length > 3) ||
                                                                nota.length >
                                                                    12) && (
                                                                <span>...</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                        {/* {theNoteItem.note.split("\n")[0]} */}
                                        {/* </div> */}
                                        {/* {`${theNoteItem?.note[0]?.slice(0, 24)}${
                                        theNoteItem?.note?.length > 1 ||
                                        theNoteItem?.note[0]?.length > 24
                                            ? "..."
                                            : ""
                                    }`} */}
                                    </TextData>
                                </div>
                            </th>,
                        );
                        j++;
                    } else {
                        cells.push(
                            <th
                                key={`col-${i + 2}`}
                                id={`col-${i + 2}`}
                                style={{
                                    padding: 0,
                                    boxShadow: "inset 0 0 0 0.5px #edeff5",
                                    // width: `${100 / 6}px`,
                                }}
                            >
                                <div
                                    className="option-box add-note-btn-container"
                                    style={{
                                        height: "70%",
                                    }}
                                >
                                    <Button
                                        backgroundColor="#EDEFF5"
                                        width="fit-content"
                                        height="100%"
                                        padding="0 7px 0 2px"
                                        onClick={() => {
                                            setDayNumber(i);
                                            department &&
                                                setDepartment &&
                                                setDepartment(department._id);
                                            toggleOpenNote(true);
                                        }}
                                        style={{
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                            border: "1px solid #c7cbe2",
                                            cursor: "pointer",
                                            display: "flex",
                                        }}
                                    >
                                        <PlusSVG
                                            color="#4C5690"
                                            height={24}
                                            width={24}
                                        />
                                        <TextData
                                            variant="label"
                                            width="auto"
                                            color="#4C5690"
                                            size="10px"
                                            fontWeight="400"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                        >
                                            Ajouter une note
                                        </TextData>
                                    </Button>
                                </div>
                            </th>,
                        );
                    }

                    firstDayOfWeek = moment(firstDayOfWeek, "YYYY-MM-DD")
                        .add(1, "days")
                        .format("YYYY-MM-DD");
                }
                return cells;
            } else return null;
        }
        return null;
    };
    //     return notes.map((note: any, index: any) => {
    //         return (
    //             <>
    //                 <th
    //                     id={`col-${index}`}
    //                     style={{
    //                         padding: 0,
    //                         boxShadow: "inset 0 0 0 0.5px #edeff5",
    //                         // width: `${100 / 6}px`,
    //                     }}
    //                 >
    //                     <div
    //                         className='option-box add-note-btn-container'
    //                         style={{
    //                             height: "70%",
    //                         }}
    //                     >
    //                         <Button
    //                             backgroundColor='#EDEFF5'
    //                             width='fit-content'
    //                             height='100%'
    //                             padding='0 7px 0 2px'
    //                             onClick={() => toggleOpenNote(index, true)}
    //                             style={{
    //                                 marginLeft: "auto",
    //                                 marginRight: "auto",
    //                                 border: "1px solid #c7cbe2",
    //                                 cursor: "pointer",
    //                                 display: "flex",
    //                             }}
    //                         >
    //                             <PlusSVG
    //                                 color='#4C5690'
    //                                 height={24}
    //                                 width={24}
    //                             />
    //                             <TextData
    //                                 variant='label'
    //                                 width='auto'
    //                                 color='#4C5690'
    //                                 size='10px'
    //                                 fontWeight='400'
    //                                 style={{
    //                                     cursor: "pointer",
    //                                 }}
    //                             >
    //                                 Ajouter note
    //                             </TextData>
    //                         </Button>
    //                         {/* <TextData
    //                             variant='regular'
    //                             color='#6F78AB'
    //                             size='12px'
    //                             fontWeight='500'
    //                             style={{
    //                                 margin: "0 2px",
    //                                 fontFamily: '"DM Sans-Bold", Helvetica',
    //                                 whiteSpace: "nowrap",
    //                             }}
    //                             title={note}
    //                             width=''
    //                         >
    //                             {`${note.slice(0, 24)}...`}
    //                         </TextData> */}
    //                     </div>
    //                     <div onClick={(e) => e.stopPropagation()}>
    //                         <AddNoteCard
    //                             display={openNote[index]}
    //                             defaultDay={moment(startOfWeek).add(
    //                                 index,
    //                                 "days"
    //                             )}
    //                             onClose={() => {
    //                                 toggleOpenNote(index, false);
    //                             }}
    //                         />
    //                     </div>
    //                 </th>
    //             </>
    //         );
    //     });
    // }

    const [weathers, setWeathers] = React.useState(weatherValues ?? []);
    const SelectWeathers = () => {
        const iconComponents: {
            [key: string]: React.FunctionComponent<
                React.SVGProps<SVGSVGElement>
            >;
        } = {
            snow: SnowSVG,
            sun: SunSVG,
            wind: WindSVG,
        };

        if (configType === "weather") {
            if (period === "day") {
                return <th colSpan={7}>météo</th>;
            } else if (period === "week") {
                return weathers.map((weather: any, index: any) => {
                    const { highTemperature, lowTemperature, icon } = weather;
                    const IconComponent = iconComponents[icon] || SunSVG;

                    return (
                        <th
                            key={`col-${index + 2}`}
                            id={`col-${index + 2}`}
                            style={{
                                padding: 0,
                                boxShadow: "inset 0 0 0 0.5px #edeff5",
                            }}
                        >
                            <div className="option-box">
                                <IconComponent
                                    style={{
                                        display: "inline",
                                        marginRight: "5px",
                                    }}
                                    width={24}
                                    height={24}
                                />
                                <TextData
                                    variant="regular"
                                    width="auto"
                                    fontWeight="400"
                                    size="12px"
                                >
                                    {`${highTemperature}°/${lowTemperature}°`}
                                </TextData>
                            </div>
                        </th>
                    );
                });
            } else return null;
        }
        return null;
    };

    const OptionButton = ({
        Icon,
        label,
        isActive,
        onClick,
    }: OptionButtonProps) => (
        <Button
            width=""
            className={["option-container"]}
            height=""
            backgroundColor={isActive ? "#2A8BAB" : "transparent"}
            style={{ border: "none", outline: "none" }}
            onClick={onClick}
        >
            <Icon color={isActive ? "#EDEFF5" : "#6F78AB"} />
            <TextData
                variant="label"
                width="auto"
                color={isActive ? "#EDEFF5" : "#6F78AB"}
                size="12px"
                fontWeight="400"
                style={{
                    cursor: "pointer",
                }}
            >
                {label}
            </TextData>
        </Button>
    );

    const PlanningConfigurationOptions = () => {
        let options: any = [];
        if (isServices) {
            options.push({
                icon: ServiceSVG,
                label: "Services",
                selectedConfigType: "service",
            });
        }
        if (isMeteo) {
            options.push({
                icon: WeatherSVG,
                label: "Météo",
                selectedConfigType: "weather",
            });
        }
        if (isNotes) {
            options.push({
                icon: NoteSVG,
                label: "Note",
                selectedConfigType: "note",
            });
        }

        return (
            <div className="planning-configuration-options">
                {options.map(({ icon, label, selectedConfigType }: any) => (
                    <OptionButton
                        key={configType}
                        Icon={icon}
                        label={label}
                        isActive={configType === selectedConfigType}
                        onClick={() => {
                            setConfigType(
                                selectedConfigType as
                                    | "service"
                                    | "note"
                                    | "weather",
                            );
                            //
                            const configTypes =
                                localStorage.getItem("configTypes");
                            let parsedConfigTypes: any = {};
                            if (configTypes) {
                                parsedConfigTypes = JSON.parse(configTypes);
                            }
                            parsedConfigTypes[department?._id || "without"] =
                                selectedConfigType;
                            localStorage.setItem(
                                "configTypes",
                                JSON.stringify(parsedConfigTypes),
                            );

                            //
                        }}
                    />
                ))}
            </div>
        );
    };

    const [stepDay, setStep] = React.useState(0);
    const [TheWeekNumber, setWeekNumber] = React.useState<any>("");
    const [TheWeekDays, setWeekDays] = React.useState<string[]>([]);
    React.useEffect(() => {
        const { weekNumber, weekDays } = getCurrentWeekNumberAndDays(
            new Date(new Date().getTime() + stepDay * 24 * 60 * 60 * 1000 * 7),
            [6],
        );
        setWeekNumber(weekNumber);
        setWeekDays(weekDays);
    }, [stepDay]);
    const services = [
        { name: "Matin", valeur: 6 },
        { name: "Aprés-midi", valeur: 6 },
        { name: "Soir", valeur: 6 },
    ];
    const note = "Réservation 40 personnes est une opportunité etc bla blabla";
    const widthSize = 100 / TheWeekDays.length;
    if (isServices || isMeteo || isNotes) {
        return (
            <tr className="configuration-row" style={style}>
                <th id="col-1" style={{ padding: 0, height: "40px" }}>
                    <PlanningConfigurationOptions />
                </th>
                {SelectServices()}
                {SelectNotes()}
                {SelectWeathers()}
            </tr>
        );
    }
    return null;
}
interface OptionButtonProps {
    Icon: React.ElementType;
    label: string;
    isActive: boolean;
    onClick: () => void;
}
// interface ServiceStyle {
//     service: {
//         name: string;
//         value: string;
//     }[];
// }
interface WeatherStyle {
    highTemperature: string;
    lowTemperature: string;
    icon: "snow" | "wind" | "sun";
}
interface PlanningTableConfigurationProps {
    configTypeName?: "service" | "note" | "weather";
    serviceValues?: any; // ServiceStyle[];
    noteValues?: String[];
    weatherValues?: WeatherStyle[];
    isServices?: boolean;
    startOfWeek?: string;
    notes: any;
}
PlanningTableConfiguration.defaultProps = {
    configTypeName: "service",
    serviceValues: [
        { name: "Matin", valeur: 6 },
        { name: "Aprés-midi", valeur: 6 },
        // [
        //     { name: "Matin", valeur: 6 },
        //     { name: "Aprés-midi", valeur: 6 },
        //     { name: "Soir", valeur: 6 },
        // ],
        // [
        //     { name: "Matin", valeur: 6 },
        //     { name: "Aprés-midi", valeur: 6 },
        //     { name: "Soir", valeur: 6 },
        // ],
        // [
        //     { name: "Matin", valeur: 6 },
        //     { name: "Aprés-midi", valeur: 6 },
        //     { name: "Soir", valeur: 6 },
        // ],
        // [
        //     { name: "Matin", valeur: 6 },
        //     { name: "Aprés-midi", valeur: 6 },
        //     { name: "Soir", valeur: 6 },
        // ],
        // [
        //     { name: "Matin", valeur: 6 },
        //     { name: "Aprés-midi", valeur: 6 },
        //     { name: "Soir", valeur: 6 },
        // ],
        // [
        //     { name: "Matin", valeur: 6 },
        //     { name: "Aprés-midi", valeur: 6 },
        //     { name: "Soir", valeur: 6 },
        // ],
        // [
        //     { name: "Matin", valeur: 6 },
        //     { name: "Aprés-midi", valeur: 6 },
        //     { name: "Soir", valeur: 6 },
        // ],
    ],
    noteValues: ["test", "test", "test", "test", "test", "test", "test"],
    weatherValues: [
        { highTemperature: "17", lowTemperature: "11", icon: "snow" },
        { highTemperature: "17", lowTemperature: "11", icon: "wind" },
        { highTemperature: "17", lowTemperature: "11", icon: "snow" },
        { highTemperature: "17", lowTemperature: "11", icon: "sun" },
        { highTemperature: "17", lowTemperature: "11", icon: "snow" },
        { highTemperature: "17", lowTemperature: "11", icon: "snow" },
        { highTemperature: "17", lowTemperature: "11", icon: "wind" },
    ],
};
