import React from "react";
import { CheckBox } from "../../../Icons/CheckBox";
import Theme from "../../../tailwind-theme";

export default function RolePickerInput({
    roles,
    value,
    onPress,
    title,
    direction = "row",
}: RolePickerInputInterface) {
    return (
        <div>
            {title ? (
                <span
                    className={`inline-block text-v-800 font-bold mb-4 ${
                        direction === "row" ? "text-lg" : "text-5xl"
                    }`}
                >
                    {title}
                </span>
            ) : (
                ""
            )}
            <div
                className={`flex flex-wrap  ${
                    direction === "col" ? "gap-5" : "flex-col  gap-2"
                }`}
            >
                {roles.map((role, keyR) => (
                    <div
                        style={{
                            background: role.bgColor,
                            // display: "flex",
                            minHeight: "120px",
                            overflow: "auto",
                        }}
                        key={keyR}
                        className={`flex grow rounded-lg ${
                            direction === "col"
                                ? "basis-[200px] flex-col items-start p-4 "
                                : "items-center p-1"
                        }`}
                    >
                        <div
                            style={{
                                background:
                                    value === role.value && direction === "row"
                                        ? role.bgActiveColor
                                        : "transparent",
                                width: direction === "row" ? "30%" : "inherit",
                            }}
                            className={`flex items-center gap-2 p-3 font-semibold rounded-md min-w-[130px] cursor-pointer ${
                                direction === "row" ? "text-lg" : "text-3xl"
                            }  ${
                                value === role.value
                                    ? direction === "row"
                                        ? "text-white"
                                        : role.value === "Employee"
                                        ? "text-employee"
                                        : value === "Manager"
                                        ? "text-manager"
                                        : "text-admin"
                                    : "text-n-500"
                            }`}
                            onClick={() => {
                                onPress(role.value);
                            }}
                        >
                            {role.value === value ? (
                                <div
                                    className={`flex items-center justify-center  rounded-full ${
                                        direction === "row"
                                            ? "w-4 h-4"
                                            : "w-5 h-5"
                                    }  ${
                                        direction === "row"
                                            ? "bg-white"
                                            : role.value === "Employee"
                                            ? "bg-employee "
                                            : value === "Manager"
                                            ? "bg-manager"
                                            : "bg-admin"
                                    } `}
                                >
                                    <CheckBox
                                        color={
                                            role.value === "Employee"
                                                ? Theme.colors["employee"]
                                                : value === "Manager"
                                                ? Theme.colors["manager"]
                                                : Theme.colors["admin"]
                                        }
                                    />
                                </div>
                            ) : (
                                <span
                                    className={`inline-block rounded-full border-2  ${
                                        direction === "row"
                                            ? "w-4 h-4"
                                            : "w-5 h-5"
                                    } `}
                                    style={{
                                        borderColor:
                                            value === role.value
                                                ? "transparent"
                                                : role.bgActiveColor,
                                    }}
                                />
                            )}
                            {role.title}
                        </div>
                        <span
                            className={`inline-block bg-t-900-op-2 ${
                                direction === "col"
                                    ? "w-full h-[1px] my-3"
                                    : "h-[30px] w-[1px]  mx-2"
                            }`}
                        />
                        <div
                            className='flex items-center'
                            style={{
                                width: direction === "row" ? "65%" : "inherit",
                            }}
                        >
                            <span
                                className='font-light text-md px-2 text-n-500'
                                style={{
                                    color:
                                        direction === "row"
                                            ? role.bgActiveColor
                                            : "",
                                }}
                            >
                                {role.desc}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

interface RolePickerInputInterface {
    title?: string | React.ReactNode;
    roles: RoleInterface[];
    value: string;
    onPress: (value: string) => void;
    direction?: "row" | "col";
}

interface RoleInterface {
    title: string | React.ReactNode;
    desc: string | React.ReactNode;
    bgColor: string;
    bgActiveColor: string;
    bgMidColor: string;
    value: string;
}
