import React from "react";
import Modal from "../../organism/Modal";
import { useIntl } from "react-intl";
import Button from "../../atoms/ZButton";

export default function AddUnavailabilityDialog({
  show,
  onHide,
  onSubmit,
  unavaibility,
  EditUnavaibilityForm,
  actionLoading
}: AddUnavailabilityDialogInterface) {
  const intl = useIntl();
  const ConfirmComponent = (
    <Button
      title={ unavaibility._id
        ? "Modifier"
        : intl.formatMessage({ id: "MENU.ADD"})}
      variant="primary"
      onClick={onSubmit}
      loading={actionLoading}
    />
  );

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        title={
          unavaibility._id
            ? intl.formatMessage({ id: "EMPLOYEE.EDIT.EDIT.UNAVAIBILITY" })
            : intl.formatMessage({ id: "EMPLOYEE.EDIT.ADD.UNAVAIBILITY" })
        }
        body={EditUnavaibilityForm}
        width={640}
        height={720}
        ConfirmComponent={ConfirmComponent}
      />
    </div>
  );
}

interface AddUnavailabilityDialogInterface {
  show: boolean;
  onHide: () => void;
  onSubmit: () => void;
  EditUnavaibilityForm: React.ReactNode;
  unavaibility: any;
  actionLoading : boolean;
}
