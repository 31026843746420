import React from "react";
import Modal from "../../organism/Modal";
import { useIntl } from "react-intl";
import Button from "../../atoms/ZButton";

export default function EditPrimesDialog({
  show,
  onHide,
  onSubmit,
  prime,
  EditPrimeForm,
  actionLoading,
}: EditPrimeDialogInterface) {
  const checkError = () => {
    let error = false;
    Object.keys(prime).forEach((key) => {
      if (prime[key].hasError) {
        error = true;
      }
    });
    return error;
  };
  const intl = useIntl();
  const ConfirmComponent = (
    <Button
      title={
        prime.acompteId.value && prime.acompteId.value !== ""
          ? "Modifier"
          : intl.formatMessage({ id: `MENU.ADD` })
      }
      disabled={checkError()}
      variant="primary"
      onClick={() => {
        if (!checkError()) {
          onSubmit();
        }
      }}
      loading={actionLoading}
    />
  );

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        title={
          prime.acompteId.value && prime.acompteId.value !== ""
            ? intl.formatMessage({ id: "EMPLOYEE.EDIT.EDIT.PRIME" })
            : intl.formatMessage({ id: "EMPLOYEE.EDIT.ADD.PRIME" })
        }
        body={EditPrimeForm}
        width={440}
        height={560}
        ConfirmComponent={ConfirmComponent}
      />
    </div>
  );
}

interface EditPrimeDialogInterface {
  show: boolean;
  onHide: () => void;
  onSubmit: () => void;
  EditPrimeForm: React.ReactNode;
  prime: any;
  actionLoading: boolean;
}
