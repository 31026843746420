import * as React from "react";
import TextData from "../../../components/atoms/TextData";
import { ReactComponent as CommingSoonSVG } from "../icons/commingSoon.svg";

const comingSoonContent: Record<string, string> = {
    productivity:
        "Vous aurez la possibilité d'avoir plus d'informations et de statistiques de votre entreprise. Soyez prêts !",
    activity:
        "Vous aurez des informations détaillées sur les tâches effectuées par vos collaborateurs. Soyez prêts !",
    employe:
        "Vous aurez des informations détaillés sur le rapport des employés",
    dashboard:
        "Cette nouvelle page regroupera toutes les informations importantes de votre entreprise. Soyez prêts !",
    month: "Vous aurez la possibilité de gérer et consulter le planning par mois.",
};
const defaultTextToShow =
    "Vous aurez la possibilité d'avoir plus d'informations et de statistiques de votre entreprise. Soyez prêts !";
export default function CommingSoon(props: ComingSoonProps) {
    const { page } = props;
    const textToShow = page ? comingSoonContent[page] : defaultTextToShow;
    return (
        <div className='comming-soon-container'>
            <div className='comming-soon-body'>
                <CommingSoonSVG />
                <TextData width='auto' color='#1A7290' size='14px'>
                    En cours de développement
                </TextData>
                <TextData
                    width='auto'
                    color='#11106E'
                    size='32px'
                    fontWeight='bolder'
                >
                    Bientôt, disponible !
                </TextData>
                <TextData
                    width='400px'
                    style={{ textAlign: "center" }}
                    color='#101844'
                    size='14px'
                >
                    {textToShow}
                </TextData>
            </div>
        </div>
    );
}
export const ComingSoonTicket = () => {
    return (
        <div
            style={{
                position: "absolute",
                backgroundColor: "#2a83ab41",
                backdropFilter: "blur(2px)",
                height: "100%",
                width: "100%",
                zIndex: "9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    color: "#2d38ad",
                    border: "3px dashed #2d38ad",
                    backgroundColor: "rgba(255, 255, 255,0.4)",

                    padding: "10px 20px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                }}
            >
                Bientôt disponible
            </div>
        </div>
    );
};
interface ComingSoonProps {
    page?: string;
}
CommingSoon.defaultProps = {
    page: "",
};
