import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import { PointeuseState } from "./types";
import {
    fetchShiftList,
    fetchShiftWeek,
    addEmployeeToPointeuse,
    updatePointage,
    updateShift,
    validateJourney,
    fetchDepartements,
    validateShift,
} from "./service";

function printNumber(number: number) {
    let n = "";
    n = (
        Math.floor(number / 60) >= 10
            ? Math.floor(number / 60)
            : "0" + Math.floor(number / 60)
    ).toString();
    n += ":";
    n += (
        Math.floor(number % 60) >= 10
            ? Math.floor(number % 60)
            : "0" + Math.floor(number % 60)
    ).toString();
    return n;
}
const removeEmptyBadge = (shifts: any[]) => {
    let newShifts: any[] = [];
    let exist = false;
    for (let shift of shifts) {
        exist = false;
        for (let i = 0; i < shift.shiftbadge.arriver.length && !exist; i++) {
            if (shift.shiftbadge.arriver[i].time) {
                exist = true;
                break;
            }
        }
        let newShiftbadge = {
            ...shift.shiftbadge,
            arriver: exist ? [...shift.shiftbadge.arriver] : [],
        };
        exist = false;
        for (let i = 0; i < shift.shiftbadge.quitter.length && !exist; i++) {
            if (shift.shiftbadge.quitter[i].time) {
                exist = true;
                break;
            }
        }
        newShiftbadge = {
            ...newShiftbadge,
            quitter: exist ? [...shift.shiftbadge.quitter] : [],
        };
        newShifts.push({ ...shift, shiftbadge: newShiftbadge });
    }
    return newShifts;
};

const order = (shifts: any[], planning: null | any[]): any[] => {
    let newShifts: any[] = [];
    shifts?.forEach((shift) => {
        const prevu: { arriver: any[]; quitter: any[]; pause: number } = {
            arriver: [],
            quitter: [],
            pause: 0,
        };
        if (planning && planning?.length > 0) {
            planning?.forEach((plan: any) => {
                if (
                    plan.employee.toString() === shift.employee._id.toString()
                ) {
                    prevu.arriver.push({
                        type: "ARRIVER",
                        time: printNumber(plan.from),
                        day: plan.day,
                    });
                    prevu.quitter.push({
                        type: "QUITTER",
                        time: printNumber(plan.to),
                        day: plan.day,
                    });
                    prevu.pause += plan.pause;
                }
            });
        }
        let newShiftRowId: number = -1;
        if (shift.absent && shift.deleted) {
            newShiftRowId = newShifts.findIndex(
                (s) =>
                    s.deleted &&
                    s.absent &&
                    s.employee._id.toString() === shift.employee._id.toString(),
            );
        } else if (shift.absent && !shift.deleted) {
            newShiftRowId = newShifts.findIndex(
                (s) =>
                    s.absent &&
                    !s.deleted &&
                    s.employee._id.toString() === shift.employee._id.toString(),
            );
        } else if (shift.deleted && !shift.absent) {
            newShiftRowId = newShifts.findIndex(
                (s) =>
                    s.deleted &&
                    !s.absent &&
                    s.employee._id.toString() === shift.employee._id.toString(),
            );
        } else {
            newShiftRowId = newShifts.findIndex(
                (s) =>
                    s.employee._id.toString() === shift.employee._id.toString(),
            );
        }
        if (newShiftRowId !== -1) {
            newShifts[newShiftRowId].shiftbadge.arriver = [
                ...newShifts[newShiftRowId].shiftbadge.arriver,
                ...shift.shiftbadge.arriver,
            ];
            newShifts[newShiftRowId].shiftbadge.quitter = [
                ...newShifts[newShiftRowId].shiftbadge.quitter,
                ...shift.shiftbadge.quitter,
            ];
            newShifts[newShiftRowId].shiftbadge.pause += shift.shiftbadge.pause;

            newShifts[newShiftRowId].shiftrenumere.arriver = [
                ...newShifts[newShiftRowId].shiftrenumere.arriver,
                ...shift.shiftrenumere.arriver.map((s: any, index: any) => ({
                    ...s,
                    shiftId: shift._id,
                    index,
                })),
            ];
            newShifts[newShiftRowId].shiftrenumere.quitter = [
                ...newShifts[newShiftRowId].shiftrenumere.quitter,
                ...shift.shiftrenumere.quitter.map((s: any, index: any) => ({
                    ...s,
                    shiftId: shift._id,
                    index,
                })),
            ];

            newShifts[newShiftRowId].repasHistory.push({
                repas: shift.repas,
                shiftId: shift._id,
            });
            newShifts[newShiftRowId].absentHistory.push({
                absent: shift.absent,
                shiftId: shift._id,
            });
            newShifts[newShiftRowId].absentTypeHistory.push({
                type: shift.absence,
                shiftId: shift._id,
            });
            newShifts[newShiftRowId].ignoredHistory.push({
                ignored: shift.deleted,
                shiftId: shift._id,
            });

            newShifts[newShiftRowId].pauseTotal += shift.pauseTotal;
            newShifts[newShiftRowId].duree += shift.duree;

            newShifts[newShiftRowId].pauseHistory = [
                ...newShifts[newShiftRowId].pauseHistory,
                ...shift.pauseHistory.map((p: any) => ({
                    ...p,
                    shiftId: shift._id,
                })),
            ];

            newShifts[newShiftRowId].pauses.push({
                ...newShifts[newShiftRowId].pause,
                shiftId: shift._id,
            });
        } else {
            newShifts.push({
                ...shift,
                shitfprevu: prevu,
                shiftId: shift._id,
                repasHistory: [{ shiftId: shift._id, repas: shift.repas }],
                absentHistory: [{ shiftId: shift._id, absent: shift.absent }],
                absentTypeHistory: [
                    { shiftId: shift._id, type: shift.absence },
                ],
                ignoredHistory: [
                    { shiftId: shift._id, ignored: shift.deleted },
                ],
                shiftrenumere: {
                    arriver: shift.shiftrenumere.arriver.map(
                        (s: any, index: any) => ({
                            ...s,
                            shiftId: shift._id,
                            index,
                        }),
                    ),
                    quitter: shift.shiftrenumere.quitter.map(
                        (s: any, index: any) => ({
                            ...s,
                            shiftId: shift._id,
                            index,
                        }),
                    ),
                },
                pauses: [
                    { pause: shift.shiftrenumere.pause, shiftId: shift._id },
                ],
            });
        }
    });
    return newShifts;
};

const initialState: PointeuseState = {
    listLoading: false,
    shiftsLoading: false,
    weekLoading: false,
    actionLoading: false,
    error: null,
    lastSync: undefined,
    weekDetails: null,
    planning: [],
    plans: {},
    shiftWeeks: [],
    defaultShifts: [],
    shifts: [],
    shiftsMap: {},
    employees: [],
    showedEmployees: {},
    toSelectEmployees: [],
    uniqueEmployees: [],
    employeesById: {},
    data: {},
    monthDetails: {
        treatedDays: [],
        validatedDays: [],
    },
    filters: {
        departements: [],
    },
    departements: [],
    filteredDataByDepartments: {},
    pointeuseEmployees: [],
    options: {
        hidePause: false,
        hideDays: false,
        hideMeals: false,
        showTimeInMinutes: false,
    },
};

const pointeuseSlice = createSlice({
    name: "pointeuse",
    initialState,
    reducers: {
        resetAllPointeuse: (state) => {
            state = initialState;
        },
        filterUpdated: (state: PointeuseState, action: PayloadAction<any>) => {
            const filters = action.payload.filters;
            state.filters = filters;
        },
        optionsUpdate: (state, action) => {
            if (action.payload.hidePause !== undefined) {
                state.options.hidePause = action.payload.hidePause;
            }
            if (action.payload.hideDays !== undefined) {
                state.options.hideDays = action.payload.hideDays;
            }
            if (action.payload.hideMeals !== undefined) {
                state.options.hideMeals = action.payload.hideMeals;
            }
            if (action.payload.showTimeInMinutes !== undefined) {
                state.options.showTimeInMinutes =
                    action.payload.showTimeInMinutes;
            }
            localStorage.setItem(
                "pointeuseOptions",
                JSON.stringify(state.options),
            );
        },
        updatePointeuse: (state, action) => {
            if (action.payload.departments !== undefined) {
                state.departements = action.payload.departments;
            }
            if (action.payload.filteredDataByDepartments !== undefined) {
                state.filteredDataByDepartments =
                    action.payload.filteredDataByDepartments;
            }
            if (action.payload.employees !== undefined) {
                let employeesById: any = [];
                for (let emp of action.payload.employees) {
                    const { _id } = emp;
                    employeesById[_id] = emp;
                }
                state.employeesById = employeesById;
            }
            if (action.payload.reset) {
                state.listLoading = false;
                state.shiftsLoading = false;
                state.weekLoading = false;
                state.actionLoading = false;
                state.error = null;
                state.lastSync = undefined;
                state.weekDetails = null;
                state.planning = [];
                state.plans = {};
                state.shiftWeeks = [];
                state.defaultShifts = [];
                state.shifts = [];
                state.shiftsMap = {};
                state.employees = [];
                state.showedEmployees = {};
                state.toSelectEmployees = [];
                state.uniqueEmployees = [];
                state.data = {};
                state.monthDetails = {
                    treatedDays: [],
                    validatedDays: [],
                };
                state.filters = {
                    departements: [],
                };
                state.departements = [];
                state.filteredDataByDepartments = {};
                state.options = {
                    hidePause: false,
                    hideDays: false,
                    hideMeals: false,
                    showTimeInMinutes: false,
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchShiftList.pending, (state: PointeuseState) => {
            state.shiftsLoading = true;
            state.error = null;
        });
        builder.addCase(
            fetchShiftList.fulfilled,
            (state: PointeuseState, action: PayloadAction<any>) => {
                const { lastSync, shifts, planning, users } = action.payload;
                state.lastSync = lastSync;
                state.employees = users;
                state.planning = planning;
                let shiftsMap: any = {};
                let allEmployees: string[] = [];
                let planningByUser: any = {};
                for (let i = 0; i < planning?.length; i++) {
                    if (planning[i].type === 0) continue;
                    allEmployees.push(planning[i].employee);
                    if (!planningByUser[planning[i].employee]) {
                        planningByUser[planning[i].employee] = [];
                    }
                    planningByUser[planning[i].employee].push({
                        ...planning[i],
                    });
                }

                // let newShifts = removeEmptyBadge(shifts);
                // console.log(" all shoifts = ", shifts);
                let newShifts = [...shifts];
                let usersHaveShifts: any = [];
                let deletedShiftMap: any = {};
                let absentShiftMap: any = {};
                for (let i = 0; i < newShifts.length; i++) {
                    const { employee } = newShifts[i];
                    usersHaveShifts.push(employee);
                    if (newShifts[i].deleted) {
                        if (!deletedShiftMap[employee?._id])
                            deletedShiftMap[employee?._id] = [];
                        deletedShiftMap[employee?._id] = [
                            ...deletedShiftMap[employee?._id],
                            newShifts[i],
                        ];
                    } else if (newShifts[i].absent) {
                        if (!absentShiftMap[employee?._id])
                            absentShiftMap[employee?._id] = [];
                        absentShiftMap[employee?._id] = [
                            ...absentShiftMap[employee?._id],
                            newShifts[i],
                        ];
                    } else {
                        if (!shiftsMap[employee?._id])
                            shiftsMap[employee?._id] = [];
                        shiftsMap[employee?._id] = [
                            ...shiftsMap[employee?._id],
                            newShifts[i],
                        ];
                    }
                    allEmployees.push(employee?._id);
                }
                // let orderedShifts = order(newShifts, planning);
                const uniqueEmployees = Array.from(new Set(allEmployees));
                state.uniqueEmployees = uniqueEmployees;
                let showedEmployees: any = {};
                let data: any = {};
                for (let userId of uniqueEmployees) {
                    let duration = 0;
                    shiftsMap[userId]?.map((shift: any) => {
                        duration += shift.duree;
                    });
                    let normal = {
                        plans: planningByUser[userId] || [],
                        shifts: shiftsMap[userId] || [],
                        duration,
                    };
                    let deleted: any = [];
                    if (deletedShiftMap?.[userId]?.length) {
                        for (
                            let i = 0;
                            i < deletedShiftMap[userId].length;
                            i++
                        ) {
                            deleted.push({
                                plans: planningByUser[userId] || [],
                                shifts:
                                    [{ ...deletedShiftMap[userId][i] }] || [],
                            });
                        }
                    }
                    let absent: any = [];
                    if (absentShiftMap?.[userId]?.length) {
                        for (
                            let i = 0;
                            i < absentShiftMap[userId].length;
                            i++
                        ) {
                            absent.push({
                                plans: planningByUser[userId] || [],
                                shifts:
                                    [{ ...absentShiftMap[userId][i] }] || [],
                            });
                        }
                    }
                    data[userId] = {
                        normal,
                        deleted,
                        absent,
                    };
                }
                let user = JSON.parse(sessionStorage.getItem("user") || "");
                let toSelectEmployees = [
                    ...users.filter((item: any) => {
                        if (user?.role !== "Administrator") {
                            return (
                                !uniqueEmployees.includes(item._id) &&
                                item.role !== "Administrator"
                            );
                        }
                        return !uniqueEmployees.includes(item._id);
                    }),
                ];
                state.data = data;
                state.toSelectEmployees = toSelectEmployees;
                state.showedEmployees = showedEmployees;
                state.shiftsLoading = false;
                state.error = null;
            },
        );
        builder.addCase(fetchShiftList.rejected, (state: PointeuseState) => {
            state.shiftsLoading = false;
            state.error = "error";
        });
        builder.addCase(fetchShiftWeek.pending, (state: PointeuseState) => {
            state.weekLoading = true;
            state.error = null;
        });
        builder.addCase(
            fetchShiftWeek.fulfilled,
            (state: PointeuseState, action: PayloadAction<any>) => {
                state.weekDetails = action.payload.data;
                state.weekLoading = false;
                state.error = null;
            },
        );
        builder.addCase(fetchShiftWeek.rejected, (state: PointeuseState) => {
            state.weekLoading = false;
            state.error = "error";
        });
        builder.addCase(
            addEmployeeToPointeuse.pending,
            (state: PointeuseState) => {
                state.listLoading = true;
                state.error = null;
            },
        );
        builder.addCase(
            addEmployeeToPointeuse.fulfilled,
            (state: PointeuseState, action: PayloadAction<any>) => {
                let { data } = action.payload;
                let newDataState = { ...state.data };
                newDataState[data.employee] = {
                    shifts: [{ ...removeEmptyBadge([{ ...data }])[0] }] || [],
                    plans: state.plans[data.employee] || [],
                };
                state.data = newDataState;
                // let { defaultShifts, planning } = { ...current(state) };
                // let newShifts: any[] = [];
                // defaultShifts.forEach((s) => {
                //     newShifts.push(s);
                // });
                // newShifts.push({
                //     ...data,
                //     employee: state.employees.find(
                //         (x) => data.employee.toString() === x._id.toString()
                //     ),
                // });
                // state.defaultShifts = newShifts;
                // state.shifts = order(
                //     JSON.parse(JSON.stringify(newShifts)),
                //     planning
                // );
                state.listLoading = false;
                state.error = null;
            },
        );
        builder.addCase(
            addEmployeeToPointeuse.rejected,
            (state: PointeuseState) => {
                state.listLoading = false;
                state.error = "error";
            },
        );
        builder.addCase(updatePointage.pending, (state: PointeuseState) => {
            state.actionLoading = true;
            state.error = null;
        });
        builder.addCase(
            updatePointage.fulfilled,
            (state: PointeuseState, action: PayloadAction<any>) => {
                let { data } = action.payload;
                let newDataState = { ...state.data };
                newDataState[data.employee] = {
                    ...newDataState[data.employee],
                    shifts: data,
                };
                state.data = newDataState;
                // let { defaultShifts, planning } = { ...current(state) };
                // let newShifts = defaultShifts.map((shift: any) => {
                //     if (shift._id === data._id) {
                //         return {
                //             ...data,
                //             employee: shift.employee,
                //         };
                //     }
                //     return shift;
                // });
                // state.defaultShifts = newShifts;
                // state.shifts = order(
                //     JSON.parse(JSON.stringify(newShifts)),
                //     planning
                // );
                state.actionLoading = false;
                state.error = null;
            },
        );
        builder.addCase(updatePointage.rejected, (state: PointeuseState) => {
            state.actionLoading = false;
            state.error = "error";
        });
        builder.addCase(updateShift.pending, (state: PointeuseState) => {
            state.listLoading = true;
            state.error = null;
        });
        builder.addCase(
            updateShift.fulfilled,
            (state: PointeuseState, action: PayloadAction<any>) => {
                let { data } = action.payload;
                let { defaultShifts, planning } = { ...current(state) };
                let newShifts = defaultShifts.map((shift: any) => {
                    if (shift._id === data._id) {
                        return {
                            ...data,
                            employee: shift.employee,
                        };
                    }
                    return shift;
                });
                state.defaultShifts = newShifts;
                state.shifts = order(
                    JSON.parse(JSON.stringify(newShifts)),
                    planning,
                );
                state.listLoading = false;
                state.error = null;
            },
        );
        builder.addCase(updateShift.rejected, (state: PointeuseState) => {
            state.listLoading = false;
            state.error = "error";
        });
        builder.addCase(validateJourney.pending, (state: PointeuseState) => {
            state.actionLoading = true;
            state.error = null;
        });
        builder.addCase(
            validateJourney.fulfilled,
            (state: PointeuseState, action: PayloadAction<any>) => {
                const { data, payload } = action.payload;
                if (state.weekDetails) {
                    let days = [...state.weekDetails.days];
                    let found = false;
                    days = days.map((day) => {
                        if (day.number === payload.dayNumber) {
                            found = true;
                            return {
                                ...day,
                                validated: payload.validated,
                                locked: payload.locked,
                            };
                        }
                        return day;
                    });
                    if (!found) {
                        days = [
                            ...days,
                            {
                                number: payload.dayNumber,
                                validated: payload.validated,
                                locked: payload.locked,
                            },
                        ];
                    }
                    state.weekDetails = { ...state.weekDetails, days };
                }
                state.actionLoading = false;
                state.error = null;
            },
        );
        builder.addCase(validateJourney.rejected, (state: PointeuseState) => {
            state.actionLoading = false;
            state.error = "error";
        });
        builder.addCase(fetchDepartements.pending, (state: PointeuseState) => {
            state.listLoading = true;
            state.error = null;
        });
        builder.addCase(
            fetchDepartements.fulfilled,
            (state: PointeuseState, payload: PayloadAction<any>) => {
                let copyDepartments: any = payload.payload.data;
                for (let i = 0; i < copyDepartments.length; i++) {
                    copyDepartments[i] = { ...copyDepartments[i], pointage: 0 };
                }
                state.departements = [...copyDepartments];

                state.listLoading = false;
                state.error = null;
            },
        );
        builder.addCase(fetchDepartements.rejected, (state: PointeuseState) => {
            state.listLoading = false;
            state.error = "error";
        });
        builder.addCase(validateShift.pending, (state: PointeuseState) => {
            state.listLoading = true;
            state.error = null;
        });
        builder.addCase(validateShift.rejected, (state: PointeuseState) => {
            state.listLoading = false;
            state.error = "error";
        });
        builder.addCase(
            validateShift.fulfilled,
            (state: PointeuseState, payload: PayloadAction<any>) => {
                let { data } = payload.payload;
                let { defaultShifts, planning } = { ...current(state) };
                let newShifts = defaultShifts.map((shift: any) => {
                    if (shift._id === data._id) {
                        return {
                            ...data,
                            employee: shift.employee,
                        };
                    }
                    return shift;
                });
                state.defaultShifts = [...newShifts];
                state.shifts = order(
                    JSON.parse(JSON.stringify(newShifts)),
                    planning,
                );
                state.listLoading = false;
                state.error = null;
            },
        );
    },
});

const PointeuseReducer = pointeuseSlice.reducer;
export const reducersActions = pointeuseSlice.actions;
export const { resetAllPointeuse } = pointeuseSlice.actions;

export default PointeuseReducer;
