import React from "react";
import PinCode from "../../../../components/atoms/PinCode";

export const PinCodeColumnFormatter = (content: any, idx: number, row: any) => {
  return (
    <>
    {content && content !== "" &&
      <PinCode value={content}  />
    }
    </>
  );
};
