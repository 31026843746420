import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UsersState } from "./types";
import { getUsers } from "./service";
import { useAppSelector } from "../../../hooks/useReduxHooks";
import store from "../../store";

const initialState: UsersState = {
    loading: false,
    success: false,
    error: false,
    users: [],
};

const userSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        resetAllUsers: (state) => {
            state = initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUsers.pending, (state: UsersState) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(
            getUsers.fulfilled,
            (state: UsersState, action: PayloadAction<any>) => {
                state.loading = false;
                state.success = true;
                state.error = false;
                state.users = [];
                action.payload.forEach((elem: any) => {
                    state.users?.push({
                        id: elem._id,
                        fname: elem.fname,
                        lname: elem.lname,
                        picture: elem.picture,
                        sites: [...elem.belongsTo.map((site: any) => site._id)],
                    });
                });
            },
        );
        builder.addCase(getUsers.rejected, (state: UsersState) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        });
    },
});

export const { resetAllUsers } = userSlice.actions;
export default userSlice.reducer;
