/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    changeLanguage,
    changeFullScreen,
} from "../redux/features/globalConfig/globalConfigSlice";

export const useLanguage = (lang?: string) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (lang) {
            dispatch(changeLanguage({ language: lang }));
        }
    }, []);

    const language = useSelector((state: any) => state.globalConfig.language);
    const isFullScreen = useSelector(
        (state: any) => state.globalConfig.isFullScreen
    );

    const setLanguage = (language: string) => {
        dispatch(changeLanguage({ language }));
    };
    const switchFullScreen = () => {
        dispatch(changeFullScreen());
    };

    return { language, setLanguage, switchFullScreen, isFullScreen };
};
