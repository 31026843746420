export const getPagesCount = (totalSize : number, sizePerPage: number) : number => {
    return Math.ceil(totalSize / sizePerPage);
};

export const getPages = (page : number, pagesCount: number, paginationSize:number) : Array<number> => {
    let result : Array<number> = [];
    for(let i = 1; i <= pagesCount; ++i){
        result.push(i)
    }
    return  result;
}

export const getRangeData = (data : Array<any>, page : number, sizePerPage : number) => {
    let from = (page - 1) * sizePerPage;
    let to = (page) * sizePerPage;
    return data.slice(from, to);
}

