import React from "react";
import {
  GoogleMap,
  Marker,
  Circle,
  useLoadScript,
  useGoogleMap,
} from "@react-google-maps/api";

const API_KEY = "AIzaSyDKq7ZpOvfOB2GHRcVNotZMlx3XokkS_6Q";
const center = { lat: 48.8566, lng: 2.3522 };

const circleOptions = {
  strokeColor: "#3B82F4",
  strokeWeight: 1,
  fillColor: "#3B82F4",
  fillOpacity: 0.2,
  visible: true,
};

type LatLng = {
  lat: number;
  lng: number;
};

export default function Map({
  position,
  radius,
  radiusUnit = 1000,
  onPositionUpdate,
}: MapInterface) {
  const { isLoaded } = useLoadScript({
    id: "google-map-pointeuse",
    googleMapsApiKey: API_KEY,
  });

  const [marker, setMarker] = React.useState<LatLng>();
  const [circle, setCircle] = React.useState<number>();

  React.useEffect(() => {
    if (isLoaded) {
      if (position) {
        setMarker(position);
      }
      if (radius) {
        setCircle(radius * radiusUnit);
      }
    }
  }, [isLoaded]);

  React.useEffect(() => {
    if (radius) {
      setCircle(radius * radiusUnit);
    }
  }, [radius]);

  React.useEffect(() => {
    if (marker) {
      setMarker(position);
    }
  }, [position]);

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    const lat = event.latLng?.lat();
    const lng = event.latLng?.lng();
    if (lat && lng) {
      onPositionUpdate && onPositionUpdate({ lat, lng });
    }
  };

  if (!isLoaded) {
    return <p>loading...</p>;
  }
  return (
    <GoogleMap
      center={marker || center}
      mapContainerClassName="w-full h-full"
      zoom={10}
      onClick={handleMapClick}
    >
      {marker && (
        <Marker
          position={marker}
          onLoad={(props) => {
          }}
        />
      )}
      {circle && marker && (
        <Circle
          center={{
            lat: marker.lat,
            lng: marker.lng,
          }}
          radius={circle}
          options={circleOptions}
        />
      )}
    </GoogleMap>
  );
}

interface MapInterface {
  position?: LatLng;
  radius?: number;
  radiusUnit?: number;
  onPositionUpdate?: (value: LatLng) => void;
}
