import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { VacationRuleState } from "./type";
import { getAllRulesService, getUsersByRuleService } from "./service";

const initialVacationRuleState: VacationRuleState = {
    loading: false,
    error: false,
    success: false,
    list: null,
    operation: null,
};

const vacationRuleState = createSlice({
    name: "vacationRule",
    initialState: initialVacationRuleState,
    reducers: {
        resetAllVacationRule: (state) => {
            state = initialVacationRuleState;
        },
        resetVacationRule: (state) => {
            state.loading = false;
            state.error = false;
            state.success = false;
            state.list = null;
            state.operation = null;
        },
        resetVacationRuleOperation: (state) => {
            state.operation = null;
        },
        loadRuleToOperation: (state, action) => {
            if (!state.operation) {
                state.operation = {
                    _id: "",
                    designation: "",
                    baseWorkDays: 0,
                    baseEntitlement: 0,
                    restrictedBypassLimitDays: -1,
                    users: [],
                };
            }
            if (action.payload._id) {
                state.operation._id = action.payload._id;
            }
            if (action.payload.designation !== undefined) {
                state.operation.designation = action.payload.designation;
            }
            if (action.payload.baseWorkDays !== undefined) {
                state.operation.baseWorkDays = action.payload.baseWorkDays;
            }
            if (action.payload.baseEntitlement !== undefined) {
                state.operation.baseEntitlement =
                    action.payload.baseEntitlement;
            }
            if (action.payload.restrictedBypassLimitDays !== undefined) {
                state.operation.restrictedBypassLimitDays =
                    action.payload.restrictedBypassLimitDays;
            }
            if (action.payload.users) {
                state.operation.users = action.payload.users;
            }
        },
    },
    extraReducers(builder) {
        // CASE : getAllRulesService
        builder.addCase(
            getAllRulesService.pending,
            (state: VacationRuleState) => {
                state.loading = true;
                state.success = false;
                state.error = false;
            }
        );
        builder.addCase(
            getAllRulesService.fulfilled,
            (state: VacationRuleState, action: PayloadAction<any>) => {
                state.loading = false;
                state.success = true;
                state.error = false;
                state.list = [];
                console.log("action.payload ", action.payload);
                if (action.payload !== undefined) {
                    for (const vacationRule of action.payload) {
                        state.list.push({
                            _id: vacationRule._id,
                            designation: vacationRule.designation,
                            baseWorkDays: vacationRule.baseWorkDays,
                            baseEntitlement: vacationRule.baseEntitlement,
                            restrictedBypassLimitDays:
                                vacationRule.restrictedBypassLimitDays,
                        });
                    }
                }
            }
        );
        builder.addCase(
            getAllRulesService.rejected,
            (state: VacationRuleState) => {
                state.loading = false;
                state.success = false;
                state.error = true;
            }
        );
        //CASE getUsersByRuleService
        builder.addCase(
            getUsersByRuleService.pending,
            (state: VacationRuleState) => {
                state.loading = true;
                state.success = false;
                state.error = false;
            }
        );
        builder.addCase(
            getUsersByRuleService.fulfilled,
            (state: VacationRuleState, action: PayloadAction<any>) => {
                state.loading = false;
                state.success = true;
                state.error = false;
                if (action.payload !== undefined) {
                    for (const user of action.payload) {
                        state.operation?.users?.push(user._id);
                    }
                }
            }
        );
        builder.addCase(
            getUsersByRuleService.rejected,
            (state: VacationRuleState) => {
                state.loading = false;
                state.success = false;
                state.error = true;
            }
        );
    },
});

export const {
    resetVacationRule,
    loadRuleToOperation,
    resetVacationRuleOperation,
    resetAllVacationRule,
} = vacationRuleState.actions;

export default vacationRuleState.reducer;
