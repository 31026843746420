import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import ZToggleButton from "../../atoms/ZToggleButton";
import ZInput from "../../atoms/ZInput";
import ZSelect from "../../atoms/ZSelect";
import DaysPicker from "../../molecules/DaysPicker";
import Button from "../../atoms/ZButton";
import Map from "../../organism/Map";

const radiusOptions = [
  { label: "5km", value: "5" },
  { label: "10km", value: "10" },
  { label: "15km", value: "15" },
  { label: "20km", value: "20" },
];

const DAYS = [
  { label: <FormattedMessage id="MENU.MONDAY_SHORT" />, value: 0 },
  { label: <FormattedMessage id="MENU.TUESDAY_SHORT" />, value: 1 },
  { label: <FormattedMessage id="MENU.WEDNESDAY_SHORT" />, value: 2 },
  { label: <FormattedMessage id="MENU.THURSDAY_SHORT" />, value: 3 },
  { label: <FormattedMessage id="MENU.FRIDAY_SHORT" />, value: 4 },
  { label: <FormattedMessage id="MENU.SATURDAY_SHORT" />, value: 5 },
  { label: <FormattedMessage id="MENU.SUNDAY_SHORT" />, value: 6 },
];

export default function Pointeuse({ pointeuse, onChange }: PointeuseInterface) {
  const intl = useIntl();

  return (
    <div>
      <h2 className="pt-8 text-v-800 font-bold text-9xl">
        {intl.formatMessage({ id: "EMPLOYEE.EDIT.POINTEUSE.TITLE" })}
      </h2>
      <div className="flex justify-between">
        <div className="basis-[100px] grow">
          <div className="flex items-center justify-between mb-8">
            <label className="text-n-800 font-bold text-lg">
              {intl.formatMessage({
                id: "EMPLOYEE.EDIT.POINTEUSE.ACTIVE_BY_LOCALISATION",
              })}
            </label>
            <div>
              <ZToggleButton
                isChecked={pointeuse.active.value}
                onPress={() => {}}
              />
            </div>
          </div>
        </div>
        <div className="basis-[100px] grow" />
      </div>
      <div className="flex flex-wrap gap-10 mb-10">
        <div className="basis-[300px] grow my-10">
          <div className="flex items-center gap-4 mb-8">
            <ZInput
              required={false}
              field={pointeuse.address}
              label={intl.formatMessage({
                id: "EMPLOYEE.EDIT.POINTEUSE.ADDRESS",
              })}
            />
            <div>
              <label className="text-n-400 text-base font-medium mb-1">
                {intl.formatMessage({ id: "EMPLOYEE.EDIT.POINTEUSE.RADIUS" })}
              </label>
              <ZSelect
                containerClasses="!bg-white h-[48px] !min-w-[100px] !border !border-n-100"
                options={radiusOptions}
                onChange={(op) => {
                  onChange("radius", op.value);
                }}
                value={pointeuse.radius.value}
              />
            </div>
          </div>
          <div>
            <label className="inline-block text-n-400 text-base font-medium mb-4">
              {intl.formatMessage({ id: "EMPLOYEE.EDIT.POINTEUSE.DAYS" })}
            </label>
          </div>
          <DaysPicker
            days={DAYS}
            value={pointeuse.days.value}
            onChange={() => {}}
          />
        </div>
        <div className="basis-[300px] min-h-[400px] grow relative">
          <Map
            position={pointeuse.position.value}
            radius={parseFloat(pointeuse.radius.value)}
            onPositionUpdate={(value) => {
              onChange("position", value);
            }}
          />
        </div>
      </div>
      <div className="flex justify-end items-center border-t min-h-[100px]">
        <Button
          title={intl.formatMessage({ id: "MENU.SAVE" })}
          onClick={() => {}}
          variant="primary"
        />
      </div>
    </div>
  );
}

interface PointeuseInterface {
  pointeuse: any;
  onChange: (field: string, value: any) => void;
}
