import * as React from "react";

import TextData from "../../components/atoms/TextData";
import Button from "../../components/atoms/Button";
import DashboardTemplate from "../../components/templates/Dashboard";
import SettingSection from "../../components/organism/SettingSection";
import IntegrationCard from "../../components/molecules/IntegrationCard";
import CommingSoon from "./Elements/CommingSoon";
import NoAccess from "./Elements/NoAccess";
import { useBranch } from "../../hooks/useBranch";

import Input from "../../components/atoms/Input";
import ToggleButton from "../../components/atoms/Toggle";
import Radio from "../../components/atoms/Radio";

import Jalia from "./icons/jalia.svg";
import Popina from "./icons/popina.svg";
import Silae from "./icons/silae.svg";

function Caisses() {
    return (
        <div className='caisses-container'>
            <IntegrationCard
                textButton='Activer'
                activated={true}
                logo={Jalia}
                body={{
                    title: "Jalia",
                    description:
                        "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco",
                }}
            />
            <IntegrationCard
                textButton='Désactiver'
                activated={false}
                logo={Popina}
                body={{
                    title: "Popina",
                    description:
                        "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco",
                }}
            />
        </div>
    );
}

export default function Integration(props: IntegrationProps) {
    const {
        settings,
        commingSoon,
        includeButtons,
        submitButtonText,
        handleSubmitButton,
        cancelButtonText,
        handleCancelButton,
    } = props;
    const { selectedBranchSettings } = useBranch();

    return (
        <div className='integration-container'>
            {!selectedBranchSettings._id && <NoAccess />}
            {commingSoon && <CommingSoon />}
            <div
                style={{
                    height: "calc(100vh - 310px)",
                    overflowY: "auto",
                }}
            >
                {settings?.map((elem, index) => {
                    return (
                        <SettingSection
                            groupTitle={elem.headerTitle}
                            groupDescription={elem.headerDescription}
                            configOptions={elem.configOptions}
                        />
                    );
                })}
            </div>
            {includeButtons && (
                <div className='settings-footer'>
                    <Button
                        style={{
                            backgroundColor: "#EDEFF5",
                            color: "#2A346D",
                            fontSize: "14px",
                        }}
                        backgroundColor='#EDEFF5'
                        textColor='#2A346D'
                        onClick={handleCancelButton}
                        width='120px'
                        height='40px'
                    >
                        {cancelButtonText}
                    </Button>
                    <Button
                        style={{
                            backgroundColor: "#2A8BAB",
                            color: "white",
                            fontSize: "14px",
                        }}
                        backgroundColor='#2A8BAB'
                        textColor='white'
                        onClick={handleSubmitButton}
                        width='120px'
                        height='40px'
                    >
                        {/* {submitButtonText} */}
                        Enregistrer
                    </Button>
                </div>
            )}
        </div>
    );
}

interface ConfigOptionTemplate {
    optionTitle: string;
    titleColor?: string;
    titleSize?: string;
    titleWeight?: string;
    optionDescription: string;
    descriptionColor?: string;
    descriptionSize?: string;
    descriptionWeight?: string;
    optionInput: React.ReactNode;
}
interface IntegrationProps {
    settings?: {
        headerTitle?: string;
        headerDescription?: string;
        configOptions?: React.ReactNode | ConfigOptionTemplate[];
    }[];
    commingSoon?: boolean;
    includeButtons?: boolean;
    submitButtonText?: string;
    handleSubmitButton?: () => void;
    cancelButtonText?: string;
    handleCancelButton?: () => void;
}
Integration.defaultProps = {
    settings: [
        {
            headerTitle: "Caisses",
            headerDescription:
                "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
            configOptions: [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",

                    optionInput: <Caisses />,
                },
            ],
        },
        {
            headerTitle: "Logiciels de paie",
            headerDescription:
                "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
            configOptions: [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionInput: (
                        <IntegrationCard
                            textButton='Activer'
                            activated={true}
                            logo={Silae}
                            body={{
                                title: "Silae",
                                description:
                                    "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco",
                            }}
                        />
                    ),
                },
            ],
        },
    ],
    commingSoon: true,
    includeButtons: true,
    submitButtonText: "Modifier",
    handleSubmitButton: () => {},
    cancelButtonText: "Annuler",
    handleCancelButton: () => {},
};
