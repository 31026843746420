import React from "react";
import moment from "moment";
import ReactCountryFlag from "react-country-flag";
import Flag from "react-world-flags";
import {
    User,
    Clock,
    Contract,
    Bag,
    BirthdayCake,
    Female,
    Male,
    BordereArrowLeft,
    BorderArrowRight,
    MapPin,
} from "../../../../../Icons";
import Theme from "../../../../../tailwind-theme";
import { Link } from "react-router-dom";
import { ContractProps } from "../../../../../redux/features/employees/types";
import { HRProps } from "../../../../../redux/features/employees/types";
import { useIntl } from "react-intl";
import { generatePicUrl } from "../../../../../_helpers/PictureHelpers";

export function CellColumnFormatter(
    content: any,
    rowId: number,
    row: any,
    props: any
) {
    //const navigate = useNavigate();
    const contract: ContractProps = row.contract;
    const hr: HRProps = row.humanResource;
    const intl = useIntl();

    return (
        <div className='flex gap-4'>
            <div
                className={`rounded h-[47px] w-[47px] flex items-center justify-center ${
                    props.rows[rowId] === true
                        ? "bg-v-100-op-2"
                        : "bg-t-100-op-2"
                }`}
            >
                {row.picture && row.picture !== "" ? (
                    <img
                        src={generatePicUrl(row.picture)}
                        alt='user-image'
                        className='w-full h-full rounded'
                    />
                ) : (
                    <div className='text-n-700 font-bold'>
                        {row.fname[0]} {row.lname[0]}
                    </div>
                )}
            </div>
            <div className='w-full'>
                <div className='h-[47px] mb-3 flex items-center '>
                    <Link
                        to={`/employees/${row._id}/edit`}
                        className=' text-n-700 font-bold cursor-pointer text-2xl hover:text-t-500 '
                    >
                        {row.fname} {row.lname}
                    </Link>
                </div>

                <div className='flex justify-between'>
                    <div className='flex flex-col gap-2  w-[200px]'>
                        <span className='block text-base text-n-600 font-medium'>
                            {contract &&
                            contract.dateDebutContract &&
                            contract.dateDebutContract !== "" ? (
                                moment(contract.dateDebutContract).format(
                                    "DD/MM/YYYY"
                                )
                            ) : (
                                <i style={{ color: "#888888" }}>
                                    {intl.formatMessage({
                                        id: "REGISTER.CONTRACT.START_DATE.EMPTY",
                                    })}
                                </i>
                            )}
                        </span>
                        <div className='flex gap-2'>
                            {contract && (
                                <>
                                    {contract.dateFinContract !== "" &&
                                    (moment(contract?.dateFinContract).isBefore(
                                        moment(),
                                        "day"
                                    ) ||
                                        moment(
                                            contract?.dateFinContract
                                        ).isSame(moment(), "day")) ? (
                                        <>
                                            <span className='text-base text-n-600 font-medium'>
                                                {intl.formatMessage({
                                                    id: "CONTRACT.TERMINATED",
                                                })}
                                            </span>

                                            <div>
                                                <BorderArrowRight />
                                            </div>
                                        </>
                                    ) : (contract.dateDebutContract &&
                                          moment(
                                              contract?.dateDebutContract
                                          ).isBefore(moment(), "day")) ||
                                      moment(
                                          contract?.dateDebutContract
                                      ).isSame(moment(), "day") ? (
                                        <>
                                            <span className='text-base text-n-600 font-medium'>
                                                {intl.formatMessage({
                                                    id: "CONTRACT.IN.PROGRESS",
                                                })}
                                            </span>
                                            <div>
                                                <BordereArrowLeft />
                                            </div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                        </div>
                        <span className='block text-base text-n-600 font-medium'>
                            {contract &&
                            contract.dateFinContract &&
                            contract.dateFinContract !== "" ? (
                                moment(contract.dateFinContract).format(
                                    "DD/MM/YYYY"
                                )
                            ) : (
                                <i style={{ color: "#888888" }}>
                                    {/* {intl.formatMessage({
                                        id: "REGISTER.CONTRACT.END_DATE.EMPTY",
                                    })} */}
                                </i>
                            )}
                        </span>
                    </div>
                    <div className='flex flex-col gap-2  w-[200px]'>
                        <div className='flex items-center gap-4'>
                            <Contract
                                color={
                                    props.rows[rowId] === true
                                        ? Theme.colors["v-500"]
                                        : Theme.colors["t-500"]
                                }
                            />
                            <span className='block text-base text-n-600 font-medium'>
                                {contract &&
                                contract.type &&
                                contract.type !== "" ? (
                                    contract.type
                                ) : (
                                    <i style={{ color: "#888888" }}>
                                        {intl.formatMessage({
                                            id: "REGISTER.CONTRACT.TYPE.EMPTY",
                                        })}
                                    </i>
                                )}
                            </span>
                        </div>
                        <div className='flex items-center  gap-4  w-[200px]'>
                            <div>
                                <Clock
                                    width={18}
                                    height={18}
                                    color={
                                        props.rows[rowId] === true
                                            ? Theme.colors["v-500"]
                                            : Theme.colors["t-500"]
                                    }
                                />
                            </div>

                            <span className='block text-base text-n-600 font-medium'>
                                {contract &&
                                contract.tempsDeTravailHebdomadaire &&
                                contract.tempsDeTravailHebdomadaire !== "" ? (
                                    `${
                                        contract.tempsDeTravailHebdomadaire
                                    } ${intl.formatMessage({
                                        id: "MENU.HOURS",
                                    })}`
                                ) : (
                                    <i style={{ color: "#888888" }}>
                                        {intl.formatMessage({
                                            id: "REGISTER.WORK_HOURS.EMPTY",
                                        })}
                                    </i>
                                )}
                            </span>
                        </div>
                    </div>
                    <div className='flex gap-4  w-[200px]'>
                        <div>
                            <Bag
                                color={
                                    props.rows[rowId] === true
                                        ? Theme.colors["v-500"]
                                        : Theme.colors["t-500"]
                                }
                            />
                        </div>

                        <div className='flex flex-col gap-2'>
                            <span className='block text-base text-n-600 font-medium'>
                                {contract &&
                                contract.emploi &&
                                contract.emploi !== "" ? (
                                    contract.emploi
                                ) : (
                                    <i style={{ color: "#888888" }}>
                                        {intl.formatMessage({
                                            id: "REGISTER.POSTE.EMPTY",
                                        })}
                                    </i>
                                )}
                            </span>
                            <span className='block text-base text-n-600 font-medium'>
                                {contract &&
                                contract.niveau &&
                                contract.niveau !== "" ? (
                                    contract.niveau
                                ) : (
                                    <i style={{ color: "#888888" }}>
                                        {intl.formatMessage({
                                            id: "REGISTER.LEVEL.EMPTY",
                                        })}
                                    </i>
                                )}
                            </span>
                            <span className='block text-base text-n-600 font-medium'>
                                {contract &&
                                contract.echelon &&
                                contract.echelon !== "" ? (
                                    contract.echelon
                                ) : (
                                    <i style={{ color: "#888888" }}>
                                        {intl.formatMessage({
                                            id: "REGISTER.ECHELON.EMPTY",
                                        })}
                                    </i>
                                )}
                            </span>
                        </div>
                    </div>
                    <div className='flex flex-col gap-2  w-[200px]'>
                        <div className='flex items-center gap-4'>
                            <div>
                                <BirthdayCake
                                    color={
                                        props.rows[rowId] === true
                                            ? Theme.colors["v-500"]
                                            : Theme.colors["t-500"]
                                    }
                                />
                            </div>

                            <span className='block text-base text-n-600 font-medium'>
                                {hr && hr.birthday && hr.birthday !== "" ? (
                                    moment(hr.birthday).format("DD/MM/YYYY")
                                ) : (
                                    <i style={{ color: "#888888" }}>
                                        {intl.formatMessage({
                                            id: "REGISTER.BIRTHDAY.EMPTY",
                                        })}
                                    </i>
                                )}
                            </span>
                        </div>

                        <div className='flex items-center gap-4'>
                            {hr && hr.nationality && hr.nationality !== "" ? (
                                <>
                                    <div>
                                        {hr.gender === "male" ? (
                                            <Male />
                                        ) : (
                                            <Female />
                                        )}
                                    </div>
                                    <span className='block text-base text-n-600 font-medium'>
                                        {hr.gender === "male"
                                            ? intl.formatMessage({
                                                  id: "MENU.MALE",
                                              })
                                            : intl.formatMessage({
                                                  id: "MENU.FEMALE",
                                              })}
                                    </span>
                                </>
                            ) : (
                                <span
                                    className='block text-base text-n-600 font-medium'
                                    style={{ color: "#888888" }}
                                >
                                    {intl.formatMessage({
                                        id: "REGISTER.GENDER.EMPTY",
                                    })}
                                </span>
                            )}
                        </div>
                        <div className='flex items-center gap-4'>
                            {hr && hr.nationality && hr.nationality !== "" ? (
                                <>
                                    <div>
                                        <Flag code={hr.nationality} />
                                        {/* <ReactCountryFlag
                                            countryCode={hr.nationality}
                                            svg
                                            style={{
                                                width: "18px",
                                                height: "12px",
                                            }}
                                        /> */}
                                    </div>

                                    <span className='block text-base text-n-600 font-medium'>
                                        {hr.nationality}
                                    </span>
                                </>
                            ) : (
                                <span
                                    className='block text-base text-n-600 font-medium'
                                    style={{ color: "#888888" }}
                                >
                                    {intl.formatMessage({
                                        id: "REGISTER.NATIONALITY.EMPTY",
                                    })}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className='flex flex-col gap-2  w-[200px]'>
                        <div className='flex gap-4'>
                            <div>
                                <MapPin
                                    color={
                                        props.rows[rowId] === true
                                            ? Theme.colors["v-500"]
                                            : Theme.colors["t-500"]
                                    }
                                />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <span className='block text-base text-n-600 font-medium'>
                                    {hr && hr.adress && hr.adress !== "" ? (
                                        hr.adress
                                    ) : (
                                        <i style={{ color: "#888888" }}>
                                            {intl.formatMessage({
                                                id: "REGISTER.ADRESS.EMPTY",
                                            })}
                                        </i>
                                    )}
                                </span>
                                <span className='block text-base text-n-600 font-medium'>
                                    {hr && hr.zipCode && hr.zipCode !== "" ? (
                                        hr.zipCode
                                    ) : (
                                        <i style={{ color: "#888888" }}>
                                            {intl.formatMessage({
                                                id: "REGISTER.ZIPCODE.EMPTY",
                                            })}
                                        </i>
                                    )}
                                </span>
                                <span className='block text-base text-n-600 font-medium'>
                                    {hr && hr.city && hr.city !== "" ? (
                                        hr.city
                                    ) : (
                                        <i style={{ color: "#888888" }}>
                                            {intl.formatMessage({
                                                id: "REGISTER.CITY.EMPTY",
                                            })}
                                        </i>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
