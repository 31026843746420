import * as React from "react";
import { useNavigate } from "react-router-dom";
import Startup from "../../components/templates/Startup";
import Slide0 from "./Slide0";
import Slide1 from "./Slide1";
import Slide2 from "./Slide2";
import Slide3 from "./Slide3";
import Slide4 from "./Slide4";
import { useBranch } from "../../hooks/useBranch";
import { ROUTER } from "../../constants/env";
import { useSchedule } from "../../hooks/useSchedule";
import { useTimeTracker } from "../../hooks/useTimeTracker";
import Swal from "sweetalert2";

function StartupPage() {
    const { saveSelectedBranch, selectedBranchSettings, updateBranchSettings } =
        useBranch();
    const { updateScheduleSettings } = useSchedule();
    const { updateTimeTracker } = useTimeTracker();
    const [branchs, setbranchs] = React.useState([]);
    React.useEffect(() => {
        const sessionBranchs = sessionStorage.getItem("branchs");
        let listOfBranchs = [];
        if (sessionBranchs) {
            listOfBranchs = JSON.parse(sessionBranchs);
            setbranchs(listOfBranchs);
        }

        if (listOfBranchs.length > 0) {
            sessionStorage.setItem(
                "selectedBranch",
                JSON.stringify(listOfBranchs[0])
            );
            saveSelectedBranch(listOfBranchs[0]);
        }
    }, []);

    const [step, setstep] = React.useState(0);
    const [branchNumber, setbranchNumber] = React.useState(1);
    const handleMove = (count: number) => {
        const nextMove = step + count;
        if (nextMove < pages.length) {
            setstep(nextMove);
        }
        if (nextMove === pages.length) {
            if (branchs.length > branchNumber) {
                sessionStorage.setItem(
                    "selectedBranch",
                    JSON.stringify(branchs[branchNumber])
                );
                saveSelectedBranch(branchs[branchNumber]);
                setbranchNumber(branchNumber + 1);
                setstep(0);
            }
        }
    };
    const handleBranchSelection = (branch: any) => {
        sessionStorage.setItem("selectedBranch", JSON.stringify(branch));
        saveSelectedBranch(branch);
    };
    const handleSaveBranch = () => {
        updateBranchSettings();
        Swal.fire({
            title: "Cette opération a été effectuée avec succès",
            color: "#2A8BAB",
            text: "",
            icon: "success",
            confirmButtonText: "Oui",
            confirmButtonColor: "#2A8BAB",
        });
    };
    const handleSavePlanning = () => {
        updateScheduleSettings();
        Swal.fire({
            title: "Cette opération a été effectuée avec succès",
            color: "#2A8BAB",
            text: "",
            icon: "success",
            confirmButtonText: "Oui",
            confirmButtonColor: "#2A8BAB",
        });
    };
    const handleSaveTimeTracker = () => {
        updateTimeTracker();
        Swal.fire({
            title: "Cette opération a été effectuée avec succès",
            color: "#2A8BAB",
            text: "",
            icon: "success",
            confirmButtonText: "Oui",
            confirmButtonColor: "#2A8BAB",
        });
    };
    const navigate = useNavigate();
    const handleComplete = () => {
        if (branchs.length === branchNumber) {
            sessionStorage.removeItem("selectedBranch");
            saveSelectedBranch();
            navigate(ROUTER.SETTINGS.BRANCH);
        }
    };
    const pages = [
        {
            element: (
                <Slide0
                    handleSelectBranch={handleBranchSelection}
                    branchs={branchs}
                    defaultBranch={selectedBranchSettings}
                    handleMove={handleMove}
                />
            ),
        },
        {
            element: (
                <Slide1
                    handleSaveButton={handleSaveBranch}
                    handleMove={handleMove}
                    currentStep={step}
                />
            ),
        },
        {
            element: (
                <Slide2
                    handleSaveButton={handleSavePlanning}
                    handleMove={handleMove}
                    currentStep={step}
                />
            ),
        },
        {
            element: (
                <Slide3
                    handleSaveButton={handleSaveTimeTracker}
                    handleMove={handleMove}
                />
            ),
        },
        {
            element: (
                <Slide4
                    handleDoneButton={handleComplete}
                    handleMove={handleMove}
                />
            ),
        },
    ];
    return <Startup>{pages[step].element}</Startup>;
}

export default StartupPage;
