import React from "react";
import { Close } from "../../../Icons";

export default function ModalHeader({
  title,
  close,
  onHide,
  headerClasses,
}: ModalHeaderInterface) {
  return (
    <div
      className={`flex justify-between items-center rounded-t-xl bg-n-0-op-2 h-80px border-b p-4 ${headerClasses}`}
    >
      <h2 className="text-3xl font-bold text-n-700">{title}</h2>
      {close && (
        <div className="cursor-pointer text-n-200 hover:text-n-400" onClick={onHide}>
          <Close />
        </div>
      )}
    </div>
  );
}

interface ModalHeaderInterface {
  title: string;
  close?: boolean;
  onHide?: () => void;
  headerClasses?: string;
}
