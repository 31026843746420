export const ChevronRight = ({
  width = 20,
  height = 20,
  color = "currentColor",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9499 9.40796L8.23328 4.69962C8.15581 4.62152 8.06364 4.55952 7.96209 4.51721C7.86054 4.47491 7.75162 4.45312 7.64161 4.45312C7.5316 4.45312 7.42268 4.47491 7.32113 4.51721C7.21958 4.55952 7.12741 4.62152 7.04994 4.69962C6.89474 4.85576 6.80762 5.06697 6.80762 5.28712C6.80762 5.50728 6.89474 5.71849 7.04994 5.87462L11.1749 10.0413L7.04994 14.1663C6.89474 14.3224 6.80762 14.5336 6.80762 14.7538C6.80762 14.9739 6.89474 15.1852 7.04994 15.3413C7.12712 15.42 7.21916 15.4827 7.32072 15.5256C7.42229 15.5685 7.53135 15.5908 7.64161 15.5913C7.75187 15.5908 7.86094 15.5685 7.9625 15.5256C8.06406 15.4827 8.1561 15.42 8.23328 15.3413L12.9499 10.633C13.0345 10.5549 13.102 10.4602 13.1482 10.3548C13.1944 10.2494 13.2182 10.1355 13.2182 10.0205C13.2182 9.90537 13.1944 9.79153 13.1482 9.68612C13.102 9.5807 13.0345 9.48599 12.9499 9.40796V9.40796Z"
      fill={color}
    />
  </svg>
);

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}
