import * as React from "react";
import Swal from "sweetalert2";
import SelectInput from "../../../components/atoms/SelectInput";
import TextData from "../../../components/atoms/TextData";
import ToggleButton from "../../../components/atoms/Toggle";
import ListOfColors from "../../../components/molecules/ListOfColors";
import AvancesList from "../Elements/ScheduleElements/AvancesList";
import MultiPlanningTickets from "../Elements/ScheduleElements/MultiPlanningTickets";
import ServicesList from "../Elements/ScheduleElements/ServicesList";
import TicketsList from "../Elements/ScheduleElements/TicketsList";
import { useSchedule } from "../../../hooks/useSchedule";
import NewDepartmentCard from "../../../components/organism/NewDepartmentCard";
import { useUser } from "../../../hooks/useUsers";
import { useBranch } from "../../../hooks/useBranch";
import NewShiftTicketCard from "../../../components/organism/NewShiftTicketCard";
import NewServiceCard from "../../../components/organism/NewServiceCard";
import NewReceiptCard from "../../../components/organism/NewReceiptCard";
import { ROUTER } from "../../../constants/env";
import { useEmployees } from "../../../hooks/useEmployees";

export const ScheduleProps = () => {
    const { selectedBranchSettings, Settings } = useBranch();
    const {
        scheduleSettings,
        saveScheduleSettings,
        multiplePlannings,
        tickets,
        services,
        bonusTypes,
        absences,
        selectedPlan,
        selectedTicket,
        selectedService,
        selectedBonusType,
        selectedAbsence,
        saveSelectedPlan,
        saveSelectedTicket,
        saveSelectedService,
        saveSelectedBonusType,
        saveSelectedAbsence,
        updateMultiPlanning,
        updateTicket,
        updateService,
        createBonusType,
        updateBonusType,
        updateAbsence,
        deleteMultiPlanning,
        deleteTicket,
        deleteService,
        deleteBonusType,
    } = useSchedule();
    const { users, getUsersList } = useUser();
    const {
        employees,
        getEmployees,
        getPrimesTypes,
        onUpdateFilterBy,
        filterBy,
    } = useEmployees();
    React.useEffect(() => {
        getEmployees({ archived: false });
    }, []);

    React.useEffect(() => {
        getEmployees({ archived: false });
    }, [selectedBranchSettings]);

    React.useEffect(() => {
        onUpdateFilterBy({
            ...filterBy,
            name: "",
            niveau: [],
            site: selectedBranchSettings._id
                ? selectedBranchSettings._id.toString()
                : undefined,
        });
    }, [selectedBranchSettings]);
    const myUsers = () => {
        return (
            employees
                // .filter((emp: any) => {
                //     for (let site of emp.belongsTo) {
                //         if (site._id === Settings.site) {
                //             return true;
                //         }
                //     }
                //     return false;
                // })
                .map((user: any) => {
                    return {
                        id: user._id,
                        name: `${user.fname} ${user.lname}`,
                        picture: ROUTER.STATIC_IMG(user.picture),
                        isSelected: false,
                    };
                })
        );
    };

    // TODO:    Planning Mulitple       TODO:
    const handleListOfPlannings = () => {
        let result: any[] = [];
        if (multiplePlannings.length > 0) {
            multiplePlannings.forEach((planning: any) => {
                result.push({
                    id: planning._id,
                    name: planning.name,
                });
            });
        }
        return result;
    };
    const [listOfPlannings, setListOfPlannings] = React.useState(
        handleListOfPlannings(),
    );
    React.useEffect(() => {
        setListOfPlannings(handleListOfPlannings());
    }, [multiplePlannings]);

    const [createNewPlanningStatus, setCreateNewPlanningStatus] =
        React.useState(false);
    const [newEmployees, setNewEmployees] = React.useState<any>([]);
    React.useEffect(() => {
        if (!createNewPlanningStatus) {
            setNewEmployees([]);
            saveSelectedPlan({
                _id: null,
                name: "",
                employees: [],
                site: "",
            });
        }
    }, [createNewPlanningStatus]);
    const handleNewPlanningName = (name: string) => {
        saveSelectedPlan({ _id: null, name });
    };
    const handleEmployeeToNewPlanning = (employee: any) => {
        if (employee.checked) {
            setNewEmployees((prevEmployees: any) => {
                const newEmp = [
                    ...prevEmployees,
                    {
                        id: employee.id,
                    },
                ];
                saveSelectedPlan({
                    _id: null,
                    employees: newEmp,
                });
                return newEmp;
            });
        } else {
            setNewEmployees((prevEmployees: any) => {
                const newEmp = newEmployees.filter(
                    (emp: any) => emp.id !== employee.id,
                );
                saveSelectedPlan({
                    _id: null,
                    employees: newEmp,
                });
                return newEmp;
            });
        }
    };
    const handleSelectAllToNewPlanning = (selectAll: any) => {
        if (selectAll.checked) {
            setNewEmployees(() => {
                const empIDs = users
                    .filter((emp: any) => emp.sites.includes(filterBy.site))

                    .map((user: any) => user.id);
                saveSelectedPlan({
                    _id: null,
                    employees: empIDs,
                });
                return empIDs;
            });
        } else {
            setNewEmployees(() => {
                saveSelectedPlan({
                    _id: null,
                    employees: [],
                });
                return [];
            });
        }
    };
    const handleCreateNewPlanning = () => {
        if (selectedPlan.name === "" && selectedPlan.employees.length === 0) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Veuillez remplir les champs",
            });
            return;
        }
        if (selectedPlan._id === null) {
            const newPlan = {
                name: selectedPlan.name,
                employees: selectedPlan.employees.map((emp: any) => emp.id),
                id: null,
                siteId: selectedBranchSettings._id,
            };
            updateMultiPlanning(newPlan);
            setCreateNewPlanningStatus(false);
        }
    };
    const handleRemoveNewPlanning = () => {
        setCreateNewPlanningStatus(false);
    };

    const [updatePlanningStatus, setUpdatePlanningStatus] =
        React.useState(false);
    React.useEffect(() => {
        if (!updatePlanningStatus) {
            saveSelectedPlan({
                _id: null,
                name: "",
                employees: [],
                site: "",
            });
        }
    }, [updatePlanningStatus]);
    React.useEffect(() => {
        getUsersList();
    }, [selectedBranchSettings]);
    const handleUpdatePlanningTicket = async (idPlanning: string) => {
        try {
            setUpdatePlanningStatus(true);
            const selectedPlanning = multiplePlannings.find(
                (plan: any) => plan._id === idPlanning,
            );

            const emp = users
                .filter((emp: any) => emp.sites.includes(filterBy.site))
                .map((employee: any) => {
                    const isSelected =
                        selectedPlanning?.employees?.includes(employee.id) ||
                        false;
                    return {
                        id: employee.id,
                        name: `${employee.fname} ${employee.lname}`,
                        picture: ROUTER.STATIC_IMG(employee.picture),
                        isSelected,
                    };
                });
            saveSelectedPlan({
                _id: idPlanning,
                employees: emp,
                name: selectedPlanning?.name,
            });
        } catch (error) {
            console.error(error);
            setUpdatePlanningStatus(true);
        }
    };
    const handleUpdatedPlanningName = (name: string) => {
        saveSelectedPlan({ name });
    };
    const handleEmployeeUpdatedPlanning = (employee: any) => {
        const newEmp = selectedPlan.employees.map((emp: any) => {
            if (emp.id === employee.id) {
                //selectedEmployee
                return {
                    id: emp.id,
                    name: emp.name,
                    picture: emp.picture,
                    isSelected: employee.checked,
                };
            }
            return emp;
        });
        saveSelectedPlan({
            employees: newEmp,
        });
    };
    const handleUpdatedSelectAllEmployees = (selectAll: any) => {
        const newEmp = selectedPlan.employees.map((emp: any) => {
            return {
                id: emp.id,
                name: emp.name,
                picture: emp.picture,
                isSelected: selectAll.checked,
            };
        });
        saveSelectedPlan({
            employees: newEmp,
        });
    };
    const handleSumbitUpdatePlanning = () => {
        if (selectedPlan._id !== null) {
            const newPlan = {
                name: selectedPlan.name,
                employees: selectedPlan.employees
                    .filter((emp: any) => {
                        if (emp.isSelected) {
                            return emp.id;
                        }
                    })
                    .map((emp: any) => emp.id),
                id: selectedPlan._id,
                siteId: selectedBranchSettings._id,
            };
            updateMultiPlanning(newPlan);
            setUpdatePlanningStatus(false);
        }
    };
    const handleCancelUpdatePlanning = () => {
        setUpdatePlanningStatus(false);
    };

    const [deletePlanningStatus, setDeletePlanningStatus] =
        React.useState(false);
    const handleDeletePlanningTicket = (idPlanning: string) => {
        // setDeletePlanningStatus(true);
        Swal.fire({
            title: "Êtes-vous certain(e) de vouloir supprimer ce planning ?",
            color: "#2A8BAB",
            showCancelButton: true,
            confirmButtonText: "Oui",
            confirmButtonColor: "#2A8BAB",
            cancelButtonText: "Non",
            cancelButtonColor: "#EDEFF5",
        }).then((result) => {
            if (result.isConfirmed) {
                const selectedPlanning = multiplePlannings.find(
                    (plan: any) => plan._id === idPlanning,
                );
                saveSelectedPlan({
                    _id: selectedPlanning.id,
                    employees: selectedPlanning.employees,
                    name: selectedPlanning.name,
                });
                deleteMultiPlanning(idPlanning);
                Swal.fire({
                    title: "Cette opération a été effectuée avec succès",
                    color: "#2A8BAB",
                    text: "",
                    icon: "success",
                    confirmButtonText: "Oui",
                    confirmButtonColor: "#2A8BAB",
                });
            } else if (result.isDismissed) {
                Swal.fire({
                    title: "Cette opération a été annulée",
                    color: "#2A8BAB",
                    text: "",
                    icon: "info",
                });
            }
        });
    };
    // TODO:    Etiquettes       TODO:
    const handleListOfTickets = () => {
        let result: any[] = [];
        if (tickets.length > 0) {
            tickets.forEach((ticket: any) => {
                result.push({
                    id: ticket._id,
                    name: ticket.name,
                    backgroundColor: ticket.color,
                    fromHour: ticket.fromHour,
                    toHour: ticket.toHour,
                });
            });
        }
        return result;
    };
    const [listOfTickets, setListOfTickets] = React.useState(
        handleListOfTickets(),
    );
    React.useEffect(() => {
        setListOfTickets(handleListOfTickets());
    }, [tickets]);
    const [isCreateNewTicket, setisCreateNewTicket] = React.useState(false);
    React.useEffect(() => {
        if (!isCreateNewTicket) {
            saveSelectedTicket({
                _id: null,
                name: "",
                color: "",
                site: "",
            });
        }
    }, [isCreateNewTicket]);
    const handleNewTicketName = (ticketName: string) => {
        saveSelectedTicket({ _id: null, name: ticketName });
    };
    const handleNewTicketColorSelection = (color: string) => {
        saveSelectedTicket({ _id: null, color });
    };
    const handleCreateNewTicket = (data: any) => {
        // console.log("fffffffff");
        // console.log(" sttt  ff 88 = ", data);
        if (data.name === "" || data.color === "") {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Veuillez remplir les champs",
            });
            return;
        }
        if (data.name !== "" && data.color !== "") {
            const newTicket = {
                name: data.name,
                color: data.color,
                id: data.id || null,
                siteId: selectedBranchSettings._id,
                fromHour: data.fromHour,
                toHour: data.toHour,
            };
            updateTicket(newTicket);
            setisCreateNewTicket(false);
        }
    };
    const handleRemoveNewTicket = () => {
        setisCreateNewTicket(false);
    };

    const [updateTicketStatus, setUpdateTicketStatus] = React.useState(false);
    React.useEffect(() => {
        if (!updateTicketStatus) {
            saveSelectedTicket({
                _id: null,
                name: "",
                color: "",
                site: "",
            });
        }
    }, [updateTicketStatus]);
    const handleUpdateTicket = (idTicket: string) => {
        try {
            setUpdateTicketStatus(true);
            const selectedTicket = tickets.find(
                (ticket: any) => ticket._id === idTicket,
            );
            saveSelectedTicket({
                _id: selectedTicket?._id,
                name: selectedTicket?.name,
                color: selectedTicket?.color,
                fromHour: selectedTicket?.fromHour,
                toHour: selectedTicket?.toHour,
            });
        } catch (error) {
            console.error(error);
            setUpdateTicketStatus(false);
        }
    };
    const handleUpdatedTicketName = (name: string) => {
        saveSelectedTicket({ name });
    };
    const handleUpdatedTicketColor = (color: string) => {
        saveSelectedTicket({ color });
    };
    const handleSubmitUpdatedTicket = () => {
        console.log(" 777777");
        if (
            selectedTicket._id !== null &&
            selectedTicket.name !== "" &&
            selectedTicket.color !== ""
        ) {
            const updatedTicket = {
                name: selectedTicket.name,
                color: selectedTicket.color,
                id: selectedTicket._id,
                siteId: selectedBranchSettings._id,
            };
            updateTicket(updatedTicket);
            setUpdateTicketStatus(false);
        }
    };
    const handleCancelUpdatedTicket = () => {
        setUpdateTicketStatus(false);
    };
    const [deleteTicketStatus, setDeleteTicketStatus] = React.useState(false);
    const handleDeleteTicket = (idTicket: string) => {
        // console.log("5555555555");
        // setDeletePlanningStatus(true);
        Swal.fire({
            title: "Êtes-vous certain(e) de vouloir supprimer cette étiquette ?",
            color: "#2A8BAB",
            showCancelButton: true,
            confirmButtonText: "Oui",
            confirmButtonColor: "#2A8BAB",
            cancelButtonText: "Non",
            cancelButtonColor: "#EDEFF5",
        }).then((result) => {
            if (result.isConfirmed) {
                const selectedTicket = tickets.find(
                    (ticket: any) => ticket._id === idTicket,
                );
                saveSelectedTicket({
                    _id: selectedTicket?._id,
                    name: selectedTicket?.name,
                    color: selectedTicket?.color,
                });
                deleteTicket(idTicket);
                Swal.fire({
                    title: "cette opération a été effectuée avec succès",
                    color: "#2A8BAB",
                    text: "",
                    icon: "success",
                    confirmButtonText: "Oui",
                    confirmButtonColor: "#2A8BAB",
                });
            } else if (result.isDismissed) {
                Swal.fire({
                    title: "Cette opération a été annulée",
                    color: "#2A8BAB",
                    text: "",
                    icon: "info",
                });
            }
        });
    };

    // TODO:    Services       TODO:
    const handleListOfServices = () => {
        let result: any[] = [];
        if (services.length > 0) {
            services.forEach((service: any) => {
                result.push({
                    id: service.id,
                    name: service.name,
                    from: service.from,
                    to: service.to,
                });
            });
        }
        return result;
    };
    const [listOfServices, setListOfServices] = React.useState(
        handleListOfServices(),
    );
    React.useEffect(() => {
        setListOfServices(handleListOfServices());
    }, [services]);
    const [isCreateNewService, setisCreateNewService] = React.useState(false);
    React.useEffect(() => {
        if (!isCreateNewService) {
            saveSelectedService({
                id: null,
                name: "",
                from: "",
                to: "",
                siteId: "",
            });
        }
    }, [isCreateNewService]);
    const handleNewServiceName = (ticketName: string) => {
        saveSelectedService({ id: null, name: ticketName });
    };
    const handleNewServiceStartTime = (from: string) => {
        saveSelectedService({ id: null, from });
    };
    const handleNewServiceEndTime = (to: string) => {
        saveSelectedService({ id: null, to });
    };
    const [borderColor, setBorderColors] = React.useState({
        startTimeBorderColor: "",
        endTimeBorderColor: "",
        headerBorderColor: "",
    });
    const handleSubmitCreateNewService = async () => {
        if (
            selectedService.name === "" ||
            selectedService.from === "" ||
            selectedService.to === ""
        ) {
            setBorderColors((prev: any) => {
                let colors = { ...prev };
                if (selectedService.name === "") {
                    colors.headerBorderColor = "red";
                }
                if (selectedService.from === "") {
                    colors.startTimeBorderColor = "red";
                }
                if (selectedService.to === "") {
                    colors.endTimeBorderColor = "red";
                }
                return colors;
            });

            return;
        }
        if (
            selectedService.id === null &&
            selectedService.name !== "" &&
            selectedService.from !== "" &&
            selectedService.to !== ""
        ) {
            const newService = {
                name: selectedService.name,
                from: selectedService.from,
                to: selectedService.to,
                id: null,
                siteId: selectedBranchSettings._id,
            };
            await updateService(newService);
            setisCreateNewService(false);
        }
    };
    const handleCancelNewService = () => {
        setisCreateNewService(false);
    };

    const [updateServiceStatus, setUpdateServiceStatus] = React.useState(false);
    React.useEffect(() => {
        if (!updateServiceStatus) {
            saveSelectedService({
                id: null,
                name: "",
                from: "",
                to: "",
                siteId: "",
            });
        }
    }, [updateServiceStatus]);
    const handleUpdateService = (idService: string) => {
        try {
            setUpdateServiceStatus(true);
            const selectedService = services.find(
                (service: any) => service.id === idService,
            );
            saveSelectedService({
                id: selectedService?.id,
                name: selectedService?.name,
                from: selectedService?.from,
                to: selectedService?.to,
                siteId: selectedService?.siteId,
            });
        } catch (error) {
            console.error(error);
            setUpdateServiceStatus(false);
        }
    };
    const handleUpdatedServiceName = (name: string) => {
        saveSelectedService({ name });
    };
    const handleUpdatedServiceStartTime = (from: string) => {
        saveSelectedService({ from });
    };
    const handleUpdatedServiceEndTime = (to: string) => {
        saveSelectedService({ to });
    };
    const handleSubmitUpdatedService = () => {
        if (selectedService.id !== null && selectedService.name !== "") {
            const updatedService = {
                id: selectedService.id,
                name: selectedService.name,
                from: selectedService.from,
                to: selectedService.to,
                siteId: selectedBranchSettings._id,
            };
            updateService(updatedService);
            setUpdateServiceStatus(false);
        }
    };
    const handleCancelUpdatedService = () => {
        setUpdateServiceStatus(false);
    };

    const [deleteServiceStatus, setDeleteServiceStatus] = React.useState(false);
    const handleDeleteService = (idService: string) => {
        Swal.fire({
            title: "Êtes-vous certain(e) de vouloir supprimer cette étiquette ?",
            color: "#2A8BAB",
            showCancelButton: true,
            confirmButtonText: "Oui",
            confirmButtonColor: "#2A8BAB",
            cancelButtonText: "Non",
            cancelButtonColor: "#EDEFF5",
        }).then((result) => {
            if (result.isConfirmed) {
                const selectedService = services.find(
                    (service: any) => service.id === idService,
                );
                saveSelectedService({
                    id: selectedService?.id,
                    name: selectedService?.name,
                    from: selectedService?.from,
                    to: selectedService?.to,
                    siteId: selectedService?.siteId,
                });
                deleteService(idService);
                Swal.fire({
                    title: "cette opération a été effectuée avec succès",
                    color: "#2A8BAB",
                    text: "",
                    icon: "success",
                    confirmButtonText: "Oui",
                    confirmButtonColor: "#2A8BAB",
                });
            } else if (result.isDismissed) {
                Swal.fire({
                    title: "Cette opération a été annulée",
                    color: "#2A8BAB",
                    text: "",
                    icon: "info",
                });
            }
        });
    };
    // TODO:    Bonus Types       TODO:
    const handleListOfBonusTypes = () => {
        let result: any[] = [];
        if (bonusTypes.length > 0) {
            bonusTypes.forEach((bonusType: any) => {
                result.push({
                    id: bonusType.acompteTypeId,
                    name: bonusType.name,
                    affectSalary: bonusType.affectSalary,
                    affectType: bonusType.affectType,
                    siteId: bonusType.siteId,
                });
            });
        }
        return result;
    };
    const [listOfBonusTypes, setListOfBonusTypes] = React.useState(
        handleListOfBonusTypes(),
    );
    React.useEffect(() => {
        setListOfBonusTypes(handleListOfBonusTypes());
    }, [bonusTypes]);
    // /////////////////
    const [isCreateNewBonusType, setisCreateNewBonusType] =
        React.useState(false);
    React.useEffect(() => {
        if (!isCreateNewBonusType) {
            saveSelectedBonusType({
                acompteTypeId: null,
                name: "",
                affectSalary: false,
                affectType: "",
                siteId: "",
            });
        }
    }, [isCreateNewBonusType]);
    const handleNewBonusTypeName = (bonusTypeName: string) => {
        saveSelectedBonusType({ acompteTypeId: null, name: bonusTypeName });
    };
    const handleNewBonusTypeAffectSalary = (affectSalary: any) => {
        const affectSalaryStatus = affectSalary.target.checked;
        saveSelectedBonusType({
            acompteTypeId: null,
            affectSalary: affectSalaryStatus,
        });
        if (
            affectSalaryStatus === true &&
            selectedBonusType.affectType === ""
        ) {
            saveSelectedBonusType({
                affectType: "+",
            });
        }
    };
    const handleNewBonusTypeAffectType = (affectType: any) => {
        switch (affectType.value.toLowerCase()) {
            case "addition":
                saveSelectedBonusType({
                    acompteTypeId: null,
                    affectType: "+",
                });
                break;
            case "soustraction":
                saveSelectedBonusType({
                    acompteTypeId: null,
                    affectType: "-",
                });
                break;
        }
    };
    const handleSubmitCreateNewBonusType = (data: any) => {
        if (data.name === "") {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Veuillez remplir les champs",
            });
            return;
        }
        if (data.acompteTypeId === null && data.name !== "") {
            const newBonusType = {
                name: data.name,
                affectSalary: data.affectSalary,
                affectType: data.affectType,
                acompteTypeId: null,
                siteId: selectedBranchSettings._id,
            };
            createBonusType(newBonusType);
            setisCreateNewBonusType(false);
        }
    };
    const handleCancelNewBonusType = () => {
        setisCreateNewBonusType(false);
    };

    const [updateBonusTypeStatus, setUpdateBonusTypeStatus] =
        React.useState(false);
    React.useEffect(() => {
        if (!updateBonusTypeStatus) {
            saveSelectedBonusType({
                acompteTypeId: null,
                name: "",
                affectSalary: false,
                affectType: "",
                siteId: "",
            });
        }
    }, [updateBonusTypeStatus]);
    const handleUpdateBonusType = (idBonusType: string) => {
        try {
            setUpdateBonusTypeStatus(true);
            const selectedBonusType = bonusTypes.find(
                (bonusType: any) => bonusType.acompteTypeId === idBonusType,
            );
            saveSelectedBonusType({
                acompteTypeId: selectedBonusType?.acompteTypeId,
                name: selectedBonusType?.name,
                affectSalary: selectedBonusType?.affectSalary,
                affectType: selectedBonusType?.affectType,
                siteId: selectedBonusType?.siteId,
            });
        } catch (error) {
            console.error(error);
            setUpdateBonusTypeStatus(false);
        }
    };
    const handleUpdatedBonusTypeName = (name: string) => {
        saveSelectedBonusType({ name });
    };
    const handleUpdatedBonusTypeAffectSalary = (affectSalary: any) => {
        console.log("affectSalary: ", affectSalary.target.checked);
        saveSelectedBonusType({ affectSalary: affectSalary.target.checked });
    };
    const handleUpdatedBonusTypeAffectType = (affectType: any) => {
        console.log("affectType", affectType);
        switch (affectType.value.toLowerCase()) {
            case "addition":
                saveSelectedBonusType({ affectType: "+" });
                break;
            case "soustraction":
                console.log("soustraction");
                saveSelectedBonusType({ affectType: "-" });
                break;
        }
    };
    const handleSubmitUpdatedBonusType = (data: any) => {
        if (data.acompteTypeId !== null && data.name !== "") {
            const updatedBonusType = {
                name: data.name,
                affectSalary: data.affectSalary,
                affectType: data.affectType,
                acompteTypeId: data.acompteTypeId,
                siteId: selectedBranchSettings._id,
            };
            console.log("updatedBonusType", updatedBonusType);
            updateBonusType(updatedBonusType);
            setUpdateBonusTypeStatus(false);
        }
    };
    const handleCancelUpdatedBonusType = () => {
        setUpdateBonusTypeStatus(false);
    };

    const [deleteBonusTypeStatus, setDeleteBonusTypeStatus] =
        React.useState(false);
    const handleDeleteBonusType = (idBonusType: string) => {
        // setDeletePlanningStatus(true);
        Swal.fire({
            title: "Êtes-vous certain(e) de vouloir supprimer cette avance/prime ?",
            color: "#2A8BAB",
            showCancelButton: true,
            confirmButtonText: "Oui",
            confirmButtonColor: "#2A8BAB",
            cancelButtonText: "Non",
            cancelButtonColor: "#EDEFF5",
        }).then((result) => {
            if (result.isConfirmed) {
                const selectedBonusType = bonusTypes.find(
                    (bonusType: any) => bonusType.acompteTypeId === idBonusType,
                );
                saveSelectedBonusType({
                    acompteTypeId: selectedBonusType?.acompteTypeId,
                    name: selectedBonusType?.name,
                    affectSalary: selectedBonusType?.affectSalary,
                    affectType: selectedBonusType?.affectType,
                    siteId: selectedBonusType?.siteId,
                });
                deleteBonusType(idBonusType);
                Swal.fire({
                    title: "cette opération a été effectuée avec succès",
                    color: "#2A8BAB",
                    text: "",
                    icon: "success",
                    confirmButtonText: "Oui",
                    confirmButtonColor: "#2A8BAB",
                });
            } else if (result.isDismissed) {
                Swal.fire({
                    title: "Cette opération a été annulée",
                    color: "#2A8BAB",
                    text: "",
                    icon: "info",
                });
            }
        });
    };
    // TODO:    Absences       TODO:
    const [selectionAbsenceType, setSelectionAbsenceType] = React.useState({
        label: absences?.[0].absence,
        value: absences?.[0]._id,
        color: absences?.[0].color,
    });

    const listOfColors = () => {
        const additionalColors: any[] = [
            "#1abc9c",
            "#16a085",
            "#2ecc71",
            "#27ae60",
            "#3498db",
            "#2980b9",
            "#9b59b6",
            "#8e44ad",
            "#34495e",
            "#2c3e50",
            "#f1c40f",
            "#f39c12",
            "#e67e22",
            "#d35400",
            "#e74c3c",
            "#c0392b",
            // "#ecf0f1",
            // "#bdc3c7",
            "#95a5a6",
            "#7f8c8d",
        ];
        const allColors = [
            ...absences
                ?.map((absence: any) => {
                    if (absence?.color) {
                        return absence.color;
                    }
                })
                .filter(Boolean),
            ...additionalColors,
        ];
        const uniqueColors = Array.from(new Set(allColors));
        return uniqueColors;
    };
    const handleAbsenceTypeSelection = (selectElement: any) => {
        setSelectionAbsenceType(selectElement);

        saveSelectedAbsence({
            _id: selectElement?.value,
            absence: selectElement?.label,
            color: selectElement?.color,
        });
    };
    const handleAbsenceColorSelection = (color: string) => {
        console.log("selectedAbsence:", selectedAbsence);
        if (
            selectedAbsence._id === undefined ||
            selectedAbsence.absence === undefined
        ) {
            saveSelectedAbsence({
                _id: selectionAbsenceType?.value,
                absence: selectionAbsenceType?.label,
                color,
            });
        } else {
            saveSelectedAbsence({ color });
        }
    };
    const multiplePlanningSettings = () => {
        if (scheduleSettings.multiplePlanningStatus) {
            return [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "",
                    optionDescription: "",
                    optionInput: (
                        <ToggleButton
                            isChecked={scheduleSettings.multiplePlanningStatus}
                            onClick={() => {
                                saveScheduleSettings({
                                    multiplePlanningStatus:
                                        !scheduleSettings.multiplePlanningStatus,
                                });
                            }}
                        />
                    ),
                    optionInput2: (
                        <MultiPlanningTickets
                            newTicketButtonText="Nouveau Département"
                            tickets={listOfPlannings}
                            handleTicketEdit={handleUpdatePlanningTicket}
                            handleTicketDelete={handleDeletePlanningTicket}
                            isCreatePopup={createNewPlanningStatus}
                            isUpdatePopup={updatePlanningStatus}
                            isDeletePopup={deletePlanningStatus}
                            setIsCreatePopup={setCreateNewPlanningStatus}
                            setIsUpdatePopup={setUpdatePlanningStatus}
                            setIsDeletePopup={setDeletePlanningStatus}
                            createPopupCard={
                                <NewDepartmentCard
                                    inputOnChange={handleNewPlanningName}
                                    onEmployeeItemClick={
                                        handleEmployeeToNewPlanning
                                    }
                                    employeeList={myUsers()}
                                    onSelectAllEmployees={
                                        handleSelectAllToNewPlanning
                                    }
                                    onClose={handleRemoveNewPlanning}
                                    onCancelButton={handleRemoveNewPlanning}
                                    onSubmitButton={handleCreateNewPlanning}
                                />
                            }
                            UpdatePopupCard={
                                <NewDepartmentCard
                                    headerCardTitle="Editer un département"
                                    inputValue={selectedPlan.name}
                                    inputOnChange={handleUpdatedPlanningName}
                                    onEmployeeItemClick={
                                        handleEmployeeUpdatedPlanning
                                    }
                                    employeeList={selectedPlan.employees}
                                    isEdit={true}
                                    data={selectedPlan}
                                    onSubmitButton={handleSumbitUpdatePlanning}
                                    onDeleteDepartment={
                                        handleDeletePlanningTicket
                                    }
                                    onClose={handleCancelUpdatePlanning}
                                    onCancelButton={handleCancelUpdatePlanning}
                                    onSelectAllEmployees={
                                        handleUpdatedSelectAllEmployees
                                    }
                                />
                            }
                        />
                    ),
                },
            ];
        } else {
            return [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "",
                    optionDescription: "",
                    optionInput: (
                        <ToggleButton
                            isChecked={scheduleSettings.multiplePlanningStatus}
                            onClick={() => {
                                saveScheduleSettings({
                                    multiplePlanningStatus:
                                        !scheduleSettings.multiplePlanningStatus,
                                });
                            }}
                        />
                    ),
                },
            ];
        }
    };
    return [
        // {
        //     headerTitle: "Autorisation",
        //     // titleColor: "#ccc",
        //     // descriptionColor: "#ccc",
        //     headerDescription:
        //         "Permet de définir des niveaux d'autorisation pour les utilisateurs et les administrateurs du système. ",
        //     configOptions: [
        //         {
        //             optionTitle:
        //                 "Le responsable doit approuver un changement de shift entre employés",
        //             titleColor: "#2A346D", //"#2A346D",
        //             titleSize: "16px",
        //             titleWeight: "bolder",
        //             descriptionColor: "#6F78AB", //"#6F78AB",
        //             descriptionSize: "13",
        //             descriptionWeight: "normal",
        //             optionDescription:
        //                 "Permet aux employés de proposer un changement de leur horaire de travail avec un autre employé et d'envoyer une demande d'approbation à leur responsable. ",

        //             optionInput: (
        //                 <ToggleButton
        //                     // isChecked={scheduleSettings.authorization}
        //                     isChecked={false}
        //                     isDisabled={true}
        //                     onClick={() => {
        //                         saveScheduleSettings({
        //                             authorization:
        //                                 !scheduleSettings.authorization,
        //                         });
        //                     }}
        //                 />
        //             ),
        //         },
        //     ],
        // },
        {
            headerTitle: "Planning multiple",
            headerDescription:
                "Vous permet de créer des départements afin de vous faciliter la planification. Dans chaque département, on inclut les collaborateurs concernés, Exemple : Département cuisine, salle, Accueil,…. Dans cuisine, on aura le chef, les commis, les plongeurs,… ",
            configOptions: multiplePlanningSettings(),
        },
        {
            headerTitle: "Étiquettes",
            headerDescription:
                "Vous permet d'assigner les tâches en fonction des compétences, de votre organisation ou du poste.Exemple : équipe A, équipe B, serveur, caissier,…",
            configOptions: [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "",
                    optionDescription: "",
                    optionInput: (
                        <TicketsList
                            tickets={listOfTickets}
                            handleTicketEdit={handleUpdateTicket}
                            handleTicketDelete={handleDeleteTicket}
                            isCreatePopup={isCreateNewTicket}
                            isUpdatePopup={updateTicketStatus}
                            isDeletePopup={deleteTicketStatus}
                            setIsCreatePopup={setisCreateNewTicket}
                            setIsUpdatePopup={setUpdateTicketStatus}
                            setIsDeletePopup={setDeleteTicketStatus}
                            createPopupCard={
                                <NewShiftTicketCard
                                    inputOnChange={handleNewTicketName}
                                    onSelectionColor={
                                        handleNewTicketColorSelection
                                    }
                                    isEdit={false}
                                    onSubmitButton={handleCreateNewTicket}
                                    onClose={handleRemoveNewTicket}
                                    onCancelButton={handleRemoveNewTicket}
                                    colors={listOfColors()}
                                    displayColorsCount={listOfColors().length}
                                />
                            }
                            UpdatePopupCard={
                                <NewShiftTicketCard
                                    header="Editer une étiquette"
                                    // inputValue={selectedTicket.name}
                                    // selectionColor={selectedTicket.color}
                                    // from={selectedTicket.fromHour}
                                    // to={selectedTicket.toHour}
                                    isEdit={true}
                                    data={selectedTicket}
                                    inputOnChange={handleUpdatedTicketName}
                                    onSelectionColor={handleUpdatedTicketColor}
                                    handleTicketDelete={handleDeleteTicket}
                                    //onSubmitButton={handleSubmitUpdatedTicket}
                                    onSubmitButton={handleCreateNewTicket}
                                    onClose={handleCancelUpdatedTicket}
                                    onCancelButton={handleCancelUpdatedTicket}
                                    colors={listOfColors()}
                                    displayColorsCount={listOfColors().length}
                                />
                            }
                        />
                    ),
                },
            ],
        },
        {
            headerTitle: "Services",
            headerDescription:
                "Vous permet de créer des services selon des tranches horaires afin de visualiser rapidement le nombre de personnes planifiées. Exemple : service matin (de 8h à 12h) et soir (de 14h à 17h). Au niveau du planning, vous aurez 10 collaborateurs le matin (dont 2 caissiers, 5 serveurs,…) et seulement 2 collaborateurs planifiés le soir. ",
            configOptions: [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "",
                    optionDescription: "",
                    optionInput: (
                        <MultiPlanningTickets
                            newTicketButtonText="Nouveau service"
                            tickets={listOfServices}
                            handleTicketEdit={handleUpdateService}
                            handleTicketDelete={handleDeleteService}
                            isCreatePopup={isCreateNewService}
                            isUpdatePopup={updateServiceStatus}
                            isDeletePopup={deleteServiceStatus}
                            setIsCreatePopup={setisCreateNewService}
                            setIsUpdatePopup={setUpdateServiceStatus}
                            setIsDeletePopup={setDeleteServiceStatus}
                            createPopupCard={
                                <NewServiceCard
                                    onChangeHeader={handleNewServiceName}
                                    onStartTimeChange={
                                        handleNewServiceStartTime
                                    }
                                    onEndTimeChange={handleNewServiceEndTime}
                                    onSubmitButton={
                                        handleSubmitCreateNewService
                                    }
                                    onClose={handleCancelNewService}
                                    onCancelButton={handleCancelNewService}
                                    headerBorderColor={
                                        borderColor.headerBorderColor
                                    }
                                    endTimeBorderColor={
                                        borderColor.endTimeBorderColor
                                    }
                                    startTimeBorderColor={
                                        borderColor.startTimeBorderColor
                                    }
                                />
                            }
                            UpdatePopupCard={
                                <NewServiceCard
                                    header="Editer un service"
                                    // headerValue={selectedService?.name}
                                    onChangeHeader={handleUpdatedServiceName}
                                    // startTimeValue={selectedService?.from}
                                    // endTimeValue={selectedService?.to}
                                    data={selectedService}
                                    isEdit={true}
                                    handleDeleteService={handleDeleteService}
                                    onStartTimeChange={
                                        handleUpdatedServiceStartTime
                                    }
                                    onEndTimeChange={
                                        handleUpdatedServiceEndTime
                                    }
                                    onClose={handleCancelUpdatedService}
                                    onSubmitButton={handleSubmitUpdatedService}
                                    onCancelButton={handleCancelUpdatedService}
                                />
                            }
                        />
                    ),
                },
            ],
        },
        // {
        //     headerTitle: "Météo",
        //     headerDescription: "description",
        //     isComing: true,
        //     configOptions: [
        //         {
        //             titleColor: "#2A346D",
        //             titleSize: "16px",
        //             titleWeight: "bolder",
        //             descriptionColor: "#6F78AB",
        //             descriptionSize: "13",
        //             descriptionWeight: "normal",
        //             optionTitle: "Activer le météo",
        //             optionDescription:
        //                 "Pour consulter le météo dans le planning. ",
        //             optionInput: (
        //                 <ToggleButton
        //                     isChecked={false}
        //                     // onClick={(e: any) =>
        //                     //     setEnableRegister(e.target.checked)
        //                     // }
        //                 />
        //             ),
        //         },
        //     ],
        // },
        {
            headerTitle: "Avances/Primes types",
            headerDescription:
                "Vous permet de créer des types personnalisés de prime, avance, remboursement crédit,… Ainsi dans la fiche de chaque collaborateur, vous pouvez indiquer les montants, la date et une description pour garder un historique.",
            configOptions: [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "",
                    optionDescription: "",
                    optionInput: (
                        <MultiPlanningTickets
                            newTicketButtonText="Nouveau type d'avance/primes"
                            tickets={listOfBonusTypes}
                            handleTicketEdit={handleUpdateBonusType}
                            handleTicketDelete={handleDeleteBonusType}
                            isCreatePopup={isCreateNewBonusType}
                            isUpdatePopup={updateBonusTypeStatus}
                            isDeletePopup={deleteBonusTypeStatus}
                            setIsCreatePopup={setisCreateNewBonusType}
                            setIsUpdatePopup={setUpdateBonusTypeStatus}
                            setIsDeletePopup={setDeleteBonusTypeStatus}
                            createPopupCard={
                                <NewReceiptCard
                                    onHeaderChange={handleNewBonusTypeName}
                                    onCheckboxChange={
                                        handleNewBonusTypeAffectSalary
                                    }
                                    selectOption={[
                                        {
                                            label: "Addition",
                                            value: "Addition",
                                        },
                                        {
                                            label: "Soustraction",
                                            value: "Soustraction",
                                        },
                                    ]}
                                    onSelectChange={
                                        handleNewBonusTypeAffectType
                                    }
                                    onSubmitButton={
                                        handleSubmitCreateNewBonusType
                                    }
                                    onClose={handleCancelNewBonusType}
                                    onCancelButton={handleCancelNewBonusType}
                                />
                            }
                            UpdatePopupCard={
                                <NewReceiptCard
                                    header="Editer un type d'avance / prime"
                                    headerValue={selectedBonusType?.name}
                                    onHeaderChange={handleUpdatedBonusTypeName}
                                    checkboxValue={
                                        selectedBonusType?.affectSalary
                                    }
                                    isEdit={true}
                                    data={selectedBonusType}
                                    handleDeleteBonusType={
                                        handleDeleteBonusType
                                    }
                                    onCheckboxChange={
                                        handleUpdatedBonusTypeAffectSalary
                                    }
                                    selectValue={selectedBonusType?.affectType}
                                    onSelectChange={
                                        handleUpdatedBonusTypeAffectType
                                    }
                                    onSubmitButton={
                                        handleSubmitUpdatedBonusType
                                    }
                                    onClose={handleCancelUpdatedBonusType}
                                    onCancelButton={
                                        handleCancelUpdatedBonusType
                                    }
                                />
                            }
                        />
                    ),
                },
            ],
        },
        {
            headerTitle: "Absences",
            headerDescription:
                "Vous permet de changer la couleur des absences, tel que les congés, les repos,…. Même si votre planning est créé, vous pouvez changer la couleur.  ",
            configOptions: [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "",
                    optionDescription: "",
                    optionInput: (
                        <SelectInput
                            title="Type d'absence"
                            options={absences?.map((absence: any) => {
                                return {
                                    label: absence?.absence,
                                    value: absence?._id,
                                    color: absence?.color,
                                };
                            })}
                            selectedValue={selectionAbsenceType}
                            onSelectOption={handleAbsenceTypeSelection}
                            style={{
                                width: "300px",
                            }}
                        />
                    ),
                    optionInput2: (
                        <div>
                            <TextData size="12px" color="#8E96C1">
                                Choisissez la couleur
                            </TextData>
                            <ListOfColors
                                colors={listOfColors()}
                                colorSelection={selectionAbsenceType?.color}
                                getSelectedColor={handleAbsenceColorSelection}
                                displayCount={listOfColors().length}
                            />
                        </div>
                    ),
                },
            ],
        },
    ];
};
