import classnames from "classnames";
import * as React from "react";

import FlagES from "../../../utils/img/es-flag.svg";

import "./style.scss";

export default function Picture(props: PictureProps) {
    const {
        imgPath,
        title,
        alt,
        bounce,
        id,
        width,
        height,
        className,
        style,
        onClick,
    } = props;

    const classProps: string = classnames(
        "Picture",
        className,
        bounce ? "animate-bounce" : null
    );
    return (
        <img
            src={imgPath}
            title={title}
            alt={alt}
            className={classProps}
            id={id}
            style={{ ...style, width, height }}
            onClick={onClick}
        />
    );
}

interface PictureProps {
    imgPath: string;
    title?: string;
    alt?: string;
    bounce?: boolean;
    id?: string;
    width?: string;
    height?: string;
    className?: string[];
    style?: React.CSSProperties;
    onClick: React.MouseEventHandler;
}

Picture.defaultProps = {
    imgPath: FlagES,
    title: "img",
    alt: "img",
    bounce: false,
    id: null,
    width: "auto",
    height: "auto",
    className: [],
    onClick: () => {
    },
    style: null,
};
