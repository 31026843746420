import React from "react";
import "./style.css";
import Button from "../../components/atoms/Button";
import TextData from "../../components/atoms/TextData";
import Loading from "../../components/atoms/Loading";
import { ROUTER } from "../../constants/env";
import { Link } from "react-router-dom";
import {
    NotificationState,
    NotificationsState,
} from "../../redux/features/notifications/types";
import { useNotifications } from "../../hooks/useNotifications";
import NotificationItem from "./NotificationItem";
import { ReactComponent as CheckSeenSVG } from "./check_seen.svg";
export default function Notification() {
    const {
        allNotifications,
        loading,
        getAllNotifications,
        setNotificationsSeen,
    } = useNotifications();
    React.useEffect(() => {
        getAllNotifications();
    }, []);
    return (
        <div className="notifications-page-container">
            <div className="head">
                <TextData color="#11106E" size="24" fontWeight="bold">
                    Notifications
                </TextData>
                <Link to={ROUTER.SETTINGS.NOTIFICATIONS}>
                    <Button
                        textColor="white"
                        backgroundColor="#2A8BAB"
                        style={{
                            fontSize: "16px",
                            gap: "0.25rem",
                            border: "1px solid #2A8BAB",
                        }}
                        width="auto"
                        padding="0.75rem 20px"
                        height="50px"
                        // onClick={exportClassicReport}
                    >
                        Paramètres des notifications
                    </Button>
                </Link>
            </div>
            {loading ? (
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                        height: "75vh",
                        backgroundColor: "white",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                        borderRadius: "8px",
                        padding: "10px",
                    }}
                >
                    <Loading width="500px" height="250px" />
                </div>
            ) : (
                <div className="content">
                    {(allNotifications.today.length > 0 ||
                        allNotifications.old.length > 0) && (
                        <TextData
                            fontWeight="bold"
                            size="14px"
                            color={`${
                                allNotifications?.unread > 0
                                    ? "#2A8BAB"
                                    : "#6F78AB"
                            }`}
                            style={{
                                position: "absolute",
                                right: "10px",
                                cursor: "pointer",
                                display: "flex",
                                gap: "3px",
                            }}
                            onClick={setNotificationsSeen}
                        >
                            <CheckSeenSVG />
                            Marquer comme lus
                        </TextData>
                    )}
                    {allNotifications.today.length > 0 && (
                        <div
                            className="today"
                            style={{
                                borderBottom: `${
                                    allNotifications.old.length > 0
                                        ? "1px solid rgb(209, 209, 209)"
                                        : ""
                                }`,
                                paddingBottom: `${
                                    allNotifications.old.length > 0
                                        ? "10px"
                                        : ""
                                }`,
                                marginBottom: `${
                                    allNotifications.old.length > 0
                                        ? "10px"
                                        : ""
                                }`,
                            }}
                        >
                            <div className="sub-head">
                                <TextData
                                    fontWeight="bold"
                                    size="14px"
                                    color="#6F78AB"
                                >
                                    Aujourd'hui
                                </TextData>
                            </div>

                            {allNotifications.today.map(
                                (
                                    notification: NotificationState,
                                    index: number,
                                ) => {
                                    const {
                                        title,
                                        type,
                                        content,
                                        seen,
                                        created_at,
                                        clicked,
                                    } = notification;
                                    return (
                                        <NotificationItem
                                            key={index}
                                            title={title}
                                            type={type}
                                            content={content}
                                            seen={seen}
                                            created_at={created_at}
                                            clicked={clicked}
                                            isToday={true}
                                        />
                                    );
                                },
                            )}
                        </div>
                    )}
                    <div className="old">
                        {allNotifications.old.length > 0 && (
                            <div className="sub-head">
                                <TextData
                                    fontWeight="bold"
                                    size="14px"
                                    color="#6F78AB"
                                >
                                    Anciennes
                                </TextData>
                            </div>
                        )}
                        {allNotifications?.old.map(
                            (
                                notification: NotificationState,
                                index: number,
                            ) => {
                                const {
                                    title,
                                    type,
                                    content,
                                    seen,
                                    created_at,
                                    clicked,
                                } = notification;
                                return (
                                    <NotificationItem
                                        key={index}
                                        title={title}
                                        type={type}
                                        content={content}
                                        seen={seen}
                                        created_at={created_at}
                                        clicked={clicked}
                                        isToday={false}
                                    />
                                );
                            },
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
