export const FilledTriangle = ({
  width = 26,
  height = 14,
  fill = "currentColor",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.026 13.5034L0.901679 0.753414L25.1504 0.753416L13.026 13.5034Z"
      fill={fill}
    />
  </svg>
);

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
}
