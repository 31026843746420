import React from "react";
import ModalHeader from "../../molecules/ModalHeader";
import ModalFooter from "../../molecules/ModalFooter";
import ModalBody from "../../molecules/ModalBody";

export default function Modal({
    show = true,
    onHide,
    width = 800,
    height = 800,
    containerStyle,
    title,
    fotterClasses,
    headerClasses,
    headerFormatter,
    footerFormatter,
    confirm,
    cancel,
    confirmAction,
    cancelAction,
    body,
    confirmRightComponent,
    confirmLeftComponent,
    cancelLeftComponent,
    cancelRightComponent,
    ConfirmComponent,
    CancelComponent,
    confirmLoading,
    bodyClasses,
    header = true,
    footer = true,
}: ModalInterface) {
    let containerClass = `fixed z-[999] bg-white top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-xl shadow max-w-full max-h-full
      ${show ? "" : "hidden"}`;
    React.useEffect(() => {
        if (show) {
            document.body.style.overflow = "hidden";
        }
        return () => {
            document.body.style.overflow = "unset";
        };
    }, [show]);

    const getBodyHeight = () => {
        let h =
            height <= window.innerHeight - 80
                ? height - 160
                : window.innerHeight - 240;
        if (!footer) {
            h += 80;
        }
        if (!header) {
            h += 80;
        }
        return h + "px";
    };

    const getContainerHeight = (height: number) => {
        if (height >= window.innerHeight - 80) {
            return window.innerHeight - 80 + "px";
        }
        return height + "px";
    };
    const getContainerWidth = (width: number) => {
        if (width >= window.innerWidth - 80) {
            return window.innerWidth - 80 + "px";
        }
        return width + "px";
    };
    return (
        <>
            <div
                className={`${
                    show
                        ? "fixed  !z-[999] top-0 left-0 w-screen h-screen bg-black"
                        : ""
                }`}
                style={{
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                }}
                onClick={onHide}
            >
                <div
                    style={
                        containerStyle
                            ? {
                                  height: getContainerHeight(height),
                                  width: getContainerWidth(width),
                                  ...containerStyle,
                              }
                            : {
                                  height: getContainerHeight(height),
                                  width: getContainerWidth(width),
                              }
                    }
                    className={`${containerClass}`}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {header && (
                        <>
                            {headerFormatter ? (
                                headerFormatter()
                            ) : (
                                <ModalHeader
                                    close={true}
                                    title={title}
                                    onHide={onHide}
                                    headerClasses={headerClasses}
                                />
                            )}
                        </>
                    )}
                    <div
                        className='overflow-y-auto  max-h-full modalBody'
                        style={{
                            height: getBodyHeight(),
                        }}
                    >
                        <ModalBody bodyClasses={bodyClasses} height={height}>
                            {body}
                        </ModalBody>
                    </div>
                    {footer && (
                        <>
                            {footerFormatter ? (
                                footerFormatter()
                            ) : (
                                <ModalFooter
                                    confirm={confirm}
                                    cancel={cancel}
                                    confirmAction={confirmAction}
                                    cancelAction={cancelAction || onHide}
                                    fotterClasses={fotterClasses}
                                    confirmLeftComponent={confirmLeftComponent}
                                    confirmRightComponent={
                                        confirmRightComponent
                                    }
                                    cancelLeftComponent={cancelLeftComponent}
                                    cancelRightComponent={cancelRightComponent}
                                    ConfirmComponent={ConfirmComponent}
                                    CancelComponent={CancelComponent}
                                    confirmLoading={confirmLoading}
                                />
                            )}
                        </>
                    )}
                    {/* <ModalBody bodyClasses={bodyClasses} height={height}>
                        {body}
                    </ModalBody> */}
                </div>
            </div>
        </>
    );
}

interface ModalInterface {
    header?: boolean;
    footer?: boolean;
    show: boolean;
    onHide?: () => void;
    width?: number;
    height?: number;
    containerStyle?: React.CSSProperties;
    title: string;
    confirm?: string;
    cancel?: string;
    confirmAction?: () => void;
    ConfirmComponent?: React.ReactNode;
    CancelComponent?: React.ReactNode;
    cancelAction?: () => void;
    body: React.ReactNode;
    headerFormatter?: () => React.ReactNode;
    footerFormatter?: () => React.ReactNode;
    headerClasses?: string;
    fotterClasses?: string;
    bodyClasses?: string;
    confirmRightComponent?: React.ReactNode;
    cancelRightComponent?: React.ReactNode;
    confirmLeftComponent?: React.ReactNode;
    cancelLeftComponent?: React.ReactNode;
    confirmLoading?: boolean;
}
