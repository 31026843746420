import * as React from "react";
import PopUpCard from "../../molecules/PopUpCard";
import Input from "../../atoms/Input";
import TextData from "../../atoms/TextData";
import InputFile from "../../atoms/InputFile";

import "./style.scss";

export default function NewContractTemplateCard(
    props: NewContractTemplateCardProps
) {
    const {
        headerCardTitle,
        display,
        onClose,
        onClickButton1,
        onClickButton2,
        setUploadFile,
        setTitle,
        setType,
        title,
        type,
        button1Text,
        button2Text,
    } = props;
    const [contractTitle, setContractTitle] = React.useState(title);
    const [contractType, setContractType] = React.useState(type);
    React.useEffect(() => {
        setContractTitle(title);
    }, [title]);
    React.useEffect(() => {
        setContractType(type);
    }, [type]);

    return (
        <PopUpCard
            display={display}
            onClose={onClose}
            header={headerCardTitle}
            onClickButton1={onClickButton1}
            onClickButton2={onClickButton2}
            button1Text={button1Text}
            button2Text={button2Text}
        >
            <div className="new-contract-template-container">
                <div>
                    <Input
                        value={contractTitle}
                        onChange={setTitle}
                        captionText="Titre de document"
                        captionColor="#7b99b1"
                        captionSize="14px"
                        borderWidth={1}
                        borderColor="#3598b8"
                        textSize={14}
                        textColor="#555"
                        inputRadius="4px"
                        placeholder="ex: Contrat de travail..."
                        style={{
                            inputStyle: {
                                padding: "7px",
                                width: "100%",
                            },
                            errorStyle: {},
                        }}
                    />
                </div>
                <div>
                    <Input
                        value={contractType}
                        onChange={setType}
                        captionText="Type de contrat"
                        captionColor="#7b99b1"
                        captionSize="14px"
                        borderWidth={1}
                        borderColor="#3598b8"
                        textSize={14}
                        textColor="#555"
                        inputRadius="4px"
                        placeholder="ex: CDI"
                        style={{
                            inputStyle: {
                                padding: "7px",
                                width: "100%",
                            },
                            errorStyle: {},
                        }}
                    />
                </div>
                <div>
                    <TextData color="#7b99b1" size="14px" variant="regular">
                        Sélectionner un document
                    </TextData>
                    <InputFile
                        text="Importer un document"
                        acceptFormat="word"
                        uploadedFile={setUploadFile}
                    />
                </div>
            </div>
        </PopUpCard>
    );
}

interface NewContractTemplateCardProps {
    headerCardTitle?: string;
    display?: boolean;
    onClose?: () => void;
    onClickButton1?: () => void;
    onClickButton2?: () => void;
    setUploadFile?: (file: any) => void;
    setTitle?: (title: string) => void;
    title?: string;
    setType?: (type: string) => void;
    type?: string;
    button1Text?: string;
    button2Text?: string;
}
NewContractTemplateCard.defaultProps = {
    headerCardTitle: "Importer un modéle de contrat",
    button1Text: "Annuler",
    button2Text: "Importer",
};
