import * as React from "react";

import TextData from "../../atoms/TextData";
import Picture from "../../atoms/Picture";
import Input from "../../atoms/Input";
import Button from "../../atoms/Button";

import "./style.scss";

import InputIcon from "./assets/passwordIcon.svg";
import invalidIcon from "./assets/falseIcon.svg";
import validIcon from "./assets/validIcon.svg";
import logo from "./assets/logo.svg";
import { useParams, useNavigate } from "react-router-dom";
import { useResetPassword } from "../../../hooks/useResetPassword";
import { ROUTER } from "../../../constants/env";

export default function ResetPassword(props: ResetPasswordProps) {
    const {
        title,
        passwordRequirements,
        newPasswordLabel,
        confirmPasswordLabel,
        submitButtonText,
        connectLinkText,
    } = props;
    const [passwordType, setpasswordType] = React.useState("password");
    const { resetToken } = useParams();
    const { auth, setPassword, confirmedPassword, resetPassword } =
        useResetPassword();
    const [isMatched, setIsMatched] = React.useState<boolean>(false);
    const [validation, setValidation] = React.useState<boolean[]>(
        Array(passwordRequirements?.length).fill(false)
    );
    React.useEffect(() => {
        const newValidation = Array(passwordRequirements?.length).fill(false);
        passwordRequirements?.forEach((condition, index) => {
            const { patternCondition } = condition;
            const isValid = new RegExp(`${patternCondition}`).test(
                auth.password
            );
            newValidation[index] = isValid;
        });
        setValidation(newValidation);
    }, []);
    const navigate = useNavigate();
    const handleInputChange = (inputValue: any) => {
        const newValidation = Array(passwordRequirements?.length).fill(false);
        passwordRequirements?.forEach((condition, index) => {
            const { patternCondition } = condition;
            const isValid = new RegExp(`${patternCondition}`).test(inputValue);
            newValidation[index] = isValid;
        });
        setValidation(newValidation);
        setPassword(inputValue);
    };
    const handleConfirmInputChange = (inputValue: any) => {
        if (auth.password === inputValue) {
            setIsMatched(true);
            confirmedPassword(inputValue);
        } else {
            setIsMatched(false);
        }
    };
    const isPasswordValid = () => {
        if (validation.every((isValid) => isValid) && isMatched) {
            return true;
        }
        return false;
    };
    const handleUpdatePassword = () => {
        if (isPasswordValid()) {
            resetPassword(resetToken);
        }
    };
    const globalProps = {
        titleProps: {
            fontWeight: "700",
            width: "100%",
            variant: "div" as "div",
            color: "#555",
            size: "14px",
        },
        passwordRequirementsProps: {
            eachPicture: {
                width: "14px",
                height: "14px",
            },
            eachTextData: {
                variant: "div" as "div",
                width: "100%",
                size: "10px",
                style: {
                    marginLeft: "5px",
                },
            },
        },
        inputNewPasswordProps: {
            autoComplete: "on" as "on",
            captionText: newPasswordLabel,
            placeholder: newPasswordLabel,
            onChange: handleInputChange,
            iconImg: InputIcon,
            captionColor: "#7b99b1",
            captionSize: "12px",
            borderWidth: 1,
            borderColor: "#3598b8",
            textSize: 14,
            textColor: "#555",
            inputRadius: "4px",
            inputWidth: "100%",
            style: {
                captionStyle: {
                    marginTop: "20px",
                },
                inputStyle: {
                    padding: "7px",
                },
            },
            value: auth.password,
            type: (passwordType as "text") || "password",
            onClickIconImg: () => {
                setpasswordType(
                    passwordType === "password" ? "text" : "password"
                );
            },
        },
        inputConfirmPasswordProps: {
            autoComplete: "on" as "on",
            captionText: confirmPasswordLabel,
            placeholder: confirmPasswordLabel,
            onChange: handleConfirmInputChange,
            iconImg: InputIcon,
            errorIsActivated: !isMatched,
            errorText: "Veuillez vérifier votre nouveau mot de passe",
            errorColor: "red",
            errorSize: 12,
            captionColor: "#7b99b1",
            captionSize: "12px",
            borderWidth: 1,
            borderColor: "#3598b8",
            textSize: 14,
            textColor: "#555",
            inputRadius: "4px",
            inputWidth: "100%",
            style: {
                captionStyle: {
                    marginTop: "10px",
                },
                inputStyle: {
                    padding: "7px",
                },
            },
            type: (passwordType as "text") || "password",
            onClickIconImg: () => {
                setpasswordType(
                    passwordType === "password" ? "text" : "password"
                );
            },
        },
        buttonProps: {
            width: "100%",
            height: "50px",
            backgroundColor: "#3598B8",
            textColor: "white",
            padding: "10px 5px 5px 10px",
            style: {
                marginTop: 10,
                marginBottom: 15,
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "-0.5px",
            },
            onClick: handleUpdatePassword,
            disabled: !isPasswordValid(),
        },
        connectProps: {
            variant: "div" as "div",
            color: "#809FB8",
            width: "100%",
            activateHover: true,
            style: {
                textAlign: "center" as "center",
                textDecoration: "underline",
                marginBottom: 20,
                fontWeight: "700",
                fontSize: "14px",
            },
            onClick: () => {
                navigate(ROUTER.AUTH.LOGIN);
            },
        },
    };

    return (
        <div className='reset-password-container'>
            <TextData {...globalProps.titleProps}>{title}</TextData>
            <div className='password-requirements'>
                {validation.map((isValid, index) => {
                    const textCondition =
                        passwordRequirements?.[index].textCondition;
                    const color = isValid ? "green" : "red";
                    return (
                        <div className='each-requirement' key={index}>
                            <Picture
                                {...globalProps.passwordRequirementsProps
                                    .eachPicture}
                                imgPath={isValid ? validIcon : invalidIcon}
                            />
                            <TextData
                                color={color}
                                {...globalProps.passwordRequirementsProps
                                    .eachTextData}
                            >
                                {textCondition}
                            </TextData>
                        </div>
                    );
                })}
            </div>
            <Input {...globalProps.inputNewPasswordProps} />
            <Input {...globalProps.inputConfirmPasswordProps} />
            <Button {...globalProps.buttonProps}>{submitButtonText}</Button>
            <TextData {...globalProps.connectProps}>{connectLinkText}</TextData>
            <Picture imgPath={logo} />
        </div>
    );
}
interface ResetPasswordProps {
    title?: string;
    passwordRequirements?: {
        textCondition?: string;
        patternCondition?: string;
    }[];
    newPasswordLabel?: string;
    confirmPasswordLabel?: string;
    submitButtonText?: string;
    connectLinkText?: string;
}
ResetPassword.defaultProps = {
    title: "Votre mot de passe doit contenir:",
    passwordRequirements: [
        { textCondition: "Au moins 8 caractères", patternCondition: ".{8,}" },
        {
            textCondition: "Au moins une majuscule",
            patternCondition: "(.*[A-Z]+.*)",
        },
        {
            textCondition: "Au moins une minuscule",
            patternCondition: "(.*[a-z].*)",
        },
        {
            textCondition: "Au moins un chiffre",
            patternCondition: "(.*[0-9].*)",
        },
        {
            textCondition: "Au moins un caractère special (#?!@$%^&*_.,-)",
            patternCondition: "(.*[#?!@$%^&_.,-].*)",
        },
    ],
    newPasswordLabel: "Nouveau mot de passe",
    confirmPasswordLabel: "Confirmer le nouveau mot de passe",
    submitButtonText: "Réinitialiser",
    connectLinkText: "Se connecter",
};
