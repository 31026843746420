import React from "react";
import Button from "../../../components/atoms/Button";

import { ReactComponent as DownloadSVG } from "./download.svg";
import { ReactComponent as DownArrowSVG } from "./downArrow.svg";
import "./style.scss";
import TextData from "../../../components/atoms/TextData";
import ToggleButton from "../../../components/atoms/Toggle";
import ColoredCircle from "../../../components/atoms/ColoredCircle";
import Calendar from "../../../components/organism/Calendar";
import moment from "moment";
import { capitalize } from "../../../_helpers/Functions";
import { useEmployees } from "../../../hooks/useEmployees";
import { useReport } from "../../../hooks/useReport";
import Loading from "../../../components/atoms/Loading";
import { useSites } from "../../../hooks/useSites";

// export interface FilterProps {
//   from: Moment;
//   to: Moment;
//   type: "week" | "month" | "period";
// }

// export default function WorkHoursPage() {
// const [filter, setFilter] = React.useState<FilterProps>({
//   from: moment().startOf("week"),
//   to: moment().endOf("week"),
//   type: "week",
// });

// const  navigate = useNavigate();
// const { getEmployeeWorkHours, employeeForEdit } = useEmployees();
// const { selectedBranchSettings } = useBranch();

// React.useEffect(() => {
//   if(selectedBranchSettings._id){
//     getEmployeeWorkHours({
//       from: filter.from.toISOString(),
//       to: filter.to.toISOString(),
//       employeeId: employeeForEdit._id,
//       siteId: selectedBranchSettings._id,
//     });
//   } else {
//     navigate("/employees")
//   }

// }, [filter, selectedBranchSettings]);

//   return (
//     <WorkHours
//       filter={filter}
//       onFilterUpdate={setFilter}
//       ShiftsTable={
//         filter.type === "month" ? <ShiftsMiniTables /> : <ShiftsTable />
//       }
//     />
//   );
// }

const WorkHoursHeader = () => {
    return (
        <tr className="header-table-work-hours">
            <td>
                <TextData
                    size="14px"
                    color="#2A346D"
                    fontWeight="700"
                    fontFamily="DM Sans"
                >
                    Date
                </TextData>
            </td>
            <td>
                <TextData
                    size="14px"
                    color="#2A346D"
                    fontWeight="700"
                    fontFamily="DM Sans"
                >
                    Shift badgé
                </TextData>
            </td>
            <td>
                <TextData
                    size="14px"
                    color="#2A346D"
                    fontWeight="700"
                    fontFamily="DM Sans"
                >
                    Shift réel
                </TextData>
            </td>
            <td>
                <TextData
                    size="14px"
                    color="#2A346D"
                    fontWeight="700"
                    fontFamily="DM Sans"
                >
                    Durée
                </TextData>
            </td>
            <td>
                <TextData
                    size="14px"
                    color="#2A346D"
                    fontWeight="700"
                    fontFamily="DM Sans"
                >
                    Repas
                </TextData>
            </td>
            <td>
                <TextData
                    size="14px"
                    color="#2A346D"
                    fontWeight="700"
                    fontFamily="DM Sans"
                >
                    Etablissement
                </TextData>
            </td>
            <td>
                <TextData
                    size="14px"
                    color="#2A346D"
                    fontWeight="700"
                    fontFamily="DM Sans"
                >
                    Commentaire
                </TextData>
            </td>
        </tr>
    );
};
const TimingComponent = (props: any) => {
    const { startTime, endTime, hasPause } = props;
    return (
        <div className="timing-container">
            <TextData
                variant="div"
                width="40px"
                size="14px"
                color="#4C5690"
                style={{ textAlign: "center" }}
            >
                {startTime}
            </TextData>

            <div className="timing-decorator">
                <ColoredCircle
                    color="#2A8BAB"
                    size={4}
                    outlineSize={4}
                    outlineColor="#A6D3E2"
                    isChecked={true}
                />
                <TextData
                    width="auto"
                    variant="label"
                    style={{
                        whiteSpace: "nowrap",
                    }}
                    color="#2A8BAB"
                >
                    {hasPause ? <pre>---</pre> : <pre>----</pre>}
                </TextData>
                <TextData
                    width="auto"
                    variant="label"
                    size="14px"
                    color="#8E96C1"
                >
                    {hasPause ? ` ( ${hasPause}min ) ` : <pre> | </pre>}
                </TextData>
                <TextData
                    width="auto"
                    variant="label"
                    style={{
                        whiteSpace: "nowrap",
                    }}
                    color="#2D2AAD"
                >
                    {hasPause ? <pre>---</pre> : <pre>----</pre>}
                </TextData>
                <ColoredCircle
                    color="#2D2AAD"
                    size={4}
                    outlineSize={4}
                    outlineColor="#A8A7E4"
                    isChecked={true}
                />
            </div>

            <TextData
                variant="div"
                width="40px"
                size="14px"
                color="#4C5690"
                style={{ textAlign: "center" }}
            >
                {endTime}
            </TextData>
        </div>
    );
};

const WorkHoursPerWeek = (props: any) => {
    const { employeeWorkHours } = useReport();
    const { getSiteName } = useSites();
    // const workHours = fkDataWorkHours(0);
    if (
        employeeWorkHours.workHourPeriod &&
        employeeWorkHours.workHourPeriod.length > 0
    ) {
        const WorkHoursElement = employeeWorkHours.workHourPeriod[0].shifts.map(
            (shift: any) => {
                return (
                    <tr className="content-table-work-hours">
                        <td>
                            <TextData
                                size="14px"
                                color="#4C5690"
                                fontWeight="600"
                                fontFamily="DM Sans"
                            >
                                {moment(shift.shiftDay)
                                    .locale("fr")
                                    .format("DD/MM/YYYY")}
                            </TextData>
                        </td>
                        <td>
                            <div style={{ display: "flex" }}>
                                <TimingComponent
                                    startTime={shift.shiftBadge.startShift}
                                    endTime={shift.shiftBadge.endShift}
                                    hasPause={shift.shiftBadge.pause}
                                />
                            </div>
                        </td>
                        <td>
                            <div style={{ display: "flex" }}>
                                <TimingComponent
                                    startTime={shift.shiftReel.startShift}
                                    endTime={shift.shiftReel.endShift}
                                    hasPause={shift.shiftReel.pause}
                                />
                            </div>
                        </td>
                        <td>
                            <TextData
                                size="14px"
                                color="#4C5690"
                                fontWeight="600"
                                fontFamily="DM Sans"
                            >
                                {shift.duration}
                            </TextData>
                        </td>
                        <td>
                            <TextData
                                size="14px"
                                color="#4C5690"
                                fontWeight="600"
                                fontFamily="DM Sans"
                            >
                                {shift.repas}
                            </TextData>
                        </td>
                        <td>
                            <TextData
                                size="14px"
                                color="#4C5690"
                                fontWeight="600"
                                fontFamily="DM Sans"
                            >
                                {getSiteName(shift.site)}
                            </TextData>
                        </td>
                        <td>
                            <TextData
                                size="14px"
                                color="#4C5690"
                                fontWeight="600"
                                fontFamily="DM Sans"
                            >
                                {shift.comment}
                            </TextData>
                        </td>
                    </tr>
                );
            },
        );

        return (
            <table className="work-hours-table">
                <WorkHoursHeader />
                {WorkHoursElement}
                <WorkHoursFooter data={employeeWorkHours.workHourPeriod[0]} />
            </table>
        );
    } else
        return (
            <div
                style={{
                    display: "flex",
                    height: "50vh",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <TextData color="#2A346D" size="14px" fontWeight="600">
                    Aucune information n'est disponible pour cette période
                </TextData>
            </div>
        );
};
const WorkHoursPerMonth = (props: any) => {
    const { currentDate } = props;
    const { employeeWorkHours } = useReport();
    const { getSiteName } = useSites();
    const workHours = employeeWorkHours.workHourPeriod; // fkDataWorkHours(1);
    const initialOpenState = Array.from(
        { length: workHours?.length || 10 },
        () => false,
    );
    const [isOpen, setIsOpen] = React.useState(initialOpenState);
    if (workHours && workHours.length > 0) {
        const WorkHoursElement = workHours?.map((shift: any, index: number) => {
            const toggleIsOpen = () => {
                setIsOpen([
                    ...isOpen.slice(0, index),
                    !isOpen[index],
                    ...isOpen.slice(index + 1),
                ]);
            };
            const SubShiftTable = (shift: any, index: number) => {
                if (isOpen[index] && shift.shifts) {
                    if (shift.shifts.length > 0) {
                        return shift.shifts.map((subShift: any) => {
                            return (
                                <tr className="content-table-work-hours">
                                    <td>
                                        <TextData
                                            size="14px"
                                            color="#4C5690"
                                            fontWeight="600"
                                            fontFamily="DM Sans"
                                        >
                                            {moment(shift.shiftDay)
                                                .locale("fr")
                                                .format("DD/MM/YYYY")}
                                        </TextData>
                                    </td>
                                    <td>
                                        <div style={{ display: "flex" }}>
                                            <TimingComponent
                                                startTime={
                                                    subShift.shiftBadge
                                                        .startShift
                                                }
                                                endTime={
                                                    subShift.shiftBadge.endShift
                                                }
                                                hasPause={
                                                    subShift.shiftBadge.pause
                                                }
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ display: "flex" }}>
                                            <TimingComponent
                                                startTime={
                                                    subShift.shiftReel
                                                        .startShift
                                                }
                                                endTime={
                                                    subShift.shiftReel.endShift
                                                }
                                                hasPause={
                                                    subShift.shiftReel.pause
                                                }
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <TextData
                                            size="14px"
                                            color="#4C5690"
                                            fontWeight="600"
                                            fontFamily="DM Sans"
                                        >
                                            {subShift.duration}
                                        </TextData>
                                    </td>
                                    <td>
                                        <TextData
                                            size="14px"
                                            color="#4C5690"
                                            fontWeight="600"
                                            fontFamily="DM Sans"
                                        >
                                            {subShift.repas}
                                        </TextData>
                                    </td>
                                    <td>
                                        <TextData
                                            size="14px"
                                            color="#4C5690"
                                            fontWeight="600"
                                            fontFamily="DM Sans"
                                        >
                                            {getSiteName(subShift.site)}
                                        </TextData>
                                    </td>
                                    <td>
                                        <TextData
                                            size="14px"
                                            color="#4C5690"
                                            fontWeight="600"
                                            fontFamily="DM Sans"
                                        >
                                            {subShift.comment}
                                        </TextData>
                                    </td>
                                </tr>
                            );
                        });
                    } else {
                        return (
                            <tr>
                                <td colSpan={7}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            width: "100%",
                                            color: "#4C5690",
                                            fontWeight: "600",
                                            paddingTop: "8px",
                                            paddingBottom: "8px",
                                            border: "1px solid #edeff5",
                                        }}
                                    >
                                        Aucune donnée n'est disponible
                                    </div>
                                </td>
                            </tr>
                        );
                    }
                }
            };
            const firstDayOfWeek = moment()
                .year(moment(currentDate).year())
                .isoWeek(shift.weekNumber)
                .startOf("isoWeek");
            const lastDayOfWeek = moment()
                .year(moment(currentDate).year())
                .isoWeek(shift.weekNumber)
                .endOf("isoWeek");
            return (
                <>
                    <tr
                        className="content-table-work-hours"
                        style={{
                            backgroundColor: isOpen[index]
                                ? "#EDEFF5"
                                : "#edeff533",
                            borderRadius: "8px 8px 0 0",
                        }}
                    >
                        <td
                            style={{
                                borderRadius: "8px 0 0 0",
                            }}
                        >
                            <TextData
                                size="14px"
                                color="#1A7290"
                                fontWeight="600"
                                fontFamily="DM Sans"
                            >
                                S{shift.weekNumber}
                            </TextData>
                            <TextData
                                size="14px"
                                color="#1A7290"
                                fontWeight="600"
                                fontFamily="DM Sans"
                            >
                                {firstDayOfWeek.format("DD")} -
                                {lastDayOfWeek.format("DD MMM")}
                            </TextData>
                        </td>
                        <td>
                            <div>
                                <TextData
                                    size="14px"
                                    color="#2A346D"
                                    fontWeight="700"
                                    fontFamily="DM Sans"
                                >
                                    Shift badgé
                                </TextData>
                                <TextData
                                    size="14px"
                                    color="#4C5690"
                                    fontWeight="600"
                                    fontFamily="DM Sans"
                                >
                                    {shift.totalShiftBadges}
                                </TextData>
                            </div>
                        </td>
                        <td>
                            <div>
                                <TextData
                                    size="14px"
                                    color="#2A346D"
                                    fontWeight="700"
                                    fontFamily="DM Sans"
                                >
                                    Shift réel
                                </TextData>
                                <TextData
                                    size="14px"
                                    color="#4C5690"
                                    fontWeight="600"
                                    fontFamily="DM Sans"
                                >
                                    {shift.totalShiftReels}
                                </TextData>
                            </div>
                        </td>
                        <td>
                            <TextData
                                size="14px"
                                color="#2A346D"
                                fontWeight="700"
                                fontFamily="DM Sans"
                            >
                                Durée
                            </TextData>
                            <TextData
                                size="14px"
                                color="#4C5690"
                                fontWeight="600"
                                fontFamily="DM Sans"
                            >
                                {shift.totalDuration}
                            </TextData>
                        </td>
                        <td>
                            <TextData
                                size="14px"
                                color="#2A346D"
                                fontWeight="700"
                                fontFamily="DM Sans"
                            >
                                Repas
                            </TextData>
                            <TextData
                                size="14px"
                                color="#4C5690"
                                fontWeight="600"
                                fontFamily="DM Sans"
                            >
                                {shift.totalRepas}
                            </TextData>
                        </td>
                        <td></td>
                        <td
                            style={{
                                borderRadius: "0 8px 0 0",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "end",
                                }}
                            >
                                <Button
                                    onClick={toggleIsOpen}
                                    backgroundColor="transparent"
                                >
                                    <DownArrowSVG
                                        color="#6F78AB"
                                        height={22}
                                        width={22}
                                        style={{
                                            padding: "6px 2.5px 5px 2.5px",
                                            transform: isOpen[index]
                                                ? "rotate(-90deg)"
                                                : "none",
                                        }}
                                    />
                                </Button>
                            </div>
                        </td>
                    </tr>
                    {SubShiftTable(shift, index)}
                </>
            );
        });
        return (
            <table className="work-hours-table">
                {/* <WorkHoursHeader /> */}
                {WorkHoursElement}
                <WorkHoursFooter />
            </table>
        );
    } else
        return (
            <div
                style={{
                    display: "flex",
                    height: "50vh",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <TextData color="#2A346D" size="14px" fontWeight="600">
                    Aucune information n'est disponible pour cette période
                </TextData>
            </div>
        );
};
const WorkHoursPerPeriod = (props: any) => {
    const { employeeWorkHours } = useReport();
    const { getSiteName } = useSites();
    // const workHours = fkDataWorkHours(0);
    let totalShiftBadges = 0;
    let totalShiftReels = 0;
    let totalDuration = 0;
    let totalRepas = 0;
    if (
        employeeWorkHours.workHourPeriod &&
        employeeWorkHours.workHourPeriod.length > 0
    ) {
        let allWorkHours = employeeWorkHours.workHourPeriod.flatMap(
            (workPeriod: any) => {
                return workPeriod.shifts;
            },
        );

        const calculateTimeDifference = (
            startShift: string,
            endShift: string,
        ) => {
            if (!startShift || !endShift) {
                return 0;
            }

            const startTime = moment(startShift, "mm:ss");
            const endTime = moment(endShift, "mm:ss");

            if (!startTime.isValid() || !endTime.isValid()) {
                return 0;
            }

            const timeDifference = moment.duration(endTime.diff(startTime));
            const timeDifferenceInMinutes = timeDifference.asMinutes();

            return timeDifferenceInMinutes;
        };
        const WorkHoursElement = allWorkHours.map((shift: any) => {
            totalDuration += parseFloat(shift.duration);
            totalRepas += parseInt(shift.repas) || 0;
            totalShiftBadges += calculateTimeDifference(
                shift.shiftBadge.startShift,
                shift.shiftBadge.endShift,
            );
            totalShiftReels += calculateTimeDifference(
                shift.shiftReel.startShift,
                shift.shiftReel.endShift,
            );

            return (
                <tr className="content-table-work-hours">
                    <td>
                        <TextData
                            size="14px"
                            color="#4C5690"
                            fontWeight="600"
                            fontFamily="DM Sans"
                        >
                            {moment(shift.shiftDay)
                                .locale("fr")
                                .format("DD/MM/YYYY")}
                        </TextData>
                    </td>
                    <td>
                        <div style={{ display: "flex" }}>
                            <TimingComponent
                                startTime={shift.shiftBadge.startShift}
                                endTime={shift.shiftBadge.endShift}
                                hasPause={shift.shiftBadge.pause}
                            />
                        </div>
                    </td>
                    <td>
                        <div style={{ display: "flex" }}>
                            <TimingComponent
                                startTime={shift.shiftReel.startShift}
                                endTime={shift.shiftReel.endShift}
                                hasPause={shift.shiftReel.pause}
                            />
                        </div>
                    </td>
                    <td>
                        <TextData
                            size="14px"
                            color="#4C5690"
                            fontWeight="600"
                            fontFamily="DM Sans"
                        >
                            {shift.duration}
                        </TextData>
                    </td>
                    <td>
                        <TextData
                            size="14px"
                            color="#4C5690"
                            fontWeight="600"
                            fontFamily="DM Sans"
                        >
                            {shift.repas}
                        </TextData>
                    </td>
                    <td>
                        <TextData
                            size="14px"
                            color="#4C5690"
                            fontWeight="600"
                            fontFamily="DM Sans"
                        >
                            {getSiteName(shift.site)}
                        </TextData>
                    </td>
                    <td>
                        <TextData
                            size="14px"
                            color="#4C5690"
                            fontWeight="600"
                            fontFamily="DM Sans"
                        >
                            {shift.comment}
                        </TextData>
                    </td>
                </tr>
            );
        });
        totalDuration = parseFloat(totalDuration.toFixed(2));
        totalShiftBadges = parseFloat(totalShiftBadges.toFixed(2));
        totalShiftReels = parseFloat(totalShiftReels.toFixed(2));
        return (
            <table className="work-hours-table">
                <WorkHoursHeader />
                {WorkHoursElement}
                <WorkHoursFooter
                    data={{
                        totalShiftBadges,
                        totalShiftReels,
                        totalDuration,
                        totalRepas,
                    }}
                />
            </table>
        );
    } else
        return (
            <div
                style={{
                    display: "flex",
                    height: "50vh",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <TextData color="#2A346D" size="14px" fontWeight="600">
                    Aucune information n'est disponible pour cette période
                </TextData>
            </div>
        );
};
const WorkHoursFooter = (props: any) => {
    if (props.data) {
        const { totalShiftBadges, totalShiftReels, totalDuration, totalRepas } =
            props.data;
        return (
            <tr className="footer-table-work-hours">
                <td>
                    <TextData
                        size="14px"
                        color="#2A346D"
                        fontWeight="700"
                        fontFamily="DM Sans"
                    >
                        Total
                    </TextData>
                </td>
                <td>
                    <TextData
                        size="14px"
                        color="#2A346D"
                        fontWeight="700"
                        fontFamily="DM Sans"
                    >
                        {totalShiftBadges}h
                    </TextData>
                </td>
                <td>
                    {/* <TextData
                        size="14px"
                        color="#2A346D"
                        fontWeight="700"
                        fontFamily="DM Sans"
                    >
                        {totalShiftReels}h
                    </TextData> */}
                </td>
                <td>
                    <TextData
                        size="14px"
                        color="#2A346D"
                        fontWeight="700"
                        fontFamily="DM Sans"
                    >
                        {totalDuration}h
                    </TextData>
                </td>
                <td>
                    <TextData
                        size="14px"
                        color="#2A346D"
                        fontWeight="700"
                        fontFamily="DM Sans"
                    >
                        {totalRepas}
                    </TextData>
                </td>
                <td>
                    <TextData
                        size="14px"
                        color="#2A346D"
                        fontWeight="700"
                        fontFamily="DM Sans"
                    >
                        -
                    </TextData>
                </td>
                <td>
                    <TextData
                        size="14px"
                        color="#2A346D"
                        fontWeight="700"
                        fontFamily="DM Sans"
                    >
                        -
                    </TextData>
                </td>
            </tr>
        );
    } else return null;
};
const WorkHoursComponent = (props: any) => {
    const { periodType, currentDate } = props;
    switch (periodType) {
        case 0:
            return <WorkHoursPerWeek />;
        case 1:
            return <WorkHoursPerMonth currentDate={currentDate} />;
        case 2:
            return <WorkHoursPerPeriod currentDate={currentDate} />;

        default:
            return (
                <table className="work-hours-table">
                    <WorkHoursHeader />
                    {/* <WorkHoursBody periodType={props.periodType} /> */}
                    <WorkHoursFooter />
                </table>
            );
    }
};

export default function WorkHoursPage() {
    const { employeeForEdit } = useEmployees();
    const { getEmployeeWorkHours, employeeWorkHours } = useReport();

    const [periodType, setPeriodType] = React.useState(0);

    // I don't see any utility for this
    // React.useEffect(() => {
    //     const workHoursLen = employeeWorkHours.workHourPeriod.length;
    //     if (workHoursLen > 0) {
    //         if (workHoursLen === 1) {
    //             setPeriodType(0);
    //         } else {
    //             setPeriodType(1);
    //         }
    //     }
    // }, [employeeWorkHours]);

    const [statusBtn, setStatusBtn] = React.useState([
        {
            textValue: "Semaine",
            selected: true,
        },
        {
            textValue: "Mois",
            selected: false,
        },
        {
            textValue: "Période",
            selected: false,
        },
    ]);
    const setFilterStatus = (status: number) => {
        setStatusBtn(
            statusBtn.map((value, index) => ({
                ...value,
                selected: index === status,
            })),
        );
        setPeriodType(status);
    };
    const StatusFilterElement = () => {
        const handleClick = (e: any) => {
            const status = parseInt(e.target.id);
            setFilterStatus(status);
        };
        return (
            <div className="status-filter-container">
                {statusBtn.map((buttton, index) => {
                    return (
                        <Button
                            width="100px"
                            onClick={handleClick}
                            id={`${index}`}
                            active={buttton.selected}
                            backgroundColor={
                                buttton.selected ? "#EDEFF5" : "#FFFFFF"
                            }
                            textColor={
                                buttton.selected ? "#101844" : "#1018447f"
                            }
                        >
                            {buttton.textValue}
                        </Button>
                    );
                })}
            </div>
        );
    };

    const [showExportBtnOptions, setShowExportBtnOptions] =
        React.useState(false);
    const handleAttendanceButtonClick = (e: any) => {
        console.log("test");
        // e.stopPropagation();
        setShowExportBtnOptions(!showExportBtnOptions);
    };
    const handleExportToggleButton = () => {
        console.log("toggle butn");
    };

    const AttendanceButton = () => {
        return (
            <div style={{ display: "flex", position: "relative" }}>
                <Button
                    width="250px"
                    height="auto"
                    backgroundColor="#2A8BAB"
                    style={{ borderRadius: "8px" }}
                    className={["attendance-button"]}
                    onClick={() => console.log("clicked btn")}
                >
                    <DownloadSVG />
                    <TextData color="#fff" size="13.5px" fontWeight="500">
                        Feuille d'émargement
                    </TextData>
                    <div
                        style={{
                            padding: "10px",
                        }}
                        onClick={handleAttendanceButtonClick}
                    >
                        <DownArrowSVG color="#CFE0E6" width={16} height={16} />
                    </div>
                </Button>
                {showExportBtnOptions && (
                    <>
                        <div className="attendance-export-container">
                            <TextData
                                color="#8E96C1"
                                size="10px"
                                fontWeight="700"
                                style={{ textTransform: "uppercase" }}
                            >
                                Options d'export
                            </TextData>
                            <hr />
                            <div className="export-toogle-box">
                                <TextData
                                    color="#2A346D"
                                    size="12px"
                                    fontWeight="400"
                                >
                                    Exporter les shifts badgé
                                </TextData>
                                <ToggleButton
                                    isChecked={true}
                                    onClick={handleExportToggleButton}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    };

    const [isCalenderOpen, setIsCalenderOpen] = React.useState(false);
    const [currentDate, setCurrentDate] = React.useState(
        moment().format("YYYY-MM-DD"),
    );
    const [startDay, setStartDay] = React.useState(
        moment(currentDate).startOf("month"),
    );
    const [endDay, setEndDay] = React.useState(
        moment(currentDate).endOf("month"),
    );
    const [selectedDate, setSelectedDate] = React.useState<any>((prev: any) => {
        const theStartDay = moment(currentDate).startOf("week");
        const theEndDay = moment(currentDate).endOf("week");
        if (theStartDay.format("MM") === theEndDay.format("MM")) {
            return {
                header: `Semaine ${moment(currentDate).isoWeek()}`,
                title: `${theStartDay.format("DD")} - ${theEndDay.format(
                    "DD MMMM YYYY",
                )}`,
            };
        } else {
            return {
                header: `Semaine ${moment(currentDate).isoWeek()}`,
                title: `${theStartDay.format("DD MMMM")} - ${theEndDay.format(
                    "DD MMMM YYYY",
                )}`,
            };
        }
    });
    React.useEffect(() => {
        if (periodType === 0) {
            const theStartDay = moment(currentDate).startOf("week");
            const theEndDay = moment(currentDate).endOf("week");
            setStartDay(theStartDay);
            setEndDay(theEndDay);
            setSelectedDate((prev: any) => {
                if (theStartDay.format("MM") === theEndDay.format("MM")) {
                    return {
                        header: `Semaine ${moment(currentDate).isoWeek()}`,
                        title: `${theStartDay.format(
                            "DD",
                        )} - ${theEndDay.format("DD MMMM YYYY")}`,
                    };
                } else {
                    return {
                        header: `Semaine ${moment(currentDate).isoWeek()}`,
                        title: `${theStartDay.format(
                            "DD MMMM",
                        )} - ${theEndDay.format("DD MMMM YYYY")}`,
                    };
                }
            });
        } else if (periodType === 1) {
            setSelectedDate({
                header: "",
                title: `${moment(currentDate).format("MMMM YYYY")}`,
            });
            setStartDay(moment(currentDate).startOf("month"));
            setEndDay(moment(currentDate).endOf("month"));
        } else {
            setSelectedDate((prev: any) => {
                if (
                    moment(startDay).format("MM") ===
                    moment(endDay).format("MM")
                ) {
                    return {
                        header: ``,
                        title: `${moment(startDay).format("DD")} - ${moment(
                            endDay,
                        ).format("DD MMMM YYYY")}`,
                    };
                } else {
                    return {
                        header: ``,
                        title: `${moment(startDay).format(
                            "DD MMMM",
                        )} - ${moment(endDay).format("DD MMMM YYYY")}`,
                    };
                }
            });
        }
    }, [currentDate, periodType]);

    React.useEffect(() => {
        if (employeeForEdit._id) {
            getEmployeeWorkHours(
                employeeForEdit._id,
                startDay.format("YYYY-MM-DD"),
                endDay.format("YYYY-MM-DD"),
            );
        }
    }, [startDay, endDay]);

    const updateMonth = (modifier: number) => {
        let newCurrentDate;
        if (periodType === 0) {
            newCurrentDate = moment(currentDate)
                .subtract(modifier, "weeks")
                .format("YYYY-MM-DD");
            setFilterStatus(0);
        } else {
            newCurrentDate = moment(currentDate)
                .subtract(modifier, "months")
                .format("YYYY-MM-DD");
            setFilterStatus(1);
        }
        setCurrentDate(newCurrentDate);

        // const newDate = moment(newCurrentDate).format("MMMM YYYY");
        // setSelectedDate(newDate);
        // setFilterStatus(1);
    };
    const handlePrevMonth = () => {
        updateMonth(1);
    };
    const handleNextMonth = () => {
        updateMonth(-1);
    };
    const handleDateCalendarSelection = async (selectedDateRange: any) => {
        const { from, to } = selectedDateRange;
        await setCurrentDate(moment(from).format("YYYY-MM-DD"));
        setStartDay(from);
        setEndDay(to);
        if (moment(from).isoWeek() === moment(to).isoWeek()) {
            setFilterStatus(0);
        } else if (
            moment(from).format("YYYY-MM-DD") ===
                moment(from).startOf("month").format("YYYY-MM-DD") &&
            moment(to).format("YYYY-MM-DD") ===
                moment(to).endOf("month").format("YYYY-MM-DD")
        ) {
            setFilterStatus(1);
        } else {
            setFilterStatus(2);
        }
        setIsCalenderOpen(false);
    };
    return (
        <div className="work-hours-container">
            <div className="header-work-hours">
                <div className="date-filter-box">
                    <StatusFilterElement />
                </div>
                <div style={{ position: "relative" }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {periodType !== 2 && (
                            <Button
                                width="auto"
                                backgroundColor="transparent"
                                onClick={handlePrevMonth}
                            >
                                <DownArrowSVG
                                    width={16}
                                    height={16}
                                    color="#2A346D"
                                    style={{
                                        transform: "rotate(90deg)",
                                    }}
                                />
                            </Button>
                        )}
                        <Button
                            width="auto"
                            backgroundColor="transparent"
                            onClick={() => {
                                setIsCalenderOpen(!isCalenderOpen);
                            }}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            {periodType === 0 && (
                                <TextData
                                    width="150px"
                                    size="12px"
                                    color="#6F78AB"
                                    fontWeight="400"
                                >
                                    {selectedDate.header}
                                </TextData>
                            )}
                            <TextData
                                width="200px"
                                size="16px"
                                color="#2A346D"
                                fontWeight="600"
                            >
                                {capitalize(selectedDate.title)}
                            </TextData>
                        </Button>
                        {periodType !== 2 && (
                            <Button
                                width="auto"
                                backgroundColor="transparent"
                                onClick={handleNextMonth}
                            >
                                <DownArrowSVG
                                    width={16}
                                    height={16}
                                    color="#2A346D"
                                    style={{
                                        transform: "rotate(-90deg)",
                                    }}
                                />
                            </Button>
                        )}
                    </div>
                    {isCalenderOpen && (
                        <div
                            style={{
                                position: "absolute",
                                padding: "7px",
                                border: "1px solid gray",
                                borderRadius: "7px",
                                backgroundColor: "white",
                                left: "50%",
                                transform: "translate(-50%,0%)",
                                zIndex: 51,
                            }}
                        >
                            <Calendar
                                open={isCalenderOpen}
                                onChange={handleDateCalendarSelection}
                                from={startDay}
                                to={endDay}
                                showWeekCount={true}
                                ranged={true}
                            />
                        </div>
                    )}
                </div>
                {isCalenderOpen && (
                    <div
                        style={{
                            position: "absolute",
                            width: "100vw",
                            height: "100vh",
                            backgroundColor: "transparent",
                            top: 0,
                            left: 0,
                            zIndex: 50,
                        }}
                        onClick={() => setIsCalenderOpen(false)}
                    ></div>
                )}
                <div className="attendance-container">
                    <AttendanceButton />
                </div>
            </div>
            <div className="body-work-hours">
                {employeeWorkHours.loading ? (
                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "50vh",
                        }}
                    >
                        <Loading />
                    </div>
                ) : (
                    <WorkHoursComponent
                        periodType={periodType}
                        currentDate={currentDate}
                    />
                )}
            </div>
        </div>
    );
}
