import React, { MouseEvent } from "react";
import { FormattedMessage } from "react-intl";
import { Spinner } from "../../../Icons";
export default function Button({
    title,
    onClick,
    active,
    variant = "default",
    size = "md",
    disabled = false,
    rightComponent,
    leftComponent,
    loading = false,
    containerClasses,
    style,
}: ButtonProps) {
    const getCssClass = () => {
        let className = "rounded-lg flex items-center justify-center gap-1";
        switch (variant) {
            case "default":
                className += " text-default bg-n-0 hover:bg-n-0-hover";
                break;
            case "primary":
                className += " text-white bg-t-500 hover:bg-t-600";
                break;
            case "primary-light":
                className += " text-t-500 bg-t-500-op-1 hover:bg-t-500-op-2";
                break;
            case "danger":
                className += " text-error bg-error-op-1 hover:bg-error-op-2";
                break;
            case "secondary":
                className += " text-white bg-v-500 hover:bg-v-600";
        }
        switch (size) {
            case "lg":
                className += " min-w-[220px] text-lg p-3 ";
                break;
            case "md":
                className += " min-w-[160px] text-md px-3 py-2";
                break;
            case "sm":
                className += " min-w-[60px] text-base px-3 py-1";
                break;
            case "block":
                className += " w-full p-3";
        }

        if (disabled) {
            className += " opacity-[0.7] cursor-not-allowed";
        }
        return className;
    };

    return (
        <>
            {loading ? (
                <button
                    className={`${getCssClass()} !cursor-not-allowed ${containerClasses}`}
                    disabled
                >
                    <Spinner />
                    <FormattedMessage id="MENU.PROCESSING" />
                </button>
            ) : (
                <button
                    className={`${getCssClass()} ${containerClasses}`}
                    onClick={(event) => {
                        if (!loading) {
                            onClick && onClick(event);
                        }
                    }}
                    style={style}
                    disabled={disabled}
                >
                    {leftComponent && leftComponent}
                    {title}
                    {rightComponent && rightComponent}
                </button>
            )}
        </>
    );
}

interface ButtonProps {
    containerClasses?: string;
    title: string | React.ReactNode;
    rightComponent?: React.ReactNode;
    leftComponent?: React.ReactNode;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    variant?: "default" | "primary" | "primary-light" | "secondary" | "danger";
    size?: "sm" | "md" | "lg" | "block";
    active?: boolean;
    disabled?: boolean;
    loading?: boolean;
    style?: React.CSSProperties;
}
