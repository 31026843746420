import * as React from "react";

import TextData from "../TextData";

import "./style.scss";
import { getColorRgba } from "../../../_helpers/Functions";

export default function DotTicket(props: DotTicketProps) {
    const { children, color, width, selected, onClick, style } = props;
    return (
        <div style={{ display: "inline-block", ...style }}>
            <div
                className="dot-ticket-container"
                onClick={onClick}
                style={{
                    backgroundColor: getColorRgba(color),
                    width: width,
                    minHeight: "32px",
                    borderRadius: "4px",
                    gap: 1,
                    border: `2px solid ${selected ? "#2a8bab" : "transparent"}`,
                }}
            >
                <div
                    className="dot-ticket-color"
                    style={{
                        backgroundColor: color,
                    }}
                ></div>
                <TextData
                    variant="label"
                    width="auto"
                    color={color}
                    fontWeight="500"
                    size="12px"
                    style={{
                        padding: "0 10px 0 0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // flexDirection: "column",
                        gap: 5,
                    }}
                >
                    {children}
                </TextData>
            </div>
        </div>
    );
}

interface DotTicketProps {
    color: string;
    children: React.ReactNode;
    width?: string;
    selected?: boolean;
    onClick?: () => void;
    style?: any;
}
DotTicket.defaultProps = {
    color: "#8833FF",
    children: "cuisine",
    width: "auto",
    selected: false,
    onClick: () => {},
};
