import React from "react";
import { CheckBox } from "../../../Icons/CheckBox";
type DAY = { label: string | React.ReactNode; value: number };

export default function DayPicker({
  days,
  value,
  onChange,
}: DayPickerInterface) {
  return (
    <div className="flex gap-3">
      {days.map((day, index) => (
        <div
          key={index}
          className={`
                        relative cursor-pointer border-[2px]  flex items-center justify-center w-[48px] h-[48px] rounded-full text-base group hover:bg-white hover:border-t-500 hover:text-t-500 hover:font-semibold
                        ${
                          value === index
                            ? "bg-white border-t-500 text-t-500 font-semibold"
                            : "bg-n-0 text-n-500 border-transparent"
                        }`}
          onClick={() => onChange(day.value)}
        >
          <div
            className={`absolute top-[0px] right-[-2px] h-[18px] w-[18px] bg-white rounded-full  p-[2px] ${
              value === day.value ? "block" : "hidden"
            } group-hover:block`}
          >
            <div className="flex items-center justify-center bg-t-500 w-full h-full rounded-full">
              <CheckBox color="white" background="transparent" />
            </div>
          </div>

          {day.label}
        </div>
      ))}
    </div>
  );
}

interface DayPickerInterface {
  days: DAY[];
  onChange: (value: number) => void;
  value: number;
}
