import * as React from "react";

import "./style.scss";
import closeIcon from "../../../utils/img/icons/close.svg";
import InfoIcon from "../../../utils/img/icons/infoWarn.svg";
import AlertIcon from "./Alert.svg";

function HeaderComponent({
    HeaderTitle,
    HeaderIcon,
    HeaderColor,
    borderColor,
}: {
    HeaderTitle?: string | null;
    HeaderIcon?: string | null;
    HeaderColor?: string;
    borderColor?: string;
}) {
    return (
        <>
            {HeaderIcon && HeaderTitle && (
                <div
                    className='header-container'
                    style={{
                        backgroundColor: borderColor,
                        color: HeaderColor,
                    }}
                >
                    <img className='header-icon' alt='' src={HeaderIcon} />
                    <h2 className='header-title'>{HeaderTitle}</h2>
                </div>
            )}
        </>
    );
}
function BodyComponent({
    style,
    title,
    body,
}: {
    style?: {
        titleStyles: React.CSSProperties;
        bodyStyles: React.CSSProperties;
    };
    title?: string;
    body?: string;
}) {
    return (
        <div className='alert-body'>
            <h2 style={style?.titleStyles}>{title}</h2>
            <p style={style?.bodyStyles}>{body}</p>
        </div>
    );
}
export default function Alert(props: AlertProps) {
    const { HeaderTitle, HeaderIcon, HeaderColor, Header, positionAbsolute } =
        props;

    const {
        width,
        borderWidth,
        borderColor,
        borderRadius,
        backgroundColor,
        closable,
        alertClosed,
        icon,
        style,
        title,
        body,
        RenderBody,
    } = props;
    const alertContainer = {
        width,
        position: positionAbsolute
            ? ("absolute" as "absolute")
            : ("relative" as "relative"),
    };
    const containerStyles = {
        borderWidth,
        borderColor,
        borderRadius,
        backgroundColor,
    };

    const [isClosed, setIsClosed] = React.useState(alertClosed);

    const handleClose = () => {
        setIsClosed(!isClosed);
    };
    if (!isClosed) {
        return (
            <div className='Alert' style={alertContainer}>
                {Header ||
                    (HeaderTitle && (
                        <HeaderComponent
                            HeaderTitle={HeaderTitle}
                            HeaderIcon={HeaderIcon}
                            HeaderColor={HeaderColor}
                            borderColor={borderColor}
                        />
                    ))}
                <div className='alert-container' style={containerStyles}>
                    {icon ? (
                        <img src={icon} alt='' className='alert-icon' />
                    ) : null}

                    {RenderBody || (
                        <BodyComponent
                            style={style}
                            title={title}
                            body={body}
                        />
                    )}

                    {closable ? (
                        <img
                            alt=''
                            src={closeIcon}
                            className='alert-closable'
                            onClick={handleClose}
                        />
                    ) : null}
                </div>
            </div>
        );
    } else {
        return null;
    }
}

interface AlertProps {
    width?: string;
    positionAbsolute: boolean;
    borderWidth?: string;
    borderColor?: string;
    borderRadius?: string;
    backgroundColor?: string;
    Header?: React.ReactElement;
    HeaderTitle?: string | null;
    HeaderColor?: string;
    HeaderIcon?: string | null;
    closable?: boolean;
    alertClosed?: boolean;
    icon?: string;
    title?: string;
    body?: string;
    style?: {
        titleStyles: React.CSSProperties; //titleStyles color, fontSize, and fontWeight)
        bodyStyles: React.CSSProperties; //bodyStyles (an object with properties color, fontSize, and fontWeight) or
    };
    RenderBody?: React.ReactElement;
}

Alert.defaultProps = {
    alertClosed: false,
    width: "90%",
    positionAbsolute: true,
    borderWidth: "4px",
    borderColor: "var(--v-500)",
    borderRadius: "5px",
    backgroundColor: "var(--v-500)",
    Header: null,
    HeaderTitle: "Alerte",
    HeaderColor: "white",
    HeaderIcon: AlertIcon,
    closable: true,
    icon: InfoIcon,
    title: "Nouvelle fonctionnalité",
    body: "Amet minim mollit nim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
    style: {
        titleStyles: { fontSize: "21px", color: "white", fontWeight: "bold" },
        bodyStyles: { fontSize: "17px", color: "white", fontWeight: "regular" },
    },
    RenderBody: null,
};
