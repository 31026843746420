import React from "react";
import Calendar from "../Calendar";
import TextData from "../../atoms/TextData";
import moment from "moment";
import { ReactComponent as CloseSVG } from "./close.svg";
import { ReactComponent as ChevronSVG } from "./Chevron.svg";
import { ReactComponent as ExpandSVG } from "./expand.svg";
import { ReactComponent as CollapseSVG } from "./collapse.svg";
import usePointeuse from "../../../hooks/usePointeuse";

export default function PointeuseSideOptions(props: any) {
    const {
        isCollapse,
        setIsCollapse,
        selectedDay,
        departmentIds,
        setDepartmentsIds,
    } = props;
    const {
        departments,
        data,
        setDepartments,
        setFilteredByDataDepartments,
        filteredDataByDepartments,
    } = usePointeuse();
    const colors = [
        "#ff6633",
        "#8833ff",
        "#33bfff",
        "#15b905",
        "#ff9600",
        "#083544",
        "#673ab7",
        "#ea1e62",
        "#0ab199",
        "#5677fd",
    ];
    function getColorRgba(color: string, outlineOpacity: string) {
        const rgbaValue = `rgba(${parseInt(
            color.slice(-6, -4),
            16,
        )}, ${parseInt(color.slice(-4, -2), 16)}, ${parseInt(
            color.slice(-2),
            16,
        )}, ${outlineOpacity})`;
        return rgbaValue;
    }

    // React.useEffect(() => {
    //     console.log(filteredDataByDepartments);
    // }, [filteredDataByDepartments]);
    return (
        <div
            className="options-section"
            style={{
                backgroundColor: "#edeff5",
                height: "100%",
                width: isCollapse ? "60px" : "230px",
                padding: "15px",
                borderLeft: "2px solid white",
            }}
        >
            <div
                style={{
                    width: "100%",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: isCollapse ? "column" : "row",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        {!isCollapse ? (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    gap: "10px",
                                }}
                            >
                                <CollapseSVG
                                    width={22}
                                    height={22}
                                    color="#11106e"
                                    style={{
                                        transform: "rotate(180deg)",
                                        cursor: "pointer",
                                        // marginLeft: "10px",
                                    }}
                                    onClick={() => setIsCollapse(true)}
                                />
                                <TextData
                                    width="max-content"
                                    variant="regular"
                                    fontWeight="700"
                                    size="16"
                                    color="#11106E"
                                    style={{
                                        overflowWrap: "break-word",
                                        marginRight: "10px",
                                        marginLeft: "5px",
                                        textAlign: "center",
                                    }}
                                >
                                    Tri par département
                                </TextData>
                            </div>
                        ) : (
                            <CollapseSVG
                                width={22}
                                height={22}
                                color="#11106e"
                                style={{
                                    cursor: "pointer",
                                    // marginLeft: "10px",
                                }}
                                onClick={() => setIsCollapse(false)}
                            />
                        )}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            padding: "0 10px 7px",
                            borderBottom: "1px solid #edeff5",
                            width: "100%",
                        }}
                    >
                        {/* <TextData
                            color='#6F78AB'
                            size='12px'
                            fontWeight='400'
                            style={{
                                fontFamily: "DM Sans",
                                cursor: "pointer",
                                width: isCollapse ? "60px" : "100px",
                            }}
                        >
                            {isCollapse ? "S" : "Semaine"} 38
                        </TextData> */}
                        {/* {!isCollapse && (
                            <TextData
                                color='#2A346D'
                                size='16px'
                                fontWeight='600'
                                style={{
                                    fontFamily: "DM Sans",
                                    cursor: "pointer",
                                    textTransform: "capitalize",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                {moment().format("MMMM YYYY")}
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "0px",
                                    }}
                                >
                                    <ChevronSVG
                                        height={22}
                                        width={22}
                                        color='#2A346D'
                                    />
                                    <ChevronSVG
                                        height={22}
                                        width={22}
                                        color='#2A346D'
                                        style={{
                                            transform: "rotate(180deg)",
                                        }}
                                    />
                                </div>
                            </TextData> 
                        )}*/}
                    </div>
                </div>
                {/* <TextData
                    color='#2A346D'
                    size='16px'
                    fontWeight='600'
                    style={{
                        fontFamily: "DM Sans",
                        cursor: "pointer",
                        textTransform: "capitalize",
                        paddingLeft: "10px",
                    }}
                >
                    
                </TextData> */}
                {/* <Calendar
                    calenderContainerClasses='border _shaddow'
                    open={true}
                    onChange={(value) => {}}
                    value={moment()}
                    showWeekCount={true}
                  
                /> */}
            </div>
            {!isCollapse && (
                <div
                    style={{
                        backgroundColor: "white",
                        padding: "5px",
                        borderRadius: "5px",
                        marginTop: "20px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            // borderTop: "1px solid #edeff5",
                            borderBottom: "1px solid #edeff5",
                            padding: "3px 6px",
                            color: "#090844",
                            fontSize: "14px",
                        }}
                    >
                        <div style={{ paddingLeft: "5px" }}>Départements</div>
                        {/* <div
                            style={{
                                marginLeft: "auto",
                                display: "flex",
                                gap: "3px",
                                alignItems: "center",
                                fontSize: "12px",
                                color: "#4c5690",
                            }}
                        >
                            <CloseSVG height={12} width={12} color='inherit' />
                            Effacer
                        </div> */}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexDirection: "column",
                            fontSize: "12px",

                            // backgroundColor: "white",

                            borderRadius: "5px",
                            padding: "5px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "100%",
                                gap: 5,
                                padding: "3px 6px",
                                marginTop: "5px",
                                backgroundColor:
                                    departmentIds.length === 0
                                        ? getColorRgba("#2a346d", "0.2")
                                        : "",
                                borderRadius: "5px",
                                cursor: "pointer",
                            }}
                            onClick={() => setDepartmentsIds([])}
                        >
                            <div
                                style={{
                                    height: "8px",
                                    width: "8px",
                                    borderRadius: "50%",
                                    backgroundColor: "#2a346d",
                                }}
                            ></div>
                            <div
                                style={{
                                    color: "#2a346d",
                                }}
                            >
                                Voir tout le monde
                            </div>
                            <div
                                style={{
                                    marginLeft: "auto",
                                    backgroundColor: "#e0e2e8",
                                    borderRadius: "5px",
                                    padding: "0 3px",
                                    color: "#4c5690",
                                }}
                            >
                                {Object.keys(data).length}
                            </div>
                        </div>
                        {departments?.map((department: any, index: number) => {
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        width: "100%",
                                        gap: 5,
                                        padding: "3px 6px",
                                        marginTop: "5px",
                                        backgroundColor: departmentIds.includes(
                                            department._id,
                                        )
                                            ? getColorRgba(
                                                  colors[index % 10],
                                                  "0.2",
                                              )
                                            : "",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setDepartmentsIds((prev: any) => {
                                            let arr: String[] = [];
                                            let found = false;
                                            for (let id of prev) {
                                                if (id === department._id) {
                                                    found = true;
                                                    continue;
                                                }
                                                arr.push(id);
                                            }
                                            if (!found) {
                                                arr.push(department._id);
                                            }
                                            return arr;
                                        });
                                    }}
                                >
                                    <div
                                        style={{
                                            height: "8px",
                                            width: "8px",
                                            borderRadius: "50%",
                                            backgroundColor: colors[index % 10],
                                        }}
                                    ></div>
                                    <div
                                        style={{
                                            color: colors[index % 10],
                                        }}
                                    >
                                        {department.name}
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: "auto",
                                            padding: "0 3px",
                                            color: "#4c5690",
                                        }}
                                    >
                                        {department.pointage}
                                    </div>
                                </div>
                            );
                        })}
                        {/* <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "100%",
                                gap: 5,
                                padding: "3px 6px",
                                marginTop: "5px",
                                backgroundColor: "rgba(255, 102, 51, 0.18)",
                                borderRadius: "5px",
                            }}
                        >
                            <div
                                style={{
                                    height: "8px",
                                    width: "8px",
                                    borderRadius: "50%",
                                    backgroundColor: "#ff6633",
                                }}
                            ></div>
                            <div
                                style={{
                                    color: "#ff6633",
                                }}
                            >
                                Cuisine
                            </div>
                            <div
                                style={{
                                    marginLeft: "auto",
                                    padding: "0 3px",
                                    color: "#4c5690",
                                }}
                            >
                                6
                            </div>
                        </div> */}
                        {/* <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "100%",
                                gap: 5,
                                padding: "3px 6px",
                                marginTop: "5px",
                            }}
                        >
                            <div
                                style={{
                                    height: "8px",
                                    width: "8px",
                                    borderRadius: "50%",
                                    backgroundColor: "#2a346d",
                                }}
                            ></div>
                            <div
                                style={{
                                    color: "#2a346d",
                                }}
                            >
                                Pizza
                            </div>
                            <div
                                style={{
                                    marginLeft: "auto",
                                    padding: "0 3px",
                                    color: "#4c5690",
                                }}
                            >
                                2
                            </div>
                        </div> */}
                        {/* <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "100%",
                                gap: 5,
                                padding: "3px 6px",
                                marginTop: "5px",
                                backgroundColor: "rgba(136, 51, 255, 0.18)",
                                borderRadius: "5px",
                            }}
                        >
                            <div
                                style={{
                                    height: "8px",
                                    width: "8px",
                                    borderRadius: "50%",
                                    backgroundColor: "#8833ff",
                                }}
                            ></div>
                            <div
                                style={{
                                    color: "#8833ff",
                                }}
                            >
                                Salle
                            </div>
                            <div
                                style={{
                                    marginLeft: "auto",
                                    padding: "0 3px",
                                    color: "#4c5690",
                                }}
                            >
                                10
                            </div>
                        </div> */}
                    </div>
                </div>
            )}
        </div>
    );
}
