import React from "react";
import { useDropzone } from "react-dropzone";
import { asyncFileReader } from "../../../_helpers/FileHelpers";
import { generatePicUrl } from "../../../_helpers/PictureHelpers";
import { Camera } from "../../../Icons";
import { MinimizedLogo } from "../../../Icons";
import Swal from "sweetalert2";

export default function DropzoneInput({
    label,
    file,
    onChange,
    imgContainerStyles,
    imgStyle,
}: DropzoneInputInterface) {
    const [imgReader, setImgReader] = React.useState<any>();

    React.useEffect(() => {
        if (file) {
            getFileSource(file);
        } else {
            //setImgReader("/assets/imgs/Fileinput.png");
        }
    }, [file]);

    const onDrop = (acceptedFiles: Array<File>) => {
        const file = acceptedFiles[0];
        onChange(file);
    };

    const getFileSource = async (file: string | File) => {
        if (typeof file == "string") {
            setImgReader(generatePicUrl(file));
            return;
        }
        let src = await asyncFileReader(file as File);
        setImgReader(src);
    };
    const onDropRejected = (fileRejections: any) => {
        fileRejections.forEach((file: any) => {
            Swal.fire({
                title: "La photo est trop volumineuse",
                text: "Veuillez choisir une photo d'une taille inférieure à 20MB",
                icon: "error",
            });
        });
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        onDropRejected,
        multiple: false,
        maxFiles: 1,
        maxSize: 21000000, //size in bytes (21000000 bytes = 21 MB)
        accept: {
            "image/*": [".png", ".gif", ".jpeg", ".jpg", ".bmp", ".svg"],
        },
    });
    return (
        <div>
            {label && <label>{label}</label>}
            <input {...getInputProps()} />
            {
                <div
                    {
                        ...getRootProps() /*  bg-no-repeat bg-center bg-[url(../public/assets/imgs/Fileinput.png)]*/
                    }
                    style={imgContainerStyles}
                    className="relative  bg-n-0 w-200px h-200px  cursor-pointer rounded-full"
                >
                    {imgReader ? (
                        <img
                            className="w-full h-full rounded-full"
                            style={imgStyle}
                            src={imgReader}
                        />
                    ) : (
                        <div className="flex h-full items-center justify-center">
                            <MinimizedLogo />
                        </div>
                    )}
                    <div className="absolute h-[60px] w-[60px] right-0 bottom-0 bg-white rounded-full p-[4px]">
                        <div className=" h-full w-full bg-t-500 text-t-500 rounded-full flex items-center justify-center">
                            <Camera />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

interface DropzoneInputInterface {
    label?: string;
    file?: File | string;
    onChange(file: File): void;
    imgContainerStyles?: React.CSSProperties;
    imgStyle?: React.CSSProperties;
}
