import * as React from "react";

import "./style.scss";

import PopupCard from "../../molecules/PopUpCard";
import ListOfColors from "../../molecules/ListOfColors";
import Input from "../../atoms/Input";
import TextData from "../../atoms/TextData";
import SVGIcon from "../../../utils/img/icons/close.svg";
import { usePlanning } from "../../../hooks/usePlanning";

export default function NewShiftTicketCard(
    props: any /*NewShiftTicketCardProps*/,
) {
    //input field
    const {
        header,
        onClose,
        handleTicketDelete,
        inputValue,
        inputOnChange,
        inputLabel,
        inputLabelColor,
        inputLabelSize,
        inputBorderColor,
        inputTextSize,
        inputTextColor,
        inputRadius,
        inputPlaceholder,
        inputWidth,
        isEdit,
        data,
    } = props;
    //selection color field
    const { selectionText, selectionColor, selectionSize } = props;
    const { colors, onSelectionColor, displayColorsCount } = props;
    const { /* onCancelButton,*/ onSubmitButton } = props;
    const onSubmit = () => {
        onSubmitButton(steakerData);
        handleClose();
    };
    const [selectedColor, setselectedColor] = React.useState(selectionColor);
    const [steakerData, setSteakerData] = React.useState({
        id: null,
        fromHour: "",
        toHour: "",
        name: "",
        color: "",
    });
    const handleClose = () => {
        setSteakerData({
            id: null,
            fromHour: "",
            toHour: "",
            name: "",
            color: "",
        });
        onClose && onClose();
    };
    React.useEffect(() => {
        console.log("we are executed steakerData = ", steakerData);
    }, [steakerData]);
    const [tickets, settickets] = React.useState(() => {
        if (inputValue === "") {
            return [];
        }
        return [inputValue];
    });
    React.useEffect(() => {
        if (data?.name) {
            setSteakerData((prev: any) => ({ ...prev, name: data.name }));
        }
        if (data?.color) {
            setSteakerData((prev: any) => ({ ...prev, color: data.color }));
        }
        if (data?._id) {
            setSteakerData((prev: any) => ({ ...prev, id: data._id }));
        }
        if (data?.fromHour) {
            setSteakerData((prev: any) => ({
                ...prev,
                fromHour: data.fromHour,
            }));
        }
        if (data?.toHour) {
            setSteakerData((prev: any) => ({ ...prev, toHour: data.toHour }));
        }
    }, [data]);

    // const handleTicketChange = (data: any) => {
    //     const inputData = data.trim();
    //     if (inputData === "") {
    //         settickets([]);
    //     }
    //     if (inputData.endsWith(".")) {
    //         settickets(inputData.split(".").filter(Boolean));
    //         setinputValue("");
    //     } else {
    //         setinputValue(data);
    //     }
    // };
    // React.useEffect(() => {
    //     if (inputVal !== "") {
    //         inputOnChange?.(inputVal);
    //     }
    // }, [inputVal]);

    const handleSelectedColor = (color: string) => {
        // if (inputVal !== "") {
        //     settickets(inputVal.split(".").filter(Boolean));
        //     setinputValue("");
        // }
        setSteakerData((prev) => ({
            ...prev,
            color,
        }));
        setselectedColor(color);
        onSelectionColor?.(color);
    };
    // const handleDoubleClickTag = (e: any) => {
    //     setinputValue(String(tickets));
    //     settickets([]);
    // };
    const onDelete = () => {
        handleTicketDelete(steakerData.id);
    };
    return (
        <PopupCard
            header={header}
            onClose={handleClose}
            isCloseIcon={true}
            onClickButton1={isEdit ? onDelete : undefined}
            button1Text={isEdit ? "Supprimer" : ""}
            button1Style={{
                backgroundColor: isEdit ? "red" : "",
                color: "white",
            }}
            onClickButton2={onSubmit}
            button2Text={isEdit ? "Modifier" : "Ajouter"}
        >
            <div className="new-ticket-card-container">
                <Input
                    captionText={
                        inputLabel + ` (${steakerData.name.length}/20) `
                    }
                    style={{
                        inputStyle: {
                            flexDirection: "row-reverse",
                        },
                    }}
                    captionColor={inputLabelColor}
                    captionSize={inputLabelSize}
                    borderColor={inputBorderColor}
                    borderWidth={1}
                    textSize={inputTextSize}
                    textColor={inputTextColor}
                    inputRadius={inputRadius}
                    placeholder={inputPlaceholder}
                    inputWidth={inputWidth}
                    maxLength={20}
                    onChange={(value: string) => {
                        if (value.length <= 20) {
                            setSteakerData((prev) => ({
                                ...prev,
                                name: value,
                            }));
                        }
                    }}
                    value={steakerData.name}

                    // tags={tickets.map((ticket) => {
                    //     return (
                    //         <div
                    //             className="new-ticket"
                    //             style={{
                    //                 backgroundColor: selectedColor,
                    //                 marginRight: "auto",
                    //             }}
                    //             // onClick={handleDoubleClickTag}
                    //         >
                    //             <div></div>
                    //             <label>{ticket}</label>
                    //         </div>
                    //     );
                    // })}
                />
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        gap: "10px",
                    }}
                >
                    <Input
                        type="time"
                        captionText={"Heure début"}
                        // defaultValue={absenceData.from}
                        // onBlur={(value: any) =>
                        //     // setAbsenceData((prev: any) => ({
                        //     //     ...prev,
                        //     //     from: value,
                        //     // }))
                        // }
                        inputWidth="calc(50% - 5px)"
                        captionColor={inputLabelColor}
                        captionSize={inputLabelSize}
                        borderColor={inputBorderColor}
                        borderWidth={1}
                        textSize={inputTextSize}
                        textColor={inputTextColor}
                        inputRadius={inputRadius}
                        placeholder={inputPlaceholder}
                        // borderColor='#C7CBE2'
                        // captionColor='#C7CBE2'
                        // borderWidth={1}
                        // captionSize='12px'
                        // iconImg={Clock}
                        // inputRadius='4px'
                        // textColor='#4C5690'
                        // textSize={14}
                        iconLocation="right"
                        onChange={(value: string) => {
                            setSteakerData((prev) => ({
                                ...prev,
                                fromHour: value,
                            }));
                        }}
                        value={steakerData.fromHour}
                        style={{
                            inputStyle: {
                                // minHeight: "48px",
                                fontWeight: "400",
                                width: "100%",
                            },
                            captionStyle: {
                                // fontWeight: "500",
                            },
                        }}
                    />
                    <Input
                        type="time"
                        captionText={"Heure fin"}
                        // defaultValue={absenceData.from}
                        // onBlur={(value: any) =>
                        //     // setAbsenceData((prev: any) => ({
                        //     //     ...prev,
                        //     //     from: value,
                        //     // }))
                        // }
                        inputWidth="calc(50% - 5px)"
                        captionColor={inputLabelColor}
                        captionSize={inputLabelSize}
                        borderColor={inputBorderColor}
                        borderWidth={1}
                        textSize={inputTextSize}
                        textColor={inputTextColor}
                        inputRadius={inputRadius}
                        placeholder={inputPlaceholder}
                        onChange={(value: string) => {
                            setSteakerData((prev) => ({
                                ...prev,
                                toHour: value,
                            }));
                        }}
                        value={steakerData.toHour}
                        // borderColor='#C7CBE2'
                        // captionColor='#C7CBE2'
                        // borderWidth={1}
                        // captionSize='12px'
                        // iconImg={Clock}
                        // inputRadius='4px'
                        // textColor='#4C5690'
                        // textSize={14}
                        iconLocation="right"
                        style={{
                            inputStyle: {
                                // minHeight: "48px",
                                fontWeight: "400",
                                width: "100%",
                            },
                            captionStyle: {
                                // fontWeight: "500",
                            },
                        }}
                    />
                </div>
                <div>
                    <TextData color={steakerData.color} size={selectionSize}>
                        {selectionText}
                    </TextData>
                    <ListOfColors
                        getSelectedColor={handleSelectedColor}
                        colors={colors}
                        width="auto"
                        displayCount={displayColorsCount}
                        colorSelection={steakerData.color}
                    />
                </div>
            </div>
        </PopupCard>
    );
}

interface NewShiftTicketCardProps {
    header?: string;
    onClose?: () => void;
    //input field
    inputLabel?: string;
    inputLabelColor?: string;
    inputLabelSize?: string;
    inputBorderColor?: string;
    inputTextSize?: number;
    inputTextColor?: string;
    inputRadius?: string;
    inputPlaceholder?: string;
    inputWidth?: string;
    inputValue?: string;
    inputOnChange?: (text: string) => void;
    //selection color field
    selectionText?: string;
    selectionColor?: string;
    selectionSize?: string;
    //list of colors field
    onSelectionColor?: (color: string) => void;
    colors?: string[];
    displayColorsCount?: number;
    // submit
    onCancelButton?: () => void;
    onSubmitButton?: any;
}

NewShiftTicketCard.defaultProps = {
    header: "Ajouter une étiquette",
    //input field
    inputLabel: "Nom de l'étiquette",
    inputValue: "",
    inputLabelColor: "#8E96C1",
    inputLabelSize: "12px",
    inputBorderColor: "#C7CBE2",
    inputTextSize: 14,
    inputTextColor: "#2A346D",
    inputRadius: "4px",
    inputPlaceholder: "",
    inputWidth: "100%",
    inputOnChange: (data: string) => {},
    //selection color field
    selectionText: "Choisir la couleur",
    selectionColor: "#8E96C1",
    selectionSize: "12px",
    //list of colors field
    onSelectionColor: (color: string) => {},
    colors: [
        "#5677FD",
        "#304FFD",
        "#2935B1",
        "#673AB7",
        "#651EFF",
        "#301A92",
        "#EA1E62",
        "#BB0F5C",
        "#880E4F",
        "#058BDB",
        "#0288D1",
        "#01579B",

        "#009688",
        "#0AB199",
        "#004D40",
        "#259B24",
        "#15B905",
        "#0D5302",
        "#FF9600",
        "#F26A04",
        "#E65100",
        "#8C6E64",
        "#795548",
        "#3E2622",

        "#465963",
        "#263238",
        "#1A252A",
    ],
    displayColorsCount: 8,
    // submit
    onCancelButton: () => {},
    onSubmitButton: () => {},
};
