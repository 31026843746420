import React from "react";
import { CheckBox } from "../../../Icons/CheckBox";

export default function StepButton({
  checked,
  current,
}: StepButtonInterface) {
  return (
    <div
      className={`flex justify-center items-center border  rounded-full relative border-box w-[36px] h-[36px] ${
        checked
          ? "bg-default-active border-default-active"
          : "bg-white border-g-100"
      }`}
    >
      {checked == true ? (
        <CheckBox color="white" background="text-t-500" width={24} height={24}/>
      ) : current ? (
        <span

          className="inline-block bg-default-active rounded-full absolute border-box w-[14px] h-[14px]"
        />
      ) : (
        <></>
      )}
    </div>
  );
}

interface StepButtonInterface {
  checked: boolean;
  current: boolean;
}
