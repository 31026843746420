import React from "react";
import { CheckBox } from "../../../Icons/CheckBox";
type DAY = { label: string | React.ReactNode; value: number };

export default function DaysPicker({
  days,
  value,
  onChange,
}: DaysPickerInterface) {
  return (
    <div className="flex gap-3">
      {days.map((day, index) => (
        <div
          key={index}
          className={`
                        relative  cursor-pointer border-[2px]  flex items-center justify-center w-[48px] h-[48px] rounded-full text-base 
                        ${
                          value.includes(index)
                            ? "bg-white border-t-500 text-t-500 font-semibold"
                            : "bg-n-0 text-n-500 border-transparent"
                        }`}
          onClick={() => {
            if (value.includes(index)) {
              onChange(value.filter((val) => val !== index));
            } else {
              onChange([...value, index]);
            }
          }}
        >
          {value.includes(index) && (
            <div className="absolute top-[0px] right-[-2px] h-[18px] w-[18px] bg-white rounded-full  p-[2px]">
              <div className="flex items-center justify-center bg-t-500 w-full h-full rounded-full">
                <CheckBox color="white" background="transparent" />
              </div>
            </div>
          )}
          {day.label}
        </div>
      ))}
    </div>
  );
}

interface DaysPickerInterface {
  days: DAY[];
  onChange: (value: number[]) => void;
  value: number[];
}
