import React from "react";
import { FormattedMessage } from "react-intl";

export default function HRFolderStatics({ value }: HRFolderStaticsInterface) {
  return (
    <div className="w-[150px]">
      <div className="flex justify-between">
        <span className={`inline-block font-medium text-base text-t-500 `}>
          {value === 100 ? (
            <FormattedMessage id="MENU.COMPLETED" />
          ) : (
            <FormattedMessage id="MENU.INCOMPLETE" />
          )}
        </span>
        <span
          className={`font-semibold text-base ${
            value === 100 ? "text-t-500" : "text-n-500"
          }`}
        >
          {value.toFixed(0)}%
        </span>
      </div>
      <div className="relative h-[8px] bg-t-0 rounded">
        <span
          className={`absolute top-0 left-0 rounded h-full  ${
            value === 100 ? "bg-t-500" : "bg-t-300"
          }`}
          style={{
            width: `${value}%`,
          }}
        />
      </div>
    </div>
  );
}

interface HRFolderStaticsInterface {
  value: number;
}
