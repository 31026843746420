import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import {
    getTimeTrackerService,
    updateTimeTrackerService,
} from "../redux/features/settings/service";
import { updateTimeTrackerSetting } from "../redux/features/settings/settingsSlice";
import { timeTrackerPayload } from "../redux/features/settings/types";
// import { updateTimeTrackerRequest } from "./types";
export const useTimeTracker = () => {
    const dispatch = useAppDispatch();
    const selectedBranchSettings = useAppSelector(
        (state: any) => state.settings,
    ).selectedBranch;
    const timeTracker = useAppSelector(
        (state: any) => state.settings,
    ).timeTracker;

    const getTimeTracker = async () => {
        const token = localStorage.getItem("token");
        if (token && selectedBranchSettings._id) {
            dispatch(
                getTimeTrackerService({
                    token,
                    branchID: selectedBranchSettings._id,
                }),
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    //navigate(ROUTER.SETTINGS.BRANCH);
                }
            });
        }
    };
    const saveTimeTracker = (payload: timeTrackerPayload) => {
        dispatch(updateTimeTrackerSetting(payload));
    };

    const updateTimeTracker = (data?: any) => {
        const token = localStorage.getItem("token");
        if (token && selectedBranchSettings._id && timeTracker) {
            let toUpdate: any = { ...timeTracker };
            if (data?.rotatePhoto !== undefined) {
                toUpdate = { ...toUpdate, rotatePhoto: data.rotatePhoto };
            }
            dispatch(
                updateTimeTrackerService({
                    token,
                    branchID: selectedBranchSettings._id,
                    timeTracker: toUpdate,
                }),
            ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                    //navigate(ROUTER.SETTINGS.BRANCH);
                    getTimeTracker();
                }
            });
        }
    };

    return {
        timeTracker,
        getTimeTracker,
        saveTimeTracker,
        updateTimeTracker,
    };
};
