import React from "react";
import Datatable from "../../../../components/organism/Datatable";
import * as ColumnFormatter from "./ColumnFormatters";
import { useEmployees } from "../../../../hooks/useEmployees";
import SelectInput from "../../../../components/atoms/SelectInput";
import { MONTHS } from "../../../../_helpers/CalendarHelpers";
const { DateTime } = require("luxon");

export default function PrimesTable({
    openEditPrimesDialog,
    openDeletePrimesDialog,
    primes,
}: PrimesTableProps) {
    const { sortPrimesByField } = useEmployees();
    const columns = [
        {
            dataField: "typeAcompte",
            text: "Type",
            formatter: ColumnFormatter.TypeColumnFormatter,
            sort: true,
        },
        {
            dataField: "montant",
            text: "Montant",
            sort: true,
        },
        {
            dataField: "date",
            text: "Date",
            formatter: ColumnFormatter.DateColumnFormatter,
            sort: true,
        },
        {
            dataField: "commentaire",
            text: "Commentaire",
        },
        {
            dataField: "",
            text: "Actions",
            formatter: ColumnFormatter.ActionColumnFormattter,
            formatExtraData: {
                openEditPrimesDialog,
                openDeletePrimesDialog,
            },
        },
    ];
    const [sortOrder, setSortOrder] = React.useState("asc");

    const [filtredPrimes, setFiltredPrimes] = React.useState(primes);
    React.useEffect(() => {
        if (primes) {
            setFiltredPrimes(primes);
        }
    }, [primes]);

    const yearsListOptions = () => {
        const currentYear = DateTime.now().year;
        let years = [];
        for (let year = currentYear; year >= currentYear - 10; year--) {
            years.push({ label: year, value: year });
        }
        return years;
    };
    const [yearsList, setyearsList] = React.useState(yearsListOptions());
    const [selectedYearOption, setSelectedYearOption] = React.useState(
        yearsList[0],
    );

    const [monthsList, setmonthsList] = React.useState(() => {
        return [{ label: "Tous", value: -1 }, ...MONTHS];
    });
    const [selectedMonthOption, setSelectedMonthOption] = React.useState({
        label: "Tous",
        value: -1,
    });

    const onYearSelection = (yearSelection: any) => {
        setSelectedYearOption(yearSelection);
    };
    const onMonthSelection = (monthSelection: any) => {
        setSelectedMonthOption(monthSelection);
    };

    React.useEffect(() => {
        if (selectedYearOption.value > 0) {
            const primesByYears = primes.filter((primeItem: any) => {
                const yearOfPrime = DateTime.fromISO(primeItem.date).year;
                return yearOfPrime === selectedYearOption.value;
            });
            if (selectedMonthOption.value >= 0) {
                const primesByMonth = primesByYears.filter((primeItem: any) => {
                    const monthOfPrime = DateTime.fromISO(primeItem.date).month;
                    return monthOfPrime - 1 === selectedMonthOption.value;
                });
                setFiltredPrimes(primesByMonth);
            } else {
                setFiltredPrimes(primesByYears);
            }
        }
    }, [selectedYearOption, selectedMonthOption, primes]);

    return (
        <>
            <div
                style={{
                    display: "flex",
                    gap: "7px",
                    marginLeft: "15px",
                }}
            >
                <SelectInput
                    options={monthsList}
                    selectedValue={selectedMonthOption}
                    title="Mois"
                    style={{ width: "150px" }}
                    isSearchable={false}
                    onSelectOption={onMonthSelection}
                />
                <SelectInput
                    options={yearsList}
                    selectedValue={selectedYearOption}
                    title="Années"
                    style={{ width: "150px" }}
                    isSearchable={false}
                    onSelectOption={onYearSelection}
                />
            </div>
            <Datatable
                data={filtredPrimes}
                columns={columns}
                onTableSort={(field, order) => {
                    sortPrimesByField(field, sortOrder);
                    setSortOrder((prev) => {
                        if (prev === "asc") {
                            return "desc";
                        } else {
                            return "asc";
                        }
                    });
                }}
            />
        </>
    );
}

interface PrimesTableProps {
    openEditPrimesDialog: (data?: any) => void;
    openDeletePrimesDialog: (data?: any) => void;
    primes: any[];
}
