import * as React from "react";
import { useNavigate, Link } from "react-router-dom";

import { ROUTER } from "../../../constants/env";

import TextData from "../../atoms/TextData";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import Checkbox from "../../atoms/Checkbox";
import Picture from "../../atoms/Picture";

import "./style.scss";
import emailIcon from "./assets/emailIcon.svg";
import passwordIcon from "./assets/passwordIcon.svg";
import logo from "./assets/logo.svg";
import { useLogin } from "../../../hooks/useLogin";
export default function LoginMolecule(props: LoginMoleculeProps) {
    const {
        heading,
        subHeading,
        inputTextCaption,
        inputPlaceholder,
        checkboxText,
        buttonText,
        forgetText,
        errorMessage,
        forgetPassOnClick,
        handleCheckbox,
    } = props;
    const { auth, setEmail, setPassword, setIsRememberedMe, setToken, login } =
        useLogin();
    const [passwordType, setpasswordType] = React.useState("password");

    const globalProps = {
        headingProps: {
            variant: "label" as "label",
            color: "#555",
            width: "100%",
            size: "40px",
            style: {
                textAlign: "center" as "center",
                fontWeight: "700",
            },
            className: ["login-title"],
        },
        subHeadingProps: {
            variant: "label" as "label",
            color: "#555",
            width: "100%",
            size: "14px",
            style: { textAlign: "center" as "center" },
            className: ["login-subtitle"],
        },
        emailProps: {
            autoComplete: "on" as "on",
            value: auth.email,
            captionText: inputTextCaption?.[0],
            captionColor: "#809FB8",
            captionSize: "12px",
            borderWidth: 1,
            borderColor: "#3598b8",
            textSize: 14,
            textColor: "#555",
            iconImg: emailIcon,
            inputRadius: "4px",
            inputWidth: "100%",
            placeholder: inputPlaceholder?.[0],
            style: {
                captionStyle: {
                    marginTop: "14px",
                    fontWeight: "400px",
                },
                inputStyle: {
                    padding: "10px 16px",
                    height: "48px",
                    alignItems: "center",
                },
                errorStyle: {},
            },
            onChange: (email: string) => {
                setEmail(email.trim());
            },
            errorColor: "#7D2828",
            errorIsActivated: auth.error,
        },
        passwordProps: {
            autoComplete: "on" as "on",
            value: auth.password,
            captionColor: "#809FB8",
            captionSize: "12px",
            borderWidth: 1,
            borderColor: "#3598b8",
            textSize: 14,
            textColor: "#555",
            inputRadius: "4px",
            inputWidth: "100%",
            style: {
                captionStyle: {
                    marginTop: "11px",
                    fontWeight: "400px",
                },
                inputStyle: {
                    padding: "10px 16px",
                    height: "48px",
                    alignItems: "center",
                },
                errorStyle: {
                    marginTop: "7px",
                    backgroundColor: "#FFDEDE",
                    borderRadius: "4px",
                    padding: "8px 10px",
                },
            },
            captionText: inputTextCaption?.[1],
            placeholder: inputPlaceholder?.[1],
            iconImg: passwordIcon,
            onChange: (pass: string) => {
                setPassword(pass);
            },
            errorColor: "#7D2828",
            errorIsActivated: auth.error,
            errorSize: 12,
            errorText: errorMessage,
            type: (passwordType as "text") || "password",
            onClickIconImg: () => {
                setpasswordType(
                    passwordType === "password" ? "text" : "password",
                );
            },
        },
        checkboxProps: {
            text: checkboxText,
            textSize: "14px",
            textColor: "#555",
            checked: auth.isRememberedMe,
            id: "save-checkbox",
            style: {
                divStyle: {
                    marginTop: "16px",
                    alignSelf: "flex-start",
                },
            },
            onClick: (data: any) => {
                setIsRememberedMe(data.target.checked);
                handleCheckbox?.(data.target.checked);
            },
        },
        buttonProps: {
            width: "100%",
            height: "50px",
            backgroundColor: "#3598B8",
            textColor: "white",
            padding: "10px 5px 5px 10px",
            style: {
                marginTop: 15,
                marginBottom: 15,
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "-0.5px",
            },
            onClick: () => {
                login();
            },
        },
        forgetPassProps: {
            variant: "div" as "div",
            color: "#809FB8",
            width: "100%",
            activateHover: true,
            style: {
                textAlign: "center" as "center",
                textDecoration: "underline",
                marginBottom: 20,
                fontWeight: "700",
                fontSize: "14px",
            },
            onClick: forgetPassOnClick,
        },
    };

    return (
        <div className="LoginMolecule-container">
            <TextData {...globalProps.headingProps}>{heading}</TextData>
            <TextData {...globalProps.subHeadingProps}>{subHeading}</TextData>
            <Input {...globalProps.emailProps} />
            <Input {...globalProps.passwordProps} />
            <Checkbox {...globalProps.checkboxProps} />
            <Button {...globalProps.buttonProps}>{buttonText}</Button>
            <Link to={ROUTER.AUTH.FORGET_PASS}>
                <TextData {...globalProps.forgetPassProps}>
                    {forgetText}
                </TextData>
            </Link>
            <Link to={ROUTER.HOME}>
                <Picture imgPath={logo} />
            </Link>
        </div>
    );
}

interface LoginMoleculeProps {
    heading?: string;
    subHeading?: string;
    inputTextCaption?: string[];
    inputPlaceholder?: string[];
    checkboxText?: string;
    buttonText?: string;
    forgetText?: string;
    errorMessage?: string;
    forgetPassOnClick?: React.MouseEventHandler<HTMLElement>;
    handleCheckbox?: React.MouseEventHandler<HTMLElement>;
}

LoginMolecule.defaultProps = {
    heading: "Bienvenue",
    subHeading: "Connectez-vous à votre compte",
    inputTextCaption: ["E-mail", "Mot de passe"],
    inputPlaceholder: ["Votre adresse E-mail", "Votre mot de passe"],
    checkboxText: "Se souvenir de moi",
    buttonText: "Se connecter",
    forgetText: "Mot de passe oublié ?",
    errorMessage: "E-mail ou mot de passe incorrect",
    forgetPassOnClick: () => {
        // window.location.href = "http://www.w3schools.com";
    },
    handleCheckbox: () => {},
};
