import * as React from "react";

import "./style.scss";

export default function Unavailability(props: UnavailabilityProps) {
    return <div>Unavailability</div>;
}

interface UnavailabilityProps {}

Unavailability.defaultProps = {};
