import React from "react";
import { getPagesCount, getPages } from "../../../_helpers/TableHelpers";
import PaginationLink from "../../atoms/PaginationLink/PaginationLink";
import { LongArrowLeft, LongArrowRight } from "../../../Icons";
import { FormattedMessage } from "react-intl";

export default function PaginationLinks({
    totalSize,
    sizePerPage,
    page,
    onPageChange,
}: PaginationLinksInterface) {
    const pagesCount = getPagesCount(totalSize, sizePerPage);
    const pages = getPages(page, pagesCount, sizePerPage);

    const handlePrevPage = () => {
        if (page > 1) {
            onPageChange(page - 1);
        }
    };

    const handleNextPage = () => {
        if (page < pagesCount) {
            onPageChange(page + 1);
        }
    };

    const handleSelectedPage = (pageNum: number) => {
        onPageChange(pageNum);
    };

    return (
        <>
            {pagesCount > 1 && (
                <div className="flex flex-wrap gap-1">
                    <PaginationLink
                        title={<FormattedMessage id="MENU.PRED" />}
                        onClick={handlePrevPage}
                        deactivated={page == 1}
                        leftIcon={<LongArrowLeft />}
                    />
                    {pages.map((pageK) => (
                        <PaginationLink
                            key={pageK}
                            title={pageK}
                            onClick={() => handleSelectedPage(pageK)}
                            active={pageK == page}
                        />
                    ))}
                    <PaginationLink
                        title={<FormattedMessage id="MENU.NEXT" />}
                        onClick={handleNextPage}
                        deactivated={page >= pagesCount}
                        rightIcon={<LongArrowRight />}
                    />
                </div>
            )}
        </>
    );
}

interface PaginationLinksInterface {
    totalSize: number;
    sizePerPage: number;
    page: number;
    onPageChange: (page: number) => void;
}
