import Swal from "sweetalert2";
import { API } from "../constants/env";

import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import { getAllHolidaysService } from "../redux/features/holiday/service";

export const useHoliday = () => {
    const disptach = useAppDispatch();

    const holidays = useAppSelector((state: any) => state.holiday);
    const userHolidays = useAppSelector(
        (state: any) => state.holiday
    ).userHolidays;

    const getUserHistory = (userId: any, startDay: any, endDay: any) => {
        disptach(getAllHolidaysService({ userId, startDay, endDay }));
    };

    return { holidays, userHolidays, getUserHistory };
};
