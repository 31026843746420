import React from "react";
import { useIntl } from "react-intl";
import Button from "../../../components/atoms/ZButton";

export default function NoPrimesFound({
    openEditPrimesDialog,
}: NoPrimesFoundProps) {
    const intl = useIntl();
    return (
        <div className="p-10 mx-auto flex flex-col max-w-[600px] items-center gap-5">
            <img
                className="max-w-[200px]"
                src="/assets/imgs/file-loop.png"
                alt="icon"
            />
            <h2 className="inline-block text-n-700 font-bold text-6xl">
                {intl.formatMessage({ id: "EMPLOYEE.PRIMES.NO_PRIMES" })}
            </h2>
            <p className="font-light text-md text-n-500 text-center">
                L'utilisateur peut ajouter des éléments comme des avances,
                primes ou pénalités, qui seront enregistrés dans l'historique et
                inclus dans le rapport du comptable. Facilitant ainsi le suivi
                et la vérification.
            </p>
            <Button
                onClick={() => openEditPrimesDialog(null)}
                title={intl.formatMessage({
                    id: "EMPLOYEE.PRIMES.CREATE_PRIME",
                })}
                variant="primary"
                leftComponent={
                    <img src="/assets/imgs/add-square-white.png" alt="icon" />
                }
            />
        </div>
    );
}

interface NoPrimesFoundProps {
    openEditPrimesDialog: (data?: any) => void;
}
