import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { VacationState } from "./type";
import { getAllVacationService } from "./service";
const initialVacationState: VacationState = {
    loading: false,
    error: false,
    success: false,
    list: [],
    selected: {
        _id: null,
        designation: "",
        color: "",
    },
    new: {
        designation: "",
        color: "",
    },
};

const vacationState = createSlice({
    name: "vacation",
    initialState: initialVacationState,
    reducers: {
        resetAllVacation: (state) => {
            state = initialVacationState;
        },
        loadSelectedVacation: (state, action) => {
            if (action.payload?._id) {
                state.selected._id = action.payload._id;
            }
            if (action.payload?.designation) {
                state.selected.designation = action.payload.designation;
            }
            if (action.payload?.color) {
                state.selected.color = action.payload.color;
            }
        },
        setSelectedVacation: (state, action) => {
            if (action.payload?.designation) {
                state.selected.designation = action.payload.designation;
            }
            if (action.payload?.color) {
                state.selected.color = action.payload.color;
            }
            if (action.payload?._id) {
                state.selected._id = action.payload._id;
            }
        },
        setCreateVacation: (state, action) => {
            if (action.payload?.designation) {
                state.new.designation = action.payload.designation;
            }
            if (action.payload?.color) {
                state.new.color = action.payload.color;
            }
        },
        resetNewVacation: (state) => {
            state.new.designation = "";
            state.new.color = "";
        },
        resetSeletedVacation: (state) => {
            state.selected.designation = "";
            state.selected.color = "";
            state.selected._id = null;
        },
    },
    extraReducers: (builder) => {
        // CASE : GETALL VACATIONS
        builder.addCase(
            getAllVacationService.pending,
            (state: VacationState) => {
                state.loading = true;
                state.success = false;
                state.error = false;
            }
        );
        builder.addCase(
            getAllVacationService.fulfilled,
            (state: VacationState, action: PayloadAction<any>) => {
                state.loading = false;
                state.success = true;
                state.error = false;
                state.list = [];
                if (action.payload !== undefined) {
                    for (const vacation of action.payload) {
                        state.list.push({
                            _id: vacation._id,
                            designation: vacation.designation,
                            color: vacation.color,
                        });
                    }
                }
            }
        );
        builder.addCase(
            getAllVacationService.rejected,
            (state: VacationState) => {
                state.loading = false;
                state.success = false;
                state.error = true;
            }
        );
    },
});

export const {
    loadSelectedVacation,
    setSelectedVacation,
    setCreateVacation,
    resetNewVacation,
    resetSeletedVacation,
    resetAllVacation,
} = vacationState.actions;

export default vacationState.reducer;
