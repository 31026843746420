import React from "react";

export default function ModalBody({
  children,
  bodyClasses,
}: ModalBodyInterface) {
  return (
    <div className={`h-full  p-4 ${bodyClasses}`}>
      {children}
    </div>
  );
}

interface ModalBodyInterface {
  height: number;
  children: React.ReactNode;
  bodyClasses?: string;
}
