import * as React from "react";

import "./style.scss";

export default function FolderHR(props: FolderHRProps) {
    return <div>Folder HR</div>;
}

interface FolderHRProps {}

FolderHR.defaultProps = {};
