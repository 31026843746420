import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../constants/env";

export const getAllVacationBalanceHistoryService = createAsyncThunk(
    "vacationRule/getAllVacationBalanceHistory",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        if (token && payload.siteID) {
            try {
                const res = await fetch(
                    API.client.leaveBalance.getAllHistoryBySite(payload.siteID),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    }
);
export const getAllUserVacationBalanceHistoryService = createAsyncThunk(
    "vacationRule/getAllUserVacationBalanceHistory",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        if (token && payload.userID) {
            try {
                const res = await fetch(
                    API.client.leaveBalance.getAllHistoryByUserID(
                        payload.userID
                    ),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    }
);

export const updateAutoVacationBalanceScoreService = createAsyncThunk(
    "vacationRule/getAllRules",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        if (token && payload.siteID) {
            try {
                const res = await fetch(
                    API.client.leaveBalance.updateAutoScoreBySite(
                        payload.siteID
                    ),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    }
);
export const CorrectVacationBalanceService = createAsyncThunk(
    "vacationRule/getAllRules",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        if (
            token &&
            payload.siteID &&
            payload.user &&
            payload.leaveCount &&
            payload.leaveType
        ) {
            try {
                const res = await fetch(
                    API.client.leaveBalance.correctVacationBalance(
                        payload.siteID
                    ),
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            user: payload.user,
                            leaveCount: payload.leaveCount,
                            leaveType: payload.leaveType,
                        }),
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    }
);
