import React from "react";

export const ChevronDown = ({
    width = 24,
    height = 24,
    color = "currentColor"
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.293 9.14795L12 13.3739L7.70697 9.14795L6.29297 10.5399L12 16.1577L17.707 10.5399L16.293 9.14795Z"
        fill={color}
      />
    </svg>
  );
};

interface IconProps {
    width?: number;
    height?: number;
    color?: string;
}