import React from "react";
import TextData from "../../atoms/TextData";
import { ReactComponent as MsgSVG } from "./Msg.svg";
import { ReactComponent as SpoonSVG } from "./spoon.svg";
import { fomatStartEndTime, formatTime } from "../../../_helpers/Functions";
import "./style.css";
import { useBranch } from "../../../hooks/useBranch";
import ShiftDetails from "./ShiftDetails";
const getColorRgba = (color: string, outlineOpacity: string) => {
    const rgbaValue = `rgba(${parseInt(color.slice(-6, -4), 16)}, ${parseInt(
        color.slice(-4, -2),
        16,
    )}, ${parseInt(color.slice(-2), 16)}, ${outlineOpacity})`;
    return rgbaValue;
};

export default function ShiftSummary(props: any) {
    const { from, to, steaker, pause, comment, repas, type, typeAbsence } =
        props.data;
    const { absenceColor } = props;
    const { branchSettings } = useBranch();
    const color = React.useMemo(() => {
        if (type === 1) {
            return steaker?.color || absenceColor;
        } else {
            return absenceColor;
        }
    }, [type, absenceColor, steaker?.color]);
    const workedTime = React.useMemo(() => {
        if (branchSettings.usePause) {
            return to > from ? to - from - pause : 1440 - from + to - pause;
        } else {
            return to > from ? to - from : 1440 - from + to;
        }
    }, [props]);
    if (!color) return <div></div>;
    return (
        <div
            className="shift-container"
            ref={props.reference}
            style={{
                borderRadius: "5px",
                border: `2px solid ${color}`,
                // marginTop: "2px",
                width: "100%",
                height: "100%",
                minHeight: "inherit",
                backgroundColor: "white",
                position: "absolute",
            }}
        >
            <div
                className="day-cell-summary"
                style={{
                    backgroundColor: getColorRgba(color, "0.1"),
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent:
                        typeAbsence || type === 0 ? "center" : "space-between",
                    flexDirection: "column",
                    padding: "5px",
                    minHeight: "inherit",
                    position: "relative",
                }}
            >
                {type === 1 && (
                    <ShiftDetails
                        workedTime={formatTime(workedTime, false, true)}
                        color={color}
                        comment={comment}
                    />
                )}
                {from !== 0 || to !== 1439 ? (
                    <>
                        <div
                            style={{
                                minHeight: "inherit",
                                width: "100%",
                            }}
                        >
                            <div
                                className="hours-adjustment"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "5px",
                                    }}
                                >
                                    <TextData
                                        variant="label"
                                        width="auto"
                                        style={{
                                            padding: "4px 6px",

                                            backgroundColor: `${color}`,
                                            borderRadius: "5px",
                                        }}
                                        color="white"
                                        size="15px"
                                    >
                                        {fomatStartEndTime(from)}
                                    </TextData>
                                    <TextData
                                        variant="label"
                                        width="auto"
                                        style={{
                                            padding: "4px 6px",
                                            backgroundColor: `${color}`,
                                            borderRadius: "5px",
                                        }}
                                        color="white"
                                        size="15px"
                                    >
                                        {fomatStartEndTime(to)}
                                    </TextData>
                                </div>
                                {branchSettings.usePause && (
                                    <TextData
                                        variant="label"
                                        width="auto"
                                        size="11px"
                                        color={color}
                                    >
                                        {pause === 0
                                            ? ""
                                            : pause < 60
                                              ? `${pause}min`
                                              : formatTime(pause)}
                                    </TextData>
                                )}
                            </div>
                        </div>
                        <div
                            className="shift-role-container"
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                {type === 1 && steaker?.name ? (
                                    <TextData
                                        variant="label"
                                        width="auto"
                                        fontWeight="600"
                                        color="#2A346D"
                                        size="15px"
                                    >
                                        {steaker?.name}
                                    </TextData>
                                ) : typeAbsence ? (
                                    <TextData
                                        variant="label"
                                        width="auto"
                                        fontWeight="600"
                                        color={absenceColor}
                                        size="15px"
                                    >
                                        {typeAbsence}
                                    </TextData>
                                ) : (
                                    <TextData
                                        variant="label"
                                        width="auto"
                                        fontWeight="600"
                                        color="#2A346D50"
                                        size="12px"
                                    >
                                        {"(Pas d'étiquette)"}
                                    </TextData>
                                )}
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                }}
                            >
                                {repas >= 1 && workedTime.useRepas && (
                                    <SpoonSVG
                                        height={20}
                                        width={20}
                                        style={{ minHeight: 15, minWidth: 15 }}
                                    />
                                )}
                                {comment && /\S/.test(comment) && (
                                    <MsgSVG
                                        height={20}
                                        width={20}
                                        style={{ minHeight: 18, minWidth: 18 }}
                                    />
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "200px",
                        }}
                    >
                        <TextData
                            variant="div"
                            width="auto"
                            color={absenceColor}
                            size="14px"
                        >
                            <div>{typeAbsence || "Repos hebdomadaire"}</div>
                            <div
                                style={{
                                    color: "#2a346d",
                                    fontWeight: "600",
                                }}
                            >
                                Toute la journée
                            </div>
                        </TextData>
                    </div>
                )}
            </div>
        </div>
    );
}
