import * as React from "react";
import { useDrop } from "react-dnd";
import "./style.scss";
import TextData from "../../atoms/TextData";
import CellEmpty from "../../molecules/CellEmpty";
import { ReactComponent as CloseSVG } from "./close.svg";
import { ReactComponent as AttentionSVG } from "./attention.svg";
import { ReactComponent as MsgSVG } from "./Msg.svg";
import { ReactComponent as SpoonSVG } from "./spoon.svg";
import { ReactComponent as DangerSVG } from "./danger.svg";
import { ReactComponent as TimeSVG } from "./time.svg";
import { ReactComponent as IndispoSVG } from "./no-work.svg";
import AttendanceCard from "../../organism/AttendanceCard";
import { fomatStartEndTime, formatTime } from "../../../_helpers/Functions";
import { usePlanning } from "../../../hooks/usePlanning";
import moment from "moment";
import { useBranch } from "../../../hooks/useBranch";
import { direction } from "html2canvas/dist/types/css/property-descriptors/direction";
export default function ShiftDetails(props: any) {
    const { workedTime, color, published = false, data, extern } = props;
    const { planningInfoDepartments } = usePlanning();
    return (
        <div
            className="details-container"
            // ref={props.reference}
            style={{
                backgroundColor: color,
                borderRadius: "3px",
                border: `1px solid ${color}`,
                flexDirection: "row",
                cursor: "inherit",
                justifyContent: "flex-start",
                alignItems: "center",
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "calc(100% - 18px)",
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <TimeSVG
                            style={{
                                minHeight: 15,
                                minWidth: 15,
                                display: "block",
                            }}
                        />
                        <TextData
                            variant="label"
                            width="auto"
                            color="white"
                            size="14px"
                            fontWeight="500"
                            style={{
                                marginLeft: "3px",
                                cursor: "pointer",
                            }}
                        >
                            Durée: <b>{workedTime}</b>
                        </TextData>
                    </div>
                    {extern && planningInfoDepartments[data.department] && (
                        <div
                            style={{
                                color: "black",
                                fontSize: "x-small",
                                fontWeight: "normal",
                            }}
                        >
                            Ce collaborateur est planifié en{" "}
                            <b>
                                {
                                    planningInfoDepartments[data.department]
                                        ?.department?.name
                                }
                            </b>
                        </div>
                    )}
                </div>

                {data.comment && /\S/.test(data.comment) && (
                    <div
                        className="cell-comment"
                        style={{
                            width: "fit-content",
                            height: "fit-content",
                            padding: "0",
                            position: "relative",
                            alignSelf: "flex-start",
                            marginTop: "7px",
                            cursor: "pointer",
                        }}
                    >
                        <MsgSVG
                            height={15}
                            width={15}
                            style={{
                                minHeight: 15,
                                minWidth: 15,
                                alignSelf: "flex-start",
                                justifySelf: "end",
                            }}
                            color="white"
                        />
                        <div
                            className="comment-text"
                            style={{
                                position: "absolute",
                                fontSize: "11px",
                                backgroundColor: "#d1d1e8",
                                zIndex: "99",
                                left: "calc(100% + 4px)",
                                top: "0",
                                border: "1px solid #817fdd",
                                borderRadius: "5px",
                                color: "#4240c3",
                                textAlign: "left",
                                width: "max-content",
                                padding: "3px 6px",
                            }}
                        >
                            {data.comment}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
