import TextData from "../../../atoms/TextData";
import EmployeeInfoHeaderRow from "../../../molecules/EmployeeInfoHeaderRow";
import SelectInput from "../../../atoms/SelectInput";
import CalendarInput from "../../../molecules/CalendarInput";
import Button from "../../../atoms/Button";
import Table from "../../Table";
import CommingSoon from "../../../../pages/Settings/Elements/CommingSoon";
import "./style.scss";
function ActivityTable(props: ActivityTableProps) {
    const { activateCommingSoon } = props;
    const tableProps = {
        fields: {
            fieldRow: [
                {
                    title: (
                        <TextData
                            variant='label'
                            color='#2A346D'
                            size='16px'
                            fontWeight='bold'
                            width='auto'
                        >
                            Date
                        </TextData>
                    ),
                    name: "name",
                    style: {
                        padding: "10px 10px",
                        paddingLeft: "40px",
                        height: "50px",
                        width: "10%",
                        borderTop: "none",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                    },
                },
                {
                    title: (
                        <TextData
                            variant='label'
                            color='#2A346D'
                            size='16px'
                            fontWeight='bold'
                            width='auto'
                        >
                            Heure
                        </TextData>
                    ),
                    name: "name",
                    style: {
                        padding: "10px 0",
                        width: "6%",
                        borderTop: "none",
                    },
                },
                {
                    title: (
                        <TextData
                            variant='label'
                            color='#2A346D'
                            size='16px'
                            fontWeight='bold'
                            width='auto'
                        >
                            Employé
                        </TextData>
                    ),
                    name: "name",
                    style: {
                        padding: "10px 0",
                        borderTop: "none",
                        width: "15%",
                    },
                },
                {
                    title: (
                        <TextData
                            variant='label'
                            color='#2A346D'
                            size='16px'
                            fontWeight='bold'
                            width='auto'
                        >
                            Activité
                        </TextData>
                    ),
                    name: "name",
                    style: {
                        padding: "10px 0",
                        borderTop: "none",
                        width: "67%",
                    },
                },
            ],
            onClickRow: () => {},
            style: { border: "1px solid #EDEFF5", borderTop: "none" },
            className: [],
        },
        data: [
            {
                dataRow: [
                    {
                        value: "23 Octobre 2022",
                        name: "name",
                        style: {
                            color: "#4C5690",
                            paddingLeft: "40px",
                            height: "80px",
                        },
                    },
                    {
                        value: "09:20",
                        name: "email",
                        style: {
                            color: "#4C5690",
                            height: "80px",
                        },
                    },
                    {
                        value: (
                            <EmployeeInfoHeaderRow
                                firstName='Jacob'
                                lastName='Jones'
                                position=''
                                weekHours=''
                                weekHoursWidth='0px'
                                imageStyle={{ marginRight: "10px" }}
                                employeeInfoStyle={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                }}
                            />
                        ),
                        name: "phone",
                    },
                    {
                        value: "Andy Warhol a aprouvé la demande de congés de Jacob Jones du 21/12/2022 au 27/12/2022",
                        name: "123 Main Street",
                        style: {
                            color: "#4C5690",
                            fontSize: 14,
                        },
                    },
                ],
                onClickRow: () => {},
                style: {
                    cursor: "pointer",
                    borderBottom: "1px solid #EDEFF5",
                },
                className: ["hover:bg-slate-100"],
            },
            {
                dataRow: [
                    {
                        value: "23 Octobre 2022",
                        name: "name",
                        style: {
                            color: "#4C5690",
                            paddingLeft: "40px",
                            height: "80px",
                        },
                    },
                    {
                        value: "09:20",
                        name: "email",
                        style: {
                            color: "#4C5690",
                            height: "80px",
                        },
                    },
                    {
                        value: (
                            <EmployeeInfoHeaderRow
                                firstName='Jacob'
                                lastName='Jones'
                                position=''
                                weekHours=''
                                weekHoursWidth='0px'
                                imageStyle={{ marginRight: "10px" }}
                                employeeInfoStyle={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                }}
                            />
                        ),
                        name: "phone",
                    },
                    {
                        value: "Andy Warhol a aprouvé la demande de congés de Jacob Jones du 21/12/2022 au 27/12/2022",
                        name: "123 Main Street",
                        style: {
                            color: "#4C5690",
                            fontSize: 14,
                        },
                    },
                ],
                onClickRow: () => {},
                style: {
                    cursor: "pointer",
                    borderBottom: "1px solid #EDEFF5",
                },
                className: ["hover:bg-slate-100"],
            },

            {
                dataRow: [
                    {
                        value: "23 Octobre 2022",
                        name: "name",
                        style: {
                            color: "#4C5690",
                            paddingLeft: "40px",
                            height: "80px",
                        },
                    },
                    {
                        value: "09:20",
                        name: "email",
                        style: {
                            color: "#4C5690",
                            height: "80px",
                        },
                    },
                    {
                        value: (
                            <EmployeeInfoHeaderRow
                                firstName='Jacob'
                                lastName='Jones'
                                position=''
                                weekHours=''
                                weekHoursWidth='0px'
                                imageStyle={{ marginRight: "10px" }}
                                employeeInfoStyle={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                }}
                            />
                        ),
                        name: "phone",
                    },
                    {
                        value: "Andy Warhol a aprouvé la demande de congés de Jacob Jones du 21/12/2022 au 27/12/2022",
                        name: "123 Main Street",
                        style: {
                            color: "#4C5690",
                            fontSize: 14,
                        },
                    },
                ],
                onClickRow: () => {},
                style: {
                    cursor: "pointer",
                    borderBottom: "1px solid #EDEFF5",
                },
                className: ["hover:bg-slate-100"],
            },
            {
                dataRow: [
                    {
                        value: "23 Octobre 2022",
                        name: "name",
                        style: {
                            color: "#4C5690",
                            paddingLeft: "40px",
                            height: "80px",
                        },
                    },
                    {
                        value: "09:20",
                        name: "email",
                        style: {
                            color: "#4C5690",
                            height: "80px",
                        },
                    },
                    {
                        value: (
                            <EmployeeInfoHeaderRow
                                firstName='Jacob'
                                lastName='Jones'
                                position=''
                                weekHours=''
                                weekHoursWidth='0px'
                                imageStyle={{ marginRight: "10px" }}
                                employeeInfoStyle={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                }}
                            />
                        ),
                        name: "phone",
                    },
                    {
                        value: "Andy Warhol a aprouvé la demande de congés de Jacob Jones du 21/12/2022 au 27/12/2022",
                        name: "123 Main Street",
                        style: {
                            color: "#4C5690",
                            fontSize: 14,
                        },
                    },
                ],
                onClickRow: () => {},
                style: {
                    cursor: "pointer",
                    borderBottom: "1px solid #EDEFF5",
                },
                className: ["hover:bg-slate-100"],
            },

            {
                dataRow: [
                    {
                        value: "23 Octobre 2022",
                        name: "name",
                        style: {
                            color: "#4C5690",
                            paddingLeft: "40px",
                            height: "80px",
                        },
                    },
                    {
                        value: "09:20",
                        name: "email",
                        style: {
                            color: "#4C5690",
                            height: "80px",
                        },
                    },
                    {
                        value: (
                            <EmployeeInfoHeaderRow
                                firstName='Jacob'
                                lastName='Jones'
                                position=''
                                weekHours=''
                                weekHoursWidth='0px'
                                imageStyle={{ marginRight: "10px" }}
                                employeeInfoStyle={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                }}
                            />
                        ),
                        name: "phone",
                    },
                    {
                        value: "Andy Warhol a aprouvé la demande de congés de Jacob Jones du 21/12/2022 au 27/12/2022",
                        name: "123 Main Street",
                        style: {
                            color: "#4C5690",
                            fontSize: 14,
                        },
                    },
                ],
                onClickRow: () => {},
                style: {
                    cursor: "pointer",
                    borderBottom: "1px solid #EDEFF5",
                },
                className: ["hover:bg-slate-100"],
            },
            {
                dataRow: [
                    {
                        value: "23 Octobre 2022",
                        name: "name",
                        style: {
                            color: "#4C5690",
                            paddingLeft: "40px",
                            height: "80px",
                        },
                    },
                    {
                        value: "09:20",
                        name: "email",
                        style: {
                            color: "#4C5690",
                            height: "80px",
                        },
                    },
                    {
                        value: (
                            <EmployeeInfoHeaderRow
                                firstName='Jacob'
                                lastName='Jones'
                                position=''
                                weekHours=''
                                weekHoursWidth='0px'
                                imageStyle={{ marginRight: "10px" }}
                                employeeInfoStyle={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                }}
                            />
                        ),
                        name: "phone",
                    },
                    {
                        value: "Andy Warhol a aprouvé la demande de congés de Jacob Jones du 21/12/2022 au 27/12/2022",
                        name: "123 Main Street",
                        style: {
                            color: "#4C5690",
                            fontSize: 14,
                        },
                    },
                ],
                onClickRow: () => {},
                style: {
                    cursor: "pointer",
                    borderBottom: "1px solid #EDEFF5",
                },
                className: ["hover:bg-slate-100"],
            },
        ],
    };
    return (
        <div
            className='activity-table-container'
            style={{ maxHeight: "66vh", overflowX: "auto" }}
        >
            {activateCommingSoon && <CommingSoon page={"activity"} />}
            <Table {...tableProps} />
        </div>
    );
}
export interface ActivityTableProps {
    activateCommingSoon?: boolean;
}
ActivityTable.defaultProps = {
    activateCommingSoon: true,
};

function ActivitySidebar() {
    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            {/* <SelectInput
                title=''
                options={[
                    {
                        label: "Toutes les activités",
                        value: "Toutes les activités",
                    },
                ]}
                style={{
                    width: "210px",
                    height: "30px",
                    marginRight: "10px",
                    borderRadius: "9px",
                    fontSize: "12px",
                    padding: "0 10px",
                }}
            ></SelectInput> */}
            {/* <CalendarInput /> */}
        </div>
    );
}
function ActivityTableHeader() {
    return (
        <div
            style={{
                display: "flex",
                width: "auto",
                justifyContent: "space-between",
                height: "80px",
                margin: "0 1.25rem",
            }}
        >
            <TextData color='#11106E' size='34' fontWeight='bold'>
                Rapports
            </TextData>
        </div>
    );
}

const ActivityTableRightMenu = ActivitySidebar;
export { ActivityTable, ActivityTableRightMenu, ActivityTableHeader };
