import { createSlice } from "@reduxjs/toolkit";
import { GlobalConfigState } from "./types";
const initialState: GlobalConfigState = {
    language: localStorage.getItem("lang"),
    isFullScreen: false,
};
const globalConfig = createSlice({
    name: "globalConfig",
    initialState,
    reducers: {
        changeLanguage: (state, action) => {
            if (action.payload?.language) {
                state.language = action.payload?.language;
                localStorage.setItem("lang", action.payload?.language);
            }
        },
        changeFullScreen: (state) => {
            state.isFullScreen = !state.isFullScreen;
        },
    },
});

export const { changeLanguage, changeFullScreen } = globalConfig.actions;
export default globalConfig.reducer;
