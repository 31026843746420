import * as React from "react";

import avatar from "./avatar.png";

import { ReactComponent as MoreSVG } from "./more.svg";
import { ReactComponent as Logo } from "./logo.svg";
import Picture from "../../atoms/Picture";
import TextData from "../../atoms/TextData";

function TopBarFirstStep(props: TopBarFirstStepProps) {
    const { avatarImg, fullName, email, handleSeeMoreIcon } = props;
    return (
        <div className='topbar-container'>
            <Logo style={{ marginLeft: 15 }} />
            <div className='profile-section'>
                <Picture imgPath={avatarImg} />
                <div>
                    <TextData color='#2A346D' size='14px' fontWeight='bolder'>
                        {fullName}
                    </TextData>
                    <TextData color='#8E96C1' size='11px'>
                        {email}
                    </TextData>
                </div>
                <MoreSVG onClick={handleSeeMoreIcon} />
            </div>
        </div>
    );
}

interface TopBarFirstStepProps {
    avatarImg?: string;
    fullName?: string;
    email?: string;
    handleSeeMoreIcon?: () => void;
}
TopBarFirstStep.defaultProps = {
    avatarImg: avatar,
    fullName: "andy warhol",
    email: "andywarhol@gmail.com",
    handleSeeMore: () => {
    },
};
export default TopBarFirstStep;
