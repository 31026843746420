import React from "react";
import Notes from "../../../components/templates/EmployeesEdit/Notes";
import { useEmployees } from "../../../hooks/useEmployees";
import NoDocumentsFound from "../../../components/templates/EmployeesEdit/NoDoumentsFound";
import AddDocumentButton from "./AddDocumentButton";
import ButtonZ from "../../../components/atoms/ZButton";
import Button from "../../../components/atoms/Button";
import { AddSquare } from "../../../Icons";
import AddDocumentCard from "../../../components/organism/AddDocumentCard";
import DocumentsTable from "./DocumentsTable";
import DeleteDocumentDialog from "./DeleteDocumentDialog";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { ReactComponent as DownArrowSVG } from "./downArrow.svg";
import { ReactComponent as TrashSVG } from "./trash.svg";
import { ReactComponent as EditSVG } from "./edit.svg";
import { ReactComponent as VisibleSVG } from "./visible.svg";
import TextData from "../../../components/atoms/TextData";
import { ROUTER } from "../../../constants/env";
import Loading from "../../../components/atoms/Loading";
import Swal from "sweetalert2";
import Ticket from "../../../components/atoms/Ticket";
import { employeeRoleColor, getColorRgba } from "../../../_helpers/Functions";
import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";
import { useDPAE } from "../../../hooks/useDPAE";

export default function NotesPage() {
    const intl = useIntl();
    const {
        getEmployeeDocuments,
        employeeForEdit,
        documents,
        listLoading,
        manageSelectedDocument,
        dropExistingDocument,
    } = useEmployees();
    const { isDepositLoading } = useDPAE();
    React.useEffect(() => {
        if (employeeForEdit) {
            getEmployeeDocuments({ id: employeeForEdit._id });
        }
    }, [employeeForEdit, isDepositLoading]);

    const [documentForDelete, setDocumentForDelete] = React.useState();
    const openDeleteDocumentDialog = (document: any) => {
        setDocumentForDelete(document);
    };
    const [openAddDocumentCard, setOpenAddDocumentCard] = React.useState(false);
    const [isAddCard, setIsAddCard] = React.useState(true);

    const AddDocumentButton = (
        <>
            <ButtonZ
                containerClasses="bg-t-500-op-1 hover:bg-t-500-op-3 text-t-500"
                title={intl.formatMessage({
                    id: "EMPLOYEE.EDIT.ADD_DOCUMENT",
                })}
                leftComponent={<AddSquare />}
                onClick={() => setOpenAddDocumentCard(true)}
            />
            <AddDocumentCard
                display={openAddDocumentCard}
                onClose={() => setOpenAddDocumentCard(false)}
                isAddCard={isAddCard}
            />
        </>
    );
    const visibilityDisplay = (text: string) => {
        const adminColor = employeeRoleColor("admin");
        const managerColor = employeeRoleColor("manager");
        const employeeColor = employeeRoleColor("employee");
        switch (text) {
            case "forEmployees":
                return (
                    <div style={{ display: "flex", gap: "7px" }}>
                        <Ticket
                            textColor={adminColor}
                            backgroundColor={getColorRgba(adminColor)}
                            border={`1px solid ${adminColor}`}
                            text="Administrateurs"
                            showClose={false}
                            showEdit={false}
                        />

                        <Ticket
                            textColor={managerColor}
                            backgroundColor={getColorRgba(managerColor)}
                            border={`1px solid ${managerColor}`}
                            text="Managers"
                            showClose={false}
                            showEdit={false}
                        />

                        <Ticket
                            textColor={employeeColor}
                            backgroundColor={getColorRgba(employeeColor)}
                            border={`1px solid ${employeeColor}`}
                            text="Employés"
                            showClose={false}
                            showEdit={false}
                        />
                    </div>
                );
            case "forManagers":
                return (
                    <div style={{ display: "flex", gap: "7px" }}>
                        <Ticket
                            textColor={adminColor}
                            backgroundColor={getColorRgba(adminColor)}
                            border={`1px solid ${adminColor}`}
                            text="Administrateurs"
                            showClose={false}
                            showEdit={false}
                        />

                        <Ticket
                            textColor={managerColor}
                            backgroundColor={getColorRgba(managerColor)}
                            border={`1px solid ${managerColor}`}
                            text="Managers"
                            showClose={false}
                            showEdit={false}
                        />
                    </div>
                );
            case "forAdministrators":
                return (
                    <div style={{ display: "flex", gap: "7px" }}>
                        <Ticket
                            textColor={adminColor}
                            backgroundColor={getColorRgba(adminColor)}
                            border={`1px solid ${adminColor}`}
                            text="Administrateurs"
                            showClose={false}
                            showEdit={false}
                        />
                    </div>
                );
            default:
                return (
                    <div style={{ display: "flex", gap: "7px" }}>
                        <Ticket
                            textColor={adminColor}
                            backgroundColor={getColorRgba(adminColor)}
                            border={`1px solid ${adminColor}`}
                            text="Administrateurs"
                            showClose={false}
                            showEdit={false}
                        />

                        <Ticket
                            textColor={managerColor}
                            backgroundColor={getColorRgba(managerColor)}
                            border={`1px solid ${managerColor}`}
                            text="Managers"
                            showClose={false}
                            showEdit={false}
                        />

                        <Ticket
                            textColor={employeeColor}
                            backgroundColor={getColorRgba(employeeColor)}
                            border={`1px solid ${employeeColor}`}
                            text="Employés"
                            showClose={false}
                            showEdit={false}
                        />
                    </div>
                );
        }
    };
    const DocumentsList = () => {
        let files = documents?.files;
        const [isOpenList, setIsOpenList] = React.useState<Array<boolean>>([]);
        React.useEffect(() => {
            if (documents && documents.categories) {
                setIsOpenList((prev: any) => {
                    return documents.categories.map((element: any) => true);
                });
            }
        }, [documents]);
        const toggleIsOpenList = (index: number) => {
            setIsOpenList((prev: any) => {
                return prev.map((isOpen: any, i: number) => {
                    if (i === index) {
                        return !isOpen;
                    } else return isOpen;
                });
            });
        };
        if (listLoading) {
            return (
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                        height: "50vh",
                    }}
                >
                    <Loading />
                </div>
            );
        }
        if (files && files.length > 0) {
            const content = documents.categories.map((category: any) => {
                return {
                    tag: category,
                    files: [],
                };
            });
            for (const file of files) {
                for (const category of file.categories) {
                    const element = content.find(
                        (item: any) => item.tag === category,
                    );
                    if (element) {
                        element.files.push(file);
                    }
                }
            }
            const handleEditDocument = (documentID: string) => {
                const existedDoc = documents.files.find(
                    (doc: any) => doc._id === documentID,
                );
                manageSelectedDocument(existedDoc);
                setIsAddCard(false);
                setOpenAddDocumentCard(true);
            };
            const handleDropDocument = (documentID: string) => {
                const existedDoc = documents.files.find(
                    (doc: any) => doc._id === documentID,
                );
                Swal.fire({
                    title: "Attention!?",
                    text: `Êtes-vous certain de vouloir supprimer le document ${existedDoc.fileName}`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: "Annuler",
                    confirmButtonText: "Oui supprimer",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await dropExistingDocument(existedDoc._id);
                        await getEmployeeDocuments({ id: employeeForEdit._id });
                        Swal.fire({
                            title: "Fichier supprimé!",
                            text: "Le fichier a été supprimé avec succès",
                            icon: "success",
                        });
                    }
                });
            };
            const handleSizeView = (fileSize: number) => {
                const sizeInMB = (fileSize / 1024).toFixed(2);
                if (parseFloat(sizeInMB) < 0.02) {
                    return `${fileSize.toFixed(2)} KB`;
                }
                return `${(fileSize / 1024).toFixed(2)} MB`;
            };
            return (
                <div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                        }}
                    >
                        <Button
                            backgroundColor="#2a8bab1a"
                            textColor="#2a8bab"
                            width="auto"
                            padding="1rem 0.75rem"
                            style={{
                                marginBottom: "7px",
                                fontSize: "15px",
                                borderRadius: "0.5rem",
                                gap: "5px",
                            }}
                            onClick={() => {
                                setIsAddCard(true);
                                manageSelectedDocument({ reset: true });
                                setOpenAddDocumentCard(true);
                            }}
                        >
                            <AddSquare />
                            Ajouter un document
                        </Button>
                        <AddDocumentCard
                            display={openAddDocumentCard}
                            onClose={() => setOpenAddDocumentCard(false)}
                            isAddCard={isAddCard}
                        />
                    </div>
                    {content.map((fileGroup: any, index: number) => {
                        return (
                            <>
                                <div
                                    style={{
                                        backgroundColor: "#EDEFF5",
                                        borderRadius: "8px",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "12px 15px",
                                        justifyContent: "space-between",
                                        marginBottom: "7px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => toggleIsOpenList(index)}
                                >
                                    {fileGroup.tag === "default" ? (
                                        <TextData
                                            variant="div"
                                            size="16px"
                                            color="#1A7290"
                                            fontWeight="700"
                                        >
                                            🗂️ Documents déposés par le salarié
                                        </TextData>
                                    ) : (
                                        <TextData
                                            variant="div"
                                            size="16px"
                                            color="#2A346D"
                                            fontWeight="700"
                                        >
                                            {fileGroup.tag}
                                        </TextData>
                                    )}
                                    <DownArrowSVG />
                                </div>
                                {isOpenList[index] && (
                                    <div>
                                        <table style={{ width: "100%" }}>
                                            {fileGroup.files.length === 0 ? (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                        marginBottom: "7px",
                                                        borderRadius: "7px",
                                                        padding: "5px 7px",
                                                        width: "100%",
                                                        height: "50px",
                                                        backgroundColor:
                                                            "#edeff5",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    Aucun document n'est présent
                                                    dans ce dossier
                                                </div>
                                            ) : (
                                                <tr>
                                                    <td>
                                                        <TextData
                                                            color="#2A346D"
                                                            size="17px"
                                                            width="auto"
                                                            fontWeight="550"
                                                            style={{
                                                                marginLeft:
                                                                    "10px",
                                                            }}
                                                        >
                                                            Nom de Fichier
                                                        </TextData>
                                                    </td>
                                                    <td>
                                                        <TextData
                                                            color="#2A346D"
                                                            size="17px"
                                                            width="auto"
                                                            fontWeight="550"
                                                            style={{
                                                                marginLeft:
                                                                    "3px",
                                                            }}
                                                        >
                                                            Taille
                                                        </TextData>
                                                    </td>
                                                    <td
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <TextData
                                                            color="#2A346D"
                                                            size="17px"
                                                            width="auto"
                                                            fontWeight="550"
                                                        >
                                                            Autorisation
                                                        </TextData>
                                                    </td>
                                                    <td
                                                        style={{
                                                            paddingLeft: "20px",
                                                        }}
                                                    >
                                                        <TextData
                                                            color="#2A346D"
                                                            size="17px"
                                                            width="auto"
                                                            fontWeight="550"
                                                        >
                                                            Actions
                                                        </TextData>
                                                    </td>
                                                </tr>
                                            )}
                                            {fileGroup.files.map(
                                                (document: any) => {
                                                    return (
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    width: "25%",
                                                                }}
                                                            >
                                                                <TextData
                                                                    color="#6F78AB"
                                                                    fontWeight="400"
                                                                    size="15px"
                                                                    width="auto"
                                                                    style={{
                                                                        marginLeft:
                                                                            "10px",
                                                                    }}
                                                                >
                                                                    {
                                                                        document.fileName
                                                                    }
                                                                </TextData>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: "15%",
                                                                }}
                                                            >
                                                                <TextData
                                                                    color="#6F78AB"
                                                                    fontWeight="400"
                                                                    size="15px"
                                                                    width="auto"
                                                                >
                                                                    {handleSizeView(
                                                                        document.fileSize,
                                                                    )}
                                                                </TextData>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: "30%",
                                                                }}
                                                            >
                                                                <TextData
                                                                    color="#6F78AB"
                                                                    fontWeight="400"
                                                                    size="15px"
                                                                    width="auto"
                                                                >
                                                                    {visibilityDisplay(
                                                                        document.visibility,
                                                                    )}
                                                                </TextData>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    width: "30%",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        gap: "7px",
                                                                        marginBottom:
                                                                            "7px",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        backgroundColor="transparent"
                                                                        style={{
                                                                            borderRadius: 5,
                                                                        }}
                                                                        width="50px"
                                                                        onClick={() => {
                                                                            const url =
                                                                                ROUTER.STATIC_FILE(
                                                                                    document.link,
                                                                                );
                                                                            window.open(
                                                                                url,
                                                                                "_blank",
                                                                            );
                                                                        }}
                                                                    >
                                                                        <VisibleSVG
                                                                            color="#2D2AAD"
                                                                            title="Consulter document"
                                                                        />
                                                                    </Button>
                                                                    <Button
                                                                        backgroundColor="transparent"
                                                                        textColor="#2D2AAD"
                                                                        width="50px"
                                                                        style={{
                                                                            borderRadius: 5,
                                                                        }}
                                                                        onClick={() =>
                                                                            handleEditDocument(
                                                                                document._id,
                                                                            )
                                                                        }
                                                                    >
                                                                        <EditSVG
                                                                            color="#2D2AAD"
                                                                            title="Modifier document"
                                                                        />
                                                                    </Button>
                                                                    <Button
                                                                        backgroundColor="transparent"
                                                                        textColor="#2D2AAD"
                                                                        width="50px"
                                                                        style={{
                                                                            borderRadius: 5,
                                                                        }}
                                                                        onClick={() => {
                                                                            handleDropDocument(
                                                                                document._id,
                                                                            );
                                                                        }}
                                                                    >
                                                                        <TrashSVG
                                                                            color="#2D2AAD"
                                                                            title="Supprimer document"
                                                                        />
                                                                    </Button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                },
                                            )}
                                        </table>
                                    </div>
                                )}
                            </>
                        );
                    })}
                </div>
            );
        } else {
            return <NoDocumentsFound addDocumentButton={AddDocumentButton} />;
        }
    };
    return (
        <div>
            <Helmet>
                <title>
                    {intl.formatMessage({ id: "APP.TITLE" })} |{" "}
                    {intl.formatMessage({ id: "EMPLOYEE.EDIT.NOTES" })}
                </title>
            </Helmet>
            {documentForDelete && (
                <DeleteDocumentDialog
                    document={documentForDelete}
                    show={documentForDelete ? true : false}
                    onHide={() => {
                        setDocumentForDelete(undefined);
                    }}
                />
            )}
            {DocumentsList()}
            {/* {documents.files.length ? (
                <Notes
                    documents={
                        <DocumentsTable
                            openDeleteDocumentDialog={openDeleteDocumentDialog}
                        />
                    }
                    addDocumentButton={AddDocumentButton}
                />
            ) : (
                // <NoDocumentsFound addDocumentButton={<AddDocumentButton />} />
                <NoDocumentsFound addDocumentButton={AddDocumentButton} />
            )} */}
        </div>
    );
}
