import * as React from "react";
import classnames from "classnames";

import "./style.scss";

const TextData: React.FC<TextDataProps> = (props: TextDataProps) => {
    const [hover, setHover] = React.useState(false);
    const hoverStyle = hover ? { cursor: "pointer" } : {};

    const {
        activateHover,
        children,
        variant,
        title,
        id,
        forId,
        fontFamily,
        fontWeight,
        size,
        className,
        onClick,
        style,
        color,
        width,
    } = props;
    const isHovered = activateHover
        ? {
              onMouseEnter: () => setHover(true),
              onMouseLeave: () => setHover(false),
          }
        : null;

    const classProps = classnames("text-data", className);

    let element;
    switch (variant) {
        case "heading":
            element = <h1 {...isHovered}>{children}</h1>;
            break;
        case "regular":
            element = <span {...isHovered}>{children}</span>;
            break;
        case "label":
            element = (
                <label {...isHovered} htmlFor={forId}>
                    {children}
                </label>
            );
            break;
        case "div":
            element = <div {...isHovered}>{children}</div>;
            break;
        default:
            element = <div {...isHovered}>{children}</div>;
            break;
    }
    return React.cloneElement(element, {
        onClick,
        title,
        className: classProps,
        id,
        style: {
            ...hoverStyle,
            fontSize: size ? parseInt(size) : undefined,
            width,
            color,
            fontWeight,
            fontFamily,
            ...style,
        },
    });
};

interface TextDataProps {
    activateHover?: boolean;
    children?: React.ReactNode;
    variant?: "heading" | "regular" | "label" | "div";
    color?: string;
    fontWeight?: string;
    width?: string;
    title?: string;
    id?: string;
    forId?: string;
    size?: string;
    className?: string[];
    onClick?: React.MouseEventHandler<HTMLElement>;
    style?: React.CSSProperties;
    fontFamily?: string;
}
TextData.defaultProps = {
    activateHover: false,
    children: "test",
    variant: "heading",
    id: "7",
    width: "fit-content",
    className: ["test1", "test2"],
    color: "#2980b9",
    fontFamily: "DM Sans",
};
export default TextData;
