import * as React from "react";
import Swal from "sweetalert2";

import FalseSVG from "./falseIcon.svg";

import { useAppDispatch, useAppSelector } from "./useReduxHooks";

import {
    getDPAEService,
    updateDPAEinfo,
} from "../redux/features/settings/service";

import { updateAdministrativeDPAE } from "../redux/features/settings/settingsSlice";
import {
    validateAPECode,
    validateDPAE,
    validateDesignation,
    validatePhoneNum,
    validateSiret,
    validateStreet,
    validateTown,
    validateURSSAFCode,
    validateZipCode,
    validateContractHealthServiceCode,
} from "../_helpers/DPAEValidation";
import { depositUserDPAE } from "../redux/features/users/service";
import { ROUTER } from "../constants/env";
import { useEmployees } from "./useEmployees";

export const useDPAE = () => {
    const dispatch = useAppDispatch();
    const { getEmployeeDocuments, employeeForEdit } = useEmployees();

    const selectedBranchSettings = useAppSelector(
        (state: any) => state.settings
    ).selectedBranch;

    const selectedUser = useAppSelector(
        (state: any) => state.employees
    ).employeeForEdit;

    const DPAE = useAppSelector((state: any) => state.settings).administrative
        .dpae;
    const isDPAEactive = useAppSelector((state: any) => state.settings)
        .administrative.enableDPAE;

    const [isDepositLoading, setIsDepositLoading] = React.useState(false);

    const getDPAEInformation = () => {
        const token = localStorage.getItem("token");
        if (selectedBranchSettings._id && token) {
            dispatch(
                getDPAEService({ branchID: selectedBranchSettings._id, token })
            ).then((res) => {});
        }
    };

    const updateDPAEinformations = async () => {
        const token = localStorage.getItem("token");
        if (DPAE) {
            let mappedArray = await Object.keys({
                siret: DPAE.siret,
                enseigne: DPAE.enseigne,
                apeCode: DPAE.apeCode,
                urssafCode: DPAE.urssafCode,
                zipCode: DPAE.zipCode,
                phoneNum: DPAE.phoneNum,
                address: DPAE.address,
                ville: DPAE.ville,
                healthServiceCode: DPAE.healthServiceCode,
            }).map((key) => {
                console.log("KEY>", key);
                if (key === "siret") {
                    return {
                        validate: !validateDPAE(validateSiret, DPAE.siret),
                        message: "Le numéro SIRET est invalide",
                    };
                } else if (key === "enseigne") {
                    return {
                        validate: !validateDPAE(
                            validateDesignation,
                            DPAE.enseigne
                        ),
                        message: "La désignation est invalide",
                    };
                } else if (key === "apeCode") {
                    return {
                        validate: !validateDPAE(validateAPECode, DPAE.apeCode),
                        message: "Le code APE(NAF) est invalide",
                    };
                } else if (key === "urssafCode") {
                    return {
                        validate: !validateDPAE(
                            validateURSSAFCode,
                            DPAE.urssafCode
                        ),
                        message: "Le code URSSAF est invalide",
                    };
                } else if (key === "zipCode") {
                    return {
                        validate: !validateDPAE(validateZipCode, DPAE.zipCode),
                        message: "Le code postal est invalide",
                    };
                } else if (key === "phoneNum") {
                    return {
                        validate: !validateDPAE(
                            validatePhoneNum,
                            DPAE.phoneNum
                        ),
                        message: "Le numéro de téléphone est invalide",
                    };
                } else if (key === "address") {
                    return {
                        validate: !validateDPAE(validateStreet, DPAE.address),
                        message: "L'adresse est invalide",
                    };
                } else if (key === "ville") {
                    return {
                        validate: !validateDPAE(validateTown, DPAE.ville),
                        message: "La ville est invalide",
                    };
                } else if (key === "healthServiceCode") {
                    return {
                        validate: !validateDPAE(
                            validateContractHealthServiceCode,
                            DPAE.healthServiceCode
                        ),
                        message: "Le code du service de santé est invalide",
                    };
                }
            });
            if (!mappedArray.some((item) => item?.validate)) {
                if (selectedBranchSettings._id && token) {
                    const actionResponse: any = await dispatch(
                        updateDPAEinfo({
                            branchID: selectedBranchSettings._id,
                            token: token,
                            body: DPAE,
                        })
                    );
                    if (actionResponse?.error) {
                        return {
                            error: true,
                            message: "something went wrong!!!",
                            options: actionResponse,
                        };
                    } else {
                        return {
                            error: false,
                            payload: actionResponse.payload,
                        };
                    }
                }
            } else {
                const htmlMsg = mappedArray
                    .map((elem: any) => {
                        console.log("elem=>>", elem);
                        if (elem && elem.validate) {
                            const htmlMessage = `
                            <div style="display: flex;align-items: center;justify-content: center;gap: 7px;">
                                <img src='${FalseSVG}'>
                                <div style="color: #FF6C66;font-size: 20px;">${elem.message}</div>
                            </div>`;
                            return `${htmlMessage}`;
                        } else return null;
                    })
                    .filter((elem) => elem);
                return {
                    error: true,
                    message: "something went wrong!!!",
                    htmlMsg: htmlMsg,
                };
            }
        }
    };

    const setSiret = (data: string) => {
        dispatch(
            updateAdministrativeDPAE({
                siret: data,
            })
        );
    };

    const setAPECode = (data: string) => {
        if (data !== undefined && data !== null) {
            dispatch(
                updateAdministrativeDPAE({
                    apeCode: data,
                })
            );
        }
    };

    const setZipCode = (data: string) => {
        if (data !== undefined && data !== null) {
            dispatch(
                updateAdministrativeDPAE({
                    zipCode: data,
                })
            );
        }
    };
    const setStreet = (data: string) => {
        if (data !== undefined && data !== null) {
            dispatch(
                updateAdministrativeDPAE({
                    address: data,
                })
            );
        }
    };
    const setDesignation = (data: string) => {
        if (data !== undefined && data !== null) {
            dispatch(
                updateAdministrativeDPAE({
                    enseigne: data,
                })
            );
        }
    };
    const setUrssafCode = (data: string) => {
        if (data !== undefined && data !== null) {
            dispatch(
                updateAdministrativeDPAE({
                    urssafCode: data,
                })
            );
        }
    };
    const setPhoneNum = (data: string) => {
        if (data !== undefined && data !== null) {
            dispatch(
                updateAdministrativeDPAE({
                    phoneNum: data,
                })
            );
        }
    };
    const setCity = (data: string) => {
        if (data !== undefined && data !== null) {
            dispatch(
                updateAdministrativeDPAE({
                    ville: data,
                })
            );
        }
    };
    const setHealthServiceCode = (data: string) => {
        if (data !== undefined && data !== null) {
            dispatch(
                updateAdministrativeDPAE({
                    healthServiceCode: data,
                })
            );
        }
    };

    const saveDPAEInfo = (payload: any) => {
        dispatch(updateAdministrativeDPAE(payload));
    };

    const depositEmployeeDPAE = async () => {
        const token = localStorage.getItem("token");
        if (selectedUser._id && token && selectedBranchSettings._id) {
            // console.log({
            //     userID: selectedUser._id,
            //     token,
            // });
            await setIsDepositLoading(true);
            dispatch(
                depositUserDPAE({
                    userID: selectedUser._id,
                    token,
                    siteID: selectedBranchSettings._id,
                })
            ).then(async (response) => {
                await setIsDepositLoading(false);
                if (response.payload.error) {
                    if (response.payload.preDepositErrors) {
                        const errors = response.payload.preDepositErrors.map(
                            (erreur: any) => {
                                return `<div style="display: flex;flex-direction:row;align-items: center;justify-content: center;gap: 7px;">
                                <img src='${FalseSVG}'>
                                <div style="color: #FF6C66;font-size: 18px;">${erreur.message}</div>
                            </div>`;
                            }
                        );
                        Swal.fire({
                            icon: "error",
                            title: "Veuillez renseigner ces champs pour que vous puissiez faire la demande de la DPAE.",
                            html: `<div style="display:flex;flex-direction:column;align-items:start;text-align:left">
                                    ${errors.join(",").replace(/,/g, "<br>")}
                            </div>`,
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Les Erreurs",
                            html: `<div style="display:flex;flex-direction:column;align-items:start;margin-left: 20%;">
                            <div style="display: flex;align-items: center;justify-content: center;gap: 7px;">
                            <img src='${FalseSVG}'>
                            <div style="color: #FF6C66;font-size: 20px;">${response.payload.message}</div>
                            </div>
                            </div>`,
                        });
                    }
                } else {
                    console.log("Error DPAE", response);
                    if (response.payload.invalidResp?.length > 0) {
                        const errors = response.payload.invalidResp.map(
                            (erreur: any) => {
                                return `<div style="color: #FF6C66;font-size: 18px;">Erreur ${erreur.code} : ${erreur.message}</div>`;
                            }
                        );
                        Swal.fire({
                            icon: "error",
                            title: "Veuillez renseigner ces champs pour que vous puissiez faire la demande de la DPAE.",
                            html: `<div style="display:flex;flex-direction:column;align-items:start;">
                                <div style="display: flex;align-items: center;justify-content: center;gap: 7px;">
                                    <img src='${FalseSVG}'>
                                    ${errors.join(",")}
                                </div>
                            </div>`,
                        });
                    } else if (response.payload.fluxId) {
                        // <h5 style="color:#2980b9">Identifiant du dossier de dépôt :</h5>
                        //     <textarea style="text-align:left;width:70%;border:1px solid gray">${response.payload.fluxId}</textarea>
                        //     <h5 style="color:#2980b9">Certificat de conformité</h5>
                        //     <textarea style="text-align:left;width:70%;height:250px;border:1px solid gray">${response.payload.conformityCertification}</textarea>
                        Swal.fire({
                            icon: "success",
                            title: "Votre dépôt a été effectué avec succès",
                            html: `<div style="display:flex;flex-direction:column;align-items:center;">
                            <h5>Pour accéder au dépôt de votre dossier, veuillez consulter la section "Documents" de votre espace collaborateur.</h5>
                            </div>`,
                            confirmButtonText: "Télécharger",
                        }).then((isConfirm) => {
                            if (isConfirm.value) {
                                window.open(
                                    ROUTER.STATIC_FILE(response.payload.link),
                                    "_blank"
                                );
                            }
                            getEmployeeDocuments({ id: employeeForEdit._id });
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Erreur",
                            text: "Une erreur s'est produite au niveau du serveur.",
                        });
                    }
                }
            });
        }
    };

    return {
        isDPAEactive,
        DPAE,
        getDPAEInformation,
        updateDPAEinformations,
        setSiret,
        setAPECode,
        setZipCode,
        setStreet,
        setDesignation,
        setPhoneNum,
        setUrssafCode,
        setCity,
        setHealthServiceCode,
        saveDPAEInfo,
        depositEmployeeDPAE,
        isDepositLoading,
    };
};
