import React from "react";

export default function InputInfos({ dpae = false, rup = false }: InputInfosProps) {
  return (
    <div className="flex items-center gap-1 mx-2">
      {dpae && (
        <span className="bg-n-0 rounded-[10px] py-[1.5px] px-[6px] text-n-600 text-sm">
          DPAE
        </span>
      )}
      {rup && (
        <span className="bg-n-0 rounded-[10px] py-[1.5px] px-[6px] text-n-600 text-sm">
          RUP
        </span>
      )}
    </div>
  );
}

interface InputInfosProps {
    dpae?: boolean;
  rup?: boolean;
}
