import React from "react";
import Button from "../../atoms/ZButton";
import { useIntl } from "react-intl";
import { AddSquare } from "../../../Icons";

export default function Primes({
    openEditPrimesDialog,
    primesTable,
}: PrimesProps) {
    const intl = useIntl();
    return (
        <div>
            <div className='flex justify-between border-b border-n-100 pb-3 mb-10'>
                <h2 className='text-v-800 font-bold text-9xl'>
                    {intl.formatMessage({ id: "EMPLOYEE.EDIT.PRIMES" })}
                </h2>
                <Button
                    containerClasses='bg-t-500-op-1 hover:bg-t-500-op-3 text-t-500'
                    title={intl.formatMessage({
                        id: "EMPLOYEE.PRIMES.CREATE_PRIME",
                    })}
                    leftComponent={<AddSquare />}
                    onClick={() => openEditPrimesDialog(null)}
                />
            </div>
            <div style={{ minHeight: "45vh" }}>{primesTable}</div>
        </div>
    );
}

interface PrimesProps {
    primesTable: React.ReactNode;
    openEditPrimesDialog: (data: any) => void;
}
