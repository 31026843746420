import * as React from "react";
import { updatePassword } from "../redux/features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import { resetPasswordServ } from "../redux/features/auth/service";
import { useNavigate } from "react-router-dom";
import { ROUTER } from "../constants/env";

export const useResetPassword = () => {
    let auth = useAppSelector((state: any) => state.auth);
    const [passwordIsIdentical, setPasswordIsIdentical] = React.useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const setPassword = (password: string) => {
        dispatch(updatePassword({ password }));
    };
    // let passwordIsIdentical: boolean = false;
    const confirmedPassword = (confirmedPassword: string) => {
        if (confirmedPassword === auth.password) {
            setPasswordIsIdentical(true);
        } else {
            setPasswordIsIdentical(false);
        }
    };
    const resetPassword = async (token?: string) => {
        if (passwordIsIdentical && token) {
            dispatch(
                resetPasswordServ({ password: auth.password, token })
            ).then((res) => {
                if (
                    res?.meta?.requestStatus === "fulfilled" &&
                    res.payload?.user
                ) {
                    navigate(ROUTER.AUTH.LOGIN);
                }
            });
        }
    };
    return {
        auth,
        setPassword,
        confirmedPassword,
        resetPassword,
    };
};
