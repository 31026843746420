import * as React from "react";
import "./style.scss";
import TextData from "../../atoms/TextData";

function EmployeeInfoCellRow(props: EmployeeInfoCellRowProps) {
  const { data } = props;
  return (
    <div className="employee-info-cell-row-container">
      {data?.map((item, index) => (
        <TextData {...item} key={index}>
          {item.value}
        </TextData>
      ))}
    </div>
  );
}
interface EmployeeInfoCellRowProps {
  data?: {
    value?: string;
    fontWeight?: string;
    width?: string;
    color?: string;
    size?: string;
    style?: React.CSSProperties;
  }[];
}
EmployeeInfoCellRow.defaultProps = {
  data: [
    {
      value: "15h00",
      fontWeight: "bold",
      width: "auto",
      color: "#4C5690",
      size: "14px",
    },
    {
      value: "(-5h00)",
      fontWeight: "bold",
      width: "auto",
      color: "#00A42E",
      size: "14px",
    },
  ],
};

export default EmployeeInfoCellRow;
