import React from "react";
import ZInput from "../ZInput";
import { FieldInterface } from "../../../_helpers/FormHelpers";
import { Calendar as CalendarIcon } from "../../../Icons";
import Calendar from "../../organism/Calendar";
import moment, { Moment } from "moment";
import Theme from "../../../tailwind-theme";

export default function DatepickerInput({
    field,
    onBlur,
    onUpdate,
    required,
    label,
    dpae = false,
    rup = false,
    desactivated = false,
    side = "right",
    style,
}: DatepickerInputInterface) {
    const [showDatePicker, setShowDatePicker] = React.useState(false);
    const [yPos, setYPos] = React.useState(0);
    const [xPos, setXPos] = React.useState(0);

    const toggleCalendar = () => {
        setShowDatePicker((prev) => !prev);
    };

    const hideCalendar = () => {
        setShowDatePicker(false);
    };

    const CALENDAR_HEIGHT = 328;
    const CONTAINER_HEIGHT = 72;

    const divRef = React.useRef<HTMLDivElement>(null);
    // React.useEffect(() => {
    //     document.addEventListener("click", (event) => {
    //         let node = event.target as Node;
    //         let root = node.getRootNode() as HTMLDivElement;
    //         if (
    //             divRef &&
    //             divRef.current &&
    //             !divRef.current.contains(event.target as Node) &&
    //             (!root.id || root.id.indexOf("react-select") === -1)
    //         ) {
    //             hideCalendar();
    //         }
    //     });
    // }, []);

    const getParentElement = (node: HTMLElement): HTMLElement | null => {
        while (node.parentNode) {
            if (node.classList.contains("modalBody")) {
                return node;
            }
            node = node.parentNode as HTMLElement;
        }
        return null;
    };

    const checkWindowHeightSpace = () => {
        const windowHeight = window.innerHeight;
        const spaceAbove = divRef.current!.getBoundingClientRect().top!;
        const spaceBelow =
            windowHeight - divRef.current!.getBoundingClientRect().bottom!;
        return (
            spaceBelow >= CALENDAR_HEIGHT + CONTAINER_HEIGHT ||
            spaceAbove < CALENDAR_HEIGHT
        );
    };

    const checkNodeHeightSpace = (target: HTMLElement) => {
        const windowHeight = window.innerHeight;
        const spaceAbove =
            divRef.current?.offsetTop! -
            (target.scrollTop ? target.scrollTop : 0);
        const spaceBelow =
            windowHeight - divRef?.current?.getBoundingClientRect()?.bottom!;
        if (spaceBelow >= CALENDAR_HEIGHT || spaceAbove < CALENDAR_HEIGHT) {
            return true;
        } else {
            return false;
        }
    };

    React.useEffect(() => {
        if (divRef.current) {
            const handleScroll = (
                target: HTMLElement | null = divRef.current,
            ) => {
                if (target) {
                    let y = divRef.current?.offsetTop!;
                    let up = checkNodeHeightSpace(target);
                    setYPos(
                        up
                            ? y - target.scrollTop + CONTAINER_HEIGHT
                            : y - target.scrollTop - CALENDAR_HEIGHT,
                    );
                }
            };

            const parentRef = getParentElement(divRef.current);
            if (parentRef) {
                parentRef.addEventListener("scroll", () => {
                    handleScroll(parentRef);
                });
                let y = divRef.current?.offsetTop!;
                let up = checkNodeHeightSpace(parentRef);
                setYPos(up ? y + CONTAINER_HEIGHT : y - CALENDAR_HEIGHT);
            } else {
                window.addEventListener("scroll", () => {
                    setYPos(getCalendarPlacement());
                });
                setYPos(getCalendarPlacement());
            }

            window.addEventListener("resize", () => {
                setXPos(divRef.current?.offsetLeft!);
            });
            setXPos(divRef.current?.offsetLeft!);
        }
    }, [divRef]);

    const getCalendarPlacement = (): number => {
        const windowHeight = window.innerHeight;
        const spaceAbove = divRef.current!.getBoundingClientRect().top!;
        const spaceBelow =
            windowHeight - divRef.current!.getBoundingClientRect().bottom!;
        if (spaceBelow >= CALENDAR_HEIGHT || spaceAbove < CALENDAR_HEIGHT) {
            return (
                divRef.current!.getBoundingClientRect().top! + CONTAINER_HEIGHT
            );
        } else {
            return (
                divRef.current!.getBoundingClientRect().top! - CALENDAR_HEIGHT
            );
        }
    };

    return (
        <div
            ref={divRef}
            className="relative"
            style={{
                opacity: desactivated ? "0.5" : "1",
            }}
        >
            <ZInput
                desactivated={desactivated}
                label={label}
                required={required}
                field={{
                    ...field,
                    value: field.value
                        ? moment(field.value).format("DD-MM-YYYY")
                        : "",
                }}
                onBlur={onBlur}
                rightInputAction={<CalendarIcon />}
                onInputClick={toggleCalendar}
                dpae={dpae}
                rup={rup}
                style={{ ...style }}
            />
            <div style={{ display: "flex", position: "relative" }}>
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: side === "right" ? 0 : "",
                        right: side === "left" ? 0 : "",
                        zIndex: 2,
                        boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}
                >
                    <Calendar
                        calenderContainerClasses="border _shaddow"
                        open={showDatePicker}
                        onChange={(value) => {
                            const val = value as Moment;
                            onUpdate(val);
                            toggleCalendar();
                        }}
                        value={moment(
                            field.value && field.value !== ""
                                ? moment(field.value)
                                : undefined,
                        )}
                        showWeekCount={false}
                    />
                </div>
            </div>
        </div>
    );
}

interface DatepickerInputInterface {
    withFeedbackLabel?: boolean;
    placeholder?: string;
    required: boolean;
    onUpdate: (data: Moment) => void;
    onBlur?: (field: string) => void;
    field: FieldInterface;
    label?: string | React.ReactNode;
    labelRightAction?: React.ReactNode;
    rup?: boolean;
    dpae?: boolean;
    desactivated?: boolean;
    side?: string;
    style?: any;
}
