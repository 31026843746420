import React from "react";
import Modal from "../../organism/Modal";
import { useIntl } from "react-intl";
import EditAmendmentForm from "./EditAmendmentForm";

export default function EditAmendmentDialog({
  show,
  onHide,
  amendment,
  onSubmit,
  onChange,
}: EditAmendmentDialogInterface) {
  const intl = useIntl();
  return (
    <>
      {amendment && (
        <Modal
          title={intl.formatMessage({ id: "MENU.CREATE_AMENDMENT" })}
          show={show}
          onHide={onHide}
          body={<EditAmendmentForm amendment={amendment} onChange={onChange} />}
          width={720}
          confirm={intl.formatMessage({ id: "MENU.SAVE" })}
        />
      )}
    </>
  );
}

interface EditAmendmentDialogInterface {
  show: boolean;
  onHide(): void;
  onChange(field: string, value: any): void;
  amendment: any;
  onSubmit(): void;
}
