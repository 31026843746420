import { extractNumber } from "../../../_helpers/Functions";
import Input from "../../../components/atoms/Input";
import ToggleButton from "../../../components/atoms/Toggle";
import { useBranch } from "../../../hooks/useBranch";
import React from "react";
import { useNotifications } from "../../../hooks/useNotifications";

export const NotificationsProps = () => {
    const {
        branchSettings,

        setDaysForUnavailabilityNotice,
        setDaysForVacationNotice,
    } = useBranch();
    const {
        notificationsSettings,
        setNotificationsSettings,
        getNotificationsSettings,
    } = useNotifications();
    const [notifSettings, setNotifSettings] = React.useState({
        disableRest: false,
    });
    React.useEffect(() => {
        getNotificationsSettings();
    }, []);
    // React.useEffect(() => {
    //     if (notificationsSettings) {
    //         const { birthdayNotif, lateArrivalNotif, notClockedOutNotif } =
    //             notificationsSettings;
    //         setNotificationsSettings((prev: any) => ({
    //             ...prev,
    //             birthdayNotif,
    //             lateArrivalNotif,
    //             notClockedOutNotif,
    //         }));
    //     }
    // }, [notificationsSettings]);
    const collaborateurParams = () => {
        return [
            {
                optionTitle: "L'anniversaire d'un collaborateur",
                titleColor: "#2A346D",
                titleSize: "16px",
                titleWeight: "bolder",
                descriptionColor: "#6F78AB",
                descriptionSize: "13",
                descriptionWeight: "normal",
                optionDescription:
                    "Recevez des notifications pour les anniversaires de vos collaborateurs. ",
                optionInput: (
                    <ToggleButton
                        onClick={(e: any) =>
                            setNotificationsSettings({
                                ...notificationsSettings,
                                birthdayNotif:
                                    !notificationsSettings.birthdayNotif,
                            })
                        }
                        isChecked={notificationsSettings.birthdayNotif}
                    />
                ),
            },
            {
                optionTitle: "Un collaborateur en retard",
                titleColor: "#2A346D",
                titleSize: "16px",
                titleWeight: "bolder",
                descriptionColor: "#6F78AB",
                descriptionSize: "13",
                descriptionWeight: "normal",
                optionDescription:
                    "Recevez une notification en cas de retard d’un collaborateur. ",
                optionInput: (
                    <ToggleButton
                        onClick={(e: any) =>
                            setNotificationsSettings({
                                ...notificationsSettings,
                                lateArrivalNotif:
                                    !notificationsSettings.lateArrivalNotif,
                            })
                        }
                        isChecked={notificationsSettings.lateArrivalNotif}
                    />
                ),
            },
            {
                optionTitle: "Un collaborateur qui n'a pas dépointé",
                titleColor: "#2A346D",
                titleSize: "16px",
                titleWeight: "bolder",
                descriptionColor: "#6F78AB",
                descriptionSize: "13",
                descriptionWeight: "normal",
                optionDescription:
                    "Recevez une notification si un collaborateur n’a pas pointé pour son départ. ",
                optionInput: (
                    <ToggleButton
                        onClick={(e: any) =>
                            setNotificationsSettings({
                                ...notificationsSettings,
                                notClockedOutNotif:
                                    !notificationsSettings.notClockedOutNotif,
                            })
                        }
                        isChecked={notificationsSettings.notClockedOutNotif}
                    />
                ),
            },
        ];
    };
    return [
        {
            headerTitle: "Collaborateur",
            headerDescription:
                "Configurez les jours les notifications où les collaborateurs sont en congé ou ne travaillent pas. ",
            configOptions: [...collaborateurParams()],
        },
        {
            headerTitle: "Repos",
            headerDescription:
                "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
            configOptions: [
                {
                    optionTitle:
                        "Désactiver les notifications des jours de repos",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Arrêtez de recevoir des notifications concernant les jours de repos des collaborateurs. ",
                    optionInput: (
                        <ToggleButton
                            onClick={(e: any) =>
                                setNotifSettings((prev: any) => ({
                                    ...prev,
                                    disableRest: !prev.disableRest,
                                }))
                            }
                            isChecked={notifSettings.disableRest}
                        />
                    ),
                },
            ],
        },
    ];
};
