import * as React from "react";
import "./style.scss";
import Modal from "../../atoms/Modal";
import Button from "../../atoms/Button";
import PopUpCard from "../../molecules/PopUpCard";
import TextData from "../../atoms/TextData";
import SelectInput from "../../atoms/SelectInput";
import DatepickerInput from "../../atoms/DatepickerInput";
import { generateDayList } from "../../../_helpers/Functions";
import moment from "moment";
import { ReactComponent as CalendarSVG } from "./calendar.svg";
import { formatDate } from "../../../_helpers/Functions";
import { usePlanning } from "../../../hooks/usePlanning";
import { ReactComponent as Plus } from "./plus.svg";
import { ReactComponent as TrashSVG } from "./trash.svg";
import { ReactComponent as InfoSVG } from "./info.svg";

export default function AddNoteCard(props: any /*AddNoteCardProps*/) {
    const { display, onClose, isEdit, dayNumber, startOfWeek, department } =
        props;
    const {
        addWeekPlanningNotes,
        editWeekPlanningNotes,
        deleteWeekPlanningNote,
        planningInfoDepartments,
        planningInfo,
    } = usePlanning();
    const [dateField, setDateField] = React.useState({
        name: "day",
        value: moment(startOfWeek).add(dayNumber, "days").format("YYYY-MM-DD"),
    });
    const [noteItem, setNoteItem] = React.useState({
        note: "",
        day: moment(startOfWeek).add(dayNumber, "days").format("DD/MM/YYYY"),
    });
    React.useEffect(() => {
        setDateField({
            name: "day",
            value: moment(startOfWeek)
                .add(dayNumber, "days")
                .format("YYYY-MM-DD"),
        });
        setNoteItem((prev) => ({
            ...prev,
            day: moment(startOfWeek)
                .add(dayNumber, "days")
                .format("DD/MM/YYYY"),
        }));
    }, [dayNumber]);
    React.useEffect(() => {
        if (!display) {
            setDateField({
                name: "day",
                value: moment(startOfWeek)
                    .add(dayNumber, "days")
                    .format("YYYY-MM-DD"),
            });
            setNoteItem((prev) => ({
                ...prev,
                note: "",
            }));
        }
    }, [display]);
    React.useEffect(() => {
        if (isEdit) {
            let noteToEdit = "";

            if (department) {
                for (
                    let i = 0;
                    i < planningInfoDepartments[department].note.length;
                    i++
                ) {
                    if (
                        planningInfoDepartments[department].note[i].day ===
                        moment(dateField.value, "YYYY-MM-DD").format(
                            "DD/MM/YYYY",
                        )
                    ) {
                        noteToEdit =
                            planningInfoDepartments[department].note[i].note;
                        break;
                    }
                }
            } else {
                for (let i = 0; i < planningInfo.note.length; i++) {
                    if (
                        planningInfo.note[i].day ===
                        moment(dateField.value, "YYYY-MM-DD").format(
                            "DD/MM/YYYY",
                        )
                    ) {
                        noteToEdit = planningInfo.note[i].note;
                        break;
                    }
                }
            }
            setNoteItem((prev) => ({ ...prev, note: noteToEdit })); //todo
        }
    }, [dateField, isEdit]);

    const handleDateChange = (value: any) => {
        const newDay = moment(new Date(value));
        setNoteItem((prev) => ({ ...prev, day: newDay.format("DD/MM/YYYY") }));
        setDateField((prev) => ({
            ...prev,
            value: newDay.format("YYYY-MM-DD"),
        }));
    };

    const HeaderTitle = () => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "25px auto 25px 40px",
                }}
            >
                <TextData
                    variant="label"
                    width="auto"
                    size="22px"
                    fontWeight="700"
                    color="#2A346D"
                >
                    {isEdit ? "Modifier la " : "Ajouter une "}note
                </TextData>
            </div>
        );
    };
    const handleAddNoteAction = () => {
        addWeekPlanningNotes(noteItem, department);
        handleClose();
    };
    const handleEditNoteAction = () => {
        editWeekPlanningNotes(noteItem, department);
        handleClose();
    };
    const handleDeleteNoteAction = () => {
        deleteWeekPlanningNote(dayNumber, department);
        handleClose();
    };
    const handleClose = () => {
        setNoteItem((prev) => ({ ...prev, note: "" }));
        onClose && onClose();
    };
    const AlertBody = () => {
        return (
            <div
                style={{
                    color: "#1a7290",
                    backgroundColor: "#cfe0e6",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    gap: "8px",
                    borderRadius: "5px",
                    padding: "8px",
                    marginBottom: "20px",
                }}
            >
                <div style={{ paddingTop: "0px" }}>
                    <InfoSVG color="inherit" height={22} width={22} />
                </div>
                <div
                    style={{
                        color: "inherit",
                        fontSize: "small",
                    }}
                >
                    Les notes que vous rajouter ici vous permettent d'adapter au
                    mieux le planning. "Exemple : Réservation groupe 40
                    personnes". Vos collaborateurs peuvent retrouver cette note
                    sur la pointeuse au moment du pointage (et si l'option est
                    activé dans les paramètres)
                </div>
            </div>
        );
    };
    return (
        <Modal modalEnabled={display} onClose={onClose}>
            <div className="adding-note-container">
                <PopUpCard
                    containerWidth="500px"
                    display={display}
                    onClose={onClose}
                    header={<HeaderTitle />}
                    widthCloseIcon="24px"
                    styleCloseIcon={{
                        height: "auto",
                        marginRight: "37px",
                        width: "24px",
                        border: "1px solid #edeff533",
                        borderTopRightRadius: "20px",
                    }}
                    headerStyle={{
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#2A346D",
                    }}
                    popupHeaderStyle={{
                        height: "80px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                    }}
                    button1Text="Annuler"
                    onClickButton1={handleClose}
                    button2Text={isEdit ? "Supprimer" : "Ajouter"}
                    onClickButton2={
                        isEdit ? handleDeleteNoteAction : handleAddNoteAction
                    }
                    button2Style={
                        isEdit
                            ? {
                                  backgroundColor: "#eb2f06",
                                  color: "white",
                                  fontSize: "14px",
                              }
                            : undefined
                    }
                    button3Text={isEdit ? "Modifier" : undefined}
                    onClickButton3={isEdit ? handleEditNoteAction : undefined}
                >
                    <div className="add-note-container">
                        <div className="add-date-container">
                            <AlertBody />

                            <div style={{ width: "150px" }}>
                                <DatepickerInput
                                    label="Jour"
                                    required={false}
                                    field={dateField}
                                    onUpdate={handleDateChange}
                                />
                            </div>
                            {/* <SelectInput
                                title='Date'
                                captionColor='#8E96C1'
                                captionSize='12px'
                                options={dayList.map((day) => {
                                    return {
                                        label: moment(
                                            day,
                                            selectDayLabelFormat
                                        ).format(selectDayLabelFormat),
                                        value: moment(
                                            day,
                                            selectDayLabelFormat
                                        ).format(selectDayValueFormat),
                                        icon: (
                                            <CalendarSVG
                                                style={{ marginRight: "5px" }}
                                            />
                                        ),
                                    };
                                })}
                                selectedValue={selectedDay}
                                onSelectOption={setSelectedDay}
                            /> */}
                        </div>
                        <div className="add-text-area-container">
                            <TextData
                                variant="label"
                                width="auto"
                                color="#8E96C1"
                                fontWeight="500"
                                size="12px"
                            >
                                Notes
                            </TextData>
                            {
                                // noteItem?.note?.map((oneNote, ind) => (
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "5px",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <textarea
                                        placeholder="Écrire une note ici. Le retour à la ligne permet de les séparer."
                                        value={noteItem?.note}
                                        onChange={(e) => {
                                            const noteArea =
                                                e.target.value.replace(
                                                    /^[ \t]+|[ \t]+$/gm,
                                                    " ",
                                                );
                                            let lines = noteArea.split("\n");
                                            if (
                                                lines.every((line) =>
                                                    /^.{0,50}$/.test(line),
                                                )
                                            ) {
                                                setNoteItem((prev) => ({
                                                    ...prev,
                                                    note: noteArea,
                                                }));
                                            }
                                        }}
                                        style={{
                                            borderRadius: "4px",
                                            border: "1px solid #C7CBE2",
                                            padding: "10px 8px",
                                            color: "#4C5690",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            width: "90%",
                                            height: "120px",
                                        }}
                                    />
                                    {/* {ind > 0 && (
                                            <Button
                                                backgroundColor='#fff'
                                                width='auto'
                                                padding='3px 1px'
                                                title='Supprimer la note'
                                                style={{
                                                    border: "1px solid #FB3640",
                                                    boxShadow:
                                                        "1px 1px 4px 0px rgba(17, 16, 110, 0.10)",
                                                    display: "inline",
                                                    marginTop: "15px",
                                                }}
                                                onClick={() => {
                                                    setNoteItem((prev) => {
                                                        let updatedNotes =
                                                            prev.note.filter(
                                                                (item, index) =>
                                                                    index !==
                                                                    ind
                                                            );
                                                        return {
                                                            ...prev,
                                                            note: updatedNotes,
                                                        };
                                                    });
                                                }}
                                            >
                                                <TrashSVG
                                                    width={17}
                                                    height={17}
                                                />
                                            </Button>
                                        )} */}
                                </div>
                            }

                            {/* {noteItem.note.length < 10 && (
                                <Button
                                    width='auto'
                                    backgroundColor='transparent'
                                    textColor='#2A8BAB'
                                    style={{
                                        gap: 10,
                                        marginBottom: 29,
                                    }}
                                    onClick={() => {
                                        setNoteItem((prev) => {
                                            const updatedNotes = [
                                                ...prev.note,
                                                "",
                                            ];
                                            return {
                                                ...prev,
                                                note: updatedNotes,
                                            };
                                        });
                                    }}
                                >
                                    <Plus />
                                    <TextData
                                        width='auto'
                                        fontWeight='600'
                                        size='14px'
                                    >
                                        Ajouter une note
                                    </TextData>
                                </Button>
                            )} */}
                        </div>
                    </div>
                </PopUpCard>
            </div>
        </Modal>
    );
}
interface AddNoteCardProps {
    display?: boolean;
    onClose?: (event?: Event) => void;
    onSubmit?: (event?: Event) => void;
    defaultDay?: any;
    isEdit?: boolean;
}
AddNoteCard.defaultProps = {
    display: true,
    onClose: () => {
        console.log("onClose");
    },
    defaultDay: moment(),
    isEdit: false,
};
