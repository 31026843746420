import React from "react";
import { useIntl } from "react-intl";
import ZInput from "../../atoms/ZInput";
import Button from "../../atoms/ZButton";
import { Eye, EyeSlash, CheckIcon, ErrorIcon } from "../../../Icons";

interface Errors {
  lowerCase: boolean;
  number: boolean;
  special: boolean;
  length: boolean;
}

export default function ChangePassword({
  password,
  onBlur,
  onChange,
  onSubmit,
  errors,
}: ChangePasswordInterface) {
  const intl = useIntl();

  const [showPasses, setShowPasses] = React.useState({
    current: false,
    new: false,
    repeat: false,
  });

  const handleShowPass = (name: string) => {
    setShowPasses((prev: any) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const checkError = (): boolean => {
    let error = false;
    if (password.new.hasError) {
      error = true;
    }
    if (password.repeat.hasError) {
      error = true;
    }
    return error;
  };

  return (
    <div>
      <h2 className="text-v-800 font-bold text-9xl mb-8">
        {intl.formatMessage({ id: "EMPLOYEE.EDIT.PASSWORD.TITLE" })}
      </h2>
      <div className="flex flex-wrap gap-8 mb-10">
        <div className="basis-[300px]">
          <div className="mb-4">
            <ZInput
              type={showPasses.current ? "text" : "password"}
              required={false}
              field={password.current}
              label={intl.formatMessage({
                id: "EMPLOYEE.EDIT.PASSWORD.CURRENT_PASSWORD",
              })}
              placeholder={intl.formatMessage({
                id: "EMPLOYEE.EDIT.PASSWORD.CURRENT_PASSWORD",
              })}
              onBlur={onBlur}
              onUpdate={(value) => {
                onChange("current", value);
              }}
              rightInputAction={
                <div
                  className="cursor-pointer"
                  onClick={() => handleShowPass("current")}
                >
                  {showPasses.current ? <EyeSlash /> : <Eye />}
                </div>
              }
            />
          </div>
          <div className="basis-[200px] grow my-2 font-semibold ">
            <div>
              <span className="inline-block text-n-500 text-lg">
                {intl.formatMessage({
                  id: "EMPLOYEE.EDIT.PASSWORD.REQUIREMENTS",
                })}
              </span>
            </div>
            <div className="flex flex-col gap-1">
              <div
                className={`flex items-center gap-2 ${
                  errors.length
                    ? "text-error-secondary"
                    : "text-success-secondary"
                }`}
              >
                {errors.length ? <ErrorIcon /> : <CheckIcon />}
                <span className="text-md">
                  {intl.formatMessage(
                    { id: "REQUIREMENTS.LENGTH" },
                    { min: 8, max: 40 }
                  )}
                </span>
              </div>
              <div
                className={`flex items-center gap-2 ${
                  errors.lowerCase
                    ? "text-error-secondary"
                    : "text-success-secondary"
                }`}
              >
                {errors.lowerCase ? <ErrorIcon /> : <CheckIcon />}
                <span className=" text-md">
                  {intl.formatMessage(
                    { id: "REQUIREMENTS.UPPERCASE_AND_LOWERCASE" },
                    { lowercase: 1, uppercase: 1 }
                  )}
                </span>
              </div>
              <div
                className={`flex items-center gap-2 ${
                  errors.number
                    ? "text-error-secondary"
                    : "text-success-secondary"
                }`}
              >
                {errors.number ? <ErrorIcon /> : <CheckIcon />}
                <span className=" text-md">
                  {intl.formatMessage(
                    { id: "REQUIREMENTS.NUMBER" },
                    { number: 1 }
                  )}
                </span>
              </div>
              <div
                className={`flex items-center gap-2 ${
                  errors.special
                    ? "text-error-secondary"
                    : "text-success-secondary"
                }`}
              >
                {errors.special ? <ErrorIcon /> : <CheckIcon />}
                <span className="text-md">
                  {intl.formatMessage(
                    { id: "REQUIREMENTS.SPECIAL_CHARACTER" },
                    { number: 1, allowed: "(%#:$*)" }
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <ZInput
              type={showPasses.new ? "text" : "password"}
              required={false}
              field={password.new}
              label={intl.formatMessage({
                id: "EMPLOYEE.EDIT.PASSWORD.NEW_PASSWORD",
              })}
              placeholder={intl.formatMessage({
                id: "EMPLOYEE.EDIT.PASSWORD.NEW_PASSWORD",
              })}
              onBlur={onBlur}
              onUpdate={(value) => {
                onChange("new", value);
              }}
              rightInputAction={
                <div
                  className="cursor-pointer"
                  onClick={() => handleShowPass("new")}
                >
                  {showPasses.new ? <EyeSlash /> : <Eye />}
                </div>
              }
            />
          </div>
          <div className="mb-4">
            <ZInput
              type={showPasses.repeat ? "text" : "password"}
              required={false}
              field={password.repeat}
              label={intl.formatMessage({
                id: "EMPLOYEE.EDIT.PASSWORD.REPEAT_PASSWORD",
              })}
              placeholder={intl.formatMessage({
                id: "EMPLOYEE.EDIT.PASSWORD.REPEAT_PASSWORD",
              })}
              onBlur={onBlur}
              onUpdate={(value) => {
                onChange("repeat", value);
              }}
              rightInputAction={
                <div
                  className="cursor-pointer"
                  onClick={() => handleShowPass("repeat")}
                >
                  {showPasses.repeat ? <EyeSlash /> : <Eye />}
                </div>
              }
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end items-center border-t min-h-[100px]">
        <Button
          title={intl.formatMessage({ id: "MENU.RESET" })}
          onClick={onSubmit}
          variant="primary"
          disabled={checkError()}
        />
      </div>
    </div>
  );
}

interface ChangePasswordInterface {
  password: any;
  onChange: (field: string, value: any) => void;
  onBlur: (field: string) => void;
  onSubmit: () => void;
  errors: Errors;
}
