import React from "react";
import Pointeuse from "../../../components/templates/EmployeesEdit/Pointeuse";
import { generateFields } from "../../../_helpers/FormHelpers";

const initValues = [
  { name: "active", value: true },
  { name: "address", value: "22 Bd d'Athènes, 13001 Marseille, France" },
  { name: "radius", value: "5" },
  { name: "days", value: [0, 1, 3] },
  { name: "position", value: { lat: 48.8566, lng: 2.3522 } },
];

export default function PointeusePage() {
  const [pointeuse, setPointeuse] = React.useState(generateFields(initValues));

  const onChange = (field: string, value: any) => {
    setPointeuse((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        value,
      },
    }));
  };

  return (
    <div>
      <Pointeuse pointeuse={pointeuse} onChange={onChange} />
    </div>
  );
}
