import * as React from "react";
import { Link } from "react-router-dom";

import { ROUTER } from "../../../constants/env";
import "./style.scss";
import logo from "./assets/logo.svg";
import emailIcon from "./assets/emailIcon.svg";

import TextData from "../../atoms/TextData";
import Button from "../../atoms/Button";
import Input from "../../atoms/Input";
import Picture from "../../atoms/Picture";
import { useForgetPassword } from "../../../hooks/useForgetPassword";

export default function ForgetPassword(props: ForgetPasswordProps) {
    const { auth, forgetPassword, setEmail } = useForgetPassword();
    const {
        heading,
        subHeading,
        buttonText,
        connectText,
        inputTextCaption,
        inputPlaceholder,
        errorMessage,
        handleConnectOnClick,
    } = props;
    const globalProps = {
        headingProps: {
            variant: "div" as "div",
            color: "#555",
            width: "100%",
            size: "36px",
            style: {
                textAlign: "center" as "center",
                fontWeight: "700",
            },
        },
        subHeadingProps: {
            variant: "div" as "div",
            color: "#555555CC",
            style: {
                textAlign: "center" as "center",
                marginTop: 22,
                marginBottom: 19,
            },
            width: "100%",
        },
        emailProps: {
            autoComplete: "on" as "on",
            captionText: inputTextCaption,
            captionColor: "#809FB8",
            captionSize: "12px",
            borderWidth: 1,
            borderColor: "#3598B8",
            textSize: 14,
            textColor: "#555",
            iconImg: emailIcon,
            inputRadius: "4px",
            inputWidth: "100%",
            placeholder: inputPlaceholder,
            value: auth.email,
            onChange: (email: string) => {
                setEmail(email.trim());
            },
            style: {
                captionStyle: {
                    marginTop: "20px",
                    fontWeight: "400",
                    letterSpacing: "-0.5px",
                },
                inputStyle: {
                    padding: "10px 16px",
                },
                errorStyle: {
                    marginTop: "7px",
                    backgroundColor: "#FF6C66",
                    borderRadius: "4px",
                    padding: "5px 10px",
                },
            },
            errorColor: "#7D2828",
            errorIsActivated: auth.error,
            errorSize: 12,
            errorText: errorMessage,
        },
        buttonProps: {
            width: "100%",
            height: "50px",
            backgroundColor: "#3598B8",
            textColor: "white",
            padding: "10px 5px 5px 10px",
            style: {
                marginTop: 15,
                marginBottom: 15,
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "-0.5px",
            },
            onClick: forgetPassword,
        },
        connectProps: {
            variant: "div" as "div",
            width: "100%",
            activateHover: true,
            color: "#809FB8",
            size: "14px",
            fontWeight: "700",
            style: {
                textAlign: "center" as "center",
                textDecoration: "underline",
                marginBottom: 25,
            },
            onClick: handleConnectOnClick,
        },
    };
    return (
        <div className="forget-password-container">
            <TextData {...globalProps.headingProps}>{heading}</TextData>
            <TextData {...globalProps.subHeadingProps}>{subHeading}</TextData>
            <Input {...globalProps.emailProps} />
            <Button {...globalProps.buttonProps}>{buttonText}</Button>
            <Link to={ROUTER.AUTH.LOGIN}>
                <TextData {...globalProps.connectProps}>{connectText}</TextData>
            </Link>
            <Link to={ROUTER.HOME}>
                <Picture imgPath={logo} />
            </Link>
        </div>
    );
}

interface ForgetPasswordProps {
    heading?: string;
    subHeading?: string;
    buttonText?: string;
    connectText?: string;
    inputTextCaption?: string;
    inputPlaceholder?: string;
    errorMessage?: string;
    handleConnectOnClick?: React.MouseEventHandler<HTMLElement>;
}
ForgetPassword.defaultProps = {
    heading: "Mot de passe oublié?",
    subHeading:
        "Nous vous enverrons les instructions à l'adresse courriel associée à votre compte.",
    buttonText: "Réinitialiser",
    connectText: "Se connecter",
    inputTextCaption: "E-mail",
    inputPlaceholder: "Votre adresse E-mail",
    errorMessage: "Adresse E-mail invalide",
    handleConnectOnClick: () => {},
};
