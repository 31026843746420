import React from "react";
import { useLanguage } from "../../hooks/useLanguage";
import { IntlProvider } from "react-intl";
import frText from "./text/fr.json";
import esText from "./text/es.json";

const allMessages = {
  fr: frText,
  es: esText,
};

export default function ESPIntlProvider({
  children,
}: ESPIntlProviderInterface) {
  const lang = localStorage.getItem("lang");
  const messages = allMessages["fr"];

  return (
    <IntlProvider locale={"fr"} messages={messages}>
      {children}
    </IntlProvider>
  );
}

interface ESPIntlProviderInterface {
  children: React.ReactNode;
}
