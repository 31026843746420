import React from "react";
import moment from "moment";

export const ArchiveDateColumnFormatter = (date: string) => {
  return (
    <span className="text-v-800 text-md font-light">
      {moment(date).format("DD MMMM, YYYY")}
    </span>
  );
};
