import React from "react";
import TabNavigator from "../../molecules/TabNavigator";
import TabContent from "../../atoms/TabContent";
import { FormattedMessage } from "react-intl";
import ZAlert from "../../atoms/ZAlert";
import InputInfos from "../../atoms/InputInfos";
import { CircleInfos } from "../../../Icons";

const contracts = [
  {
    title: <FormattedMessage id="CONTRACTS.TYPE.CDD" />,
    desc: "Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.",
    value: "CDD",
  },
  {
    title: <FormattedMessage id="CONTRACTS.TYPE.CDI" />,
    desc: "Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.",
    value: "CDI",
  },
  {
    title: <FormattedMessage id="CONTRACTS.TYPE.INTERIM" />,
    desc: "Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.",
    value: "Intérim",
  },
  {
    title: <FormattedMessage id="CONTRACTS.TYPE.EXTRA" />,
    desc: "Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.",
    value: "Extra",
  },
  {
    title: <FormattedMessage id="CONTRACTS.TYPE.INTERN" />,
    desc: "Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.",
    value: "Stagiaire",
  },
  {
    title: <FormattedMessage id="CONTRACTS.TYPE.SEASONAL" />,
    desc: "Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.",
    value: "Saisonnier",
  },
  {
    title: <FormattedMessage id="CONTRACTS.TYPE.LEARNING" />,
    desc: "Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.",
    value: "Apprentissage",
  },
];
export default function ContractTypes({
  title,
  contracts,
  value,
  onChange,
}: ContractTypesInterface) {
  const formatter = ({ title, active }: FormatterInterface) => {
    return (
      <span
        className={`rounded-full px-3 py-2 text-md hover:text-white hover:bg-v-500 ${
          active ? "text-white bg-v-500" : "text-b-500 bg-n-0"
        }`}
      >
        {title}
      </span>
    );
  };


  return (
    <div>
      <div className="flex items-center">
        <h4 className="text-n-500 font-bold text-lg">{title}</h4>
        <InputInfos rup={true} dpae={true} />
      </div>
      <TabNavigator
        headerContainerClasses="bg-none border-none"
        headerClasses="border-none !p-0 mx-1"
        containerClasses="!my-5"
        tabs={contracts.map((contract, index) => ({
          title: contract.title,
          onClick: () => onChange(contract.value),
          active: value === contract.value,
          data: { title: contract.title, active: contract.value === value },
          formatter,
        }))}
      />{/*
        {contracts.map((contract, index) => (
          <TabContent
            containerClasses="my-5"
            key={index}
            index={contract.value}
            value={value}
            component={
              <ZAlert
                title={contract.title}
                desc={contract.desc}
                icon={<CircleInfos/>}
              />
            }
          />
        ))}
      </TabNavigator>*/}
    </div>
  );
}

interface ContractTypesInterface {
  title: string | React.ReactNode;
  contracts: { title: React.ReactNode | string; desc: string; value: string }[];
  value: string;
  onChange: (value: string) => void;
}

interface FormatterInterface {
  title: string;
  active: boolean;

}

ContractTypes.defaultProps = {
  contracts,
  title: <FormattedMessage id="CONTRACTS.TYPES.TITLE" />,
};
