import React from "react";
import { CheckBox as CheckBoxIcon } from "../../../Icons";

export default function ZCheckBox({
  isDisactivated = false,
  isChecked = false,
  label,
  width = 16,
  height = 16,
  onPress,
}: ZCheckBoxInterface) {
  return (
    <div
      onClick={() => {
        if(isDisactivated === false){
          onPress();
        }
      }}
      className={`relative flex rounded-full ${
        isChecked ? "bg-t-500" : "bg-n-300"
      }
      ${isDisactivated ? "opacity-70" : "cursor-pointer"}
      `}
      style={{
        width: width + "px",
        height: height + "px",
      }}
    >
      {isChecked ? (
       <CheckBoxIcon background="bg-t-500" color="white"/>
      ) : (
        <></>
      )}
    </div>
  );
}

interface ZCheckBoxInterface {
  isChecked?: boolean;
  isDisactivated?: boolean;
  label?: string | React.ReactNode;
  width?: number;
  height?: number;
  onPress: () => void;
}
