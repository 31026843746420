import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../constants/env";

export const getAllVacationService = createAsyncThunk(
    "vacation/getAll",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        if (token && payload.siteID) {
            try {
                const res = await fetch(
                    API.client.vacation.getAll(payload.siteID),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    }
);
export const getOneVacationService = createAsyncThunk(
    "vacation/getOne",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        if (token && payload.idCongeType) {
            try {
                const res = await fetch(
                    API.client.vacation.getOne(payload.idCongeType),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    }
);
export const createVacationService = createAsyncThunk(
    "vacation/create",
    async (payload: any, { rejectWithValue }) => {
        const { color, designation, siteID } = payload;
        const token = localStorage.getItem("token");
        if (token && color && designation && siteID) {
            try {
                const res = await fetch(API.client.vacation.create, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ color, designation, siteID }),
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    }
);
export const updateVacationService = createAsyncThunk(
    "vacation/update",
    async (payload: any, { rejectWithValue }) => {
        const { color, designation, siteID, idCongeType } = payload;
        const token = localStorage.getItem("token");
        if (token && idCongeType && color && designation && siteID) {
            try {
                const res = await fetch(
                    API.client.vacation.update(idCongeType),
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({ color, designation, siteID }),
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    }
);
export const deleteVacationService = createAsyncThunk(
    "vacation/delete",
    async (payload: any, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        console.log("delete vacation service");
        if (token && payload.idCongeType) {
            try {
                const res = await fetch(
                    API.client.vacation.delete(payload.idCongeType),
                    {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    }
);
