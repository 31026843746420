import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { fetchCountriesUrl } from "../api.routes";

export const fetchCountries = createAsyncThunk(
    "countries/fetchCountries",
    async (payload, { rejectWithValue }) => {
        try {
            const res = await axios.get(fetchCountriesUrl, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            return {
                countries: res.data,
            };
        } catch (error) {
            const err = error as AxiosError;
            const data = err.response?.data;
            rejectWithValue(data);
        }
    }
);
