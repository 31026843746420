export const CircleInfos = ({
  width = 24,
  height = 30,
  color = "currentColor",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2.00049C6.486 2.00049 2 6.48649 2 12.0005C2 17.5145 6.486 22.0005 12 22.0005C17.514 22.0005 22 17.5145 22 12.0005C22 6.48649 17.514 2.00049 12 2.00049ZM12 20.0005C7.589 20.0005 4 16.4115 4 12.0005C4 7.58949 7.589 4.00049 12 4.00049C16.411 4.00049 20 7.58949 20 12.0005C20 16.4115 16.411 20.0005 12 20.0005Z"
      fill="currentColor"
    />
    <path
      d="M11 11.0005H13V17.0005H11V11.0005ZM11 7.00049H13V9.00049H11V7.00049Z"
      fill="currentColor"
    />
  </svg>
);

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}

