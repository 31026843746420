import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../../constants/env";

export const getAllHolidaysService = createAsyncThunk(
    "vacation/getAllUserHolidays",
    async (payload: any, { rejectWithValue }) => {
        const { userId, startDay, endDay } = payload;
        const token = localStorage.getItem("token");
        if (token && userId && startDay && endDay) {
            try {
                const res = await fetch(API.client.holiday.getUserHolidays, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ userId, startDay, endDay }),
                })
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    }
);
