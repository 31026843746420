import React from "react";

export const Edit = ({
  width = 24,
  height = 24,
  color = "currentColor",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 12.7969V19.6875C21 20.2312 20.5523 20.6719 20 20.6719H4C3.44771 20.6719 3 20.2312 3 19.6875V3.9375C3 3.39384 3.44771 2.95312 4 2.95312H11"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 13.1512V16.7344H10.6586L21 6.55008L17.3475 2.95312L7 13.1512Z"
      //fill="white"
      stroke={color}
      stroke-width="1.5"
      stroke-linejoin="round"
    />
  </svg>
);

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}
