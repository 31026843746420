import * as React from "react";

import { Branch } from "../Settings";
import Button from "../../components/atoms/Button";
import TextData from "../../components/atoms/TextData";
import ProgressSteps from "../../components/molecules/ProgressSteps";
import { BranchProps } from "../Settings/Configurations/BranchSettings";
import { useBranch } from "../../hooks/useBranch";
function Slide1(props: Slide1Props) {
    const { selectedBranchSettings } = useBranch();
    const {
        handleMove,
        header,
        buttonBackText,
        buttonSaveText,
        buttonDoneText,
        currentStep,

        handleSaveButton,
        handleDoneButton,
        handleBackButton,
    } = props;
    const handleSubmit = () => {
        handleMove(1);
        handleDoneButton?.();
    };
    const handleBack = () => {
        handleMove(-1);
        handleBackButton?.();
    };
    const handleSave = () => {
        handleSaveButton?.();
    };
    const globalProps = {
        headerProps: {
            style: {
                marginTop: 25,
                marginLeft: 35,
            },
            color: "#11106E",
            size: "34px",
            fontWeight: "bolder",
        },
        progressStepsProps: {
            steps: [
                {
                    stepName: "Etape 1",
                    label: "Etablissement",
                },
                {
                    stepName: "Etape 2",
                    label: "Planning",
                },
                {
                    stepName: "Etape 3",
                    label: "Pointeuse",
                },
            ],
            initialStep: currentStep,
        },
        backButtonProps: {
            className: ["button-style"],
            textColor: "#6F78AB",
            backgroundColor: "transparent",
            style: { fontSize: "14px" },
            onClick: handleBack,
            width: "160px",
            height: "45px",
        },
        saveButtonProps: {
            backgroundColor: "transparent",
            textColor: "#2A8BAB",
            style: {
                border: "1px solid #2A8BAB",
                borderRadius: 7,
                fontSize: "14px",
            },
            className: ["button-style"],
            onClick: handleSave,
            width: "160px",
            height: "45px",
        },
        doneButtonProps: {
            backgroundColor: "#2A8BAB",
            textColor: "white",
            style: {
                borderRadius: 7,
                fontSize: "14px",
            },
            className: ["button-style"],
            onClick: handleSubmit,
            width: "160px",
            height: "45px",
        },
    };
    return (
        <div className='startup-body-container'>
            <div className='startup-header'>
                <TextData {...globalProps.headerProps}>{header}</TextData>
                <div className='progressSteps-container'>
                    <ProgressSteps {...globalProps.progressStepsProps} />
                </div>
            </div>
            <div className='startup-card-container'>
                <Branch
                    selectedBranch={selectedBranchSettings.address}
                    settings={BranchProps()}
                    includeButtons={false}
                />
                <div className='footer-card-container'>
                    <div>
                        <Button {...globalProps.backButtonProps}>
                            {buttonBackText}
                        </Button>
                    </div>
                    <div>
                        <Button {...globalProps.saveButtonProps}>
                            {buttonSaveText}
                        </Button>
                        <Button {...globalProps.doneButtonProps}>
                            {buttonDoneText}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
interface Slide1Props {
    header?: string;
    buttonBackText?: string;
    buttonSaveText?: string;
    buttonDoneText?: string;
    handleMove: (count: number) => void;
    currentStep?: number;
    handleSaveButton?: () => void;
    handleDoneButton?: () => void;
    handleBackButton?: () => void;
}
Slide1.defaultProps = {
    header: "Paramètres",
    buttonBackText: "Retour",
    buttonSaveText: "Enregistrer",
    buttonDoneText: "Terminer",
    currentStep: 1,
};

export default Slide1;
