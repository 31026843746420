import { useIntl } from "react-intl";
import { EmployeesFilterProps, SortByProps } from "../../../hooks/useEmployees";
import * as Icons from "../../../Icons";
import Theme from "../../../tailwind-theme";
import SelectInput from "../../molecules/SelectInput";
import Select from "react-select";

export default function EmployeesFilter({
    onUpdateFilterBy,
    filterBy,
    sortBy,
    onUpdateSortBy,
}: EmployeesFilterPropss) {
    const intl = useIntl();
    const postOptions = [
        {
            value: "Administrator",
            label: intl.formatMessage({ id: "MENU.ADMINISTRATOR" }),
        },
        { value: "Manager", label: intl.formatMessage({ id: "MENU.MANAGER" }) },
        {
            value: "Employee",
            label: intl.formatMessage({ id: "MENU.EMPLOYEE" }),
        },
    ];

    const sortOptions = [
        {
            value: "asc",
            label: intl.formatMessage({ id: "MENU.RECENT_ADDED" }),
        },
        {
            value: "desc",
            label: intl.formatMessage({ id: "MENU.LATEST_ADDED" }),
        },
    ];

    return (
        <div className="flex flex-wrap gap-3 items-center">
            <div className="flex items-center gap-3">
                <Icons.Filter color={Theme.colors["n-500"]} />
                <span className="text-md text-n-500 font-semibold">
                    {intl.formatMessage({ id: "MENU.FILTER_BY" })}
                </span>
            </div>
            <div className="flex items-center gap-3">
                <div className="min-w-[150px]">
                    <SelectInput
                        placeholder={intl.formatMessage({
                            id: "FILTER.ALL_PROFILS",
                        })}
                        options={postOptions}
                        isMulti={true}
                        value={filterBy.role}
                        onChange={(value) => {
                            onUpdateFilterBy({
                                ...filterBy,
                                role: value,
                            });
                        }}
                    />
                    {/* <Select options={postOptions} isMulti={true} /> */}
                </div>
                <div className="min-w-[150px] max-w-[300px]">
                    <SelectInput
                        placeholder={intl.formatMessage({
                            id: "FILTER.SORT_BY",
                        })}
                        options={sortOptions}
                        value={
                            sortBy.field === "created_at" &&
                            sortBy.order === "asc"
                                ? {
                                      value: "desc",
                                      label: intl.formatMessage({
                                          id: "MENU.RECENT_ADDED",
                                      }),
                                  }
                                : sortBy.field === "created_at" &&
                                    sortBy.order === "desc"
                                  ? {
                                        value: "asc",
                                        label: intl.formatMessage({
                                            id: "MENU.LATEST_ADDED",
                                        }),
                                    }
                                  : undefined
                        }
                        onChange={(value) => {
                            onUpdateSortBy({
                                field: "created_at",
                                order: value.value,
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

interface EmployeesFilterPropss {
    onUpdateFilterBy: (props: EmployeesFilterProps) => void;
    filterBy: EmployeesFilterProps;
    sortBy: SortByProps;
    onUpdateSortBy: (props: SortByProps) => void;
}
