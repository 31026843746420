import React from "react";

export default function ZAlert({
    containerClasses,
    variant = "info",
    icon,
    title,
    desc,
    formatter,
}: ZAlertInterface) {
    let variantClass = "";
    switch (variant) {
        case "info": {
            variantClass = "text-t-600 bg-t-0";
            break;
        }
    }

    return (
        <div
            className={`${containerClasses} ${variantClass} w-full rounded-lg p-4 flex items-start gap-4`}
        >
            <div>{icon}</div>
            {formatter ? (
                formatter()
            ) : (
                <div>
                    {title && (
                        <h4 className='font-bold text-3xl mb-3'>{title}</h4>
                    )}
                    {desc && <p className='text-md'>{desc}</p>}
                </div>
            )}
        </div>
    );
}

interface ZAlertInterface {
    variant?: "info";
    icon: React.ReactNode;
    title?: string | React.ReactNode;
    desc?: string;
    containerClasses?: string;
    formatter?: () => React.ReactNode;
}
