import React from "react";
import moment, { Moment } from "moment";
import CalendarHeader from "../../molecules/CalendarHeader";
import { buildCalendar } from "../../../_helpers/CalendarHelpers";
import CalendarBody from "../../molecules/CalendarBody";
import CalendarExtras from "../../molecules/CalendarExtras";

type CalendarType = Array<Array<Moment | null>>;
type RangeType = { from: Moment; to: Moment };

export default function Calendar({
    value,
    onChange,
    open = true,
    showWeekCount = true,
    from,
    to,
    ranged,
    extras,
    styles,
    calenderContainerClasses,
    monthEffect,
    showMonthArrows = true,
}: CalendarInterface) {
    const [calendar, setCalendar] = React.useState<CalendarType>([]);
    const [month, setMonth] = React.useState<Moment>(
        moment(from ? from : value)
    );

    React.useEffect(() => {
        setMonth(moment(from ? from : value));
    }, [value, from, to]);

    const nextMonth = () => {
        setMonth(month.clone().add(1, "month"));
    };

    const prevMonth = () => {
        setMonth(month.clone().subtract(1, "month"));
    };

    React.useEffect(() => {
        setCalendar(buildCalendar(month));
        monthEffect && monthEffect(month);
    }, [month]);

    const selectMonth = (m: number) => {
        let newMonth = month.clone().set("month", m);
        setMonth(newMonth);
    };

    const selectYear = (year: number) => {
        let newMonth = month.clone().set("year", year);
        setMonth(newMonth);
    };

    return (
        <>
            {open ? (
                <div
                    style={styles}
                    className={`max-w-[360px] bg-white p-3 rounded ${calenderContainerClasses}`}
                >
                    <CalendarHeader
                        value={month}
                        nextMonth={nextMonth}
                        prevMonth={prevMonth}
                        selectMonth={selectMonth}
                        selectYear={selectYear}
                        showMonthArrows={showMonthArrows}
                    />

                    <CalendarBody
                        calendar={calendar}
                        value={value}
                        showWeekCount={showWeekCount}
                        ranged={ranged}
                        from={from}
                        to={to}
                        onChange={onChange}
                    />
                    {extras && <CalendarExtras extras={extras} />}
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

interface CalendarInterface {
    value?: Moment;
    showWeekCount?: boolean;
    open?: boolean;
    onChange?(value: Moment | RangeType): void;
    from?: Moment | null;
    to?: Moment | null;
    ranged?: boolean;
    extras?: Array<{ title: string; node: React.ReactNode }>;
    styles?: React.CSSProperties;
    calenderContainerClasses?: string;
    monthEffect?: (month: Moment) => void;
    showMonthArrows?: boolean;
}
