import React from "react";
import ZTextarea from "../../atoms/ZTextarea";
import DatepickerInput from "../../atoms/DatepickerInput";
import ZInput from "../../atoms/ZInput";
import SelectInput from "../../molecules/SelectInput";
import { useEmployees } from "../../../hooks/useEmployees";
import { useIntl } from "react-intl";

export default function EditPrimeForm({
    prime,
    onChange,
    onBlur,
}: EditPrimeFormProps) {
    const { primeTypes } = useEmployees();
    const intl = useIntl();
    return (
        <div>
            <div className="mb-[12px]">
                <div>
                    <label className="text-n-400 font-medium text-base">
                        {intl.formatMessage({ id: "EDIT.PRIME.TYPE" })}
                    </label>
                    <span className="text-error font-semibold">*</span>
                </div>

                <SelectInput
                    controlCssStyles={{
                        height: "48px",
                    }}
                    options={primeTypes.map((type) => ({
                        value: type._id,
                        label: type.name,
                    }))}
                    onChange={(option) => {
                        onChange("typeAcompte", option);
                    }}
                    value={prime.typeAcompte.value}
                    onBlur={() => {
                        onBlur("typeAcompte");
                    }}
                    touched={prime.typeAcompte.touched}
                    error={prime.typeAcompte.error}
                />
            </div>

            <div className="mb-[12px]">
                <ZInput
                    required={true}
                    label={intl.formatMessage({
                        id: "EDIT.PRIME.MONTANT.LABEL",
                    })}
                    field={prime.montant}
                    onUpdate={(val) => {
                        onChange("montant", val);
                    }}
                    onBlur={() => {
                        onBlur("montant");
                    }}
                    type="number"
                />
            </div>

            <div className="mb-[12px]">
                <DatepickerInput
                    label={intl.formatMessage({ id: "EDIT.PRIME.DATE.LABEL" })}
                    required={true}
                    onUpdate={(value) => {
                        onChange("date", value);
                    }}
                    field={prime.date}
                />
            </div>
            <div className="mb-[12px]">
                <ZTextarea
                    required={false}
                    field={prime.commentaire}
                    onUpdate={(value) => {
                        onChange("commentaire", value);
                    }}
                    label={intl.formatMessage({
                        id: "EDIT.PRIME.DATE.COMMENT",
                    })}
                />
            </div>
        </div>
    );
}

interface EditPrimeFormProps {
    prime: any;
    onChange: (field: string, value: any) => void;
    onBlur: (field: string) => void;
}
