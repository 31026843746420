import React from "react";

export default function TabContent({
  value,
  index,
  component,
  containerCss,
  containerClasses,
}: TabContentInterface) {
  return (
    <>
      {value == index && (
        <div className={containerClasses} style={containerCss}>
          {component}
        </div>
      )}
    </>
  );
}

interface TabContentInterface {
  value: number | string;
  index: number | string;
  component: React.ReactNode;
  containerCss?: React.CSSProperties;
  containerClasses?: string;
}
