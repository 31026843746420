import React from "react";
import { useEmployees } from "../../../hooks/useEmployees";
import {
    generateFields,
    destructFields,
    Validation,
} from "../../../_helpers/FormHelpers";
import GeneralInfo from "../../../components/templates/EmployeesEdit/GeneralInfo";
import { useSites } from "../../../hooks/useSites";
import { UpdateEployeeRequest } from "../../../redux/features/employees/types";
import { useNotificationContext } from "../../../Context/Notification";
import { useIntl } from "react-intl";
import { useBranch } from "../../../hooks/useBranch";
import usePointeuse from "../../../hooks/usePointeuse";

export default function GeneralInfoPage({}) {
    const { addNotification } = useNotificationContext();
    const { employeeForEdit, editEmployee, actionLoading } = useEmployees();
    const { sitesManagement } = useSites();
    const [employee, setEmployee] = React.useState<Employee>();
    const intl = useIntl();
    const existPinMessage = "Ce code pin est déjà existant !";
    const existEmailMessage = "Cette adresse email est déjà utilisée !";
    const existPhoneMessage = "Ce numéro de téléphone est déjà existant !";
    const { employees } = useEmployees();

    const onChange = (field: string, value: any) => {
        if (employee) {
            const newState = {
                ...employee,
                [field]: {
                    ...employee[field],
                    value,
                },
            };
            setEmployee(newState);
            validate(newState);
        }
        let exist = false;
        if (field === "pin") {
            employees?.map((emp: any) => {
                if (emp.pin === value && employeeForEdit._id !== emp._id) {
                    exist = true;

                    setEmployee((prevEmployee: any) => {
                        return {
                            ...prevEmployee,
                            [field]: {
                                ...prevEmployee[field],
                                hasError: true,
                                touched: true,
                                error: existPinMessage,
                            },
                        };
                    });
                }
            });
            if (!exist) {
                setEmployee((prevEmployee: any) => {
                    return {
                        ...prevEmployee,
                        [field]: {
                            ...prevEmployee[field],
                            hasError: false,
                            touched: true,
                            error: "",
                        },
                    };
                });
            }
        }
        exist = false;
        if (field === "email") {
            employees?.map((emp: any) => {
                if (
                    emp.email.trim() === value.trim() &&
                    value.trim() !== "" &&
                    employeeForEdit._id !== emp._id
                ) {
                    exist = true;
                    setEmployee((prevEmployee: any) => {
                        return {
                            ...prevEmployee,
                            [field]: {
                                ...prevEmployee[field],
                                hasError: true,
                                touched: true,
                                error: existEmailMessage,
                            },
                        };
                    });
                }
            });
            if (!exist) {
                setEmployee((prevEmployee: any) => {
                    return {
                        ...prevEmployee,
                        [field]: {
                            ...prevEmployee[field],
                            hasError: false,
                            touched: true,
                            error: "",
                        },
                    };
                });
            }
        }
        exist = false;
        if (field === "phone") {
            employees?.map((emp: any) => {
                if (
                    emp.phone === value &&
                    value !== "" &&
                    employeeForEdit._id !== emp._id
                ) {
                    exist = true;
                    setEmployee((prevEmployee: any) => {
                        return {
                            ...prevEmployee,
                            [field]: {
                                ...prevEmployee[field],
                                hasError: true,
                                touched: true,
                                error: existPhoneMessage,
                            },
                        };
                    });
                }
            });
            if (!exist) {
                setEmployee((prevEmployee: any) => {
                    return {
                        ...prevEmployee,
                        [field]: {
                            ...prevEmployee[field],
                            hasError: false,
                            touched: true,
                            error: "",
                        },
                    };
                });
            }
        }
    };

    const onBlur = (field: string) => {
        if (employee) {
            const newState = {
                ...employee,
                [field]: {
                    ...employee[field],
                    touched: true,
                },
            };
            setEmployee(newState);
            validate(newState);
        }
    };

    const validate = (state: Employee) => {
        new Validation()
            .add(state.fname, [
                { REQUIRED: true, message: "Le prénom est requis!" },
            ])
            .add(state.lname, [
                { REQUIRED: true, message: "Le nom est requis!" },
            ])
            .add(state.pin, [
                { REQUIRED: true, message: "Le pin est requis!" },
                { MIN_LENGTH: 4, message: "Longeur minimum est de 4" },
                { MAX_LENGTH: 4, message: "Longeur maximum est de 4" },
                {
                    EXIST: state.pin.error === existPinMessage,
                    message: existPinMessage,
                },
            ])
            .add(state.email, [
                {
                    EXIST: state.email.error === existEmailMessage,
                    message: existEmailMessage,
                },
            ])
            .add(state.phone, [
                {
                    EXIST: state.phone.error === existPhoneMessage,
                    message: existPhoneMessage,
                },
            ])
            .add(state.belongsTo, [
                {
                    AT_LEAST_ONE: !state.belongsTo.value?.length,
                    message:
                        "Veuillez choisir un établissement d'affiliation !",
                },
            ])
            .validate((res) => {
                setEmployee((prev) => ({
                    ...prev,
                    ...generateFields(res),
                }));
            });
    };

    const onSubmit = () => {
        if (employee) {
            const values = destructFields(employee);
            editEmployee(values as UpdateEployeeRequest).then((res) => {
                const data: any = res.payload;
                if (data.status === "success") {
                    addNotification({
                        title: intl.formatMessage({
                            id: "EMPLOYEE.EDIT.NOTIFICATION.SUCCESS.TITLE",
                        }),
                        message: intl.formatMessage({
                            id: "EMPLOYEE.EDIT.NOTIFICATION.SUCCESS.MESSAGE",
                        }),
                        type: "success",
                    });
                } else if (data.status === "fail") {
                    addNotification({
                        title: intl.formatMessage({
                            id: "EMPLOYEE.EDIT.NOTIFICATION.FAIL.TITLE",
                        }),
                        message:
                            data?.data?.message ||
                            intl.formatMessage({
                                id: "EMPLOYEE.EDIT.NOTIFICATION.FAIL.MESSAGE",
                            }),
                        type: "fail",
                    });
                }
            });
        }
    };

    React.useEffect(() => {
        if (employeeForEdit) {
            setEmployee(() => {
                return generateFields([
                    { name: "userId", value: employeeForEdit._id },
                    { name: "fname", value: employeeForEdit.fname },
                    { name: "lname", value: employeeForEdit.lname },
                    { name: "email", value: employeeForEdit.email },
                    { name: "phone", value: employeeForEdit.phone },
                    { name: "picture", value: employeeForEdit.picture },
                    { name: "role", value: employeeForEdit.role },
                    { name: "pin", value: employeeForEdit.pin },
                    { name: "niveau", value: employeeForEdit.niveau },
                    {
                        name: "tempsDeTravailHebdomadaire",
                        value: employeeForEdit.contract
                            ?.tempsDeTravailHebdomadaire,
                    },
                    {
                        name: "tempsDeTravailMensuel",
                        value: employeeForEdit.contract?.tempsTravailMonthly,
                    },
                    { name: "belongsTo", value: employeeForEdit.belongsTo },
                    { name: "manage", value: employeeForEdit.manage },
                    { name: "planning", value: employeeForEdit.planning },
                    { name: "file", value: null },
                ]);
            });
        }
    }, [employeeForEdit]);

    const { selectedBranchSettings, getBranchSettings, branchSettings } =
        useBranch();
    const { fetchDepartements, departements } = usePointeuse();
    React.useEffect(() => {
        if (selectedBranchSettings && selectedBranchSettings._id) {
            getBranchSettings();
            fetchDepartements({ siteId: selectedBranchSettings._id });
        }
    }, [selectedBranchSettings]);

    return (
        <>
            {employee && (
                <GeneralInfo
                    sites={sitesManagement}
                    employee={employee}
                    onChange={onChange}
                    onBlur={onBlur}
                    onSubmit={onSubmit}
                    actionLoading={actionLoading}
                    departements={departements}
                    multiplePlanning={branchSettings.multiplePlanning}
                    level={employeeForEdit.niveau}
                />
            )}
        </>
    );
}

interface Employee {
    [key: string]: any;
}
