import { configureStore } from "@reduxjs/toolkit";
import globalConfigSlice from "./features/globalConfig/globalConfigSlice";
import authSlice from "./features/auth/authSlice";
import EmployeesReducer from "./features/employees/employeesSlice";
import settingsSlice from "./features/settings/settingsSlice";
import userSlice from "./features/users/userSlice";
import SitesReducer from "./features/sites/sitesSlice";
import CountriesReducer from "./features/countries/countriesSlice";
import reportSlice from "./features/reports/reportSlice";
import PointeuseReducer from "./features/pointeuse/pointeuseSlice";
import planningReducer from "./features/planning/planningSlice";
import notificationsReducer from "./features/notifications/notificationsSlice";
import vacationReducer from "./features/vacation/vacationSlice";
import vacationRuleReducer from "./features/vacationRules/vacationRuleSlice";
import leaveBalanceReducer from "./features/leaveBalance/leaveBalanceSlice";
import holidayReducer from "./features/holiday/holidaySlice";
import employeeWorkHourReducer from "./features/employeeWorkHours/employeeWorkHourSlice";
const store = configureStore({
    reducer: {
        globalConfig: globalConfigSlice,
        auth: authSlice,
        employees: EmployeesReducer,
        sites: SitesReducer,
        countries: CountriesReducer,
        pointeuse: PointeuseReducer,
        settings: settingsSlice,
        users: userSlice,
        reports: reportSlice,
        planning: planningReducer,
        // sample: sampleReducer,
        notifications: notificationsReducer,
        vacation: vacationReducer,
        vacationRule: vacationRuleReducer,
        leaveBalance: leaveBalanceReducer,
        holiday: holidayReducer,
        employeeWorkHour: employeeWorkHourReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
