import * as React from "react";
import "./style.scss";
import ColoredCircle from "../../atoms/ColoredCircle";

import { ReactComponent as ArrowIcon } from "./arrow.svg";

const CHECKED_COLOR_OPACITY = 0.4;

export default function ListOfColors(props: ListOfColorsProps) {
    const {
        colors,
        displayCount,
        size,
        outlineOpacity,
        colorSelection,
        width,
        getSelectedColor,
    } = props;
    const [displayAll, setDisplayAll] = React.useState(false);
    const [selectedColor, setSelectedColor] = React.useState(colorSelection);
    React.useEffect(() => {
        setSelectedColor(colorSelection);
    }, [colorSelection]);

    const handleColorSelect = (color: string) => {
        setSelectedColor(color);
        getSelectedColor(color);
    };

    React.useEffect(() => {}, [colors]);

    const displayColors = displayAll ? colors : colors.slice(0, displayCount);
    const getColorRgba: any = (color: string) => {
        let rgbaValue = "";
        try {
            rgbaValue = `rgba(${parseInt(color.slice(-6, -4), 16)}, ${parseInt(
                color.slice(-4, -2),
                16
            )}, ${parseInt(color.slice(-2), 16)}, ${outlineOpacity})`;
        } catch (error) {
            return getColorRgba("#FFFFFF");
        }
        return rgbaValue;
    };
    return (
        <div
            className='list-of-colors-container'
            style={{
                width,
            }}
        >
            {displayColors.map((color, index) => (
                <ColoredCircle
                    key={index}
                    size={size}
                    color={color ? color : "#FFFFFF"}
                    outlineSize={(size * 20) / 100}
                    outlineColor={getColorRgba(color)}
                    outlineOpacity={outlineOpacity}
                    isChecked={color === selectedColor}
                    onSelectColor={handleColorSelect}
                />
            ))}
            {colors.length > displayCount && (
                <button onClick={() => setDisplayAll(!displayAll)}>
                    <ArrowIcon
                        className={displayAll ? "rotate-180" : ""}
                        height={size}
                        width={size}
                    />
                </button>
            )}
        </div>
    );
}

interface ListOfColorsProps {
    colors: string[];
    displayCount: number;
    size: number;
    outlineOpacity?: number;
    colorSelection?: string;
    width?: string;
    getSelectedColor: (color: string) => void;
}

ListOfColors.defaultProps = {
    colors: [
        "#304FFD",
        "#0AB199",
        "#651EFF",
        "#15B905",
        "#BB0F5C",
        "#F26A04",
        "#0288D1",
        "#795548",
        "#8C6E64",
        "#3E2622",
        "#880E4F",
        "#0D5302",
    ],
    displayCount: 4,
    colorSelection: "#BB0F5C",
    size: 24,
    outlineOpacity: CHECKED_COLOR_OPACITY,
    width: "300px",
    getSelectedColor: (color: string) => {},
};
