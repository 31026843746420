import React from "react";

export default function CalendarExtras({ extras }: CalenderExtrasInterface) {
  const [actifMenu, setActifMenu] = React.useState(0);

  const getTitleClassName = (index: number) => {
    let baseClass = "text-lg mr-3 text-default-active cursor-pointer";
    if (actifMenu == index) {
      baseClass += " font-semibold border-b-3px border-default-active pb-3";
    } else {
    }
    return baseClass;
  };

  const openMenu = (index: number) => {
    if (actifMenu !== index) {
      setActifMenu(index);
    }
  };

  return (
    <div className="mt-5">
      <div className="border-b pb-3">
        <div className="mx-3">
          {extras.map((extra, keyExt) => (
            <a
              onClick={() => {
                openMenu(keyExt);
              }}
              className={getTitleClassName(keyExt)}
              key={keyExt}
            >
              {extra.title}
            </a>
          ))}
        </div>
      </div>
      <div className="mt-5 mx-10">{extras[actifMenu].node}</div>
    </div>
  );
}

interface CalenderExtrasInterface {
  extras: Array<{ title: string; node: React.ReactNode }>;
}
