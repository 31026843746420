import React from "react";
import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import { fetchCountries } from "../redux/features/countries/service";

export function useCountries() {
  const dispatch = useAppDispatch();

  const getCountries = () => {
    dispatch(fetchCountries());
  };

  const { countries } = useAppSelector((state) => state.countries);
  return {
    countries,
    getCountries,
  };
}
