import React from "react";
import Modal from "../../organism/Modal";
import Button from "../../atoms/ZButton";
import { FormattedMessage } from "react-intl";

export default function ActionNotification({
  title,
  message,
  type,
  show,
  onHide,
}: ActionNotificationInterface) {
  const NotificationBody = (
    <div className="flex justify-center items-center h-full">
      <div>
        {title && (
          <h3 className="font-bold text-3xl text-v-800 mb-2">{title}</h3>
        )}
        {message && <p className="text-n-500 text-lg">{message}</p>}
      </div>
    </div>
  );

  const ConfirmButton = (
    <Button
      title={<FormattedMessage id="MENU.OK" />}
      variant={type === "success" ? "primary" : "danger"}
      size="lg"
      onClick={onHide}
    />
  );

  return (
    <Modal
      title=""
      show={show}
      header={false}
      footer={true}
      fotterClasses="!border-none !justify-center"
      bodyClasses="!h-[120px]"
      body={NotificationBody}
      ConfirmComponent={ConfirmButton}
      width={400}
      height={200}
      containerStyle={{
        zIndex: "1000"
      }}
    />
  );
}

interface ActionNotificationInterface {
  message: string | React.ReactNode;
  title: string | React.ReactNode;
  type: "success" | "fail";
  show: boolean;
  onHide(): void;
}
