import React from "react";
import { useIntl } from "react-intl";
import ZInput from "../../atoms/ZInput";
import DropzoneInput from "../../molecules/DropzoneInput";
import RolePickerInput from "../../molecules/RolePickerInput";
import EstablishementPicker from "../../molecules/EstablishmentPicker";
import theme from "../../../tailwind-theme";
import { FormattedMessage } from "react-intl";
import Button from "../../atoms/ZButton";
import LevelProgressBar from "../../molecules/LevelProgressBar";
import { useLogin } from "../../../hooks/useLogin";

const roles = [
    {
        title: <FormattedMessage id="MENU.EMPLOYEE" />,
        desc: <FormattedMessage id="ROLE.EMPLOYEE.DESC" />,
        value: "Employee",
        bgColor: theme?.colors?.["employee-op-05"],
        bgMidColor: theme.colors["employee-op-2"],
        bgActiveColor: theme.colors["employee"],
    },
    {
        title: <FormattedMessage id="MENU.MANAGER" />,
        desc: <FormattedMessage id="ROLE.MANAGER.DESC" />,
        value: "Manager",
        bgColor: theme.colors["manager-op-05"],
        bgMidColor: theme.colors["manager-op-2"],
        bgActiveColor: theme.colors["manager"],
    },
    {
        title: <FormattedMessage id="MENU.ADMINISTRATOR" />,
        desc: <FormattedMessage id="ROLE.ADMINISTRATOR.DESC" />,
        value: "Administrator",
        bgColor: theme.colors["admin-op-05"],
        bgMidColor: theme.colors["admin-op-2"],
        bgActiveColor: theme.colors["admin"],
    },
];

export default function GeneralInfos({
    employee,
    onChange,
    onBlur,
    sites,
    onSubmit,
    actionLoading,
    departements,
    level,
    multiplePlanning,
}: GeneralInfosInterface) {
    const [levels, setLevels] = React.useState<any>([]);
    const { user } = useLogin();
    const roles =
        user?.role === "Manager"
            ? [
                  {
                      title: <FormattedMessage id="MENU.EMPLOYEE" />,
                      desc: <FormattedMessage id="ROLE.EMPLOYEE.DESC" />,
                      value: "Employee",
                      bgColor: theme?.colors?.["employee-op-05"],
                      bgMidColor: theme.colors["employee-op-2"],
                      bgActiveColor: theme.colors["employee"],
                  },
                  {
                      title: <FormattedMessage id="MENU.MANAGER" />,
                      desc: <FormattedMessage id="ROLE.MANAGER.DESC" />,
                      value: "Manager",
                      bgColor: theme.colors["manager-op-05"],
                      bgMidColor: theme.colors["manager-op-2"],
                      bgActiveColor: theme.colors["manager"],
                  },
              ]
            : [
                  {
                      title: <FormattedMessage id="MENU.EMPLOYEE" />,
                      desc: <FormattedMessage id="ROLE.EMPLOYEE.DESC" />,
                      value: "Employee",
                      bgColor: theme?.colors?.["employee-op-05"],
                      bgMidColor: theme.colors["employee-op-2"],
                      bgActiveColor: theme.colors["employee"],
                  },
                  {
                      title: <FormattedMessage id="MENU.MANAGER" />,
                      desc: <FormattedMessage id="ROLE.MANAGER.DESC" />,
                      value: "Manager",
                      bgColor: theme.colors["manager-op-05"],
                      bgMidColor: theme.colors["manager-op-2"],
                      bgActiveColor: theme.colors["manager"],
                  },
                  {
                      title: <FormattedMessage id="MENU.ADMINISTRATOR" />,
                      desc: <FormattedMessage id="ROLE.ADMINISTRATOR.DESC" />,
                      value: "Administrator",
                      bgColor: theme.colors["admin-op-05"],
                      bgMidColor: theme.colors["admin-op-2"],
                      bgActiveColor: theme.colors["admin"],
                  },
              ];
    React.useEffect(() => {
        onChange("niveau", levels[0] ?? "");
    }, [levels]);
    React.useEffect(() => {
        const empLevel = parseInt(level ?? "");
        if (empLevel < 4 && empLevel > 0) {
            setLevels([String(empLevel)]);
        }
    }, [level]);

    const intl = useIntl();
    const checkError = () => {
        let error = false;
        Object.keys(employee).forEach((key) => {
            if (employee[key].hasError) {
                error = true;
            }
        });
        return error;
    };
    return (
        <div>
            <h2 className="text-v-800 font-bold text-9xl">
                {intl.formatMessage({ id: "EMPLOYEE.EDIT.GENERAL_INFOS" })}
            </h2>
            <div className="flex flex-wrap-reverse gap-4 mt-4 border-b">
                <div className="grow">
                    <div className="flex flex-wrap  gap-4 my-5">
                        <div className="basis-[180px] grow">
                            <ZInput
                                required={true}
                                label={intl.formatMessage({
                                    id: "EMPLOYEE.CREATE.LAST_NAME",
                                })}
                                field={employee.lname}
                                onBlur={onBlur}
                                onUpdate={(value) => {
                                    let capitalValue: string = String(value);
                                    const namePattern = /^[A-Za-z]+$/;
                                    if (
                                        namePattern.test(capitalValue) ||
                                        capitalValue === ""
                                    ) {
                                        capitalValue = capitalValue
                                            .toLowerCase()
                                            .split(" ")
                                            .map(
                                                (word: string) =>
                                                    word
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                    word.slice(1),
                                            )
                                            .join(" ");
                                        onChange("lname", capitalValue);
                                    }
                                }}
                                dpae={true}
                                rup={true}
                            />
                        </div>
                        <div className="basis-[180px] grow">
                            <ZInput
                                required={true}
                                label={intl.formatMessage({
                                    id: "EMPLOYEE.CREATE.FIRST_NAME",
                                })}
                                field={employee.fname}
                                onBlur={onBlur}
                                onUpdate={(value) => {
                                    let capitalValue: string = String(value);
                                    const namePattern = /^[A-Za-z]+$/;
                                    if (
                                        namePattern.test(capitalValue) ||
                                        capitalValue === ""
                                    ) {
                                        capitalValue = capitalValue
                                            .toLowerCase()
                                            .split(" ")
                                            .map(
                                                (word: string) =>
                                                    word
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                    word.slice(1),
                                            )
                                            .join(" ");
                                        onChange("fname", capitalValue);
                                    }
                                }}
                                dpae={true}
                                rup={true}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-4 my-5">
                        <div className="basis-[180px] grow">
                            <ZInput
                                required={false}
                                label={intl.formatMessage({
                                    id: "EMPLOYEE.CREATE.EMAIL",
                                })}
                                field={employee.email}
                                onBlur={onBlur}
                                onUpdate={(value) => {
                                    const emailPattern = /^[a-zA-Z0-9@._-]*$/;
                                    if (
                                        emailPattern.test(String(value)) ||
                                        String(value) === ""
                                    ) {
                                        onChange("email", value);
                                    }
                                }}
                            />
                        </div>
                        <div className="basis-[180px] grow">
                            <ZInput
                                required={false}
                                type="text"
                                isPhoneNumber={true}
                                label={intl.formatMessage({
                                    id: "EMPLOYEE.CREATE.PHONE",
                                })}
                                field={employee.phone}
                                onBlur={onBlur}
                                onUpdate={(value) => {
                                    if (String(value).length <= 15) {
                                        onChange("phone", value);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="min-w-[180px]">
                    <DropzoneInput
                        file={employee.picture.value}
                        onChange={(file) => {
                            onChange("picture", file);
                        }}
                    />
                </div>
            </div>

            <div>
                <span className="inline-block text-v-800 font-bold text-5xl my-8">
                    {intl.formatMessage({ id: "EMPLOYEE.EDIT.ROLE_PICKER" })}
                </span>
                <div className="flex flex-wrap justify-start gap-8">
                    <div className="basis-[300px] grow-[3]">
                        <RolePickerInput
                            direction="col"
                            value={employee.role.value}
                            roles={roles}
                            onPress={(value) => {
                                onChange("role", value);
                            }}
                        />
                    </div>
                    <div className="grow basis-[180px]">
                        <div className="mb-2">
                            {/* <label className='text-n-400 font-medium text-base'>
                                {intl.formatMessage({
                                    id: "EMPLOYEE.EDIT.PIN",
                                })}
                            </label> */}
                            <ZInput
                                required={true}
                                label={intl.formatMessage({
                                    id: "EMPLOYEE.EDIT.PIN",
                                })}
                                field={employee.pin}
                                onBlur={onBlur}
                                onUpdate={(value) => {
                                    onChange("pin", value);
                                }}
                                isNumber={true}
                                maxLength={4}
                            />
                        </div>
                        <div className="grow">
                            {/* <label className='text-n-400 font-medium text-base'>
                                {intl.formatMessage({
                                    id: "EMPLOYEE.CREATE.WORK_HOURS.LABEL",
                                })}
                            </label> */}
                            <ZInput
                                required={false}
                                type="number"
                                label={intl.formatMessage({
                                    id: "EMPLOYEE.CREATE.WORK_HOURS.LABEL",
                                })}
                                field={employee.tempsDeTravailHebdomadaire}
                                onBlur={onBlur}
                                onUpdate={(value) => {
                                    let theValue = Number(value);
                                    if (
                                        !(
                                            isNaN(theValue) ||
                                            theValue < 0 ||
                                            theValue > 99 ||
                                            String(value).length > 2
                                        )
                                    ) {
                                        onChange(
                                            "tempsDeTravailHebdomadaire",
                                            value,
                                        );
                                    }
                                }}
                                rup={true}
                                isNumber={true}
                                maxLength={2}
                            />
                        </div>
                        <div className="grow">
                            <ZInput
                                required={false}
                                type="number"
                                label={intl.formatMessage({
                                    id: "EMPLOYEE.CREATE.WORK_HOURS.MONTHLY_LABEL",
                                })}
                                field={employee.tempsDeTravailMensuel}
                                onBlur={onBlur}
                                onUpdate={(value) => {
                                    const decimalRegex = /^\d+(\.\d{0,2})?$/;
                                    if (
                                        !(
                                            !decimalRegex.test(String(value)) ||
                                            Number(value) < 0 ||
                                            Number(value) > 999.99 ||
                                            String(value).length > 6
                                        )
                                    ) {
                                        onChange(
                                            "tempsDeTravailMensuel",
                                            value,
                                        );
                                    }
                                }}
                                isNumber={true}
                                maxLength={6}
                            />
                        </div>
                        <div className="grow">
                            <label className="text-n-400 font-medium text-base">
                                Niveau Collaborateur
                            </label>
                            <LevelProgressBar
                                setLevels={setLevels}
                                levels={levels}
                                oneLevel={true}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-8">
                <EstablishementPicker
                    establishements={sites}
                    title={intl.formatMessage({
                        id: "EMPLOYEE.CREATE.ESTABLISHEMENT",
                    })}
                    onChange={onChange}
                    manage={employee.manage.value}
                    belongsTo={employee.belongsTo.value}
                    planning={employee.planning.value}
                    role={employee.role.value}
                    direction="col"
                    departements={departements}
                    multiplePlanning={multiplePlanning}
                />
            </div>
            <div className="flex justify-end py-6 border-t border-n-100">
                <Button
                    title={intl.formatMessage({ id: "MENU.UPDATE" })}
                    variant="primary"
                    onClick={onSubmit}
                    size="lg"
                    loading={actionLoading}
                    disabled={checkError()}
                />
            </div>
        </div>
    );
}

interface GeneralInfosInterface {
    sites: any[];
    employee: any;
    onChange: (field: string, value: any) => void;
    onBlur: (field: string) => void;
    onSubmit: () => void;
    actionLoading: boolean;
    departements: any[];
    multiplePlanning: boolean;
    level?: string;
}
