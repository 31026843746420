import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import { fetchSitesManagemnt } from "../redux/features/sites/service";
import { FetchSitesManagementRequest } from "../redux/features/sites/types";
import { shallowEqual } from "react-redux";
import { API } from "../constants/env";
export function useSites() {
    const dispatch = useAppDispatch();

    const getSitesManagement = () => {
        dispatch(fetchSitesManagemnt({}));
    };

    const { sitesManagement } = useAppSelector(
        (state) => state.sites,
        shallowEqual
    );

    const getSiteName = (siteID: string) => {
        const siteName = sitesManagement.find((site) => site._id === siteID);
        if (siteName) {
            return siteName.name;
        } else return "";
    };

    return {
        sitesManagement,
        getSitesManagement,
        getSiteName,
    };
}
