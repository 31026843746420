import * as React from "react";
import Swal from "sweetalert2";

import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import {
    getAllCollectiveAgreementsService,
    updateSelectedCollectiveAgreementService,
} from "../redux/features/settings/service";
import {
    updateEnableCollectiveAgreement,
    updateEnforceCollectiveAgreement,
    updateSelectedCollectiveAgreementReducer,
} from "../redux/features/settings/settingsSlice";

export const useCollectiveAgreement = () => {
    const dispatch = useAppDispatch();

    const selectedBranchSettings = useAppSelector(
        (state: any) => state.settings
    ).selectedBranch;

    const administrative = useAppSelector(
        (state: any) => state.settings
    ).administrative;
    const enableCollectiveAgreement = administrative.enableCollectiveAgreement;
    const enforceCollectiveAgreement =
        administrative.enforceCollectiveAgreement;
    const listOfCollectiveAgreements =
        administrative.listOfCollectiveAgreements;
    const selectedCollectiveAgreement =
        administrative.selectedCollectiveAgreement;

    const getAllCollectiveAgreements = () => {
        const token = localStorage.getItem("token");
        dispatch(getAllCollectiveAgreementsService({ token }));
    };
    const setSelectedCollectiveAgreement = (collectiveAgreementID: string) => {
        if (listOfCollectiveAgreements?.length > 0) {
            const selectedOne = listOfCollectiveAgreements.find((elem: any) => {
                return elem._id === collectiveAgreementID;
            });
            dispatch(updateSelectedCollectiveAgreementReducer(selectedOne));
        }
    };

    const setEnableCollectiveAgreement = (isEnable: boolean) => {
        dispatch(updateEnableCollectiveAgreement(isEnable));
    };
    const setEnforceCollectiveAgreement = (isEnforce: boolean) => {
        dispatch(updateEnforceCollectiveAgreement(isEnforce));
    };
    const updateSelectedCollectiveAgreement = async () => {
        const token = localStorage.getItem("token");
        const actionResponse: any = await dispatch(
            updateSelectedCollectiveAgreementService({
                token,
                siteId: selectedBranchSettings._id,
                collectiveAgreementId: selectedCollectiveAgreement._id,
            })
        );
        if (actionResponse?.error) {
            return {
                error: true,
                message: "something went wrong!!!",
                options: actionResponse,
            };
        } else {
            return { error: false, payload: actionResponse.payload };
        }
    };

    return {
        enableCollectiveAgreement,
        enforceCollectiveAgreement,
        listOfCollectiveAgreements,
        selectedCollectiveAgreement,

        getAllCollectiveAgreements,
        setSelectedCollectiveAgreement,
        setEnableCollectiveAgreement,
        setEnforceCollectiveAgreement,
        updateSelectedCollectiveAgreement,
    };
};
