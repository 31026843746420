import React from "react";
import UserNameImgCard from "../../../../components/molecules/UserNameImgCard";
import { generatePicUrl } from "../../../../_helpers/PictureHelpers";

export const NameColumnFormatter = (content: any, rowIdx: number, row: any) => {
  return (
    <UserNameImgCard
      firstName={row.fname}
      lastName={row.lname}
      role={row.role}
      img={row.picture && generatePicUrl(row.picture)}
      variant={"n-800"}
    />
  );
};
