import * as React from "react";

import "./style.scss";
import TextData from "../TextData";
import { ReactComponent as NoAccessSVG } from "./no-access.svg";

export default function WarningPanel(props: WarningPanelProps) {
    const { ImageComponent, title, description } = props;
    return (
        <div className='no-access'>
            <div className='no-access-body'>
                <ImageComponent />
                <TextData
                    color='#6F78AB'
                    size='14px'
                    fontWeight='bolder'
                    style={{ textAlign: "center" }}
                >
                    {title}
                </TextData>
                <TextData
                    color='#6F78AB'
                    size='14px'
                    style={{ textAlign: "center" }}
                >
                    {description}
                </TextData>
            </div>
        </div>
    );
}

interface WarningPanelProps {
    title: string;
    description: string;
    ImageComponent: string;
}
WarningPanel.defaultProps = {
    ImageComponent: NoAccessSVG,
    title: "Paramètres",
    description:
        "Veuillez sélectionner un établissement pour accéder aux paramètres !",
};
