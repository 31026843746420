import React from "react";

export default function TabNavigator({
    tabs = [],
    children,
    variant = "default",
    containerClasses,
    headerClasses,
    headerContainerClasses,
    bodyClasses,
    rightComponent,
    style,
    tabsStyle,
}: TabnavigatorInterface) {
    const getCssClassName = (active: boolean | undefined) => {
        let baseName =
            "font-semibold cursor-pointer p-3 border-default-active hover:border-b-3px text-lg";
        switch (variant) {
            case "default": {
                if (active) {
                    baseName += ` text-default-active border-b !border-default-active border-b-3px`;
                } else {
                    baseName += ` text-default hover:text-default-active `;
                }
                break;
            }
            case "primary": {
                if (active) {
                    baseName += ` text-primary-active border-b !border-primary-active border-b-3px`;
                } else {
                    baseName += ` text-primary hover:text-primary-active`;
                }
                break;
            }
            case "secondary": {
                if (active) {
                    baseName += ` text-secondary-active border-b !border-secondary-active border-b-3px`;
                } else {
                    baseName += ` text-secondary hover:text-secondary-active`;
                }
                break;
            }
        }
        return baseName;
    };
    return (
        <div className={`${containerClasses}`} style={{ ...style } || {}}>
            <div
                className={`flex justify-between flex-wrap-reverse border-b ${headerContainerClasses}`}
                style={{ ...tabsStyle } || {}}
            >
                <ul className="flex gap-2 list-none">
                    {tabs.map((tab, key) => (
                        <li
                            onClick={() => tab.onClick && tab.onClick(key)}
                            className={`${getCssClassName(
                                tab.active,
                            )} ${headerClasses}`}
                            key={key}
                        >
                            {tab.formatter
                                ? tab.formatter(tab.data)
                                : tab.title}
                        </li>
                    ))}
                </ul>
                {rightComponent && rightComponent}
            </div>
            <div className={bodyClasses}>{children}</div>
        </div>
    );
}

interface TabnavigatorInterface {
    tabs: Array<TabsInterface>;
    variant?: "default" | "primary" | "secondary";
    rightComponent?: React.ReactNode;
    children?: React.ReactNode;
    containerClasses?: string;
    headerContainerClasses?: string;
    headerClasses?: string;
    bodyClasses?: string;
    style?: any;
    tabsStyle?: any;
}

interface TabsInterface {
    title: string | React.ReactNode;
    formatter?: (data: any) => React.ReactNode;
    active: boolean;
    onClick(rowIdx: number): void;
    data?: any;
}
