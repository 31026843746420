import * as React from "react";

import { TimeTracker } from "../Settings";
import Button from "../../components/atoms/Button";
import TextData from "../../components/atoms/TextData";
import ProgressSteps from "../../components/molecules/ProgressSteps";
import { ReactComponent as StartSVG } from "./startIcon.svg";
function Slide4(props: Slide4Props) {
    const {
        handleMove,
        currentStep,
        header,
        title,
        description,
        buttonText,
        handleDoneButton,
    } = props;
    const handleSubmit = () => {
        handleMove(1);
        handleDoneButton?.();
    };
    const globalProps = {
        headerPorps: {
            style: {
                marginTop: 25,
                marginLeft: 35,
            },
            color: "#11106E",
            size: "34px",
            fontWeight: "bolder",
        },
        progressStepsProps: {
            steps: [
                {
                    stepName: "Etape 1",
                    label: "Etablissement",
                },
                {
                    stepName: "Etape 2",
                    label: "Planning",
                },
                {
                    stepName: "Etape 3",
                    label: "Pointeuse",
                },
            ],
            initialStep: currentStep,
        },
        titleProps: {
            width: "auto",
            color: "#1A7290",
            size: "18px",
            fontWeight: "bold",
            style: {
                marginBottom: 7,
            },
        },
        descriptionProps: {
            width: "350px",
            color: "#6F78AB",
            size: "14px",
            style: { textAlign: "center" as "center", marginBottom: 7 },
        },
        buttonTextProps: {
            width: "170px",
            height: "35px",
            backgroundColor: "#2A8BAB",
            textColor: "white",
            style: {
                borderRadius: 7,
                fontSize: "14px",
            },
            className: ["button-style"],
            onClick: handleSubmit,
        },
    };
    return (
        <div className='startup-body-container'>
            <div className='startup-header'>
                <TextData {...globalProps.headerPorps}>{header}</TextData>
            </div>
            <div className='startup-card-container' id='slide4-body-container'>
                <div className='progressSteps-container'>
                    <ProgressSteps {...globalProps.progressStepsProps} />
                </div>
                <div className='slide-info-card-container'>
                    <StartSVG />
                    <TextData {...globalProps.titleProps}>{title}</TextData>
                    <TextData {...globalProps.descriptionProps}>
                        {description}
                    </TextData>
                    <Button {...globalProps.buttonTextProps}>
                        {buttonText}
                    </Button>
                </div>
            </div>
        </div>
    );
}
interface Slide4Props {
    currentStep?: number;
    header?: string;
    title?: string;
    description?: string;
    buttonText?: string;
    handleMove: (count: number) => void;
    handleDoneButton?: () => void;
}
Slide4.defaultProps = {
    currentStep: 4,
    header: "Paramètres",
    title: "Configuration terminé",
    description:
        "Tous les paramètres ont été enregistrés avec succès ! Vous pouvez modifier les paramètres plus tard dans le menu mon profil.",
    buttonText: "OK",
};

export default Slide4;
