import * as React from "react";

import Authentification from "../../components/templates/Authentification/Authentification";

export default function CheckEmailPage(props: CheckEmailPageProps) {
    const { authType } = props;
    return (
        <div>
            <Authentification authType={authType} />
        </div>
    );
}

interface CheckEmailPageProps {
    authType?:
        | "login"
        | "reset-password"
        | "change-password"
        | "forget-password"
        | "check-email"
        | "custom";
}

CheckEmailPage.defaultProps = {
    authType: "check-email",
};
