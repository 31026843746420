import React from "react";
import Modal from "../../../components/organism/Modal";
import { useIntl } from "react-intl";
import { useEmployees } from "../../../hooks/useEmployees";
import { Helmet } from "react-helmet";
import Button from "../../../components/atoms/ZButton";
import { useNotificationContext } from "../../../Context/Notification";

export default function DeletePrimeDialog({
  prime,
  show,
  onHide,
}: RestoreEmployeeDialogInterface) {
  const { addNotification } = useNotificationContext();
  const {
    actionLoading,
    fetchPrimes,
    deletePrime,
    employeeForEdit,
  } = useEmployees();

  const intl = useIntl();

  const Body = (
    <div className="h-full flex justify-center items-center flex-wrap">
      <div>
        {actionLoading === true
          ? intl.formatMessage({ id: "PRIME.DIALOG.DELETE.DELETING" })
          : intl.formatMessage({
              id: "PRIME.DIALOG.DELETE.CONFIRMATION",
            })}
      </div>
    </div>
  );

  const removePrime = (id: string) => {
    deletePrime({ acompteId: id }).then((res: any) => {
      const data: any = res.payload;
      if (data.status === "success") {
        addNotification({
          title: intl.formatMessage({
            id: "PRIME.DELETE.NOTIFICATION.SUCCESS.TITLE",
          }),
          message: intl.formatMessage({
            id: "PRIME.DELETE.NOTIFICATION.SUCCESS.MESSAGE",
          }),
          type: "success",
        });
        fetchPrimes({ userId: employeeForEdit._id });
      } else if (data.status === "fail") {
        addNotification({
          title: intl.formatMessage({
            id: "PRIME.DELETE.NOTIFICATION.FAIL.TITLE",
          }),
          message: intl.formatMessage({
            id: "PRIME.DELETE.NOTIFICATION.FAIL.MESSAGE",
          }),
          type: "fail",
        });
      }
      onHide();
    });
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "APP.TITLE" })} |{" "}
          {intl.formatMessage({ id: "PRIME.DIALOG.DELETE.TITLE" })}
        </title>
      </Helmet>
      <Modal
        width={400}
        height={300}
        show={show}
        onHide={onHide}
        title={`${intl.formatMessage({
          id: "PRIME.DIALOG.DELETE.TITLE",
        })}`}
        body={Body}
        ConfirmComponent={
          <Button
            title={intl.formatMessage({ id: "MENU.DELETE" })}
            variant="danger"
            loading={actionLoading}
            onClick={() => removePrime(prime._id)}
            size="block"
          />
        }
      />
    </>
  );
}

interface RestoreEmployeeDialogInterface {
  prime: any;
  show: boolean;
  onHide(): void;
}
