import * as React from "react";
import "./style.scss";

import { ROUTER } from "../../../constants/env";

import EmployeeProfileHeader from "../../../components/organism/EmployeeProfileHeader";
import TabNavigator from "../../../components/molecules/TabNavigationV2";

import { ReactComponent as ProfileSVG } from "./Profile.svg";
import { ReactComponent as DocumentSVG } from "./Document.svg";
import { ReactComponent as CalendarSVG } from "./Calendar.svg";
import { ReactComponent as ArrowSVG } from "./Arrow.svg";
import GeneralInfo from "./GeneralInfo";
import FolderHR from "./FolderHR";
import Unavailability from "./Unavailability";

export default function EmployeeProfile(props: EmployeeProfileProps) {
    return (
        <div className="employee-profile-container">
            <EmployeeProfileHeader />
            <TabNavigator
                tabs={[
                    {
                        title: (
                            <div className="tab-title-container">
                                <ProfileSVG />
                                Informations générales
                            </div>
                        ),
                        component: <GeneralInfo />,
                        link: "/employee/655e1f3933f4613fb1e259af/edit",
                    },
                    {
                        title: (
                            <div className="tab-title-container">
                                <DocumentSVG />
                                Dossier RH
                                <ArrowSVG />
                            </div>
                        ),
                        component: <FolderHR />,
                        link: "/employee/655e1f3933f4613fb1e259ae/edit",
                        // link: ROUTER.SETTINGS.SCHEDULE,
                    },
                    {
                        title: (
                            <div className="tab-title-container">
                                <CalendarSVG />
                                Indisponibilité
                            </div>
                        ),
                        component: <Unavailability />,
                        link: "/employee/655e1f3933f4613fb1e259ad/edit",
                        // link: ROUTER.SETTINGS.SCHEDULE,
                    },
                ]}
                inactiveColor="#6F78AB"
                activeColor="#2A8BAB"
                activeBorderBottom="3px solid #2A8BAB"
            />
            {/* <div>employee body component</div> */}
        </div>
    );
}

interface EmployeeProfileProps {}

EmployeeProfile.defaultProps = {};
