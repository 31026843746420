import * as React from "react";

import "./style.scss";
import TextData from "../../../../components/atoms/TextData";
import ZInput from "../../../../components/atoms/ZInput";
import DropzoneInput from "../../../../components/molecules/DropzoneInput";
import { useIntl } from "react-intl";
import Input from "../../../../components/atoms/Input";

export default function GeneralInfo(props: GeneralInfoProps) {
    const intl = useIntl();
    return (
        <div className="general-info-container">
            <TextData fontWeight="700" size="27px" color="#2A346D">
                Informations Personnelles
            </TextData>
            <div>
                <div className="general-info-inputs-container">
                    <Input
                        captionText={"Nom"}
                        captionSize="12px"
                        captionColor="#8E96C1"
                        required={true}
                        borderColor="#C7CBE2"
                        borderWidth={1}
                        placeholder="Votre nom"
                        textColor="#4C5690"
                        textSize={14}
                        style={{
                            inputCoreStyle: {
                                paddingLeft: 16,
                            },
                        }}
                    />
                    <Input
                        captionText={"Prénom"}
                        captionSize="12px"
                        captionColor="#8E96C1"
                        required={true}
                        borderColor="#C7CBE2"
                        borderWidth={1}
                        placeholder="Jones"
                        textColor="#4C5690"
                        textSize={14}
                        style={{
                            inputCoreStyle: {
                                paddingLeft: 16,
                            },
                        }}
                    />
                    <Input
                        captionText={"E-mail"}
                        captionSize="12px"
                        captionColor="#8E96C1"
                        required={true}
                        borderColor="#C7CBE2"
                        borderWidth={1}
                        placeholder="Votre nom"
                        textColor="#4C5690"
                        textSize={14}
                        style={{
                            inputCoreStyle: {
                                paddingLeft: 16,
                            },
                        }}
                    />
                    <Input
                        captionText={"Téléphone"}
                        captionSize="12px"
                        captionColor="#8E96C1"
                        required={true}
                        borderColor="#C7CBE2"
                        borderWidth={1}
                        placeholder="+216 12 345 678"
                        textColor="#4C5690"
                        textSize={14}
                        style={{
                            inputCoreStyle: {
                                paddingLeft: 16,
                            },
                        }}
                    />
                </div>
                <div className="change-photo-container"></div>
            </div>
        </div>
    );
}

interface GeneralInfoProps {}

GeneralInfo.defaultProps = {};
