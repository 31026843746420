import React from "react";

export default function TableUserRole({
    role = "Administrator",
    text = "default",
}: TableUserRoleInterface) {
    const getCircleClass = () => {
        let className: string =
            "inline-block rounded-full w-[8px] h-[8px] text-sm font-light";
        switch (role) {
            case "Administrator": {
                className += " bg-admin";
                break;
            }
            case "Manager": {
                className += " bg-manager";
                break;
            }
            case "Employee": {
                className += " bg-employee";
                break;
            }
        }
        return className;
    };

    const getTextClass = () => {
        let className: string = "text-sm font-light";
        switch (text) {
            case "default": {
                className += " text-default";
                break;
            }
            case "primary": {
                className += " text-primary";
                break;
            }
            case "secondary": {
                className += " text-secondary";
                break;
            }
            case "n-600": {
                className += " text-n-600";
                break;
            }
        }
        return className;
    };

    return (
        <div className='flex items-center justify-center py-2 px-2 border gap-2 rounded-full w-[110px]'>
            <span className={getCircleClass()}></span>
            <span className={getTextClass()}>
                {role === "Administrator"
                    ? "Administrateur"
                    : role == "Manager"
                    ? "Manager"
                    : "Employé"}
            </span>
        </div>
    );
}

interface TableUserRoleInterface {
    role: "Administrator" | "Manager" | "Employee";
    text?: "default" | "primary" | "secondary" | "n-600";
}
