import axios, { AxiosError } from "axios";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { FetchSitesManagementRequest } from "./types";
import { fetchSitesManagementUrl } from "../api.routes";

export const fetchSitesManagemnt = createAsyncThunk(
    "sites/fetchSitesManagement",
    async (payload: FetchSitesManagementRequest, { rejectWithValue }) => {
        try {
            const res = await axios.get(fetchSitesManagementUrl, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            return res.data;
        } catch (err) {
            const error = err as AxiosError;
            if (error) {
                return rejectWithValue(error?.response?.data);
            }
        }
    }
);
