import { extractNumber } from "../../../_helpers/Functions";
import Input from "../../../components/atoms/Input";
import ToggleButton from "../../../components/atoms/Toggle";
import { useBranch } from "../../../hooks/useBranch";
import { useMobile } from "../../../hooks/useMobile";

export const MobileProps = () => {
    const {
        branchSettings,

        setDaysForUnavailabilityNotice,
        setDaysForVacationNotice,
    } = useBranch();
    const { mobile, saveMobile } = useMobile();
    const indisponibilityParams = () => {
        if (mobile.employeeIndisponibility) {
            return [
                {
                    optionTitle: "Indisponibilité pour les employés",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Permettez aux employés de signaler des périodes d’indisponibilité récurrentes sur plusieurs semaines, telles que des rendez-vous, des obligation scolaire, ou des préférences personnelles. Cela facilite l’élaboration d’un planning en tenant compte des disponibilités de chacun. ",
                    optionInput: (
                        <ToggleButton
                            onClick={(e: any) =>
                                saveMobile({
                                    employeeIndisponibility: e.target.checked,
                                })
                            }
                            isChecked={mobile.employeeIndisponibility}
                        />
                    ),
                },
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle:
                        "Jour de préavis requis pour modifier ses indisponibilités.",
                    optionDescription:
                        "Définissez le nombre de jours à l’avance que les employés doivent donner avant de pouvoir modifier leurs indisponibilités. ",
                    optionInput: (
                        <Input
                            captionText={null}
                            inputWidth="100px"
                            placeholder=""
                            borderColor="gray"
                            borderWidth={1}
                            value={`${branchSettings.joursIndisponibilite}`}
                            onChange={(value: any) =>
                                setDaysForUnavailabilityNotice(
                                    extractNumber(value),
                                )
                            }
                            type="number"
                            style={{
                                inputStyle: {
                                    padding: "10px 5px",
                                    outline: "none",
                                },
                            }}
                        />
                    ),
                },
            ];
        } else {
            return [
                {
                    optionTitle: "Indisponibilité pour les employés",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Permettez aux employés de signaler des périodes d’indisponibilité récurrentes sur plusieurs semaines, telles que des rendez-vous, des obligation scolaire, ou des préférences personnelles. Cela facilite l’élaboration d’un planning en tenant compte des disponibilités de chacun. ",
                    optionInput: (
                        <ToggleButton
                            onClick={(e: any) =>
                                saveMobile({
                                    employeeIndisponibility: e.target.checked,
                                })
                            }
                            isChecked={mobile.employeeIndisponibility}
                        />
                    ),
                },
            ];
        }
    };
    const congesParams = () => {
        if (mobile.employeeConges) {
            return [
                {
                    optionTitle: "Congés pour les employés",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Activez les demandes de congés via l’application pour les employés, facilitant ainsi le processus de demande et de gestion des congés. ",
                    optionInput: (
                        <ToggleButton
                            onClick={(e: any) =>
                                saveMobile({ employeeConges: e.target.checked })
                            }
                            isChecked={mobile.employeeConges}
                        />
                    ),
                },
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle:
                        "Jour de préavis requis pour la demande d'un congé.",
                    optionDescription:
                        "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",
                    optionInput: (
                        <Input
                            captionText={null}
                            inputWidth="100px"
                            placeholder=""
                            borderColor="gray"
                            borderWidth={1}
                            value={`${branchSettings.joursConge}`}
                            onChange={(value: any) =>
                                setDaysForVacationNotice(extractNumber(value))
                            }
                            type="number"
                            style={{
                                inputStyle: {
                                    padding: "10px 5px",
                                    outline: "none",
                                },
                            }}
                        />
                    ),
                },
            ];
        } else {
            return [
                {
                    optionTitle: "Congés pour les employés",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Activez les demandes de congés via l’application pour les employés, facilitant ainsi le processus de demande et de gestion des congés.",
                    optionInput: (
                        <ToggleButton
                            onClick={(e: any) =>
                                saveMobile({ employeeConges: e.target.checked })
                            }
                            isChecked={mobile.employeeConges}
                        />
                    ),
                },
            ];
        }
    };
    return [
        {
            headerTitle: "Jours de préavis",
            headerDescription:
                "Déterminez le nombre de jours de préavis nécessaires avant qu’un employé puisse vous faire une demande . ",
            configOptions: [...indisponibilityParams(), ...congesParams()],
        },
        {
            headerTitle: "Les paramètres Mobile",
            headerDescription:
                "Configurez les options d’accès mobile pour les employés ",
            configOptions: [
                {
                    optionTitle: "L'employé peut voir le planning des autres",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Permettez aux employés d’accéder aux plannings des autres membres de l’équipe. ",
                    optionInput: (
                        <ToggleButton
                            onClick={(e: any) =>
                                saveMobile({
                                    seeOthersPlanning: e.target.checked,
                                })
                            }
                            isChecked={mobile.seeOthersPlanning}
                        />
                    ),
                },
                {
                    optionTitle: "Planning téléchargable en PDF",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Offrez la possibilité de télécharger le planning sous forme de fichier PDF pour une consultation pratique et hors ligne. ",
                    optionInput: (
                        <ToggleButton
                            onClick={(e: any) =>
                                saveMobile({
                                    downloadPlanningFromMobile:
                                        e.target.checked,
                                })
                            }
                            isChecked={mobile.downloadPlanningFromMobile}
                        />
                    ),
                },
                {
                    optionTitle: "Rapport des collaborateurs",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Afin que vos collaborateurs puissent consulter les heures et jours travaillées, les pauses et le repas directement sur le téléphone. ",
                    optionInput: (
                        <ToggleButton
                            onClick={(e: any) =>
                                saveMobile({
                                    reportPageVisibility: e.target.checked,
                                })
                            }
                            isChecked={mobile.reportPageVisibility}
                        />
                    ),
                },
                {
                    optionTitle: "Affichage des pauses dans le rapport",
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionDescription:
                        "Si vous gérez les pauses, vos collaborateurs auront le temps de pause comptabiliser ",
                    optionInput: (
                        <ToggleButton
                            onClick={(e: any) =>
                                saveMobile({
                                    pauseInReportVisibility: e.target.checked,
                                })
                            }
                            isChecked={mobile.pauseInReportVisibility}
                        />
                    ),
                },
            ],
        },
    ];
};
