export const timeOptions = [
    { label: "00:00", value: "00:00" },
    { label: "00:30", value: "00:30" },
    { label: "01:00", value: "01:00" },
    { label: "01:30", value: "01:30" },
    { label: "02:00", value: "02:00" },
    { label: "02:30", value: "02:30" },
    { label: "03:00", value: "03:00" },
    { label: "03:30", value: "03:30" },
    { label: "04:00", value: "04:00" },
    { label: "04:30", value: "04:30" },
    { label: "05:00", value: "05:00" },
    { label: "05:30", value: "05:30" },
    { label: "06:00", value: "06:00" },
    { label: "06:30", value: "06:30" },
    { label: "07:00", value: "07:00" },
    { label: "07:30", value: "07:30" },
    { label: "08:00", value: "08:00" },
    { label: "08:30", value: "08:30" },
];

export const generateFields = (targetFields: FieldInterface[]) => {
    let fields: { [key: string]: FieldInterface } = {};
    for (const target of targetFields) {
        fields[target.name] = {
            name: target.name,
            value: target.value,
            hasError: target.hasError ? target.hasError : false,
            error: target.error ? target.error : "",
            touched: target.touched ? target.touched : false,
        };
    }
    return fields;
};

export const destructFields = (targetFields: {
    [key: string]: FieldInterface;
}) => {
    const keys = Object.keys(targetFields);
    const values: { [key: string]: any } = {};
    keys.map((key) => {
        values[key] = targetFields[key].value;
    });
    return values;
};

export class Validation {
    private fields: FieldInterface[];
    private conditions: Conidition[][];

    constructor() {
        this.fields = [];
        this.conditions = [];
    }

    add(field: FieldInterface, conditions: Conidition[]) {
        this.fields.push(field);
        this.conditions.push(conditions);
        return this;
    }

    validate(callback: (fields: FieldInterface[]) => void) {
        const newFields: FieldInterface[] = this.fields.map((field) => ({
            ...field,
            hasError: false,
            error: "",
        }));
        this.fields.forEach((field, index) => {
            const { value } = field;
            for (const condition of this.conditions[index]) {
                if (condition.REQUIRED) {
                    if (value === undefined || value === null || value === "") {
                        newFields.push({
                            ...field,
                            hasError: true,
                            error: condition.message,
                        });
                        break;
                    }
                } else if (condition.MIN_LENGTH !== undefined) {
                    if (
                        value &&
                        value.toString().length < condition.MIN_LENGTH
                    ) {
                        newFields.push({
                            ...field,
                            hasError: true,
                            error: condition.message,
                        });
                        break;
                    }
                } else if (condition.MAX_LENGTH !== undefined) {
                    if (
                        value &&
                        value.toString().length > condition.MAX_LENGTH
                    ) {
                        newFields.push({
                            ...field,
                            hasError: true,
                            error: condition.message,
                        });
                        break;
                    }
                } else if (condition.REGEX) {
                    let reg = new RegExp(condition.REGEX);
                    if (!reg.test(value)) {
                        newFields.push({
                            ...field,
                            hasError: true,
                            error: condition.message,
                        });
                        break;
                    }
                } else if (condition.TEST) {
                    if (condition.TEST()) {
                        newFields.push({
                            ...field,
                            hasError: true,
                            error: condition.message,
                        });
                        break;
                    }
                } else if (condition.EXIST !== undefined) {
                    if (condition.EXIST) {
                        newFields.push({
                            ...field,
                            hasError: true,
                            error: condition.message,
                        });
                        break;
                    }
                } else if (condition.AT_LEAST_ONE !== undefined) {
                    if (condition.AT_LEAST_ONE) {
                        newFields.push({
                            ...field,
                            hasError: true,
                            error: condition.message,
                        });
                        break;
                    }
                }
            }
        });
        return callback(newFields);
    }
}

export interface FieldInterface {
    name: string;
    value: any;
    error?: string;
    hasError?: boolean;
    touched?: boolean;
}

interface Conidition {
    REQUIRED?: boolean;
    MIN_LENGTH?: number;
    MAX_LENGTH?: number;
    REGEX?: string;
    TEST?: () => boolean;
    message: string;
    EXIST?: boolean;
    AT_LEAST_ONE?: boolean;
}

/*
export type Field = {[key: string] : FieldInterface};
export type  FormFields =  Field | {[key: string] : Field}


export const generateFields = (targetFields : FieldInterface[]) : FormFields => {
    let fields : FormFields = {}
    for(const target of targetFields){
      // Case object value is an object
      if(typeof target.value === "object" && !Array.isArray(target.value)){
        const keys = Object.keys(target.value);
        fields[target.name] = generateFields(keys.map(key => ({
          name : key,
          value : target.value[key]
        }))) as Field;
      } else {
        fields[target.name] = {
          name: target.name,
          value : target.value,
          hasError : target.hasError ? target.hasError : false,
          error : target.error ? target.error : "",
          touched : target.touched ? target.touched : false
        }
      }
    }
    return fields;
} 
*/
