import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CountriesStateInterface } from "./type";
import { fetchCountries } from "./service";

const initialState: CountriesStateInterface = {
    countries: [],
    listLoading: false,
    actionLoading: false,
    error: null,
};

const countriesSlice = createSlice({
    name: "countries",
    initialState,
    reducers: {
        resetAllCountries: (state) => {
            state = initialState;
        },
    },
    extraReducers: (build) => {
        build.addCase(
            fetchCountries.pending,
            (state: CountriesStateInterface) => {
                state.listLoading = true;
                state.error = null;
            }
        );
        build.addCase(
            fetchCountries.fulfilled,
            (state: CountriesStateInterface, action: PayloadAction<any>) => {
                const { countries } = action.payload;
                state.countries = countries;
                state.error = null;
                state.listLoading = false;
            }
        );
        build.addCase(
            fetchCountries.rejected,
            (state: CountriesStateInterface, action: PayloadAction<any>) => {
                state.error = "error";
                state.listLoading = false;
            }
        );
    },
});

export const { resetAllCountries } = countriesSlice.actions;
const { reducer } = countriesSlice;
export default reducer;
