import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    signIn,
    verifyToken,
    forgetPasswordServ,
    resetPasswordServ,
    changePasswordServ,
} from "./service";
import {
    AuthState,
    UserState,
    SignInResponse,
    CheckmeResponse,
    ForgetPasswordResponse,
    ChangePasswordResponse,
} from "./types";
const initialUserState: UserState = {
    _id: null,
    email: null,
    lname: null,
    fname: null,
    username: null,
    role: null,
    phone: null,
    picture: null,
    pin: null,
    license: null,
    niveau: null,
    belongsTo: null,
    contract: null,
    deleted: null,
    hideInResgitre: null,
    humanResource: null,
    manage: null,
    mobileTally: null,
    planning: null,
    updatedPassword: null,
};
const userStateString = sessionStorage.getItem("user");
const initialState: AuthState = {
    success: null,
    loading: false,
    error: false,
    email: "",
    password: "",
    isRememberedMe: false,
    token: localStorage.getItem("token"),
    user: userStateString ? (JSON.parse(userStateString) as UserState) : null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        resetAllAuth: (state) => {
            state = initialState;
        },
        logout: (state, action) => {
            localStorage.removeItem("token");
            sessionStorage.clear();
            state.user = null;
        },
        updateEmail: (state, action) => {
            if (action.payload?.email) {
                state.email = action.payload?.email;
            }
        },
        updatePassword: (state, action) => {
            if (action.payload?.password) {
                state.password = action.payload?.password;
            }
        },
        toggleIsRememberedMe: (state, action) => {
            state.isRememberedMe = action.payload?.isRememberedMe;
        },
        updateToken: (state, action) => {
            if (action.payload?.token) {
                state.token = action.payload?.token;
                localStorage.setItem("token", action.payload?.token);
            }
        },
        updateLoading: (state, action) => {
            state.loading = action.payload?.loading;
        },
    },
    extraReducers: (builder) => {
        // CASE 1 : user register for an account
        builder.addCase(signIn.pending, (state: AuthState) => {
            state.loading = true;
            state.error = false;
        });
        builder.addCase(
            signIn.fulfilled,
            (state: AuthState, action: PayloadAction<SignInResponse>) => {
                if (action.payload?.role === "Employee") {
                    state.error = true;
                    state.loading = false;
                    state.token = null;
                    localStorage.removeItem("token");
                    state.user = null;
                    sessionStorage.removeItem("user");
                } else {
                    state.success = true;
                    state.loading = false;
                    state.error = false;
                    state.email = action.payload?.email;
                    state.token = action.payload?.token;
                    localStorage.setItem("token", action.payload?.token);
                    state.user = action.payload;
                    sessionStorage.setItem("user", JSON.stringify(state.user));
                }
            },
        );
        builder.addCase(signIn.rejected, (state: AuthState) => {
            state.error = true;
            state.loading = false;
            state.token = null;
            localStorage.removeItem("token");
            state.user = null;
            sessionStorage.removeItem("user");
        });
        // CASE 2 : forget password
        builder.addCase(forgetPasswordServ.pending, (state: AuthState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(forgetPasswordServ.fulfilled, (state: AuthState) => {
            state.success = true;
            state.loading = false;
            state.error = false;
        });
        builder.addCase(forgetPasswordServ.rejected, (state: AuthState) => {
            state.success = false;
            state.error = true;
            state.loading = false;
        });
        // CASE 3 : verify user token
        builder.addCase(verifyToken.pending, (state: AuthState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(
            verifyToken.fulfilled,
            (state: AuthState, action: PayloadAction<CheckmeResponse>) => {
                state.success = true;
                state.loading = false;
                state.error = false;
                state.email = action.payload?.email;
                state.user = action.payload;
                sessionStorage.setItem("user", JSON.stringify(state.user));
            },
        );
        builder.addCase(verifyToken.rejected, (state: AuthState) => {
            state.error = true;
            state.loading = false;
            state.token = null;
            localStorage.removeItem("token");
            state.user = null;
            sessionStorage.removeItem("user");
        });
        // Case 4 : reset user password
        builder.addCase(resetPasswordServ.pending, (state: AuthState) => {
            state.error = false;
            state.loading = true;
        });
        builder.addCase(resetPasswordServ.fulfilled, (state: AuthState) => {
            state.success = true;
            state.loading = false;
            state.error = false;
            state.token = null;
            localStorage.removeItem("token");
            state.user = null;
            sessionStorage.removeItem("user");
        });
        builder.addCase(resetPasswordServ.rejected, (state: AuthState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });
        // Case 5 : change password
        builder.addCase(changePasswordServ.pending, (state: AuthState) => {
            state.error = false;
            state.loading = true;
        });
        builder.addCase(changePasswordServ.fulfilled, (state: AuthState) => {
            state.success = true;
            state.loading = false;
            state.error = false;
        });
        builder.addCase(changePasswordServ.rejected, (state: AuthState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });
    },
});

export const {
    updateEmail,
    updatePassword,
    toggleIsRememberedMe,
    updateToken,
    updateLoading,
    logout,
    resetAllAuth,
} = authSlice.actions;
export default authSlice.reducer;
