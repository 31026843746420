import * as React from "react";
import Button from "../../atoms/Button";
import TextData from "../../atoms/TextData";

import { ReactComponent as DownloadSVG } from "./download.svg";
import "./style.scss";

function TagCorner(props: TagCornerProps) {
    const { size, color } = props;
    return (
        <div
            className="tag-corner"
            style={{
                borderTop: `${size}px solid ${color}`,
                borderLeft: `${size}px solid transparent`,
            }}
        />
    );
}

export default function TableWeekHeader(props: TableWeekHeaderProps) {
    const {
        tagCornerSize,
        tagCornerColor,
        frontTitle,
        frontTitleColor,
        frontTitleSize,
        frontTitleWeight,
        frontSubTitle,
        frontSubTitleColor,
        frontSubTitleSize,
        frontSubTitleWeight,
        backTitle,
        backBackgroundColor,
        backOnClick,
        backButtonWidth,
        BackButtonIcon,
        backButtonBackgroundColor,
        backButtonStyle,
        backButtonPadding,
        backButtonIconColor,
        backButtonTextColor,
        backButtonFontSize,
        isValidWeek,
        onValidWeekClick,
    } = props;
    const [isClicked, setisClicked] = React.useState(false);
    const frontDivRef = React.useRef<HTMLDivElement>(null);
    const backDivRef = React.useRef<HTMLDivElement>(null);
    React.useLayoutEffect(() => {
        if (frontDivRef.current && backDivRef.current) {
            const frontDivHeight =
                frontDivRef.current.getBoundingClientRect().height ?? 0;
            const backDivHeight =
                backDivRef.current.getBoundingClientRect().height ?? 0;
            const frontDivWidth =
                frontDivRef.current.getBoundingClientRect().width ?? 0;
            const backDivWidth =
                backDivRef.current.getBoundingClientRect().width ?? 0;

            const maxHeight = Math.max(frontDivHeight, backDivHeight);
            const maxWidth = Math.max(frontDivWidth, backDivWidth);

            frontDivRef.current.style.cssText = `height: ${maxHeight}px; width: ${maxWidth}px;`;
            backDivRef.current.style.cssText = `height: ${maxHeight}px; width: ${maxWidth}px;`;
        }
    }, [isClicked]);
    const showFront = () => {
        setisClicked(false);
    };
    const showBack = () => {
        if (isValidWeek === true) {
            setisClicked(true);
        }
    };
    const globalProps = {
        headerContainerProps: {
            onMouseEnter: showBack,
            onMouseLeave: showFront,
            className: "table-week-header-container",
            style: {
                backgroundColor: isClicked
                    ? backBackgroundColor
                    : "transparent",
            },
        },
        frontCardProps: {
            className: `cell-container ${isClicked ? "offScreen" : ""}`,
            ref: frontDivRef,
            style: {
                visibility: isClicked
                    ? ("hidden" as "hidden")
                    : ("unset" as "unset"),
            },
        },
        tagCornerProps: { size: tagCornerSize, color: tagCornerColor },
        frontTitleProps: {
            size: frontTitleSize,
            color: frontTitleColor,
            fontWeight: frontTitleWeight,
            width: "auto",
        },
        frontSubTitleProps: {
            size: frontSubTitleSize,
            color: frontSubTitleColor,
            fontWeight: frontSubTitleWeight,
            width: "auto", //TODO: 160px
        },
        backCardProps: {
            className: `cell-container ${!isClicked ? "offScreen" : ""}`,
            ref: backDivRef,
            style: {
                visibility: !isClicked
                    ? ("hidden" as "hidden")
                    : ("unset" as "unset"),
            },
        },
        backButtonProps: {
            width: backButtonWidth,
            backgroundColor: backButtonBackgroundColor,
            style: backButtonStyle,
            padding: backButtonPadding,
            onClick: backOnClick,
        },
        backButtonIconProps: {
            style: {
                color: backButtonIconColor,
                marginLeft: 2,
            },
        },
        backButtonTextProps: {
            size: backButtonFontSize,
            color: backButtonTextColor,
            fontWeight: "normal",
        },
    };
    return (
        <div {...globalProps.headerContainerProps}>
            <div {...globalProps.frontCardProps}>
                <TagCorner {...globalProps.tagCornerProps} />
                <TextData {...globalProps.frontTitleProps}>
                    {frontTitle}
                </TextData>
                <TextData {...globalProps.frontSubTitleProps}>
                    {frontSubTitle}
                </TextData>
            </div>
            <div {...globalProps.backCardProps}>
                <TagCorner {...globalProps.tagCornerProps} />
                {backTitle && (
                    <Button
                        {...globalProps.backButtonProps}
                        onClick={onValidWeekClick}
                    >
                        <BackButtonIcon {...globalProps.backButtonIconProps} />
                        <TextData {...globalProps.backButtonTextProps}>
                            {backTitle}
                        </TextData>
                    </Button>
                )}
            </div>
        </div>
    );
}
interface TagCornerProps {
    size?: number;
    color?: string;
}
interface TableWeekHeaderProps {
    tagCornerSize?: number;
    tagCornerColor?: string;
    frontTitle?: string;
    frontTitleColor?: string;
    frontTitleSize?: string;
    frontTitleWeight?: string;
    frontSubTitle?: string;
    frontSubTitleColor?: string;
    frontSubTitleSize?: string;
    frontSubTitleWeight?: string;
    backTitle?: string | null;
    backOnClick?: () => void;
    backBackgroundColor?: string;
    backButtonWidth?: string;
    BackButtonIcon: React.ElementType;
    backButtonBackgroundColor?: string;
    backButtonStyle?: React.CSSProperties;
    backButtonPadding?: string;
    backButtonIconColor?: string;
    backButtonTextColor?: string;
    backButtonFontSize?: string;
    isValidWeek?: boolean;
    onValidWeekClick?: (week: any) => void;
}

TableWeekHeader.defaultProps = {
    tagCornerSize: 15,
    tagCornerColor: "#00A42E",
    frontTitle: "S35",
    frontTitleColor: "#2A346D",
    frontTitleSize: "16px",
    frontTitleWeight: "bold",
    frontSubTitle: "29/08 - 04/09",
    frontSubTitleColor: "#4C5690",
    frontSubTitleSize: "12px",
    frontSubTitleWeight: "normal",
    backTitle: "Feuille d'émargement",
    backOnClick: () => {},
    backBackgroundColor: "rgba(0, 164, 46, 0.2)",
    backButtonWidth: "160px",
    BackButtonIcon: DownloadSVG,
    backButtonBackgroundColor: "white",
    backButtonStyle: { border: "1px solid #00A42E" },
    backButtonPadding: "4px",
    backButtonIconColor: "#00A42E",
    backButtonTextColor: "#00A42E",
    backButtonFontSize: "12px",
};
