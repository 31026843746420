import React from "react";
import logoSVG from "./logo.svg";
import Picture from "../../atoms/Picture";
import TextData from "../../atoms/TextData";
import "./style.scss";
function Footer(props: FooterProps) {
    const { version, height, backgroundColor, logo, textColor, textSize } =
        props;
    return (
        <div
            className='footer-container'
            style={{
                height,
                backgroundColor,
            }}
        >
            <Picture imgPath={logo} />
            <TextData color={textColor} width='auto' size={textSize}>
                © Esperoo V{version} All Rights Reserved
            </TextData>
        </div>
    );
}
interface FooterProps {
    height?: string;
    backgroundColor?: string;
    logo?: string;
    textColor?: string;
    textSize?: string;
    version?: string;
}
Footer.defaultProps = {
    height: "60px",
    backgroundColor: "white",
    logo: logoSVG,
    textColor: "#6F78AB",
    textSize: "12px",
    version: "1.0.1",
};

export default Footer;
