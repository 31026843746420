import React from "react";

export default function UserNameImgCard({
  firstName,
  lastName,
  img,
  role,
  variant = "default",
}: UserNameImgCardInterface) {
  let textClass = "font-bold text-lg";
  switch (variant) {
    case "default": {
      textClass += " text-default";
      break;
    }
    case "primary": {
      textClass += " text-primary";
      break;
    }
    case "secondary": {
      textClass += " text-secondary";
      break;
    }
    case "n-800": {
      textClass += " text-n-800";
      break;
    }
  }

  return (
    <div className="flex items-center gap-3">
      <div className="min-w-[40px] max-w-[40px] h-[40px] ">
        {img && img !== "" ? (
          <img
            className="w-full h-full rounded-full"
            src={img}
            alt={"user image"}
          />
        ) : (
          <div
            className={`w-full h-full flex justify-center items-center rounded-full text-white font-semibold ${
              role == "Manager"
                ? "bg-manager"
                : role == "Employee"
                ? "bg-employee"
                : "bg-admin"
            }`}
          >
            {firstName[0]}
            {lastName[0]}
          </div>
        )}
      </div>
      <div className={textClass}>
        {firstName} {lastName}
      </div>
    </div>
  );
}

interface UserNameImgCardInterface {
  firstName: string;
  lastName: string;
  img?: string;
  role: "Administrator" | "Manager" | "Employee";
  variant?: "default" | "primary" | "secondary" | "n-800";
}
