import React from "react";
import { useIntl } from "react-intl";
import Button from "../../../components/atoms/ZButton";

export default function NoContractsFound({
  createNewContract,
}: NoContractsFoundInterface) {
  const intl = useIntl();
  return (
    <div className="p-10 mx-auto flex flex-col max-w-[600px] items-center gap-5">
      <img
        className="max-w-[200px]"
        src="/assets/imgs/file-loop.png"
        alt="icon"
      />
      <h2 className="inline-block text-n-700 font-bold text-6xl">
        {intl.formatMessage({ id: "EMPLOYEE.CONTRACT.NO_CONTRACT" })}
      </h2>
      <p className="font-light text-md text-n-500 text-center">
        consectetur adipiscing elit duis tristique sollicitudin nibh sit amet
        commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae congue
      </p>
      <Button
        onClick={createNewContract}
        title={intl.formatMessage({ id: "EMPLOYEE.CONTRACT.CREATE_CONTRACT" })}
        variant="primary"
        leftComponent={
          <img src="/assets/imgs/add-square-white.png" alt="icon" />
        }
      />
    </div>
  );
}

interface NoContractsFoundInterface {
  createNewContract(): void;
}
