import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUsersRequest } from "./types";
import { API } from "../../../constants/env";

export const getUsers = createAsyncThunk(
    "users/getUsers",
    async (payload: getUsersRequest, { rejectWithValue }) => {
        if (payload.token) {
            try {
                const res = await fetch(
                    API.client.users.getUsers(payload.archive),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    }
);

export const depositUserDPAE = createAsyncThunk(
    "users/depositDPAE",
    async (payload: any, { rejectWithValue }) => {
        try {
            if (payload.userID && payload.token && payload.siteID) {
                console.log("payload.userID:", payload.userID);
                console.log("payload.token:", payload.token);
                const res = await fetch(
                    API.client.users.depositDPAE(payload.userID),
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: JSON.stringify({ siteID: payload.siteID }),
                    }
                )
                    .then((res) => res.json())
                    .then((res) => {
                        return res;
                    });
                return res;
            }
        } catch (err) {
            console.log("rejected :", err);
            return rejectWithValue(err);
        }
    }
);
