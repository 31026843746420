export const Bag = ({
  width = 18,
  height = 18,
  color = "currentColor",
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75 16.5H14.25C14.8467 16.5 15.419 16.2629 15.841 15.841C16.2629 15.419 16.5 14.8467 16.5 14.25V6.75C16.5 6.15326 16.2629 5.58097 15.841 5.15901C15.419 4.73705 14.8467 4.5 14.25 4.5H12.75V3C12.75 2.60218 12.592 2.22064 12.3107 1.93934C12.0294 1.65804 11.6478 1.5 11.25 1.5H6.75C6.35218 1.5 5.97064 1.65804 5.68934 1.93934C5.40804 2.22064 5.25 2.60218 5.25 3V4.5H3.75C3.15326 4.5 2.58097 4.73705 2.15901 5.15901C1.73705 5.58097 1.5 6.15326 1.5 6.75V14.25C1.5 14.8467 1.73705 15.419 2.15901 15.841C2.58097 16.2629 3.15326 16.5 3.75 16.5ZM6.75 3H11.25V4.5H6.75V3ZM3 6.75C3 6.55109 3.07902 6.36032 3.21967 6.21967C3.36032 6.07902 3.55109 6 3.75 6H14.25C14.4489 6 14.6397 6.07902 14.7803 6.21967C14.921 6.36032 15 6.55109 15 6.75V7.7625L9 10.4295L3 7.7625V6.75ZM3 9.40425L8.6955 11.9355C8.79137 11.9781 8.8951 12.0001 9 12.0001C9.1049 12.0001 9.20863 11.9781 9.3045 11.9355L15 9.40425V14.25C15 14.4489 14.921 14.6397 14.7803 14.7803C14.6397 14.921 14.4489 15 14.25 15H3.75C3.55109 15 3.36032 14.921 3.21967 14.7803C3.07902 14.6397 3 14.4489 3 14.25V9.40425Z"
      fill={color}
    />
  </svg>
);

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}
