import React from "react";
import "./style.scss";
import { ReactComponent as ChevronSVG } from "./Chevron.svg";
import TextData from "../../atoms/TextData";
import Button from "../../atoms/Button";
import { ReactComponent as TickSVG } from "./tick.svg";
import { ReactComponent as DownloadSVG } from "./download.svg";
import { ReactComponent as EditSVG } from "./edit.svg";
import { ReactComponent as CopySVG } from "./copy.svg";
import { ReactComponent as TrashSVG } from "./trash.svg";

export default function BannerTableHeader(props: any) {
    const memoizedProps = React.useMemo(() => props, [props]);

    const { color, index, expanded, setExpanded, name, depId, published } =
        memoizedProps;
    const {
        handleOpenDuplicate,
        handleOpenEdit,
        handleOpenDelete,
        handleOpenDownload,
        handleOpenPublish,
        printPlanning,
        period,
        departments,
    } = props;

    return (
        <tr key={index}>
            <th
                colSpan={10}
                className="planning-banner-container"
                style={{
                    height: "60px",
                    backgroundColor: color,
                }}
            >
                <div className="banner-content">
                    <div className="banner-left">
                        {/* <BarSVG color="white" /> */}
                        <TextData
                            width="auto"
                            size="16px"
                            fontWeight="700"
                            color="#FFF7F4"
                            variant="regular"
                        >
                            {name}
                        </TextData>
                        {departments?.length > 0 && (
                            <span
                                style={{
                                    transition:
                                        "0.5s ease, transform 0.5s ease",
                                    transform: expanded
                                        ? "rotate(-180deg)"
                                        : "rotate(0deg)",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setExpanded((prev: any) => {
                                        let newArr = [...prev];
                                        newArr[index] = !prev[index];
                                        return newArr;
                                    });
                                }}
                            >
                                <ChevronSVG color="white" />
                            </span>
                        )}
                    </div>

                    <div className="banner-right">
                        {(depId || departments?.length === 0) && !published && (
                            <Button
                                height="40px"
                                width="auto"
                                backgroundColor="#fff"
                                padding="0px 15px"
                                style={{
                                    borderRadius: 4,
                                    gap: "12px",
                                }}
                                onClick={() =>
                                    handleOpenDelete(
                                        departments?.length === 0
                                            ? null
                                            : depId,
                                        name,
                                    )
                                }
                            >
                                <TextData
                                    variant="label"
                                    width="auto"
                                    color="#2a346d"
                                    size="14px"
                                    fontWeight="500"
                                    style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "5px",
                                    }}
                                >
                                    <TrashSVG color="#FB3640" />
                                    Supprimer
                                </TextData>
                            </Button>
                        )}
                        {(depId || departments?.length === 0) && published && (
                            <Button
                                height="40px"
                                width="auto"
                                backgroundColor="#fff"
                                padding="0px 15px"
                                style={{
                                    borderRadius: 4,
                                    gap: "12px",
                                }}
                                onClick={() =>
                                    handleOpenEdit(
                                        departments?.length === 0
                                            ? null
                                            : depId,
                                        name,
                                    )
                                }
                            >
                                <TextData
                                    variant="label"
                                    width="auto"
                                    color="#2a346d"
                                    size="14px"
                                    fontWeight="500"
                                    style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "5px",
                                    }}
                                >
                                    <EditSVG color="#FF9600" />
                                    Éditer
                                </TextData>
                            </Button>
                        )}
                        {(depId || departments?.length === 0) &&
                            period === "week" && (
                                <Button
                                    height="40px"
                                    width="auto"
                                    backgroundColor="#fff"
                                    padding="0px 15px"
                                    style={{
                                        borderRadius: 4,
                                        gap: "12px",
                                    }}
                                    onClick={() => {
                                        handleOpenDuplicate?.(
                                            depId ?? null,
                                            name,
                                        );
                                    }}
                                >
                                    <TextData
                                        variant="label"
                                        width="auto"
                                        color="#2a346d"
                                        size="14px"
                                        fontWeight="500"
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "5px",
                                        }}
                                    >
                                        <CopySVG color="#1A7290" />
                                        Dupliquer
                                    </TextData>
                                </Button>
                            )}
                        {(depId || departments?.length === 0) && (
                            <Button
                                height="40px"
                                width="auto"
                                backgroundColor="#fff"
                                padding="0px 15px"
                                style={{
                                    borderRadius: 4,
                                    gap: "12px",
                                }}
                                // onClick={() =>
                                //     handleOpenDownload(
                                //         planning.departments?.length === 0
                                //             ? null
                                //             : depId,
                                //         name,
                                //     )
                                // }
                                onClick={() => {
                                    if (departments?.length === 0)
                                        printPlanning();
                                    else if (depId) printPlanning(depId);
                                }}
                            >
                                <TextData
                                    variant="label"
                                    width="auto"
                                    color="#2a346d"
                                    size="14px"
                                    fontWeight="500"
                                    style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "5px",
                                    }}
                                >
                                    <DownloadSVG color="#FF6633" />
                                    Télécharger
                                </TextData>
                            </Button>
                        )}
                        {(depId || departments?.length === 0) && published && (
                            <div
                                className="published-planning"
                                style={{
                                    borderRadius: 4,
                                    gap: "12px",
                                    padding: "0px 16px",
                                    height: "40px",
                                    backgroundColor: "#00a42e",
                                }}
                            >
                                <TickSVG />
                                <TextData
                                    variant="label"
                                    width="auto"
                                    color="#ffffff"
                                    size="14px"
                                    fontWeight="500"
                                >
                                    Planning publié
                                </TextData>
                            </div>
                        )}
                        {(depId || departments?.length === 0) && !published && (
                            <Button
                                height="40px"
                                width="auto"
                                backgroundColor="#fff"
                                padding="0px 15px"
                                style={{
                                    borderRadius: 4,
                                    gap: "12px",
                                }}
                                onClick={() =>
                                    handleOpenPublish(
                                        departments?.length === 0
                                            ? null
                                            : depId,
                                        name,
                                    )
                                }
                            >
                                <TextData
                                    variant="label"
                                    width="auto"
                                    color="#2a346d"
                                    size="14px"
                                    fontWeight="500"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Publier le planning
                                </TextData>
                            </Button>
                        )}
                    </div>
                </div>
            </th>
        </tr>
    );
}
