import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import { useNavigate } from "react-router-dom";
import { ROUTER } from "../constants/env";
import { forgetPasswordServ } from "../redux/features/auth/service";
import { updateEmail } from "../redux/features/auth/authSlice";
export const useForgetPassword = () => {
    let auth = useAppSelector((state: any) => state.auth);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const setEmail = (email: string) => {
        dispatch(updateEmail({ email }));
    };

    const forgetPassword = async () => {
        dispatch(forgetPasswordServ({ email: auth.email })).then((res) => {
            if (res?.meta?.requestStatus === "fulfilled") {
                navigate(ROUTER.AUTH.CHECK_EMAIL);
            }
        });
    };

    return {
        auth,
        forgetPassword,
        setEmail,
    };
};
