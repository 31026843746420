import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    BranchSettingRequest,
    BranchSettingResponse,
    GetBranchSettingsPayload,
    getMultiplePlanningRequest,
    getTicketsRequest,
    getServicesRequest,
    getBonusTypesRequest,
    getAbsenceRequest,
    saveMultiplePlanningRequest,
    deleteMultiplePlanningRequest,
    multiplePlanningPayload,
    saveTicketsRequest,
    deleteTicketsRequest,
    saveServicesRequest,
    deleteServicesRequest,
    saveBonusTypesRequest,
    deleteBonusTypesRequest,
    saveAbsenceRequest,
    getTimeTrackerRequest,
    updateTimeTrackerRequest,
    updateMobileRequest,
    getMobileRequest,
    DPAE_Payload,
    getDPAERequest,
    updateDPAEinfoRequest,
    getAdministrativeRequest,
    updateAdministrativeRequest,
    CollectiveAgreement,
} from "./types";
import { API } from "../../../constants/env";
import { useLogin } from "../../../hooks/useLogin";
import { useBranch } from "../../../hooks/useBranch";

export const getListOfManagedBranchs = createAsyncThunk(
    "settings/branch/getListOfManagedBranchs",
    async (_, { rejectWithValue }) => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const res = await fetch(
                    API.client.settings.branch.getListOfManagedBranchs,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                    },
                )
                    .then((res) => {
                        return res.json();
                    })
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);

export const getBranch = createAsyncThunk(
    "settings/branch/get",
    async (payload: GetBranchSettingsPayload, { rejectWithValue }) => {
        if (payload.token && payload.branchID) {
            try {
                const res = await fetch(
                    API.client.settings.branch.get(payload.branchID),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
export const updateBranch = createAsyncThunk(
    "settings/branch/update",
    async (payload: BranchSettingRequest, { rejectWithValue }) => {
        if (payload.token && payload.branchID) {
            try {
                const res = await fetch(
                    API.client.settings.branch.update(payload.branchID),
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: JSON.stringify(payload.body),
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
export const updateSiteSettings = createAsyncThunk(
    "settings/branch/updateSiteSettings",
    async (payload: BranchSettingRequest, { rejectWithValue }) => {
        if (payload.token && payload.branchID) {
            try {
                const res = await fetch(
                    API.client.settings.branch.updateSiteSettings(
                        payload.branchID,
                    ),
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: JSON.stringify(payload.body),
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
export const fetchBranchSettings = createAsyncThunk(
    "settings/branch/fetchBranchSettings",
    async (payload: GetBranchSettingsPayload, { rejectWithValue }) => {
        if (payload.token && payload.branchID) {
            try {
                const res = await fetch(
                    API.client.settings.branch.getSiteSettings(
                        payload.branchID,
                    ),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
export const fetchPauseRepasConditions = createAsyncThunk(
    "settings/branch/fetchPauseRepasConditions",
    async (payload: any, { rejectWithValue }) => {
        if (payload.token && payload.branchID) {
            try {
                const res = await fetch(
                    API.client.settings.branch.getPauseRepasConditions(
                        payload.branchID,
                    ),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);

export const addPauseRepasCondition = createAsyncThunk(
    "settings/branch/addPauseRepasCondition",
    async (payload: any, { rejectWithValue }) => {
        console.log("   ppp = ", {
            ...payload.data,
            site: payload.branchID,
        });
        if (payload.token && payload.branchID) {
            let ob: any = { ...payload.data, site: payload.branchID };
            try {
                const res = await fetch(
                    API.client.settings.branch.createPauseRepasConditions,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: JSON.stringify(ob),
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
        return false;
    },
);

export const removePauseRepasCondition = createAsyncThunk(
    "settings/branch/removePauseRepasCondition",
    async (payload: any, { rejectWithValue }) => {
        if (payload.token && payload.conditionID) {
            try {
                const res = await fetch(
                    API.client.settings.branch.deletePauseRepasConditions(
                        payload.conditionID,
                    ),
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: JSON.stringify({}),
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);

export const getMultiplePlanning = createAsyncThunk(
    "settings/schedule/getMultiplePlanning",
    async (payload: getMultiplePlanningRequest, { rejectWithValue }) => {
        if (payload.branchID && payload.token) {
            try {
                const res = await fetch(
                    API.client.settings.schedule.getMultiplePlanning(
                        payload.branchID,
                    ),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
export const saveMultiplePlanning = createAsyncThunk(
    "settings/schedule/saveMultiplePlanning",
    async (payload: saveMultiplePlanningRequest, { rejectWithValue }) => {
        if (payload.token && payload.multiplePlanning) {
            try {
                const res = await fetch(
                    API.client.settings.schedule.saveMultiplePlanning,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: JSON.stringify(payload.multiplePlanning),
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
export const deleteMultiplePlanning = createAsyncThunk(
    "settings/schedule/deleteMultiplePlanning",
    async (payload: deleteMultiplePlanningRequest, { rejectWithValue }) => {
        if (payload.token && payload.planningID) {
            try {
                const res = await fetch(
                    API.client.settings.schedule.deleteMultiplePlanning(
                        payload.planningID,
                    ),
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
export const getTicket = createAsyncThunk(
    "settings/schedule/getTickets",
    async (payload: getTicketsRequest, { rejectWithValue }) => {
        if (payload.branchID && payload.token) {
            try {
                const res = await fetch(
                    API.client.settings.schedule.getTickets(payload.branchID),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
export const saveTicket = createAsyncThunk(
    "settings/schedule/saveTickets",
    async (payload: saveTicketsRequest, { rejectWithValue }) => {
        if (payload.ticket && payload.token) {
            try {
                const res = await fetch(
                    API.client.settings.schedule.saveTickets,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: JSON.stringify(payload.ticket),
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }

                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
        return;
    },
);
export const removeTicket = createAsyncThunk(
    "settings/schedule/deleteTickets",
    async (payload: deleteTicketsRequest, { rejectWithValue }) => {
        if (payload.ticketID && payload.token) {
            try {
                const res = await fetch(
                    API.client.settings.schedule.deleteTickets(
                        payload.ticketID,
                    ),
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }

                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
        return;
    },
);

export const getService = createAsyncThunk(
    "settings/schedule/getServices",
    async (payload: getServicesRequest, { rejectWithValue }) => {
        if (payload.branchID && payload.token) {
            try {
                const res = await fetch(
                    API.client.settings.schedule.getServices(payload.branchID),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
export const saveService = createAsyncThunk(
    "settings/schedule/saveServices",
    async (payload: saveServicesRequest, { rejectWithValue }) => {
        if (payload.service && payload.token) {
            try {
                const res = await fetch(
                    API.client.settings.schedule.saveServices,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: JSON.stringify(payload.service),
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }

                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
        return;
    },
);
export const removeService = createAsyncThunk(
    "settings/schedule/deleteServices",
    async (payload: deleteServicesRequest, { rejectWithValue }) => {
        if (payload.serviceID && payload.token) {
            try {
                const res = await fetch(
                    API.client.settings.schedule.deleteServices(
                        payload.serviceID,
                    ),
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }

                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
        return;
    },
);

export const getBonusType = createAsyncThunk(
    "settings/schedule/getBonusTypes",
    async (payload: getBonusTypesRequest, { rejectWithValue }) => {
        if (payload.branchID && payload.token) {
            try {
                const res = await fetch(
                    API.client.settings.schedule.getBonusTypes(
                        payload.branchID,
                    ),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
export const generateBonusType = createAsyncThunk(
    "settings/schedule/createBonusTypes",
    async (payload: saveBonusTypesRequest, { rejectWithValue }) => {
        if (payload.token && payload.bonusType) {
            try {
                const res = await fetch(
                    API.client.settings.schedule.createBonusTypes,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: JSON.stringify(payload.bonusType),
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }

                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
        return;
    },
);
export const modifyBonusType = createAsyncThunk(
    "settings/schedule/editBonusTypes",
    async (payload: saveBonusTypesRequest, { rejectWithValue }) => {
        if (payload.token && payload.bonusType) {
            try {
                const res = await fetch(
                    API.client.settings.schedule.editBonusTypes,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: JSON.stringify(payload.bonusType),
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }

                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
        return;
    },
);
export const removeBonusType = createAsyncThunk(
    "settings/schedule/deleteBonusTypes",
    async (payload: deleteBonusTypesRequest, { rejectWithValue }) => {
        if (payload.bonusTypeID && payload.token) {
            try {
                const res = await fetch(
                    API.client.settings.schedule.deleteBonusTypes,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: JSON.stringify({
                            acompteTypeId: payload.bonusTypeID,
                        }),
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
        return;
    },
);
export const getAbsence = createAsyncThunk(
    "settings/schedule/getAbsence",
    async (payload: getAbsenceRequest, { rejectWithValue }) => {
        if (payload.branchID && payload.token) {
            try {
                const res = await fetch(
                    API.client.settings.schedule.getAbsence(payload.branchID),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
export const modifyAbsence = createAsyncThunk(
    "settings/schedule/updateAbsence",
    async (payload: saveAbsenceRequest, { rejectWithValue }) => {
        if (payload.token && payload.absence) {
            try {
                const res = await fetch(
                    API.client.settings.schedule.editAbsence,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: JSON.stringify(payload.absence),
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(
                                res.message || "something went wrong",
                            );
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);

// TIME TRACKER
export const getTimeTrackerService = createAsyncThunk(
    "settings/timeTracker/getTimeTrackerService",
    async (payload: getTimeTrackerRequest, { rejectWithValue }) => {
        if (payload.branchID && payload.token) {
            try {
                const res = await fetch(
                    API.client.settings.timeTracker.getTimeTrackerService(
                        payload.branchID,
                    ),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });

                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
export const getMobileService = createAsyncThunk(
    "settings/mobile/getMobileService",
    async (payload: getMobileRequest, { rejectWithValue }) => {
        if (payload.branchID && payload.token) {
            try {
                const res = await fetch(
                    API.client.settings.mobile.getMobileService(
                        payload.branchID,
                    ),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
export const updateTimeTrackerService = createAsyncThunk(
    "settings/timeTracker/updateTimeTrackerSettings",
    async (payload: any /*updateTimeTrackerRequest*/, { rejectWithValue }) => {
        if (payload.branchID && payload.token && payload.timeTracker) {
            try {
                const res = await fetch(
                    API.client.settings.timeTracker.updateTimeTrackerService(
                        payload.branchID,
                    ),
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: JSON.stringify(payload.timeTracker),
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(
                                res.message || "something went wrong",
                            );
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
export const updateMobileService = createAsyncThunk(
    "settings/mobile/updateMobileService",
    async (payload: updateMobileRequest, { rejectWithValue }) => {
        if (payload.branchID && payload.token && payload.mobile) {
            try {
                const res = await fetch(
                    API.client.settings.mobile.updateMobileService(
                        payload.branchID,
                    ),
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: JSON.stringify(payload.mobile),
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(
                                res.message || "something went wrong",
                            );
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);

// DPAE
export const getDPAEService = createAsyncThunk(
    "settings/dpae/getDPAEService",
    async (payload: getDPAERequest, { rejectWithValue }) => {
        if (payload.branchID && payload.token) {
            try {
                const res = await fetch(
                    API.client.settings.administrative.getDPAE(
                        payload.branchID,
                    ),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(
                                res.message || "something went wrong",
                            );
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                console.log("rejected :", err);
                return rejectWithValue(err);
            }
        }
    },
);
export const updateDPAEinfo = createAsyncThunk(
    "settings/dpae/updateDPAEinfo",
    async (payload: updateDPAEinfoRequest, { rejectWithValue }) => {
        try {
            if (payload.branchID && payload.token && payload.body) {
                console.log("payload.branchID:", payload.branchID);
                console.log("payload.token:", payload.token);
                console.log("payload.body:", payload.body);
                const res = await fetch(
                    API.client.settings.administrative.updateDPAE(
                        payload.branchID,
                    ),
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: JSON.stringify(payload.body),
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        return res;
                    });
                return res;
            }
        } catch (err) {
            console.log("rejected :", err);
            return rejectWithValue(err);
        }
    },
);
// Contract Templates
export const getContractTemplateTagsService = createAsyncThunk(
    "settings/contract-template/tags",
    async (payload: any, { rejectWithValue }) => {
        if (payload.token) {
            try {
                const res = await fetch(
                    API.client.settings.administrative.getContractTemplateTags,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                console.log("rejected :", err);
                return rejectWithValue(err);
            }
        }
    },
);
export const getContractTemplateBySiteService = createAsyncThunk(
    "settings/contract-templates",
    async (payload: any, { rejectWithValue }) => {
        if (payload.token && payload.branchID) {
            try {
                const res = await fetch(
                    API.client.settings.administrative.getContractTemplates(
                        payload.branchID,
                    ),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                console.log("rejected :", err);
                return rejectWithValue(err);
            }
        }
    },
);
export const postNewContractTemplateService = createAsyncThunk(
    "settings/contract-template/create",
    async (payload: any, { rejectWithValue }) => {
        if (payload.token && payload.branchID) {
            try {
                const formData = new FormData();
                formData.append("file", payload.file);
                formData.append("name", payload.name);
                formData.append("type", payload.type);
                const res = await fetch(
                    API.client.settings.administrative.postNewContractTemplate(
                        payload.branchID,
                    ),
                    {
                        method: "POST",
                        headers: {
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: formData,
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                console.log("rejected :", err);
                return rejectWithValue(err);
            }
        }
    },
);

export const updateContractTemplateService = createAsyncThunk(
    "settings/contract-template/update",
    async (payload: any, { rejectWithValue }) => {
        if (payload.token) {
            try {
                const formData = new FormData();
                formData.append("file", payload.file);
                formData.append("name", payload.name);
                formData.append("type", payload.type);
                const res = await fetch(
                    API.client.settings.administrative.updateContractTemplate(
                        payload.idContractTemplate,
                    ),
                    {
                        method: "PUT",
                        headers: {
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: formData,
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                console.log("rejected :", err);
                return rejectWithValue(err);
            }
        }
    },
);
export const deleteContractTemplateService = createAsyncThunk(
    "settings/contract-template/delete",
    async (payload: any, { rejectWithValue }) => {
        if (payload.token) {
            try {
                const res = await fetch(
                    API.client.settings.administrative.deleteContractTemplate(
                        payload.idContractTemplate,
                    ),
                    {
                        method: "DELETE",
                        headers: {
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                console.log("rejected :", err);
                return rejectWithValue(err);
            }
        }
    },
);
// administrative services
export const getAdministrativeService = createAsyncThunk(
    "settings/administrative/getAdminService",
    async (payload: getAdministrativeRequest, { rejectWithValue }) => {
        if (payload.branchID && payload.token) {
            try {
                const res = await fetch(
                    API.client.settings.administrative.getAdministrativeService(
                        payload.branchID,
                    ),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
export const updateAdministrativeService = createAsyncThunk(
    "settings/administrative/updateAdminService",
    async (payload: updateAdministrativeRequest, { rejectWithValue }) => {
        if (payload.branchID && payload.token && payload.administrative) {
            try {
                const res = await fetch(
                    API.client.settings.administrative.updateAdminService(
                        payload.branchID,
                    ),
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: JSON.stringify(payload.administrative),
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(
                                res.message || "something went wrong",
                            );
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
// vacation services
export const getVacationService = createAsyncThunk(
    "settings/vacation/getVacationService",
    async (payload: any, { rejectWithValue }) => {
        if (payload.branchID && payload.token) {
            try {
                const res = await fetch(
                    API.client.settings.vacation.getVacationService(
                        payload.branchID,
                    ),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);
export const updateVacationService = createAsyncThunk(
    "settings/vacation/updateVacationService",
    async (payload: any, { rejectWithValue }) => {
        console.log("updateVac : ", payload);
        if (
            payload.branchID &&
            payload.token &&
            (payload.entitlementCalculationDate ||
                payload.enableLeaveRuleEntitlement !== undefined)
        ) {
            console.log("update ", payload.enableLeaveRuleEntitlement);
            try {
                const res = await fetch(
                    API.client.settings.vacation.updateVacationService(
                        payload.branchID,
                    ),
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: JSON.stringify({
                            entitlementCalculationDate:
                                payload.entitlementCalculationDate,
                            enableLeaveRuleEntitlement:
                                payload.enableLeaveRuleEntitlement,
                        }),
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(
                                res.message || "something went wrong",
                            );
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);

export const getAllCollectiveAgreementsService = createAsyncThunk(
    "settings/administrative/getAllCollectiveAgreements",
    async (payload: { token: string | null }, { rejectWithValue }) => {
        if (payload.token) {
            try {
                const res = await fetch(
                    API.client.settings.administrative
                        .getAllCollectiveAgreements,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                    },
                )
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            throw new Error(res.message);
                        }
                        return res;
                    });
                return res;
            } catch (err) {
                return rejectWithValue(err);
            }
        }
    },
);

export const updateSelectedCollectiveAgreementService = createAsyncThunk(
    "settings/administrative/updateSelectedCollectiveAgreement",
    async (payload: any, { rejectWithValue }) => {
        if (payload.token && payload.siteId && payload.collectiveAgreementId) {
            try {
                const response = await fetch(
                    API.client.settings.administrative
                        .updateSelectedCollectiveAgreement,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${payload.token}`,
                        },
                        body: JSON.stringify({
                            siteId: payload.siteId,
                            collectiveAgreementId:
                                payload.collectiveAgreementId,
                        }),
                    },
                );
                const data = await response.json();
                return data;
            } catch (error) {
                return rejectWithValue(error);
            }
        }
    },
);
