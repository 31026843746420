import * as React from "react";
import classnames from "classnames";
import "./style.scss";
import { ReactComponent as IconSVG } from "./icon.svg";
import { ReactComponent as DownloadSVG } from "./download.svg";
import { ReactComponent as EditSVG } from "./edit.svg";
import { ReactComponent as DeleteSVG } from "./trash.svg";
import Modal from "../Modal";
import UploadContractTemplate from "../../../pages/Settings/Elements/AdministrativeElements/UploadContractTemplate";
import NewContractTemplateCard from "../../organism/NewContractTemplateCard";
import { useContractTemplate } from "../../../hooks/useContractTemplate";
import Swal from "sweetalert2";
export default function ContractTemplateWidget(
    props: ContractTemplateWidgetProps,
) {
    const { id, url, type, title, fileType, tags, style } = props;

    const {
        getContractTemplates,
        setContractTemplate,
        updateContractTemplate,
        deleteContractTemplate,
    } = useContractTemplate();

    const [file, setFile] = React.useState(null);
    const [openEdit, setOpenEdit] = React.useState(false);

    const handleUploadFile = (file: any) => {
        setFile(file);
    };
    const handleTitle = (name: string) => {
        setContractTemplate({ name });
    };
    const handleType = (type: string) => {
        setContractTemplate({ type });
    };
    const handleOnCancelButton = () => {
        setContractTemplate({
            name: "",
            type: "",
        });
        setOpenEdit(false);
    };
    const handleOnSubmitButton = async () => {
        const res = await updateContractTemplate(id, file);
        if (res) {
            setOpenEdit(false);
            setContractTemplate({ name: "", type: "" });
            Swal.fire({
                title: "Succés!",
                text: "Votre modéle a été modifier avec succés!",
                icon: "success",
            });
        } else {
            Swal.fire({
                title: "Erreur!",
                text: "Une erreur au niveau serveur!",
                icon: "error",
                confirmButtonText: "Ok",
            });
        }
    };
    const handleEditPopup = () => {
        setContractTemplate({
            name: title,
            type,
        });
        setOpenEdit(true);
    };
    const handleDownload = () => {
        window.open(url);
    };
    const handleDelete = async () => {
        await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await deleteContractTemplate(id);
                if (res) {
                    Swal.fire(
                        "Deleted!",
                        "Your file has been deleted.",
                        "success",
                    );
                    getContractTemplates();
                } else {
                    Swal.fire({
                        title: "Erreur!",
                        text: "Une erreur au niveau serveur!",
                        icon: "error",
                        confirmButtonText: "Ok",
                    });
                }
            }
        });
    };
    return (
        <div className="contract-widget-container">
            <div className="widget-icon">
                <IconSVG />
            </div>
            <div className="widget-content">
                <div className="top-content" style={style?.header}>
                    <div>{`${type} : ${title}`}</div>
                    <div>{`${fileType}`}</div>
                </div>
                {/* <div className='bottom-content' style={style?.tags}>
                    {`tags: ${tags.map((tag) => ` ${tag}`)}`}
                </div> */}
            </div>
            <div className="widget-download-icon">
                <DownloadSVG
                    onClick={handleDownload}
                    style={{ cursor: "pointer" }}
                />
                <EditSVG
                    onClick={handleEditPopup}
                    style={{ cursor: "pointer" }}
                />
                <DeleteSVG
                    onClick={handleDelete}
                    style={{ cursor: "pointer" }}
                />
            </div>
            <Modal modalEnabled={openEdit} onClose={handleOnCancelButton}>
                <NewContractTemplateCard
                    headerCardTitle="Importer un modéle de contrat"
                    display={openEdit}
                    onClose={handleOnCancelButton}
                    onClickButton1={handleOnCancelButton}
                    onClickButton2={handleOnSubmitButton}
                    setUploadFile={handleUploadFile}
                    setTitle={handleTitle}
                    setType={handleType}
                    title={title}
                    type={type}
                    button2Text="Modifier"
                />
            </Modal>
        </div>
    );
}

interface ContractTemplateWidgetProps {
    id?: string;
    url?: string;
    type: string;
    title: string;
    fileType: string;
    tags: string[];
    style?: {
        header?: React.CSSProperties;
        tags?: React.CSSProperties;
    };
    onDownload?: (url: any) => void;
    onEdit?: (idContractTemplate: any, file: any) => void;
    onDelete?: (idContractTemplate: any) => void;
}

ContractTemplateWidget.defaultProps = {
    type: "CDI",
    title: "Contrat de travail à durée indéterminée",
    fileType: "DOCX",
    tags: ["file", "filetype", "file", "filetype"],
    onDownload: () => {
        console.log("download");
    },
};
