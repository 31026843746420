import * as React from "react";
import classnames from "classnames";
import "./style.scss";
import TextData from "../TextData";
import { ReactComponent as CheckboxSVG } from "./checkbox.svg";
export default function CircledRadio(props: CircledRadioProps) {
    const { selected, onClick, id, text, thisDay } = props;
    const [clicked, setClicked] = React.useState(selected);
    React.useEffect(() => {
        setClicked(selected);
    }, [selected]);
    const handleClicked = (e: any) => {
        onClick && onClick(id, !clicked);
        setClicked(!clicked);
    };
    const checkedClasses = classnames("circled-radio", { checked: clicked });
    return (
        <div style={{ position: "relative", display: "inline-block" }}>
            <div className={checkedClasses} onClick={handleClicked}>
                <TextData
                    variant='label'
                    width='auto'
                    color={clicked ? "#2A8BAB" : "#6F78AB"}
                    fontWeight={clicked ? "500" : "400"}
                    size='12px'
                    style={{
                        cursor: "pointer",
                    }}
                >
                    {text}
                </TextData>
                {clicked && <CheckboxSVG className='checkbox-icon' />}
            </div>
        </div>
    );
}
interface CircledRadioProps {
    id: any;
    thisDay: any;
    text: string;
    selected: boolean;
    onClick?: (data: any, data2: any) => void;
}
CircledRadio.defaultProps = {
    // thisDay: "-1",
    text: "Radio",
    name: "day",
    selected: true,
    onClick: (data: any, data2: any) => {
        console.log(data);
    },
};
