import moment from "moment";
import React from "react";

export function AddDateColumnFormatter(content: any, rowIdx: number, row: any) {
  return (
    <span className="text-n-800 text-md font-light">
      {moment(content).format("DD MMMM YYYY")}
    </span>
  );
}
