import React from "react";
import ChangePassword from "../../../components/templates/EmployeesEdit/ChangePassword";
import { destructFields, generateFields } from "../../../_helpers/FormHelpers";
import { useEmployees } from "../../../hooks/useEmployees";
import { useIntl } from "react-intl";
import { useNotificationContext } from "../../../Context/Notification";

interface Errors {
    lowerCase: boolean;
    number: boolean;
    special: boolean;
    length: boolean;
}

const LOWER_UPPER_CASE_PATERN = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
const NUMBER_PATERN = /^(?=.*\d).+$/;
const SPECIAL_PATERN =
    /^(?=.*[!,@,#,$,%,^,&,(,),_,-,+,*,/,|,<,>,?,!,[,€,.]).+$/;

const initValues = [
    { name: "current", value: "" },
    { name: "new", value: "", hasError: true },
    { name: "repeat", value: "" },
];

export default function ChangePasswordPage() {
    const { editEmployeePassword, employeeForEdit } = useEmployees();
    const [password, setPassword] = React.useState(generateFields(initValues));
    const [errors, setErrors] = React.useState<Errors>({
        lowerCase: true,
        number: true,
        special: true,
        length: true,
    });
    const onChange = (field: string, value: any) => {
        const newState = {
            ...password,
            [field]: {
                ...password[field],
                value,
            },
        };
        validate(newState);
    };

    const validate = (state: any) => {
        let errors = {
            lowerCase: false,
            number: false,
            special: false,
            length: false,
        };
        let newState: any = {
            current: { ...state.current },
        };
        const newPassword = state.new.value;
        const repeatPassword = state.repeat.value;
        if (!LOWER_UPPER_CASE_PATERN.test(newPassword)) {
            errors["lowerCase"] = true;
        }
        if (!NUMBER_PATERN.test(newPassword)) {
            errors["number"] = true;
        }
        if (!SPECIAL_PATERN.test(newPassword)) {
            errors["special"] = true;
        }
        if (newPassword.length < 8 || newPassword.length > 40) {
            errors["length"] = true;
        }
        setErrors(errors);
        if (
            errors.length ||
            errors.lowerCase ||
            errors.number ||
            errors.special
        ) {
            newState["new"] = {
                ...state.new,
                hasError: true,
            };
        } else {
            newState["new"] = {
                ...state.new,
                hasError: false,
            };
        }
        if (repeatPassword !== newPassword) {
            newState["repeat"] = {
                ...state.repeat,
                hasError: true,
                errors: "Dois",
            };
        } else {
            newState["repeat"] = {
                ...state.repeat,
                hasError: false,
                error: "Ne correspond pas au nouveau mot de passe",
            };
        }
        setPassword(newState);
    };

    const intl = useIntl();
    const { addNotification } = useNotificationContext();
    const onSubmit = () => {
        const values = destructFields(password);
        editEmployeePassword({
            userId: employeeForEdit._id,
            newPassword: values.new,
            oldPassword: values.current,
        }).then((res) => {
            const data: any = res.payload;
            if (data.status === "success") {
                addNotification({
                    title: intl.formatMessage({
                        id: "EMPLOYEE.EDIT.PASSWORD.NOTIFICATION.SUCCESS.TITLE",
                    }),
                    message: intl.formatMessage({
                        id: "EMPLOYEE.EDIT.PASSWORD.NOTIFICATION.SUCCESS.MESSAGE",
                    }),
                    type: "success",
                });
            } else if (data.status === "fail") {
                addNotification({
                    title: intl.formatMessage({
                        id: "EMPLOYEE.EDIT.PASSWORD.NOTIFICATION.FAIL.TITLE",
                    }),
                    message: intl.formatMessage({
                        id: "EMPLOYEE.EDIT.PASSWORD.NOTIFICATION.FAIL.MESSAGE",
                    }),
                    type: "fail",
                });
            }
        });
    };

    const onBlur = (field: string) => {
        setPassword((prev) => ({
            ...prev,
            [field]: {
                ...prev[field],
                touched: true,
            },
        }));
    };

    return (
        <div>
            <ChangePassword
                onSubmit={onSubmit}
                password={password}
                onChange={onChange}
                onBlur={onBlur}
                errors={errors}
            />
        </div>
    );
}
