import React from "react";
import { useIntl } from "react-intl";
import Button from "../../atoms/ZButton";
import { AddSquare } from "../../../Icons";

export default function EmployeesNoRecord({
    openCreateEmployesDialog,
    active,
}: EmployeesNoRecordProps) {
    const intl = useIntl();
    return (
        <div className="max-w-[520px] mx-auto py-8">
            <div className="flex flex-col items-center gap-6">
                <img src="/assets/imgs/no-employees-record.png" />
                <h2 className="font-bold text-5xl text-n-700 text-center">
                    {intl.formatMessage({
                        id: active
                            ? "EMPLOYEES.ACTIVE.NO_RECROD_TITLE"
                            : "EMPLOYEES.ARCHIVED.NO_RECROD_TITLE",
                    })}
                </h2>
                <p className="text-n-500 text-md text-center">
                    Bienvenue sur Esperoo ! Commencez par créer votre premier
                    utilisateur pour accéder à toutes nos fonctionnalités.
                </p>
                {active && (
                    <Button
                        leftComponent={<AddSquare />}
                        size="lg"
                        variant="primary"
                        title={intl.formatMessage({
                            id: "MENU.EMPLOYEES.ADD_EMPLOYEE",
                        })}
                        onClick={openCreateEmployesDialog}
                    />
                )}
            </div>
        </div>
    );
}

interface EmployeesNoRecordProps {
    openCreateEmployesDialog: () => void;
    active: boolean;
}
