import * as React from "react";
import Footer from "../../molecules/Footer";
import TopBarFirstStep from "../../molecules/TopBar/FirstStep";

import "./style.scss";

export default function Startup(props: StartupProps) {
    const { children } = props;
    return (
        <div className='startup-container'>
            <div className='startup-topbar-container'>
                <TopBarFirstStep />
            </div>
            {children}
            <Footer />
        </div>
    );
}
interface StartupProps {
    children?: React.ReactNode;
}

Startup.defaultProps = {
    children: "Not Found",
};
